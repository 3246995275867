/** @format */

'use strict';

angular.module('emsApp').controller('InstructorDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.instructor = entity;
	$scope.objectName = 'Instructor';
	$scope.instructorId = $stateParams.instructorId;

	if (angular.isUndefined($rootScope.instructorActiveTab)) {
		//set the default here
		$rootScope.instructorActiveTab = 0;
	}

	$scope.goToEditState = function(instructor) {
		$state.go('instructor.detail.edit({instructorId:instructor.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:instructorUpdate', function(event, result) {
		$scope.instructor = result;
	});
});
