/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('UserSalesFilterEventTypeListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		SalesFilterEventTypeFactory,
		UserSalesFilterEventTypeListDtoSearchFactory,
		UserSalesFilterEventTypeViewDtoFactory
	) {
		$scope.salesFilterEventTypeList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeSalesFilterEventType)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeSalesFilterEventType);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeSalesFilterEventType = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('user-salesfiltereventtype.new');
		};

		var getSalesFilterId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SalesFilterEventTypeSearchOverview.salesFilter)) {
				return $scope.SalesFilterEventTypeSearchOverview.salesFilter.id;
			} else {
				return undefined;
			}
		};

		var getEventTypeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SalesFilterEventTypeSearchOverview.eventType)) {
				return $scope.SalesFilterEventTypeSearchOverview.eventType.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.SalesFilterEventTypeSearchOverview = angular.copy($scope.SalesFilterEventTypeSearchOverview);

			$rootScope.pageSizeSalesFilterEventType = angular.copy($scope.pageSize);

			UserSalesFilterEventTypeListDtoSearchFactory.query(
				{
					page: $scope.SalesFilterEventTypeSearchOverview.page - 1,
					size: $scope.pageSize,
					salesFilterId: getSalesFilterId(),
					eventTypeId: getEventTypeId(),

					sort: [
						$scope.SalesFilterEventTypeSearchOverview.predicate +
							',' +
							($scope.SalesFilterEventTypeSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.salesFilterEventTypeList = result.items;

					$scope.salesFilterReferenceFilterList = result.salesFilterSearchOptions;

					$scope.eventTypeReferenceFilterList = result.eventTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isSalesFilterEventTypeSearchOverviewDirty = function() {
			return !angular.equals($scope.SalesFilterEventTypeSearchOverview, $scope.originalSalesFilterEventTypeSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.SalesFilterEventTypeSearchOverview = {};
			$rootScope.SalesFilterEventTypeSearchOverview.page = 1;
			$rootScope.SalesFilterEventTypeSearchOverview.predicate = 'id';
			$rootScope.SalesFilterEventTypeSearchOverview.ascending = false;

			$scope.SalesFilterEventTypeSearchOverview = angular.copy($rootScope.SalesFilterEventTypeSearchOverview);

			$scope.originalSalesFilterEventTypeSearchOverview = angular.copy($scope.SalesFilterEventTypeSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.SalesFilterEventTypeSearchOverview) || $rootScope.SalesFilterEventTypeSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.SalesFilterEventTypeSearchOverview = angular.copy($rootScope.SalesFilterEventTypeSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.SalesFilterEventTypeSearchOverview.predicate === itemName) {
				if ($scope.SalesFilterEventTypeSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
