/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('emsApp')
	.controller('UserEventEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		UserEventEditDtoFactory,
		AlertService,
		HistoryService,
		UserEventEditDtoSaveAndCreateFactory
	) {
		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.event = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.event['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.event) && $rootScope.isDefinedAndNotNull($scope.event.id);
		};

		var onSaveAndNewFinishedCallback = function(result) {
			// navigate to new state with result (a new entity, but with the copied values from the previous)
			$state.go('user-event.new', {
				dataModel: result,
				mappedBy: $stateParams.mappedBy,
				mappedById: $stateParams.mappedById,
			});
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:eventUpdate', result);
			$scope.submitButtonIsActive = true;
			// process success result, if necessary
			if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
				console.log('as we came on this create new page from plus button we will update history');

				// set relation as reference in the referredBy data model
				$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;

				// update previous state
				HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
			}
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			UserEventEditDtoFactory.update($scope.event, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		$scope.saveAndNew = function() {
			$scope.submitButtonIsActive = false;

			// To prevent navigating back to this current view, remove it from history.
			HistoryService.removeHistory();

			if ($scope.event.id != null) {
				UserEventEditDtoSaveAndCreateFactory.updateAndNew($scope.event, onSaveAndNewFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				UserEventEditDtoSaveAndCreateFactory.createAndNew($scope.event, onSaveAndNewFinishedCallback, saveOrUpdateErrorCallback);
			}
		};

		// triggered when click a plus button, navigates to creating a new templateEvent of type TemplateEvent
		// once TemplateEvent is saved, history is updated and the user automatically navigates back to this edit page for Event
		$scope.createTemplateEvent = function() {
			var referredBy = {
				refName: 'templateEvent',
				data: $scope.event,
			};

			// navigating to new templateEvent will add this new event state to the history stack
			$state.go($state.current.name + '.newTemplateEvent', { referredBy: referredBy }, { notify: true });
		};

		// triggered when click a plus button, navigates to creating a new location of type Location
		// once Location is saved, history is updated and the user automatically navigates back to this edit page for Event
		$scope.createLocation = function() {
			var referredBy = {
				refName: 'location',
				data: $scope.event,
			};

			// navigating to new location will add this new event state to the history stack
			$state.go($state.current.name + '.newLocation', { referredBy: referredBy }, { notify: true });
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};

		/**
		 * Tells caller whether the current shown screen (and state) is in edit mode, hence a dialog is shown
		 */
		$scope.isInEditState = function() {
			// check on stateName: *.edit  or  *.detail.edit
			return $state.current.name.toUpperCase().endsWith('EDIT');
		};
	});
