/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('LocationFactory', function($resource) {
	return $resource(
		'api/locations/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/* A custom hook can be implemented here custom/jhipster/factory/create_custom_edit_factory.ftl */
/**
 * Factory to get / update an instance of UserLocationEditDto
 */
angular.module('emsApp').factory('UserLocationEditDtoFactory', function($resource) {
	return $resource(
		'api/user/location/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of UserLocationEditDto
 * with defaults from server
 */
angular.module('emsApp').factory('UserLocationNewDtoFactory', function($resource) {
	return $resource(
		'api/user/location/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewLocationFactory', function($resource) {
	return $resource(
		'api/locations/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Locations without the use of paging
angular.module('emsApp').factory('NoPagingLocationFactory', function($resource) {
	return $resource(
		'api/locations/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('EventsByLocationFactory', function($resource) {
	return $resource(
		'api/events/location/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('DocumentsByLocationFactory', function($resource) {
	return $resource(
		'api/documents/location/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllLocationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/locations/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
