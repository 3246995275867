// CodeComposer:ignore
/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('OrderLineTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		OrderLineFactory,
		FindAllOrderLinesByQueryParamsFactory,
		AutocompleteBookingsByQueryParamsFactory,
		NoPagingOrderLineFactory,
		NoPagingBookingFactory,
		NoPagingSubscriptionFactory,
		NoPagingArticleFactory
	) {
		$scope.orderLineList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.page = 1;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeOrderLine)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeOrderLine);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeOrderLine = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newOrderLine', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(orderLine) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editOrderLine', {
				orderLineId: orderLine.id,
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
			});
		};

		$scope.getOrderLineList = function() {
			$rootScope.$emit('emsApp:bookingDetailsRefreshTrigger');
			FindAllOrderLinesByQueryParamsFactory.query(
				{
					bookingId: angular.isDefined($scope.bookingId) ? $scope.bookingId : null,
					// Searching OrderLine by subscription supports multiple
					subscriptionIdList: angular.isDefined($scope.subscriptionId) ? [$scope.subscriptionId] : [],
					articleId: angular.isDefined($scope.articleId) ? $scope.articleId : null,

					page: $scope.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.orderLineList = result;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getOrderLineList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedOrderLineId = id;
			$('#deleteOrderLineConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			OrderLineFactory.delete(
				{ id: $scope.selectedOrderLineId },
				function() {
					$scope.loadAll();
					$('#deleteOrderLineConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteOrderLineConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
