/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('ArticleTypeFactory', function($resource) {
	return $resource(
		'api/articleTypes/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewArticleTypeFactory', function($resource) {
	return $resource(
		'api/articleTypes/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all ArticleTypes without the use of paging
angular.module('emsApp').factory('NoPagingArticleTypeFactory', function($resource) {
	return $resource(
		'api/articleTypes/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PredefinedArticleTypes Enum values without the use of paging
angular.module('emsApp').factory('NoPagingPredefinedArticleTypeFactory', function($resource) {
	return $resource(
		'api/articleType/predefinedArticleTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('ArticlesByArticleTypeFactory', function($resource) {
	return $resource(
		'api/articles/articleType/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllArticleTypesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/articleTypes/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllArticleTypesByQueryParamsNonPageableFactory', function($resource) {
	return $resource(
		'api/query/articleTypesNonPageable/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
