/** @format */

(function() {
	'use strict';

	angular.module('emsApp').controller('NavbarController', NavbarController);

	NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService'];

	function NavbarController($state, Auth, Principal, ProfileService, LoginService) {
		var vm = this;

		vm.isNavbarCollapsed = true;
		vm.isAuthenticated = Principal.isAuthenticated;

		ProfileService.getProfileInfo().then(function(response) {
			// [dmitro] I'm receiving runtime exceptions because of this code. Response sometimes is undefined
			if (response) {
				vm.inProduction = response.inProduction;
				vm.swaggerEnabled = response.swaggerEnabled;
			}
		});

		vm.login = login;
		vm.logout = logout;
		vm.toggleNavbar = toggleNavbar;
		vm.collapseNavbar = collapseNavbar;
		vm.$state = $state;

		function login() {
			collapseNavbar();
			LoginService.open();
		}

		function logout() {
			collapseNavbar();
			Auth.logout();
			$state.go('home');
		}

		function toggleNavbar() {
			vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
		}

		function collapseNavbar() {
			vm.isNavbarCollapsed = true;
		}
	}
})();
