/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('UserArticleListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ArticleFactory,
		UserArticleListDtoSearchFactory,
		UserArticleViewDtoFactory
	) {
		$scope.articleList = [];
		$scope.selectedArticleList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeArticle)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeArticle);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeArticle = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('user-article.new');
		};

		var getArticleTypeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.ArticleSearchOverview.articleType)) {
				return $scope.ArticleSearchOverview.articleType.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.ArticleSearchOverview = angular.copy($scope.ArticleSearchOverview);

			$rootScope.pageSizeArticle = angular.copy($scope.pageSize);

			UserArticleListDtoSearchFactory.query(
				{
					page: $scope.ArticleSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.ArticleSearchOverview.name,
					articleTypeId: getArticleTypeId(),
					vatType: $scope.ArticleSearchOverview.vatType,

					sort: [$scope.ArticleSearchOverview.predicate + ',' + ($scope.ArticleSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.articleList = result.items;

					$scope.articleTypeReferenceFilterList = result.articleTypeSearchOptions;

					$scope.vatTypeList = result.vatTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isArticleSearchOverviewDirty = function() {
			return !angular.equals($scope.ArticleSearchOverview, $scope.originalArticleSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.ArticleSearchOverview = {};
			$rootScope.ArticleSearchOverview.page = 1;
			$rootScope.ArticleSearchOverview.predicate = 'id';
			$rootScope.ArticleSearchOverview.ascending = false;

			$scope.ArticleSearchOverview = angular.copy($rootScope.ArticleSearchOverview);

			$scope.originalArticleSearchOverview = angular.copy($scope.ArticleSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.ArticleSearchOverview) || $rootScope.ArticleSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.ArticleSearchOverview = angular.copy($rootScope.ArticleSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedArticleId = id;
			$('#deleteArticleConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			UserArticleViewDtoFactory.delete(
				{ id: $scope.selectedArticleId },
				function() {
					$scope.search();
					$('#deleteArticleConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteArticleConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleUpdate = function() {
			$state.go('user-article.bulkedit', { selectedArticleList: $scope.selectedArticleList });
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.articleList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedArticleList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedArticleList = [];

			angular.forEach($scope.articleList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedArticleList.push(value);
				}
			});
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('emsApp:articleUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.ArticleSearchOverview.predicate === itemName) {
				if ($scope.ArticleSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
