'use strict';

angular.module('emsApp').controller('RelationSalesController',
    function ($scope, $rootScope, $q, $timeout, $interval, $translate, $window, $filter, _, AlertService, RelationSalesFactory, UserSalesFilterNewDtoFactory, UserSalesFilterEditDtoFactory,
              RelationFindUserFilteredByAccountManagerFactory, UserSalesFilterListDtoSearchFactory, SalesSyncFactory, EventFactory, EventSalesFactory) {
    $scope.pageLoadTime = "Loading data ...";
    $scope.dto = undefined;
    $scope.salesFilter = {};
    $scope.submitButtonIsActive = true;
    $scope.showSearchForm = false;
    $scope.pageSizeList = [20, 50, 100, 200, 500];
    $scope.salesStatusList = {};
    $scope.salesStatusList.selected = [];
    $scope.showRelationDetails = $window.localStorage.getItem('showRelationDetails')==="true"; // compare with "true" because the localStorage.getItem returns a string
    setStatusCellPadding();
    $scope.reloadEventStatsInterval = 300; // 5 minutes

    /**
     * sets the style of the statuscells to collapse
     */
    $scope.toggleRelationDetails = function() {
        $scope.showRelationDetails = !$scope.showRelationDetails;
        $window.localStorage.setItem('showRelationDetails', $scope.showRelationDetails);
        setStatusCellPadding();
    }
    function setStatusCellPadding() {
        var padding = $scope.showRelationDetails ? '15px' : '0px';
        $scope.statusCellStyle = {padding: padding};
    }

    $scope.toggleSearchForm = function() {
        $scope.showSearchForm = !$scope.showSearchForm;
    };

    var convertDateWithoutZone = function(inputDate) {
        if ($rootScope.isDefinedAndNotNull(inputDate)) {
            return $rootScope.convertDateWithoutZone(inputDate);
        } else {
            return undefined;
        }
    };

    // timer to record grid loading time
    $scope.search = function() {
        $("#search-header-button").addClass("glyphicon-refresh-animate");

        if (!$rootScope.isDefinedAndNotNull($scope.salesFilter)) {
            $scope.salesFilter = {};
        }

        // add the selected sales statuses to the rootscope object
        $scope.salesFilter.salesStatusList = {};
        $scope.salesFilter.salesStatusList.selected = $scope.salesStatusList.selected;
        $rootScope.RelationSalesFilterSearchOverview = angular.copy($scope.salesFilter);

        $scope.timerStart = Date.now();
        $scope.pageLoadTime = "Loading data ...";

        // prevent null pointers by checking the selected user prior to getting it's ID
        var selectedUserId = ($rootScope.isDefinedAndNotNull($scope.salesFilter) && $rootScope.isDefinedAndNotNull($scope.salesFilter.selectedUser)) ? $scope.salesFilter.selectedUser.id : null;

        RelationSalesFactory.query({
            accountManagerId: selectedUserId,
            firstName: $scope.salesFilter.firstName,
            lastName: $scope.salesFilter.lastName,
            personName: $scope.salesFilter.personName,
            potentialFrom: $scope.salesFilter.potentialFrom,
            potentialTo: $scope.salesFilter.potentialTo,
            startDateFrom: convertDateWithoutZone($scope.salesFilter.startDateFrom),
            startDateTo: convertDateWithoutZone($scope.salesFilter.startDateTo),
            templateEventIdList: $rootScope.getListValues($scope.salesFilter.selectedTemplateEventList, 'id'),
            salesStatus: $rootScope.getListValues($scope.salesStatusList.selected, 'value'),
            page: $scope.salesFilter.pagingPage - 1,
            size: $scope.salesFilter.pagingSize,
            sort: ['sortOrder,asc']
        }).$promise.then(function(data) {
            // TODO add headers as function argument so we can paginate the relations
            // $scope.totalItems = headers('X-Total-Count');
            $scope.dto = data;
            $("#search-header-button").removeClass("glyphicon-refresh-animate");
        });
    };

    /**
     * Creates a date from the json datestring
     * @param jsonDate datestring (yyyy-MM-dd)
     * @returns {Date}
     */
    $scope.createDateFromJson = function(jsonDate) {
        return new Date(jsonDate);
    };

    /**
     *
     */
    $scope.convertOffsetStartDates2StartDates = function() {
        if ($rootScope.isDefinedAndNotNull($scope.salesFilter.offsetStartDateFrom)) {
            $scope.salesFilter.startDateFrom = moment().add($scope.salesFilter.offsetStartDateFrom, 'days').toDate();
        }
        if ($rootScope.isDefinedAndNotNull($scope.salesFilter.offsetStartDateTo)) {
            $scope.salesFilter.startDateTo = moment().add($scope.salesFilter.offsetStartDateTo, 'days').toDate();
        }
    };

    /**
     *
     */
    $scope.convertStartDates2OffsetStartDates = function() {
        var today = moment();
        if ($rootScope.isDefinedAndNotNull($scope.salesFilter.startDateFrom)) {
            $scope.salesFilter.offsetStartDateFrom = moment($scope.salesFilter.startDateFrom).diff(today, 'days');
        }
        if ($rootScope.isDefinedAndNotNull($scope.salesFilter.startDateTo)) {
            $scope.salesFilter.offsetStartDateTo = moment($scope.salesFilter.startDateTo).diff(today, 'days');
        }
    };

    $scope.loadFilter = function() {
    		function doCopying() {
					$scope.salesFilter = angular.copy($rootScope.RelationSalesFilterSearchOverview);
					if ($rootScope.isDefinedAndNotNull($scope.salesFilter)) {
						$scope.salesStatusList.selected = $scope.salesFilter.salesStatusList.selected;
					}
				}

    		return new Promise(function (resolve, reject) {
					if (!$rootScope.isDefinedAndNotNull($rootScope.RelationSalesFilterSearchOverview)) {
						$scope.loadDefaultSearchParams().then(resolve, reject); // load defaults
						doCopying();
					} else {
						doCopying();
						resolve();
					}
				});
    };

    $scope.loadDefaultSearchParams = function() {
        return UserSalesFilterNewDtoFactory.get().$promise.then(function(data) {
            $scope.salesFilter = data;
            $scope.convertOffsetStartDates2StartDates();
            _.merge($scope.salesFilter, $rootScope.RelationSalesFilterSearchOverview);
            $scope.originalRelationSalesFilterSearchOverview = angular.copy($scope.salesFilter);
        });
    };

    $scope.isRelationSalesFilterSearchOverviewDirty = function () {
        // TODO marco: uitbreiden met originalSalesFilter OR savedSalesFilter
        return !angular.equals($scope.salesFilter, $scope.originalRelationSalesFilterSearchOverview);
    };

    $scope.reset = function() {
        $scope.loadDefaultSearchParams().then(function () {
					$scope.search();
				});
        if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
            // we check the availability of search form as it is still used in search forms that are not inlined in the table
            $scope.searchForm.$setPristine();
        }
    };

    /**
     * Functionality for saving the SalesFilter form.
     */
    var onSalesFilterSaveFinishedCallback = function(result) {
        // After successful save, update the salesfilter dropdown.
        $("#save-button").removeClass("glyphicon-refresh-animate");
        $scope.salesFilterReferenceFilterList = $scope.querySalesFilters();
        $scope.selectedSalesFilter=result;
    };

    var saveSalesFilterOrUpdateErrorCallback = function(error) {
        $("#save-button").removeClass("glyphicon-refresh-animate");
    };

    $scope.saveSalesFilter = function() {
        $("#save-button").addClass("glyphicon-refresh-animate");
        $scope.convertStartDates2OffsetStartDates();
        UserSalesFilterEditDtoFactory.update($scope.salesFilter, onSalesFilterSaveFinishedCallback, saveSalesFilterOrUpdateErrorCallback);
    };

    /**
     * Get all Users of type AccountManagers. Result will be put in accountManagerReferenceFilterList.
     */
    $scope.queryAccountManager = function() {
        return RelationFindUserFilteredByAccountManagerFactory.query(function(result) {
            return result;
        });
    };
    $scope.accountManagerReferenceFilterList = $scope.queryAccountManager();

    /**
     * Get all SalesFilters so we can put it in the dropdown.
     * When the selection has changed, get the data for the salesfilter with id of $scope.selectedSalesFilter.id.
     */
    $scope.querySalesFilters = function() {
        return UserSalesFilterListDtoSearchFactory.query(function(result) {
            return result;
        });
    };
    $scope.salesFilterReferenceFilterList = $scope.querySalesFilters();

    $scope.salesFilterChanged = function() {
        if ($rootScope.isDefinedAndNotNull($scope.selectedSalesFilter)) {
            UserSalesFilterEditDtoFactory.get({id: $scope.selectedSalesFilter.id}).$promise.then(function (data) {
                $scope.salesFilter = data;
                $scope.convertOffsetStartDates2StartDates();
            });
        }
    };

    // To prevent updating the current selected, a change in the description will reset the id. Saving will now be a 'create new'
    $scope.descriptionChanged = function() {
        $scope.salesFilter.id = null;
    };

    /**
     * Synchronizes all {@link Subscription}s with {@link EventRelationContact}s.
     * This functionality is meant to run once, as it needs to sync the subscriptions for which there are no
     */
    $scope.syncSubscriptions = function() {
        $("#sync-button").addClass("glyphicon-refresh-animate");

        SalesSyncFactory.sync().$promise.then(function(result){
            AlertService.success("Sync events and relations was successful. There should be no more subscriptions without a corresponding sales status.");
            AlertService.info(result.message);
            $scope.search();
        }).catch(function(error){
            AlertService.error("Sync events and relations based on subscriptions failed.", error);
        }).finally(function(){
            $("#sync-button").removeClass("glyphicon-refresh-animate");
        });
    };

    /**
     * Listener to update formula for specific event
     */
    $rootScope.$on("updateEventStats", function($event, eventPkId) { // NOTE: '$event' is a javascript event, NOT an EMS Event entity
        EventFactory.get({
            id : eventPkId
        }).$promise.then(function (result) {
            var eventDto  = _.pick(result, ['id','totalReserved','totalConfirmed','target','availableSpots']); // mimic UserEventListItemDto
            updateEventFormula(eventDto);
        }).catch(function (error) {
            AlertService.error("Updating event statistics failed", error);
        });
        $event.stopPropagation();
    });

    /**
     * Update the event stats by merging in the dto values
     */
    var updateEventFormula = function(eventDto) {
        var eventToUpdate = _.find($scope.dto.events, function(o) { return o.id === eventDto.id; });
        _.merge(eventToUpdate, eventDto);
    };

    /**
     * Get all events based on filter and update the formula
     */
    var reloadEventData = function() {
        EventSalesFactory.query({
            startDateFrom: convertDateWithoutZone($scope.salesFilter.startDateFrom),
            startDateTo: convertDateWithoutZone($scope.salesFilter.startDateTo),
            templateEventIdList: $rootScope.getListValues($scope.salesFilter.selectedTemplateEventList, 'id')
        }).$promise.then(function(data) {
            _.each(data.events, function(eventDto){
                updateEventFormula(eventDto);
            });
        });
    }

    // map with sales status display values as populated from backend, see enum class SalesStatus
    $scope.salesStatus = {
        "SalesStatus.ASK": {
            "key" : "SalesStatus.ASK",
            "value" : "ASK",
            "displayValue": $translate.instant("SalesStatus.ASK"),
            "style": {"background-color": "#FDD835", "color": "black"}
        },
        "SalesStatus.WAIT_FOR_ANSWER_COLD": {
            "key" : "SalesStatus.WAIT_FOR_ANSWER_COLD",
            "value" : "WAIT_FOR_ANSWER_COLD",
            "displayValue": $translate.instant("SalesStatus.WAIT_FOR_ANSWER_COLD"),
            "style": {"background-color": "#03A9F4", "color": "black"}
        }, // light orange #FFB300
        "SalesStatus.WAIT_FOR_ANSWER_HOT": {
            "key" : "SalesStatus.WAIT_FOR_ANSWER_HOT",
            "value" : "WAIT_FOR_ANSWER_HOT",
            "displayValue": $translate.instant("SalesStatus.WAIT_FOR_ANSWER_HOT"),
            "style": {"background-color": "#FF8F00", "color": "black"}
        },
        "SalesStatus.PRESENT": {
            "key" : "SalesStatus.PRESENT",
            "value" : "PRESENT",
            "displayValue": $translate.instant("SalesStatus.PRESENT"),
            "style": {"background-color": "#7CB342", "color": "black"}
        },
        "SalesStatus.NOT_PRESENT": {
            "key" : "SalesStatus.NOT_PRESENT",
            "value" : "NOT_PRESENT",
            "displayValue": $translate.instant("SalesStatus.NOT_PRESENT"),
            "style": {"background-color": "#FF5733", "color": "white"}
        },
        "SalesStatus.CANCELLED": {
            "key" : "SalesStatus.CANCELLED",
            "value" : "CANCELLED",
            "displayValue": $translate.instant("SalesStatus.CANCELLED"),
            "style": {"background-color": "#FF5733", "color": "white"}
        },
        "SalesStatus.NOT_PRESENT_UNFIT": {
            "key" : "SalesStatus.NOT_PRESENT_UNFIT",
            "value" : "NOT_PRESENT_UNFIT",
            "displayValue": $translate.instant("SalesStatus.NOT_PRESENT_UNFIT"),
            "style": {"background-color": "#212121", "color": "white"}
        } // light grey #E0E0E0
    };
    // now, convert the map (object) salesStatus into a list for the ui-select
    $scope.salesStatusList.items = _.values($scope.salesStatus);

    /**
     * listener that is triggered by the onFinishRenderGrid directive
     */
    $scope.$on('ngRenderGridFinished', function (ngRepeatFinished) {
        var loadSeconds = (Date.now() - $scope.timerStart) / 1000;
        $scope.pageLoadTime = "It took " +  loadSeconds + " seconds to load the data";
    });

    /**
     * This is the end, do the thing we came for.
     */
    $scope.loadFilter().then(function () {
			if (!$rootScope.isDefinedAndNotNull($scope.dto)) {
				$scope.search();
			}
		}, function (err) {
    	console.error(err);
		});

    $interval(function(){
        // reload the eventformulas after the amount of seconds based on the value of $scope.reloadEventStatsInterval
        reloadEventData();
    },$scope.reloadEventStatsInterval*1000);

    $scope.eventDetailTooltip = function(event) {
        var tooltip = "<h4>"+event.name+"</h4>";
        var date = $filter('customNullableDateTime')(new Date(event.startDate)/1000,'dd-MM-yy');
        tooltip += "<h5>" + date + "</h5>";
        tooltip += "<p>Workdays remaining: " + event.workdaysRemaining + "</p>";
        tooltip += "<p>Target: " + event.target + "<br>Positions confirmed: " + event.totalConfirmed + "<br>Positions reserved: " + event.totalReserved + "</p>";
        tooltip += "<h5>Positions available: " + event.availableSpots + "</h5>";

        return tooltip;
    };
})
/**
 * Directive that is used to detect the rendering of the last grid cell in the grid, as this can take quite a while we want to know in order to show the page load time
 */
.directive('onFinishRenderGrid', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            // in relation-sales.html we have a nested ng-repeat
            // foreach relation we do foreach event
            // we want to detect the last event for the last relation, which is the last cell of the grid being rendered
            if (scope.$parent.$last === true && scope.$last === true) {
                $timeout(function () {
                    // we reached the last grid cell, trigger listeners for ngRenderGridFinished
                    scope.$emit('ngRenderGridFinished');
                });
            }
        }
    }
});
