/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('SalesFilterTemplateEventDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		SalesFilterTemplateEventFactory,
		AlertService,
		NoPagingSalesFilterTemplateEventFactory,
		NoPagingSalesFilterFactory,
		NoPagingTemplateEventFactory
	) {
		$scope.salesFilterTemplateEvent = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return (
				$rootScope.isDefinedAndNotNull($scope.salesFilterTemplateEvent) &&
				$rootScope.isDefinedAndNotNull($scope.salesFilterTemplateEvent.id)
			);
		};

		$scope.submitButtonIsActive = true;

		/**
		 * Function to find an item in a list of items based on the parent id if this dialog is opened from a tabbed overview
		 */
		$scope.findItemByParent = function(items, property) {
			//This function preselects and disables the parent reference when called from tab
			if ($stateParams.mappedBy === property && $stateParams.mappedById !== undefined) {
				angular.forEach(items, function(value) {
					// value.id is a number, $stateParams.mappedById is a string, hence we cast the $stateParams.mappedById to a number so we can compare the id using the typesafe === operator
					if (value.id === _.toNumber($stateParams.mappedById)) {
						var model = $parse('salesFilterTemplateEvent.' + property);
						model.assign($scope, value);
					}
				});
			}
		};

		// --- start default implementation
		/**
		 * This function queries all entities of type SalesFilter and checks if this SalesFilterTemplateEvent dialog is called in the context of SalesFilter.
		 * If this is the case, we detect the parent entity SalesFilterTemplateEvent and select it as model value so it is shown in the dropdown select.
		 */
		$scope.querySalesFilter = function() {
			return NoPagingSalesFilterFactory.query(function(result) {
				$scope.findItemByParent(result, 'salesFilter');
			});
		};
		$scope.salesFilterList = $scope.querySalesFilter();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type TemplateEvent and checks if this SalesFilterTemplateEvent dialog is called in the context of TemplateEvent.
		 * If this is the case, we detect the parent entity SalesFilterTemplateEvent and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryTemplateEvent = function() {
			return NoPagingTemplateEventFactory.query(function(result) {
				$scope.findItemByParent(result, 'templateEvent');
			});
		};
		$scope.templateEventList = $scope.queryTemplateEvent();
		// --- end default implementation

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:salesFilterTemplateEventUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving SalesFilterTemplateEvent failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;

			if ($scope.salesFilterTemplateEvent.id != null) {
				SalesFilterTemplateEventFactory.update($scope.salesFilterTemplateEvent, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				SalesFilterTemplateEventFactory.save($scope.salesFilterTemplateEvent, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};
	});
