/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('OrderLineDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		OrderLineFactory,
		AlertService,
		NoPagingOrderLineFactory,
		BookingFactory,
		NoPagingBookingFactory,
		AutocompleteBookingsByQueryParamsFactory,
		NoPagingSubscriptionFactory,
		NoPagingArticleFactory,
		NoPagingSubscriptionByBookingFactory,
		NoPagingArticleWithBookingFactory
	) {
		$scope.orderLine = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.orderLine) && $rootScope.isDefinedAndNotNull($scope.orderLine.id);
		};

		$scope.createBooking = function() {
			var referredBy = {
				refName: 'booking',
				data: $scope.orderLine,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new booking will add this new orderline state to the history stack
				$state.go('orderline.list.newBooking', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createSubscription = function() {
			var referredBy = {
				refName: 'subscription',
				data: $scope.orderLine,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new subscription will add this new orderline state to the history stack
				$state.go('orderline.list.newSubscription', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createArticle = function() {
			var referredBy = {
				refName: 'article',
				data: $scope.orderLine,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new article will add this new orderline state to the history stack
				$state.go('orderline.list.newArticle', { referredBy: referredBy }, { reload: false });
			}, 100);
		};

		$scope.submitButtonIsActive = true;

		$scope.autocompleteBookings = function(val) {
			var params = {
				bookingTypeAheadSearchString: val,
			};

			return AutocompleteBookingsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		/**
		 * Function to find an item in a list of items based on the parent id if this dialog is opened from a tabbed overview
		 */
		$scope.findItemByParent = function(items, property) {
			//This function preselects and disables the parent reference when called from tab
			if ($stateParams.mappedBy === property && $stateParams.mappedById !== undefined) {
				angular.forEach(items, function(value) {
					// value.id is a number, $stateParams.mappedById is a string, hence we cast the $stateParams.mappedById to a number so we can compare the id using the typesafe === operator
					if (value.id === _.toNumber($stateParams.mappedById)) {
						var model = $parse('orderLine.' + property);
						model.assign($scope, value);
					}
				});
			}
		};

		/**
		 * This function preselects and disables the parent reference in autocomplete field when called from tab
		 */
		$scope.queryBooking = function() {
			// If the id of a preselected booking is available, then retrieve from backend and set in model
			if ($stateParams.mappedById === null) {
				return null;
			} else {
				return BookingFactory.get({ id: $stateParams.mappedById }, function(result) {
					if (result !== null) {
						$scope.findItemByParent([result], 'booking');
					}
				});
			}
		};
		$scope.bookingList = $scope.queryBooking();

		// --- start custom implementation
		// custom method to load the list with subscription entries for related booking only
		$scope.querySubscription = function() {
			var bookingId =
				$rootScope.isDefinedAndNotNull($scope.orderLine) && $rootScope.isDefinedAndNotNull($scope.orderLine.booking)
					? $scope.orderLine.booking.id
					: -1;

			// check if creating / editing this orderline happens in the context of a booking (from a sub tab), if so we have to set the booking id from that perspective
			if ($stateParams.mappedBy === 'booking' && $rootScope.isDefinedAndNotNull($stateParams.mappedById)) {
				bookingId = $stateParams.mappedById;
			}

			return NoPagingSubscriptionByBookingFactory.query(
				{
					bookingId: bookingId,
				},
				function(result) {
					$scope.findItemByParent(result, 'subscription');
				}
			);
		};

		// call the method to load all subscription entries once the controller loads
		$scope.subscriptionList = $scope.querySubscription();
		// --- end custom implementation

		// --- start custom implementation
		// custom method to load the list with relationprices for related booking only

		$scope.queryArticle = function() {
			var bookingId =
				$rootScope.isDefinedAndNotNull($scope.orderLine) && $rootScope.isDefinedAndNotNull($scope.orderLine.booking)
					? $scope.orderLine.booking.id
					: -1;

			// check if creating / editing this orderline happens in the context of a booking (from a sub tab), if so we have to set the booking id from that perspective
			if ($stateParams.mappedBy === 'booking' && $rootScope.isDefinedAndNotNull($stateParams.mappedById)) {
				bookingId = $stateParams.mappedById;
			}

			return NoPagingArticleWithBookingFactory.query(
				{
					bookingId: bookingId,
				},
				function(result) {
					$scope.findItemByParent(result, 'article');
				}
			);
		};
		$scope.articleList = $scope.queryArticle();
		// --- end custom implementation

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:orderLineUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving OrderLine failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;

			//check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
			if ($scope.orderLine.booking === '') {
				$scope.orderLine.booking = null;
			}

			if ($scope.orderLine.id != null) {
				OrderLineFactory.update($scope.orderLine, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				OrderLineFactory.save($scope.orderLine, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};

		// executes when onchange is triggered for the OrderLine.Article reference listbox
		// goal is to set OrderLine.description based on chosen Article.description
		$scope.changeArticle = function(article) {
			if (article !== undefined) {
				$scope.orderLine.description = article.description;
				if ($scope.orderLine.numberOfArticles == undefined) {
					$scope.orderLine.numberOfArticles = 1;
				}
				$scope.orderLine.pricePerPiece = article.price;
				$scope.orderLine.price = article.price;
				$scope.changePrice();
			}
		};
		// executes when onchange is triggered for the OrderLine.numberOfArticles attribute
		// goal is to set OrderLine.price based on chosen OrderLine.pricePerPiece
		$scope.changePrice = function() {
			if ($scope.orderLine.pricePerPiece !== undefined) {
				$scope.orderLine.price = $scope.orderLine.numberOfArticles * $scope.orderLine.pricePerPiece;
			}
		};
	});
