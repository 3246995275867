/** @format */

'use strict';

angular.module('emsApp').controller('EventDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.event = entity;
	$scope.objectName = 'Event';
	$scope.eventId = $stateParams.eventId;

	if (angular.isUndefined($rootScope.eventActiveTab)) {
		//set the default here
		$rootScope.eventActiveTab = 0;
	}

	$scope.goToEditState = function(event) {
		$state.go('event.detail.edit({eventId:event.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:eventUpdate', function(event, result) {
		$scope.event = result;
	});
});
