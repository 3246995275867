/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('CarRentalTypeTemplateEventArticleTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarRentalTypeTemplateEventArticleFactory,
		FindAllCarRentalTypeTemplateEventArticlesByQueryParamsFactory,
		NoPagingCarRentalTypeTemplateEventArticleFactory,
		NoPagingCarRentalTypeFactory,
		NoPagingTemplateEventFactory,
		NoPagingArticleFactory,
		CarRentalTypeTemplateEventArticleFindArticleFilteredByArticleForRentalFactory
	) {
		$scope.carRentalTypeTemplateEventArticleList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.page = 1;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarRentalTypeTemplateEventArticle)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarRentalTypeTemplateEventArticle);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeCarRentalTypeTemplateEventArticle = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newCarRentalTypeTemplateEventArticle', {
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
			});
		};

		$scope.goToEditState = function(carRentalTypeTemplateEventArticle) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editCarRentalTypeTemplateEventArticle', {
				carRentalTypeTemplateEventArticleId: carRentalTypeTemplateEventArticle.id,
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
			});
		};

		$scope.getCarRentalTypeTemplateEventArticleList = function() {
			FindAllCarRentalTypeTemplateEventArticlesByQueryParamsFactory.query(
				{
					carRentalTypeId: angular.isDefined($scope.carRentalTypeId) ? $scope.carRentalTypeId : null,
					templateEventId: angular.isDefined($scope.templateEventId) ? $scope.templateEventId : null,
					articleForRentalId: angular.isDefined($scope.articleForRentalId) ? $scope.articleForRentalId : null,

					page: $scope.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carRentalTypeTemplateEventArticleList = result;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getCarRentalTypeTemplateEventArticleList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarRentalTypeTemplateEventArticleId = id;
			$('#deleteCarRentalTypeTemplateEventArticleConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarRentalTypeTemplateEventArticleFactory.delete(
				{ id: $scope.selectedCarRentalTypeTemplateEventArticleId },
				function() {
					$scope.loadAll();
					$('#deleteCarRentalTypeTemplateEventArticleConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteCarRentalTypeTemplateEventArticleConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
