/** @format */

'use strict';

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('emsApp').directive('templateeventsOverviewMappedByEventtype', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/templateevent/templateevent-tab.html',
		controller: 'TemplateEventTabController',
		scope: {
			eventTypeId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('emsApp').directive('salesfiltereventtypesOverviewMappedByEventtype', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/salesfiltereventtype/salesfiltereventtype-tab.html',
		controller: 'SalesFilterEventTypeTabController',
		scope: {
			eventTypeId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('emsApp').directive('userSalesFilterEventTypeMappedByEventType', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/salesfiltereventtype/salesfiltereventtype-list-user.tab.html',
		controller: 'UserSalesFilterEventTypeListDtoTabController',
		scope: {
			eventTypeId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
