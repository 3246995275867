/** @format */

'use strict';

angular.module('emsApp').controller('SalesFilterDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.salesFilter = entity;
	$scope.objectName = 'SalesFilter';
	$scope.salesFilterId = $stateParams.salesFilterId;

	if (angular.isUndefined($rootScope.salesFilterActiveTab)) {
		//set the default here
		$rootScope.salesFilterActiveTab = 0;
	}

	$scope.goToEditState = function(salesFilter) {
		$state.go('salesfilter.detail.edit({salesFilterId:salesFilter.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:salesFilterUpdate', function(event, result) {
		$scope.salesFilter = result;
	});
});
