/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('SalesFilterFactory', function($resource) {
	return $resource(
		'api/salesFilters/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of UserSalesFilterViewDto
 */
angular.module('emsApp').factory('UserSalesFilterViewDtoFactory', function($resource) {
	return $resource(
		'api/user/salesfilter/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of UserSalesFilterListDto's
 */
angular.module('emsApp').factory('UserSalesFilterListDtoSearchFactory', function($resource) {
	return $resource(
		'api/user/salesfilter',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/* A custom hook can be implemented here custom/jhipster/factory/create_custom_edit_factory.ftl */
/**
 * Factory to get / update an instance of UserSalesFilterEditDto
 */
angular.module('emsApp').factory('UserSalesFilterEditDtoFactory', function($resource) {
	return $resource(
		'api/user/salesfilter/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of UserSalesFilterEditDto
 * with defaults from server
 */
angular.module('emsApp').factory('UserSalesFilterNewDtoFactory', function($resource) {
	return $resource(
		'api/user/salesfilter/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewSalesFilterFactory', function($resource) {
	return $resource(
		'api/salesFilters/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all SalesFilters without the use of paging
angular.module('emsApp').factory('NoPagingSalesFilterFactory', function($resource) {
	return $resource(
		'api/salesFilters/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SalesStatuses Enum values without the use of paging
angular.module('emsApp').factory('NoPagingSalesStatusFactory', function($resource) {
	return $resource(
		'api/salesFilter/salesStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('SalesFilterEventTypesBySalesFilterFactory', function($resource) {
	return $resource(
		'api/salesFilterEventTypes/salesFilter/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('emsApp').factory('EventTypeFromSalesFilterEventTypePicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/salesFilterEventTypeList/AsEventTypeList/salesFilter/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('SalesFilterTemplateEventsBySalesFilterFactory', function($resource) {
	return $resource(
		'api/salesFilterTemplateEvents/salesFilter/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('emsApp').factory('TemplateEventFromSalesFilterTemplateEventPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/salesFilterTemplateEventList/AsTemplateEventList/salesFilter/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

angular.module('emsApp').factory('FindAllSalesFiltersByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/salesFilters/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
