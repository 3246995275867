/** @format */

'use strict';

angular.module('emsApp').controller('DocumentTypeDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.documentType = entity;
	$scope.objectName = 'DocumentType';
	$scope.documentTypeId = $stateParams.documentTypeId;

	if (angular.isUndefined($rootScope.documentTypeActiveTab)) {
		//set the default here
		$rootScope.documentTypeActiveTab = 0;
	}

	$scope.goToEditState = function(documentType) {
		$state.go('documenttype.detail.edit({documentTypeId:documentType.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:documentTypeUpdate', function(event, result) {
		$scope.documentType = result;
	});
});
