/** @format */

(function() {
	'use strict';

	// -----------------------------------------------------------------------------------------------------------------

	angular.module('emsApp').directive('mfdTaskProgress', TaskProgressDirective);

	function TaskProgressDirective() {
		return {
			restrict: 'E',
			templateUrl: 'app/services/mfd-task-progress-service.html',
			// terminal : true,
			// priority : 1000,
			controller: TaskProgressDirectiveController,
			scope: {
				taskName: '=',
				title: '=',
				customClass: '=',
			},
		};
	}

	TaskProgressDirectiveController.$inject = ['$scope', '$interval', 'TaskProgressService'];

	function TaskProgressDirectiveController($scope, $interval, TaskProgressService) {
		var taskPollRunning,
			interval = 500;

		$scope.taskProgressDto;
		$scope.type = '';

		$scope.$on('task-progress-registered', function() {
			$scope.startInterval();
		});

		$scope.startInterval = function() {
			if (angular.isDefined(taskPollRunning)) return;

			$scope.type = 'info';

			taskPollRunning = $interval(function() {
				TaskProgressService.pollTask($scope.taskName).then(function(result) {
					$scope.taskProgressDto = result;

					if (!angular.isDefined($scope.taskProgressDto)) {
						console.error('Failed to retrieve the task with name ' + $scope.taskName + ' to poll', $scope.taskProgressDto);
						stopInterval();
						return;
					}

					if ($scope.taskProgressDto.taskProgressStatus !== 'IN_PROGRESS') {
						switch ($scope.taskProgressDto.taskProgressStatus) {
							case 'SUCCESS':
								$scope.type = 'success';
								break;
							case 'ERROR':
								$scope.type = 'danger';
								break;
							default:
						}

						stopInterval();
						return;
					}
				});
			}, interval);
		};

		function stopInterval() {
			if (angular.isDefined(taskPollRunning)) {
				$interval.cancel(taskPollRunning);
				taskPollRunning = undefined;
			}
		}
	}

	// -----------------------------------------------------------------------------------------------------------------

	angular.module('emsApp').factory('TaskProgressService', TaskProgressService);

	TaskProgressService.$inject = ['localStorageService', '$resource', '$q'];

	/**
	 *
	 * NOTE tasks are persisted in localStorage by their name, however the dto stored with it contains the unique ID
	 * NOTE tasks are retrieved from backend by their ID, which is unique
	 * NOTE the localStorageService is set to sessionStorage
	 *
	 * @returns {{registerTask: registerTask, pollTask: pollTask}}
	 * @constructor
	 */
	function TaskProgressService(localStorageService, $resource, $q) {
		var taskNameKeyPrefix = 'taskProgressName-';

		/**
		 *
		 */
		function registerTask(taskName, scope) {
			return $resource('/api/progress/register')
				.get()
				.$promise.then(function(result) {
					// result is an instance of TaskProgressDto
					var dto = result;

					// add to the local storage
					updateTaskToStorage(taskName, dto);

					scope.$broadcast('task-progress-registered', taskName);

					// return the dto
					return dto;
				});
		}

		/**
		 *
		 */
		function pollTask(taskName) {
			var currentTaskDto = getTaskFromStorage(taskName);

			return $resource('/api/progress/poll/:taskId', {
				taskId: currentTaskDto.taskId,
			})
				.get()
				.$promise.then(function(result) {
					// result is an instance of TaskProgressDto
					var newTaskDto = result;

					// handle the task status
					switch (newTaskDto.taskProgressStatus) {
						case 'IN_PROGRESS':
							// update the local storage
							updateTaskToStorage(taskName, newTaskDto);
							break;
						case 'SUCCESS':
							// as we're finished, we will remove the task from the storage
							removeTaskFromStorage(taskName);
							break;
						case 'ERROR':
							// handle error and remove task from storage
							handleTaskError(newTaskDto);
							removeTaskFromStorage(taskName);
							break;
						default:
					}

					// return the dto
					return newTaskDto;
				});
		}

		function updateTaskToStorage(name, dto) {
			var taskNameKey = taskNameKeyPrefix + name;

			// remove the task from the storage (no harm if it does not yet exist)
			localStorageService.remove(taskNameKey);

			// update the task by (re) adding it to the local storage
			localStorageService.set(taskNameKey, dto);
		}

		function getTaskFromStorage(name) {
			var taskNameKey = taskNameKeyPrefix + name;

			// retrieve the task from the local storage
			return localStorageService.get(taskNameKey);
		}

		function removeTaskFromStorage(name) {
			var taskNameKey = taskNameKeyPrefix + name;

			// remove the task from the storage (no harm if it does not yet exist)
			localStorageService.remove(taskNameKey);
		}

		function handleTaskError(dto) {
			console.error('Task with id ' + dto.taskId + ' resulted in an error');
		}

		// the public API of this service
		return {
			registerTask: registerTask,
			pollTask: pollTask,
		};
	}
})();
