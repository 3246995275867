/** @format */

(function() {
	'use strict';

	angular
		.module('emsApp', [
			'ngStorage',
			'tmh.dynamicLocale',
			'pascalprecht.translate',
			'ngResource',
			'ngCookies',
			'ngAria',
			'ngCacheBuster',
			'ngFileUpload',
			'angularFileUpload',
			'ui.bootstrap',
			'ui.bootstrap.datetimepicker',
			'ui.router',
			'infinite-scroll',
			// jhipster-needle-angularjs-add-module JHipster will add new module here
			'angular-loading-bar',
			'ngSanitize',
			'nzToggle',
			'ui.select',
			'LocalStorageModule',
			// Additional modules defined by custom/jhipster/app/additionalModules.ftl
			'dialogs.main',
		])
		.run(run);

	run.$inject = [
		'$rootScope',
		'$translate',
		'$window',
		'$location',
		'stateHandler',
		'translationHandler',
		'Auth',
		'HistoryService',
		'JhiLanguageService',
		'Principal',
		'$anchorScroll',
	];

	function run(
		$rootScope,
		$translate,
		$window,
		$location,
		stateHandler,
		translationHandler,
		Auth,
		HistoryService,
		JhiLanguageService,
		Principal,
		$anchorScroll
	) {
		stateHandler.initialize();
		translationHandler.initialize();

		/* Marco: Kijk naar de xx.initialize() hierboven. Je ziet dat jhipster allerlei code van de oude app.js
		 * ook weer naar losse bestanden heeft verplaatst. In die translationHandler zit nu ook bijv. de updateTitle.
		 * Ofwel, kijken of de rest ook ieder naar een los bestandje kan!
		 * */

		var debugEnabled = true;

		/* helper function to determine whether an object is defined AND not null */
		$rootScope.isDefinedAndNotNull = function(object) {
			return angular.isDefined(object) && object !== null;
		};

		$rootScope.$on('$stateChangeStart', function(event, toState, toStateParams) {
			$rootScope.toState = toState;
			$rootScope.toStateParams = toStateParams;

			if (Principal.isIdentityResolved()) {
				Auth.authorize();
			}

			// Use the cookie stored language, unless it is not available then use language from authorized account (if any)
			JhiLanguageService.getCurrent().then(function(lang) {
				if (!lang) {
					// if no language is available from cookie storage, check for an authorized account and use that lang
					Principal.identity().then(function(account) {
						if (account) {
							console.info('No stored language, use from authorized account', account.langKey);
							$translate.use(account.langKey);
						}
					});
				}
			});
		});

		// monitor all successful state changes
		$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
			var titleKey = 'global.title';

			$rootScope.previousState = fromState;
			$rootScope.previousStateName = fromState.name;
			$rootScope.previousStateParams = fromParams;

			// add the state to the history stack
			HistoryService.addHistory(toState, toParams);

			if (debugEnabled) {
				console.log('State change from', fromState.name, 'to', toState.name, 'with params:', toParams);
			}

			// Set the page title key to the one configured in state or use default one
			if (toState.data.pageTitle) {
				titleKey = toState.data.pageTitle;
			}

			$window.document.title = $translate.instant(titleKey);
		});

		$rootScope.back = function() {
			// pop the state to return to from the history stack
			HistoryService.back();
		};

		$rootScope.pageSizeList = [20, 50, 100, 200, 500];

		// function to show booleans
		$rootScope.getActiveClassForBoolean = function(isActive) {
			return isActive ? 'label label-success' : 'label label-danger';
		};

		// ModelOptions for autocomplete
		$rootScope.modelOptions = {
			debounce: {
				default: 500,
				blur: 250,
			},
			getterSetter: true,
		};

		$rootScope.displayFields = function(fieldArray) {
			var result = '';

			for (var t = 0; t < fieldArray.length; t++) {
				var value = fieldArray[t];
				if (value !== undefined && value !== null) {
					if (result != '') {
						result += ', ';
					}
					result += value;
				}
			}
			return result;
		};

		// Helper function. Used at , for instance, the Dates at tab search
		$rootScope.convertDateWithoutZone = function(modelDate) {
			var year = modelDate.getFullYear();

			var month = modelDate.getMonth() + 1;
			month = month < 10 ? '0' + month : month;

			var day = modelDate.getDate();
			day = day < 10 ? '0' + day : day;

			// this is a java 8 DateTimeFormatter.ISO_LOCAL_DATE) e.g. 2017-05-21
			var date = year + '-' + month + '-' + day;

			return date;
		};

		// Helper function. Gets the propery from each list item, using _.map
		$rootScope.getListValues = function(list, property) {
			if (this.isDefinedAndNotNull(list)) {
				return _.map(list, property);
			} else {
				return undefined;
			}
		};

		// authorization helper
		$rootScope.userHasAnyAuthority = function(authorities) {
			return Principal.hasAnyAuthority(authorities);
		};

		// scroll to top helper
		$anchorScroll.yOffset = 90; // scrollto always adds 90 pixels to ensure the scrollTo element is not behind the navigation
		$rootScope.scrollTo = function(elementId) {
			$location.hash(elementId);
			$anchorScroll();
		};
	}
})();
