/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('SalesFilterEventTypeFactory', function($resource) {
	return $resource(
		'api/salesFilterEventTypes/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get or delete an instance of UserSalesFilterEventTypeViewDto
 */
angular.module('emsApp').factory('UserSalesFilterEventTypeViewDtoFactory', function($resource) {
	return $resource(
		'api/user/salesfiltereventtype/:id',
		{},
		{
			// Factory is only used to delete a UserSalesFilterEventTypeViewDto
		}
	);
});
/**
 * Factory to search / retrieve an array of UserSalesFilterEventTypeListDto's
 */
angular.module('emsApp').factory('UserSalesFilterEventTypeListDtoSearchFactory', function($resource) {
	return $resource(
		'api/user/salesfiltereventtype',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewSalesFilterEventTypeFactory', function($resource) {
	return $resource(
		'api/salesFilterEventTypes/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all SalesFilterEventTypes without the use of paging
angular.module('emsApp').factory('NoPagingSalesFilterEventTypeFactory', function($resource) {
	return $resource(
		'api/salesFilterEventTypes/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllSalesFilterEventTypesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/salesFilterEventTypes/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
