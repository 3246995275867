'use strict';

angular.module('emsApp').controller(
		'EventDetailWithSubscriptionsController',
		function($scope, $rootScope, $stateParams, $state, $translate, dialogs, entity, AlertService, EventSubscriptionsFactory,
				EventSubscriptionsMailUpdateFactory, DriverFactory, EventFactory, EventSnelstartFactory, EventMailChimpFactory) {
			$scope.event = entity;
			$scope.objectName = "Event";
			$scope.eventId = $stateParams.eventId;
			$scope.subscriptionList = [];
			$scope.syncingAudience = false;

			var addObjectToDisplayString = function(object, displayString) {
				if ($rootScope.isDefinedAndNotNull(object)) {
					if (displayString.trim() !== "") {
						displayString += ", ";
					}
					displayString += object;
				}
				return displayString;
			};

			$scope.getHotels = function(subscription) {
				var hotel = "";

				if (!$rootScope.isDefinedAndNotNull(subscription.driverDto)) {
					return hotel;
				}

				// get driver hotel (if any)
				if ($rootScope.isDefinedAndNotNull(subscription.driverDto.hotel)) {
					hotel = addObjectToDisplayString(subscription.driverDto.hotel, hotel);
				}
				// get second driver hotel (if any)
				if ($rootScope.isDefinedAndNotNull(subscription.secondDriverDtoList)) {
					angular.forEach(subscription.secondDriverDtoList, function(secondDriver, index) {
						hotel = addObjectToDisplayString(secondDriver.hotel, hotel);
					})
				}
				// get spectator hotel (if any)
				if ($rootScope.isDefinedAndNotNull(subscription.spectatorDtoList)) {
					angular.forEach(subscription.spectatorDtoList, function(spectator, index) {
						hotel = addObjectToDisplayString(spectator.hotel, hotel);
					})
				}

				return hotel;
			};

			$scope.getInstructionTypes = function(subscription) {
				var instructionTypes = "";

				if (!$rootScope.isDefinedAndNotNull(subscription.driverDto)) {
					return instructionTypes;
				}

				// get driver instructionType (if any)
				if ($rootScope.isDefinedAndNotNull(subscription.driverDto.instructionType)) {
					instructionTypes = addObjectToDisplayString(subscription.driverDto.instructionType, instructionTypes);
				}
				// get second driver instructionType (if any)
				if ($rootScope.isDefinedAndNotNull(subscription.secondDriverDtoList)) {
					angular.forEach(subscription.secondDriverDtoList, function(secondDriver, index) {
						instructionTypes = addObjectToDisplayString(secondDriver.instructionType, instructionTypes);
					});
				}
				return instructionTypes;
			};

			/**
			 * [dmitro] In summary of hours should go only subscriptions with listed statuses
			 */
			var subscriptionStatusToIncludeInHoursTotalSum = ['RESERVED', 'CONFIRMED'];
			$scope.getInstructionHours = function(subscription) {

				var hours = 0;

				if (!$rootScope.isDefinedAndNotNull(subscription.driverDto)) {
					return hours;
				}

				if ($rootScope.isDefinedAndNotNull(subscription.driverDto.instructionHours)) {
					if (subscriptionStatusToIncludeInHoursTotalSum.indexOf(subscription.driverDto.status) !== -1) {
						hours = subscription.driverDto.instructionHours;
					}
				}
				// get second driver instructionHours
				if ($rootScope.isDefinedAndNotNull(subscription.secondDriverDtoList)) {
					angular.forEach(subscription.secondDriverDtoList, function(secondDriver, index) {
						if (subscriptionStatusToIncludeInHoursTotalSum.indexOf(secondDriver.status) !== -1) {
							hours += secondDriver.instructionHours;
						}
					});
				}
				// get spectator instructionHours
				if ($rootScope.isDefinedAndNotNull(subscription.spectatorDtoList)) {
					angular.forEach(subscription.spectatorDtoList, function(spectator, index) {
						if (subscriptionStatusToIncludeInHoursTotalSum.indexOf(spectator.status) !== -1) {
							hours += spectator.instructionHours;
						}
					});
				}
				return hours;
			};

			$scope.getInstructors = function(subscription) {
				var instructors = "";

				if (!$rootScope.isDefinedAndNotNull(subscription.driverDto)) {
					return instructors;
				}

				// get driver instructionType (if any)
				if ($rootScope.isDefinedAndNotNull(subscription.driverDto.instructor)) {
					instructors = addObjectToDisplayString(subscription.driverDto.instructor, instructors);
				}
				// get second driver instructionType (if any)
				if ($rootScope.isDefinedAndNotNull(subscription.secondDriverDtoList)) {
					angular.forEach(subscription.secondDriverDtoList, function(secondDriver, index) {
						instructors = addObjectToDisplayString(secondDriver.instructor, instructors);
					});
				}
				return instructors;
			};

			$scope.updateInfoMail = function() {
				EventSubscriptionsMailUpdateFactory.updateInfoMail($scope.eventId).update().$promise.then(function(data) {
					$scope.subscriptionList = data;
				});
			};

			$scope.updateAfterMail = function() {
				EventSubscriptionsMailUpdateFactory.updateAfterMail($scope.eventId).update().$promise.then(function(data) {
					$scope.subscriptionList = data;
				});
			};

			$scope.syncAudiences = function () {
				$scope.syncingAudience = true;
				EventMailChimpFactory.syncAudience($scope.event.id).sync().$promise.then(function (updatedEvent) {
					$scope.syncingAudience = false;
					$scope.event = updatedEvent;
					AlertService.add({
						type: 'success',
						msg: "Audience synced",
						timeout: 3000
					})
				}, function (error) {
					$scope.syncingAudience = false;
					AlertService.add({
						type: 'danger',
						msg: "Failed to sync the audience",
						timeout: 3000
					});
				})
			};

			$scope.deleteDriver = function(subscription) {
				var driverId = subscription.driverDto.subscriptionIdPk;
				var bookingId = subscription.bookingIdPk;

				if (!$rootScope.isDefinedAndNotNull(driverId)) {
					AlertService.error("Unknown driver");
					return;
				}

				if (!$rootScope.isDefinedAndNotNull(bookingId)) {
					AlertService.error("Unknown booking");
					return;
				}

				var driverName = subscription.driverDto.subscriptionPersonName;
				var bookingName = subscription.bookingPersonName;
				var dlgTitle = $translate.instant("event.detail.subscriptions.delete.confirm.title", {
					driverName : driverName
				});
				var dlgMessage = $translate.instant("event.detail.subscriptions.delete.confirm.message", {
					driverName : driverName,
					bookingName : bookingName
				});

				var dlg = dialogs.confirm(dlgTitle, dlgMessage);

				dlg.result.then(function() {
					// user confirmed delete action
					DriverFactory.deleteDriver({
						id : driverId,
						bookingId : bookingId
					}).$promise.then(function() {
						AlertService.info("Successfully deleted driver {{driverName}}", {
							driverName : driverName
						});
						$scope.loadData();
						$scope.reloadEvent();
					}, function(error) {
						AlertService.error("Failed to delete driver {{driverName}}", {
							driverName : driverName
						}, error);
					});
				}, function() {
					// user cancelled delete action
				});
			};

			$scope.translateStatus = function(status) {
				return $rootScope.isDefinedAndNotNull(status) ? $translate.instant('SubscriptionStatus.' + status) : "";
			};

			$scope.loadData = function() {
				EventSubscriptionsFactory.query({
					id : $scope.eventId
				}, function(result) {
					$scope.subscriptionList = result;
				}, function(error) {
					AlertService.error(error);
				});
			};

			$scope.reloadEvent = function() {
				EventFactory.get({
					id : $scope.eventId
				}).$promise.then(function(result) {
					$scope.event = result;
				});
			};

			$scope.loadData();
		});
