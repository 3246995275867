/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('SubscriptionTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		SubscriptionFactory,
		FindAllSubscriptionsByQueryParamsFactory,
		AutocompleteRelationsByQueryParamsFactory,
		AutocompleteBookingsByQueryParamsFactory,
		AutocompleteCarsByQueryParamsFactory,
		NoPagingSubscriptionFactory,
		NoPagingSubscriptionTypeFactory,
		NoPagingRelationFactory,
		NoPagingBookingFactory,
		NoPagingCarFactory,
		NoPagingSubscriptionStatusFactory,
		NoPagingArticleFactory,
		NoPagingInstructorFactory,
		NoPagingTemplateEventFactory,
		SubscriptionFindArticleFilteredByDriverFactory,
		SubscriptionFindArticleFilteredBySecondDriverFactory,
		SubscriptionFindArticleFilteredBySpectatorFactory,
		SubscriptionFindArticleFilteredByInsuranceFactory,
		SubscriptionFindArticleFilteredByHotelFactory,
		SubscriptionFindArticleFilteredByFuelFactory,
		SubscriptionFindArticleFilteredByRentalCarFactory,
		SubscriptionFindArticleFilteredByInstructionFactory
	) {
		$scope.subscriptionList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeSubscription)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeSubscription);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeSubscription = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newSubscription', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(subscription) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editSubscription', {
				subscriptionId: subscription.id,
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
			});
		};

		var getRelationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.relationId)) {
				return $scope.relationId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchTab.relation)) {
					return $scope.SubscriptionSearchTab.relation.id;
				} else {
					return undefined;
				}
			}
		};

		var getBookingId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.bookingId)) {
				return $scope.bookingId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchTab.booking)) {
					return $scope.SubscriptionSearchTab.booking.id;
				} else {
					return undefined;
				}
			}
		};

		var getCarId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.carId)) {
				return $scope.carId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchTab.car)) {
					return $scope.SubscriptionSearchTab.car.id;
				} else {
					return undefined;
				}
			}
		};

		var getDriverId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.driverId)) {
				return $scope.driverId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchTab.driver)) {
					return $scope.SubscriptionSearchTab.driver.id;
				} else {
					return undefined;
				}
			}
		};

		var getSecondDriverId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.secondDriverId)) {
				return $scope.secondDriverId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchTab.secondDriver)) {
					return $scope.SubscriptionSearchTab.secondDriver.id;
				} else {
					return undefined;
				}
			}
		};

		var getSpectatorId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.spectatorId)) {
				return $scope.spectatorId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchTab.spectator)) {
					return $scope.SubscriptionSearchTab.spectator.id;
				} else {
					return undefined;
				}
			}
		};

		var getInsuranceId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.insuranceId)) {
				return $scope.insuranceId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchTab.insurance)) {
					return $scope.SubscriptionSearchTab.insurance.id;
				} else {
					return undefined;
				}
			}
		};

		var getHotelId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.hotelId)) {
				return $scope.hotelId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchTab.hotel)) {
					return $scope.SubscriptionSearchTab.hotel.id;
				} else {
					return undefined;
				}
			}
		};

		var getFuelId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.fuelId)) {
				return $scope.fuelId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchTab.fuel)) {
					return $scope.SubscriptionSearchTab.fuel.id;
				} else {
					return undefined;
				}
			}
		};

		var getRelationOfId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.relationOfId)) {
				return $scope.relationOfId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchTab.relationOf)) {
					return $scope.SubscriptionSearchTab.relationOf.id;
				} else {
					return undefined;
				}
			}
		};

		var getInstructorId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.instructorId)) {
				return $scope.instructorId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchTab.instructor)) {
					return $scope.SubscriptionSearchTab.instructor.id;
				} else {
					return undefined;
				}
			}
		};

		var getTrainingTypeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.trainingTypeId)) {
				return $scope.trainingTypeId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchTab.trainingType)) {
					return $scope.SubscriptionSearchTab.trainingType.id;
				} else {
					return undefined;
				}
			}
		};

		var getRentalCarId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.rentalCarId)) {
				return $scope.rentalCarId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchTab.rentalCar)) {
					return $scope.SubscriptionSearchTab.rentalCar.id;
				} else {
					return undefined;
				}
			}
		};

		var getInstructionId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.instructionId)) {
				return $scope.instructionId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchTab.instruction)) {
					return $scope.SubscriptionSearchTab.instruction.id;
				} else {
					return undefined;
				}
			}
		};

		$scope.autocompleteRelations = function(val) {
			var params = {
				relationTypeAheadSearchString: val,
			};

			return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		$scope.autocompleteBookings = function(val) {
			var params = {
				bookingTypeAheadSearchString: val,
			};

			return AutocompleteBookingsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.SubscriptionSearchTab = angular.copy($scope.SubscriptionSearchTab);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeSubscription = angular.copy($scope.pageSize);

			FindAllSubscriptionsByQueryParamsFactory.query(
				{
					page: $scope.SubscriptionSearchTab.page - 1,
					size: $scope.pageSize,
					subscriptionType: $scope.SubscriptionSearchTab.subscriptionType,
					relationId: getRelationId(),
					bookingId: getBookingId(),
					carId: getCarId(),
					status: $scope.SubscriptionSearchTab.status,
					driverId: getDriverId(),
					secondDriverId: getSecondDriverId(),
					spectatorId: getSpectatorId(),
					insuranceId: getInsuranceId(),
					hotelId: getHotelId(),
					fuelId: getFuelId(),
					relationOfId: getRelationOfId(),
					instructorId: getInstructorId(),
					trainingTypeId: getTrainingTypeId(),
					rentalCarId: getRentalCarId(),
					instructionId: getInstructionId(),

					sort: [$scope.SubscriptionSearchTab.predicate + ',' + ($scope.SubscriptionSearchTab.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.subscriptionList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isSubscriptionSearchTabDirty = function() {
			return !angular.equals($scope.SubscriptionSearchTab, $scope.originalSubscriptionSearchTab);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.SubscriptionSearchTab = {};
			$rootScope.SubscriptionSearchTab.page = 1;
			$rootScope.SubscriptionSearchTab.predicate = 'description';
			$rootScope.SubscriptionSearchTab.ascending = true;

			$scope.SubscriptionSearchTab = angular.copy($rootScope.SubscriptionSearchTab);

			$scope.originalSubscriptionSearchTab = angular.copy($scope.SubscriptionSearchTab);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.SubscriptionSearchTab) || $rootScope.SubscriptionSearchTab === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.SubscriptionSearchTab = angular.copy($rootScope.SubscriptionSearchTab);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedSubscriptionId = id;
			$('#deleteSubscriptionConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			SubscriptionFactory.delete(
				{ id: $scope.selectedSubscriptionId },
				function() {
					$scope.search();
					$('#deleteSubscriptionConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteSubscriptionConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('emsApp:subscriptionUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.SubscriptionSearchTab.predicate === itemName) {
				if ($scope.SubscriptionSearchTab.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
