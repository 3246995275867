/** @format */

'use strict';

// Location has edit layout enabled. Edit layout template url: location-dialog.html
angular.module('emsApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Location'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('location'); // main translation loaded for location
		$translatePartialLoader.addPart('event'); // one to many reference from main entity
		$translatePartialLoader.addPart('document'); // one to many reference from main entity
		$translatePartialLoader.addPart('country'); // one to many reference from main entity

		return $translate.refresh();
	};

	/**
	 * "private" function called by wrapper new and edit functions.
	 */
	var getModalState = function(url, isEditMode) {
		if (!angular.isDefined(url) || url === null) {
			url = '/new';
		}

		if (!angular.isDefined(isEditMode) || isEditMode === null) {
			// default mode is NEW, however this situation should and will be avoided by as this function should only be
			isEditMode = false;
		}

		var templateUrl;
		// The default assignment to templateUrl can be overridden in hook custom/jhipster/state/template_url/add_or_edit.ftl
		templateUrl = 'app/entities/location/location-dialog.html';

		return {
			/* new state for entity location */
			/* to access: ui-ref="location.list.new" */
			/* url: hostname/location/new */
			url: url,
			data: {
				authorities: ['ROLE_USER'],
				isModal: true,
			},
			params: {
				mappedBy: null,
				mappedByType: null,
				mappedById: null,
				dataModel: null, // this data model is set from the new relation state OR from the save and new logic
				mappedByDisplayValue: null,
				dataModelType: null, // this data model type is the name of the modelobject, lowercase
				referredBy: null, // will be populated once this new state is triggered from another new state with + button
				hiddenParam: 'YES', // hides params from url
			},
			onEnter: [
				'$stateParams',
				'$state',
				'$uibModal',
				'$rootScope',
				'HistoryService',
				function($stateParams, $state, $uibModal, $rootScope, HistoryService) {
					var navigateBack = true;

					$uibModal
						.open({
							templateUrl: templateUrl,
							controller: 'LocationDialogController',
							size: 'lg',
							backdrop: 'static',
							resolve: {
								entity: [
									'LocationFactory',
									'NewLocationFactory',
									function(LocationFactory, NewLocationFactory) {
										// NOTE either this dialog was opened with the purpose of
										// - creating a new Location
										// - resuming the creation of a new new Location AFTER new instances were created of x..1 reference(s)

										// if dataModel is defined, we're coming back from a new created x..1 reference
										if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
											var model = $stateParams.dataModel;
											// reset 'remembered' values to prevent future misbehaviour
											$rootScope.previousStateParams.dataModel = null;
											$stateParams.dataModel = null;

											// instead of returning a new model, we return the updated model
											return model;
										} else {
											if (isEditMode) {
												// get the entity by its ID
												return LocationFactory.get({ id: $stateParams.locationId }).$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											} else {
												// get a new empty entity
												return NewLocationFactory.get().$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											}
										}
									},
								],
								translatePartialLoader: [
									'$translate',
									'$translatePartialLoader',
									function($translate, $translatePartialLoader) {
										return getTranslations($translate, $translatePartialLoader);
									},
								],
							},
						})
						.result.then(function(result) {
							// process success result, if necessary
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// set relation as reference in the referredBy data model
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;
							}
						})
						.catch(function(closeReason) {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// reset relation in referred data model object
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = null;
							}
							if (closeReason === 'createNew') {
								// only navigate back in history if the close reason allows us to
								navigateBack = false;
							}
						})
						.finally(function() {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
							}

							if (navigateBack) {
								$rootScope.back();
							}
						});
				},
			],
		};
	};

	/**
	 * Body for location new state
	 */
	function getNewState(url) {
		return getModalState(url, false);
	}

	/**
	 * Body for location edit state
	 */
	function getEditState(url) {
		return getModalState(url, true);
	}

	$stateProvider.state('location', {
		/* abstract state for entity location */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/location',
		data: {
			authorities: ['ROLE_USER'],
			pageTitle: 'Locations',
			isModal: false,
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	$stateProvider.state('location.list', {
		/* list state for entity location */
		/* to access: ui-ref="location.list" */
		/* url: hostname/location/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/location/locations.html',
				controller: 'LocationController',
			},
		},
	});

	$stateProvider.state('location.detail', {
		/* detail state for entity location */
		/* to access: ui-sref="location.detail" */
		/* url: hostname/location/detail/:locationId */
		url: '/detail/:locationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/location/location-detail.html',
				controller: 'LocationDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'LocationFactory',
				function($stateParams, LocationFactory) {
					return LocationFactory.get({ id: $stateParams.locationId });
				},
			],
		},
	});

	/* url: http://<hostname>/location/list/new */
	$stateProvider.state('location.list.new', getNewState());
	/* url: http://<hostname>/location/list/:locationId/edit */
	$stateProvider.state('location.list.edit', getEditState('/:locationId/edit'));
	/* url: http://<hostname>/location/detail/:locationId/edit */
	$stateProvider.state('location.detail.edit', getEditState('/edit'));

	$stateProvider.state('event.list.newLocation', getNewState('/location/new'));
	$stateProvider.state('document.list.newLocation', getNewState('/location/new'));

	$stateProvider.state('user-location', {
		/* abstract state for DTO UserLocationListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/user/location',
		data: {
			authorities: ['ROLE_USER', 'ROLE_ADMIN'],
			pageTitle: 'Location',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO user */
	var dtoEditState = {
		/* edit state for DTO UserLocationEditDto */
		/* to access: ui-sref="user-location.edit" */
		/* url: http://<hostname>/user/location/edit/:locationId */
		url: '/edit/:locationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/location/location-edit-user.html',
				controller: 'UserLocationEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'UserLocationEditDtoFactory',
				function($stateParams, $rootScope, UserLocationEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return UserLocationEditDtoFactory.get({ id: $stateParams.locationId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('user-location.edit', dtoEditState);

	/* new (create) state for DTO user */
	var dtoCreateState = {
		/* new state for DTO UserLocationEditDto */
		/* to access: ui-sref="user-location.new" */
		/* url: http://<hostname>/user/location/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/location/location-edit-user.html',
				controller: 'UserLocationEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'UserLocationNewDtoFactory',
				function($stateParams, $rootScope, UserLocationNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return UserLocationNewDtoFactory.get({ id: $stateParams.locationId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('user-location.new', dtoCreateState);

	// create state for plus button on Event edit / new page for reference Location and dto property value user
	$stateProvider.state('user-event.new.newLocation', getCreateState('/location/new'));
	$stateProvider.state('user-event.edit.newLocation', getCreateState('/location/new'));
	// create state for plus button on Document edit / new page for reference Location and dto property value user
	$stateProvider.state('user-document.new.newLocation', getCreateState('/location/new'));
	$stateProvider.state('user-document.edit.newLocation', getCreateState('/location/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/location/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
