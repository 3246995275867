/** @format */

'use strict';
angular.module('emsApp').service('DocumentDownloadService', function(DocumentDownloadFactory, GetHeaderFilename, FileDownloadError) {
	return function(id) {
		console.log('downloading requested file...');
		DocumentDownloadFactory.download({ id: id })
			.$promise.then(function(result) {
				var url = URL.createObjectURL(new Blob([result.data]));
				var a = document.createElement('a');
				console.log(result.headerData);
				a.href = url;
				a.download = GetHeaderFilename(result.headerData);
				a.target = '_blank';
				a.click();
			})
			.catch(FileDownloadError)
			.catch(function(error) {
				console.log(error.data); // in JSON
			});
	};
});
