/** @format */

'use strict';

angular.module('emsApp').controller('VatDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.vat = entity;
	$scope.objectName = 'Vat';
	$scope.vatId = $stateParams.vatId;

	if (angular.isUndefined($rootScope.vatActiveTab)) {
		//set the default here
		$rootScope.vatActiveTab = 0;
	}

	$scope.goToEditState = function(vat) {
		$state.go('vat.detail.edit({vatId:vat.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:vatUpdate', function(event, result) {
		$scope.vat = result;
	});
});
