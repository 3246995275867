/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('EventDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		EventFactory,
		AlertService,
		NoPagingEventFactory,
		AutocompleteEventsByQueryParamsFactory,
		NoPagingTemplateEventFactory,
		NoPagingLocationFactory,
		NoPagingEventStatusFactory,
		EventSaveAndCreateFactory
	) {
		$scope.event = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.event) && $rootScope.isDefinedAndNotNull($scope.event.id);
		};

		$scope.createTemplateEvent = function() {
			var referredBy = {
				refName: 'templateEvent',
				data: $scope.event,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new templateEvent will add this new event state to the history stack
				$state.go('event.list.newTemplateEvent', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createLocation = function() {
			var referredBy = {
				refName: 'location',
				data: $scope.event,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new location will add this new event state to the history stack
				$state.go('event.list.newLocation', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createStatus = function() {
			var referredBy = {
				refName: 'status',
				data: $scope.event,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new status will add this new event state to the history stack
				$state.go('event.list.newEventStatus', { referredBy: referredBy }, { reload: false });
			}, 100);
		};

		/**
		 * Tells caller whether the current shown screen (and state) is in edit mode, hence a dialog is shown
		 */
		$scope.isInEditState = function() {
			// check on stateName: *.edit  or  *.detail.edit
			return $state.current.name.toUpperCase().endsWith('EDIT');
		};

		$scope.submitButtonIsActive = true;

		/**
		 * Function to find an item in a list of items based on the parent id if this dialog is opened from a tabbed overview
		 */
		$scope.findItemByParent = function(items, property) {
			//This function preselects and disables the parent reference when called from tab
			if ($stateParams.mappedBy === property && $stateParams.mappedById !== undefined) {
				angular.forEach(items, function(value) {
					// value.id is a number, $stateParams.mappedById is a string, hence we cast the $stateParams.mappedById to a number so we can compare the id using the typesafe === operator
					if (value.id === _.toNumber($stateParams.mappedById)) {
						var model = $parse('event.' + property);
						model.assign($scope, value);
					}
				});
			}
		};

		// --- start default implementation
		/**
		 * This function queries all entities of type TemplateEvent and checks if this Event dialog is called in the context of TemplateEvent.
		 * If this is the case, we detect the parent entity Event and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryTemplateEvent = function() {
			return NoPagingTemplateEventFactory.query(function(result) {
				$scope.findItemByParent(result, 'templateEvent');
			});
		};
		$scope.templateEventList = $scope.queryTemplateEvent();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type Location and checks if this Event dialog is called in the context of Location.
		 * If this is the case, we detect the parent entity Event and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryLocation = function() {
			return NoPagingLocationFactory.query(function(result) {
				$scope.findItemByParent(result, 'location');
			});
		};
		$scope.locationList = $scope.queryLocation();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type Status and checks if this Event dialog is called in the context of Status.
		 * If this is the case, we detect the parent entity Event and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryStatus = function() {
			return NoPagingEventStatusFactory.query(function(result) {
				$scope.findItemByParent(result, 'status');
			});
		};
		$scope.statusList = $scope.queryStatus();
		// --- end default implementation

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:eventUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving Event failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		var onSaveAndNewFinishedCallback = function(result) {
			$scope.$emit('emsApp:eventUpdate', result);
			// the result contains a new entity, but with the copied values from the previous
			$scope.event = result;
			$scope.updateDropdownModels();
			$scope.submitButtonIsActive = true;
		};

		$scope.updateDropdownModels = function() {
			$scope.queryTemplateEvent();
			$scope.queryLocation();
			$scope.queryStatus();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;

			if ($scope.event.id != null) {
				EventFactory.update($scope.event, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				EventFactory.save($scope.event, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			}
		};

		$scope.saveAndNew = function() {
			$scope.submitButtonIsActive = false;

			if ($scope.event.id != null) {
				EventSaveAndCreateFactory.updateAndCreate($scope.event, onSaveAndNewFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				EventSaveAndCreateFactory.saveAndCreate($scope.event, onSaveAndNewFinishedCallback, saveOrUpdateErrorCallback);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};

		// executes when onchange is triggered for the Event.TemplateEvent reference listbox
		// goal is to set Event.description based on chosen TemplateEvent.description
		$scope.changeTemplateEvent = function(templateEvent) {
			if (templateEvent !== undefined) {
				$scope.event.description = templateEvent.description;
			}
		};
	});
