'use strict';

// This factory was generated by MetaFactory pattern, but modified later. Generation is disabled and unused factories
// were removed. The NoPagingUserFactory is in the Relation functionality where you can search by account manager
// which is a user.userType
//Get all Users without the use of paging
angular.module('emsApp').factory('NoPagingUserFactory', function($resource) {
	return $resource('api/users/nopaging', {}, {
		'query' : {
			method : 'GET',
			isArray : true
		}
	});
});
