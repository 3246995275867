/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('DocumentDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		DocumentFactory,
		AlertService,
		FileUploader,
		NoPagingDocumentFactory,
		NoPagingDocumentTypeFactory,
		CarFactory,
		NoPagingCarFactory,
		AutocompleteCarsByQueryParamsFactory,
		NoPagingLocationFactory,
		RelationFactory,
		NoPagingRelationFactory,
		AutocompleteRelationsByQueryParamsFactory,
		EventFactory,
		NoPagingEventFactory,
		AutocompleteEventsByQueryParamsFactory,
		$cookies
	) {
		$scope.document = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.document) && $rootScope.isDefinedAndNotNull($scope.document.id);
		};

		$scope.createType = function() {
			var referredBy = {
				refName: 'type',
				data: $scope.document,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new type will add this new document state to the history stack
				$state.go('document.list.newDocumentType', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createCar = function() {
			var referredBy = {
				refName: 'car',
				data: $scope.document,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new car will add this new document state to the history stack
				$state.go('document.list.newCar', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createLocation = function() {
			var referredBy = {
				refName: 'location',
				data: $scope.document,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new location will add this new document state to the history stack
				$state.go('document.list.newLocation', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createRelation = function() {
			var referredBy = {
				refName: 'relation',
				data: $scope.document,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new relation will add this new document state to the history stack
				$state.go('document.list.newRelation', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createEvent = function() {
			var referredBy = {
				refName: 'event',
				data: $scope.document,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new event will add this new document state to the history stack
				$state.go('document.list.newEvent', { referredBy: referredBy }, { reload: false });
			}, 100);
		};

		var isUploadReady = true;
		var isUploadDisabled = false; // this variable is used in the statement to determine whether the file input should be enabled
		var errorMessage = '';

		var uploader = ($scope.file = new FileUploader({
			url: 'api/documents/',
			autoUpload: false,
			headers: {
				Accept: 'application/json, text/plain, */*',
				'X-CSRF-TOKEN': $cookies.get('CSRF-TOKEN'), // cookie with name CSRF-TOKEN is used by Angular
			},
		}));

		$scope.isDisableFileInput = function() {
			return uploader.queueLimit == uploader.queue.length || isUploadDisabled;
		};

		uploader.onErrorItem = function(fileItem, response, status, headers) {
			// console.log("uploader.onErrorItem");
			isUploadReady = false;
		};

		uploader.onAfterAddingFile = function(fileItem) {
			// console.log("uploader.onAfterAddingFile");
			if (uploader.queue.length == 2) {
				//delete one item on the left side of the array (Jquery function)
				uploader.queue.shift();
			}
			$scope.submitButtonIsActive = true;
		};

		var clearFileInputAndQueue = function() {
			uploader.clearQueue();
			//Clear the file input. Since there is no ng-model binding we can only do this by directly calling the DOM element(s) and set it to null
			angular.element("input[type='file']").val(null);
		};

		uploader.onWhenAddingFileFailed = function(item, filter, options) {
			$scope.submitButtonIsActive = false;
			AlertService.error('error.file.upload.onWhenAddingFileFailed');
			clearFileInputAndQueue();
		};

		uploader.onCompleteItem = function(fileItem, result, status) {
			if (status == 201) {
				onSaveFinishedCallback(result);
			} else {
				// something went wrong, cancel all items in uploader and clear the queue
				uploader.cancelAll();
				uploader.clearQueue();

				// default error message in case none of below statusses match
				errorMessage = 'error.file.upload.general';

				switch (status) {
					case 500: {
						if (result.indexOf('SizeLimitExceededException') != -1) {
							errorMessage = 'error.file.upload.size.exceeded';
						}
					}
					// more cases to come
				}

				AlertService.error(errorMessage);
				$scope.submitButtonIsActive = false;
			}
		};

		$scope.submitButtonIsActive = false;

		$scope.autocompleteCars = function(val) {
			var params = {
				carTypeAheadSearchString: val,
			};

			return AutocompleteCarsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};
		$scope.autocompleteRelations = function(val) {
			var params = {
				relationTypeAheadSearchString: val,
			};

			return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};
		$scope.autocompleteEvents = function(val) {
			var params = {
				eventTypeAheadSearchString: val,
			};

			return AutocompleteEventsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		/**
		 * Function to find an item in a list of items based on the parent id if this dialog is opened from a tabbed overview
		 */
		$scope.findItemByParent = function(items, property) {
			//This function preselects and disables the parent reference when called from tab
			if ($stateParams.mappedBy === property && $stateParams.mappedById !== undefined) {
				angular.forEach(items, function(value) {
					// value.id is a number, $stateParams.mappedById is a string, hence we cast the $stateParams.mappedById to a number so we can compare the id using the typesafe === operator
					if (value.id === _.toNumber($stateParams.mappedById)) {
						var model = $parse('document.' + property);
						model.assign($scope, value);
					}
				});
			}
		};

		// --- start default implementation
		/**
		 * This function queries all entities of type Type and checks if this Document dialog is called in the context of Type.
		 * If this is the case, we detect the parent entity Document and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryType = function() {
			return NoPagingDocumentTypeFactory.query(function(result) {
				$scope.findItemByParent(result, 'type');
			});
		};
		$scope.typeList = $scope.queryType();
		// --- end default implementation

		/**
		 * This function preselects and disables the parent reference in autocomplete field when called from tab
		 */
		$scope.queryCar = function() {
			// If the id of a preselected car is available, then retrieve from backend and set in model
			if ($stateParams.mappedById === null) {
				return null;
			} else {
				return CarFactory.get({ id: $stateParams.mappedById }, function(result) {
					if (result !== null) {
						$scope.findItemByParent([result], 'car');
					}
				});
			}
		};
		$scope.carList = $scope.queryCar();

		// --- start default implementation
		/**
		 * This function queries all entities of type Location and checks if this Document dialog is called in the context of Location.
		 * If this is the case, we detect the parent entity Document and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryLocation = function() {
			return NoPagingLocationFactory.query(function(result) {
				$scope.findItemByParent(result, 'location');
			});
		};
		$scope.locationList = $scope.queryLocation();
		// --- end default implementation

		/**
		 * This function preselects and disables the parent reference in autocomplete field when called from tab
		 */
		$scope.queryRelation = function() {
			// If the id of a preselected relation is available, then retrieve from backend and set in model
			if ($stateParams.mappedById === null) {
				return null;
			} else {
				return RelationFactory.get({ id: $stateParams.mappedById }, function(result) {
					if (result !== null) {
						$scope.findItemByParent([result], 'relation');
					}
				});
			}
		};
		$scope.relationList = $scope.queryRelation();

		/**
		 * This function preselects and disables the parent reference in autocomplete field when called from tab
		 */
		$scope.queryEvent = function() {
			// If the id of a preselected event is available, then retrieve from backend and set in model
			if ($stateParams.mappedById === null) {
				return null;
			} else {
				return EventFactory.get({ id: $stateParams.mappedById }, function(result) {
					if (result !== null) {
						$scope.findItemByParent([result], 'event');
					}
				});
			}
		};
		$scope.eventList = $scope.queryEvent();

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:documentUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving Document failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		$scope.save = function() {
			var formData = [];
			$scope.submitButtonIsActive = false;
			if (!angular.isUndefined($scope.document.createdOn) && $scope.document.createdOn != null) {
				formData.push({ createdOn: $scope.document.createdOn });
			}
			if (!angular.isUndefined($scope.document.description) && $scope.document.description != null) {
				formData.push({ description: $scope.document.description });
			}
			if (!angular.isUndefined($scope.document.name) && $scope.document.name != null) {
				formData.push({ name: $scope.document.name });
			}
			if (!angular.isUndefined($scope.document.fileName) && $scope.document.fileName != null) {
				formData.push({ fileName: $scope.document.fileName });
			}
			if (!angular.isUndefined($scope.document.file) && $scope.document.file != null) {
				formData.push({ file: $scope.document.file });
			}
			if (!angular.isUndefined($scope.document.fileType) && $scope.document.fileType != null) {
				formData.push({ fileType: $scope.document.fileType });
			}
			if (!angular.isUndefined($scope.document.type) && $scope.document.type != null) {
				formData.push({ typeId: $scope.document.type.id });
			}
			if (!angular.isUndefined($scope.document.car) && $scope.document.car != null) {
				formData.push({ carId: $scope.document.car.id });
			}
			if (!angular.isUndefined($scope.document.location) && $scope.document.location != null) {
				formData.push({ locationId: $scope.document.location.id });
			}
			if (!angular.isUndefined($scope.document.relation) && $scope.document.relation != null) {
				formData.push({ relationId: $scope.document.relation.id });
			}
			if (!angular.isUndefined($scope.document.event) && $scope.document.event != null) {
				formData.push({ eventId: $scope.document.event.id });
			}
			if (!angular.isUndefined($scope.file.queue[0]) && isUploadReady) {
				$scope.file.queue[0].formData = formData;
				$scope.submitButtonIsActive = false;
				$scope.file.uploadAll(); // triggers the multipart form POST
			} else {
				if (isUploadReady) {
					AlertService.error('error.file.upload.not.selected', {});
				} else {
					AlertService.error(errorMessage, {});
				}
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};

		// this statement disables the file input
		isUploadDisabled = true;

		// executes when onchange is triggered for Document.DocumentType reference listbox
		// goal is to push the new filters to the uploader based on the chosen DocumentType
		// after this the file upload will be enabled
		$scope.changeDocumentType = function(documentType) {
			if (angular.isDefined(documentType)) {
				// reset the filters as well as the file upload queue and push a new file extention filter to it to ensure only one is active
				uploader.filters = [];
				clearFileInputAndQueue();
				uploader.filters.push({
					name: documentType.functionalType + 'Filter',
					fn: function(item /*{File|FileLikeObject}*/, options) {
						// TODO note the complex file type values for example for docx
						// |vnd.openxmlformats-officedocument.wordprocessingml.document|

						var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
						var hasType = documentType.technicalTypes.indexOf(type) !== -1;

						// console.log("has type by item.type: " + hasType);

						if (!hasType) {
							// if no item type has been dound
							var nameExt = '|' + item.name.slice(item.name.lastIndexOf('.') + 1) + '|';
							hasType = documentType.technicalTypes.indexOf(nameExt) !== -1;

							// console.log("has type by file name extention: " + hasType);
						}

						return hasType;
					},
				});
				isUploadDisabled = false; // enables the file input IF queue conditions are met in $scope.isDisableFileInput function
			} else {
				// no document type was chosen, disable file input
				isUploadDisabled = true;
			}
		};
	});
