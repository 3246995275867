'use strict';

angular.module('emsApp').controller("ModalStatusController", [
    "$scope", "$rootScope", "$state", "$uibModal", "$uibModalInstance", "$translate", "AlertService", "RelationSalesFactory",
    "AutocompleteRelationsByQueryParamsFactory", "AutocompleteCarsByQueryParamsFactory", "NoPagingTemplateEventFactory", "SubscriptionFindArticleFilteredByInsuranceFactory",
    function ($scope, $rootScope, $state, $uibModal, $uibModalInstance, $translate, AlertService, RelationSalesFactory,
              AutocompleteRelationsByQueryParamsFactory, AutocompleteCarsByQueryParamsFactory, NoPagingTemplateEventFactory, SubscriptionFindArticleFilteredByInsuranceFactory) {
        if ($rootScope.isDefinedAndNotNull($scope.subscriptionId)) {
            $scope.subtitle = $translate.instant("relation-sales.modal.subtitle-ok");
        } else {
            $scope.subtitle = $translate.instant("relation-sales.modal.subtitle-empty");
        }

        $scope.trainingTypeList = NoPagingTemplateEventFactory.query();
        $scope.insuranceList = SubscriptionFindArticleFilteredByInsuranceFactory.query();
        $scope.showSubscriptionDetails = false;
        $scope.subscriptionDetails = {};
        $scope.newSalesStatus = {};

        // Returns true if SalesStatus is eligible for subscription details
        var statusRequiresSubscriptionDetails = function(salesStatus) {
            return /^(PRESENT|WAIT_FOR_ANSWER_HOT)$/.test(salesStatus.value);
        };

        // add 'ok' checkmark to button of current status
        $scope.activeButtonClass = function(status) {
            if ($scope.currentStatus === status) {
                return "glyphicon glyphicon-ok";
            }
        };

        /**
         * Submits the new salesstatus. Results in showing subscription details, updating the salesstatus
         * on the event relation contact, or just closing the modal.
         */
        $scope.submitStatus = function(newSalesStatus) {
            if (statusRequiresSubscriptionDetails(newSalesStatus) && !statusRequiresSubscriptionDetails($scope.currentStatus)) {
                // either PRESENT or WAIT_FOR_ANSWER_HOT was chosen, an event relation status will be created during creation of booking
                $state.go('event.detail.booking', {
                    eventId: $scope.$parent.event.id,
                    bookingId: $scope.$parent.bookingId, // could be present, optional
                    driverId: $scope.$parent.subscriptionId,
                    relationId: $scope.$parent.relationId,
                    predefinedSubscriptionStatus: (newSalesStatus.value==="PRESENT") ? "CONFIRMED": "RESERVED"
                }, {reload: true});
            } else if ($scope.currentStatus !== newSalesStatus) {
                // just update status as no booking and subscription are required
                $scope.updateStatus($scope.currentStatus, newSalesStatus);
                $uibModalInstance.dismiss('status changed without subscription');
            } else {
                $uibModalInstance.dismiss('no status change');
            }
        };

        /**
         * Updates the status by creating or updating the EventRelationContact.
         * The payload dto may contain subscription details.
         * @param oldSalesStatus The existing sales status formatted as (e.g.) ASK
         * @param newSalesStatus The chosen sales status formatted as (e.g.) PRESENT
         */
        $scope.updateStatus = function (oldSalesStatus, newSalesStatus) {
            var dto = {};
            if ($scope.showSubscriptionDetails) {
                dto = {
                    secondDriverId: ($scope.subscriptionDetails.secondDriver || {}).id,
                    spectatorId: ($scope.subscriptionDetails.spectator || {}).id,
                    carId: ($scope.subscriptionDetails.car || {}).id,
                    insuranceId: ($scope.subscriptionDetails.insurance || {}).id,
                    trainingTypeId: ($scope.subscriptionDetails.trainingType || {}).id,
                    instructionHours: $scope.subscriptionDetails.instructionHours
                }
            }

            dto.driverEventRelationContact = {
                event: {id: $scope.event.id},
                relation: {id: $scope.relation.id},
                salesStatus: newSalesStatus.value
            };

            if ($rootScope.isDefinedAndNotNull(dto.secondDriverId)) {
                dto.secondDriverEventRelationContact = {
                    event: {id: $scope.event.id},
                    relation: {id: dto.secondDriverId},
                    salesStatus: newSalesStatus.value
                };
            }

            RelationSalesFactory.create(dto).$promise.then(function (result) {
                processSalesStatusResult(result, oldSalesStatus);
            }).catch(function (error) {
                AlertService.error("Creating or updating sales status failed", error);
            });

            $uibModalInstance.dismiss('save');
        };

        /**
         * Do things with result:
         *  - show message
         *  - update the stats of the affected event
         */
        var processSalesStatusResult = function (result, oldSalesStatus) {
            // update the sales status for this driver
            if (!$rootScope.isDefinedAndNotNull(oldSalesStatus.value)) {
                oldSalesStatus = "SalesStatus.ASK";
            }
            updateSalesStatus(result['driver'], oldSalesStatus);

            // emit the event relation contact for the second driver if the second driver exists
            if (result['secondDriver']) {
                $rootScope.$emit("updateSecondDriverSalesStatus", result['secondDriver']);
            }

            // update the event stats as subscriptions have been updated
            $rootScope.$emit("updateEventStats", result['driver']['eventPkId']);
        };

        /**
         * Returns only the name of the relationDisplayValue in the UserEventRelationContactListItemDto (defined by display.format in the model).
         * e.g. "(6546) Eric Cartman, Hallway Authorities"
         * becomes "Eric Cartman"
         */
        function formatRelationDisplayValue(dtoRelationDisplayValue) {
            return dtoRelationDisplayValue.split(/[),]\s*/)[1];
        }

        /**
         * Update the data on the page on update from UI
         * @param result EventRelationContact
         * @param oldSalesStatus Old SalesStatus to be used to inform the user
         */
        var updateSalesStatus = function (result, oldSalesStatus) {
            console.log('Updating sales status to ' + result.salesStatusDisplayValue + ' for ' + result.relationDisplayValue + ' on ' + result.eventDisplayValue);

            // loop over all available event relation contacts to see if there is a connection between the current event and relation
            angular.forEach($scope.eventContacts, function (value, key) {
                if (value['eventPkId'] === $scope.event.id) {
                    // if for this relation there is an entry in the array for this event, remove it as we will add the new UserEventRelationContactListItemDto
                    $scope.eventContacts.splice(key, 1);
                }
            });

            // add the new UserEventRelationContactListItemDto
            $scope.eventContacts.push(result);

            // render the new status
            $scope.renderStatus($scope.eventContacts, result.eventPkId);

            // inform the user on the new sales status of the driver
            var oldSalesStatusDisplayValue = oldSalesStatus.displayValue;
            var newSalesStatusDisplayValue = $scope.salesStatus[result['salesStatusDisplayValue']].displayValue;
            var relationDisplayValue = formatRelationDisplayValue(result['relationDisplayValue']);
            var msg = "Sales status updated from " + oldSalesStatusDisplayValue + " into " + newSalesStatusDisplayValue + " for " + relationDisplayValue + " on " + result['eventDisplayValue'];
            AlertService.success(msg);
        };

        $scope.autocompleteRelations = function(val) {
            var params = {
                'relationTypeAheadSearchString': val
            };

            return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
                return results;
            });
        };
        $scope.autocompleteCars = function(val) {
            var params = {
                'carTypeAheadSearchString': val
            };

            return AutocompleteCarsByQueryParamsFactory.query(params).$promise.then(function(results) {
                return results;
            });
        };
        // called from cancel button in dialog
        $scope.clear = function() {
            // dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
            $uibModalInstance.dismiss('cancel');
        };
    }]).controller('EventRelationStatusController',
    function ($scope, $rootScope, $translate, $uibModal, $document, _, AlertService, RelationSalesFactory, SalesRelationSubscriptionFactory) {

        // initial and default sales status value
        $scope.statusStyle = $scope.salesStatus["SalesStatus.ASK"]["style"];
        $scope.subscriptionId = null;
        $scope.bookingId = null;
        $scope.modalTitle = "";
        $scope.test = '';

        /**
         * Search all event contacts for the event, and if there is a match, show the sales status found
         * @param eventContacts
         * @param eventId
         * @returns {String} the sales status
         */
        $scope.renderStatus = function (eventContacts, eventId) {
            // initial / default status which is used in case no EventRelationContact is available
            var status = "SalesStatus.ASK";

            // loop over all available event relation contacts to see if there is a connection between the current event and relation
            angular.forEach(eventContacts, function (value, key) {
                if (value.eventPkId === eventId) {
                    status = value.salesStatusDisplayValue;
                }
            });

            $scope.currentStatus = $scope.salesStatus[status];
            $scope.statusCellStyle = _.merge($scope.statusCellStyle, $scope.salesStatus[status]["style"]);
            return $translate.instant(status);
        };

        /**
         * Listener to update formula for specific event
         */
        $rootScope.$on("updateSecondDriverSalesStatus", function($event, erc) { // NOTE: '$event' is a javascript event, NOT an EMS Event entity
            if ($rootScope.isDefinedAndNotNull(erc) && $scope.relation.id === erc.relationPkId && $scope.event.id === erc.eventPkId) {
                // stop event propagation once relevant sales status tile is found
                $event.stopPropagation();

                // slice the event contacts and push new event relation contact
                // loop over all available event relation contacts to see if there is a connection between the current event and relation
                angular.forEach($scope.eventContacts, function (value, key) {
                    if (value['eventPkId'] === $scope.event.id) {
                        // if for this relation there is an entry in the array for this event, remove it as we will add the new UserEventRelationContactListItemDto
                        $scope.eventContacts.splice(key, 1);
                    }
                });

                // add the new UserEventRelationContactListItemDto so that the status is updated automatically
                $scope.eventContacts.push(erc);
            }
        });

        $scope.openModal = function (size, parentSelector) {
            var parentElem = parentSelector ? angular.element($document[0].querySelector(parentSelector)) : undefined;

            SalesRelationSubscriptionFactory.get({
                relationId: $scope.relation.id,
                eventId: $scope.event.id
            }).$promise.then(function (result) {
                $scope.subscriptionId = result.subscriptionId; // can be null
                $scope.bookingId = result.bookingId; // can be null
                $scope.personName = result.personName;
                $scope.eventName = result.eventName;
                $scope.relationId = $scope.relation.id;

                $scope.modalInstance = $uibModal.open({
                    animation: true,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'statusUpdateModal.html',
                    controller: 'ModalStatusController',
                    // controller: 'EventRelationStatusController',
                    scope: $scope,
                    resolve: {
                        subscriptionId: function () {
                            return $scope.subscriptionId;
                        },
                        bookingId: function () {
                            return $scope.bookingId;
                        },
                        relationId: function() {
                            return $scope.relation.id;
                        }
                    },
                    // size: size,
                    appendTo: parentElem
                });
            });
        };
    });
