/** @format */

'use strict';

angular.module('emsApp').controller('BaseModelDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.baseModel = entity;
	$scope.objectName = 'BaseModel';
	$scope.baseModelId = $stateParams.baseModelId;

	if (angular.isUndefined($rootScope.baseModelActiveTab)) {
		//set the default here
		$rootScope.baseModelActiveTab = 0;
	}

	$scope.goToEditState = function(baseModel) {
		$state.go('basemodel.detail.edit({baseModelId:baseModel.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:baseModelUpdate', function(event, result) {
		$scope.baseModel = result;
	});
});
