/** @format */

'use strict';

// Relation has edit layout enabled. Edit layout template url: relation-dialoglayout.html
// Relation has detail layout enabled. Detail layout template url: relation-detaillayout.html
angular.module('emsApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Relation'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('relation'); // main translation loaded for relation
		$translatePartialLoader.addPart('relationtype'); // one to many reference from main entity
		$translatePartialLoader.addPart('relationsource'); // one to many reference from main entity
		$translatePartialLoader.addPart('gender'); // one to many reference from main entity
		$translatePartialLoader.addPart('clientof'); // one to many reference from main entity
		$translatePartialLoader.addPart('langkey'); // one to many reference from main entity
		$translatePartialLoader.addPart('companytype'); // one to many reference from main entity
		$translatePartialLoader.addPart('user'); // one to many reference from main entity
		$translatePartialLoader.addPart('relationmailgroup'); // one to many reference from main entity
		$translatePartialLoader.addPart('booking'); // one to many reference from main entity
		$translatePartialLoader.addPart('bookingstatus'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('confirmationemailstatus'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('car'); // one to many reference from main entity
		$translatePartialLoader.addPart('subscription'); // one to many reference from main entity
		$translatePartialLoader.addPart('subscriptiontype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('subscriptionstatus'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('document'); // one to many reference from main entity
		$translatePartialLoader.addPart('instructor'); // one to many reference from main entity
		$translatePartialLoader.addPart('eventrelationcontact'); // one to many reference from main entity
		$translatePartialLoader.addPart('salesstatus'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('country'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlerelationprice'); // one to many reference from main entity

		return $translate.refresh();
	};

	/**
	 * "private" function called by wrapper new and edit functions.
	 */
	var getModalState = function(url, isEditMode) {
		if (!angular.isDefined(url) || url === null) {
			url = '/new';
		}

		if (!angular.isDefined(isEditMode) || isEditMode === null) {
			// default mode is NEW, however this situation should and will be avoided by as this function should only be
			isEditMode = false;
		}

		var templateUrl;
		// The default assignment to templateUrl can be overridden in hook custom/jhipster/state/template_url/add_or_edit.ftl
		templateUrl = 'app/entities/relation/relation-dialoglayout.html';

		return {
			/* new state for entity relation */
			/* to access: ui-ref="relation.list.new" */
			/* url: hostname/relation/new */
			url: url,
			data: {
				authorities: ['ROLE_USER'],
				isModal: true,
			},
			params: {
				mappedBy: null,
				mappedByType: null,
				mappedById: null,
				dataModel: null, // this data model is set from the new relation state OR from the save and new logic
				mappedByDisplayValue: null,
				dataModelType: null, // this data model type is the name of the modelobject, lowercase
				referredBy: null, // will be populated once this new state is triggered from another new state with + button
				hiddenParam: 'YES', // hides params from url
			},
			onEnter: [
				'$stateParams',
				'$state',
				'$uibModal',
				'$rootScope',
				'HistoryService',
				function($stateParams, $state, $uibModal, $rootScope, HistoryService) {
					var navigateBack = true;

					$uibModal
						.open({
							templateUrl: templateUrl,
							controller: 'RelationDialogController',
							size: 'lg',
							backdrop: 'static',
							resolve: {
								entity: [
									'RelationFactory',
									'NewRelationFactory',
									function(RelationFactory, NewRelationFactory) {
										// NOTE either this dialog was opened with the purpose of
										// - creating a new Relation
										// - resuming the creation of a new new Relation AFTER new instances were created of x..1 reference(s)

										// if dataModel is defined, we're coming back from a new created x..1 reference
										if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
											var model = $stateParams.dataModel;
											// reset 'remembered' values to prevent future misbehaviour
											$rootScope.previousStateParams.dataModel = null;
											$stateParams.dataModel = null;

											// instead of returning a new model, we return the updated model
											return model;
										} else {
											if (isEditMode) {
												// get the entity by its ID
												return RelationFactory.get({ id: $stateParams.relationId }).$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											} else {
												// get a new empty entity
												return NewRelationFactory.get().$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											}
										}
									},
								],
								translatePartialLoader: [
									'$translate',
									'$translatePartialLoader',
									function($translate, $translatePartialLoader) {
										return getTranslations($translate, $translatePartialLoader);
									},
								],
							},
						})
						.result.then(function(result) {
							// process success result, if necessary
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// set relation as reference in the referredBy data model
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;
							}
						})
						.catch(function(closeReason) {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// reset relation in referred data model object
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = null;
							}
							if (closeReason === 'createNew') {
								// only navigate back in history if the close reason allows us to
								navigateBack = false;
							}
						})
						.finally(function() {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
							}

							if (navigateBack) {
								$rootScope.back();
							}
						});
				},
			],
		};
	};

	/**
	 * Body for relation new state
	 */
	function getNewState(url) {
		return getModalState(url, false);
	}

	/**
	 * Body for relation edit state
	 */
	function getEditState(url) {
		return getModalState(url, true);
	}

	$stateProvider.state('relation', {
		/* abstract state for entity relation */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relation',
		data: {
			authorities: ['ROLE_USER'],
			pageTitle: 'Relations',
			isModal: false,
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	$stateProvider.state('relation.list', {
		/* list state for entity relation */
		/* to access: ui-ref="relation.list" */
		/* url: hostname/relation/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/relation/relations.html',
				controller: 'RelationController',
			},
		},
	});

	$stateProvider.state('relation.detail', {
		/* detail state for entity relation */
		/* to access: ui-sref="relation.detail" */
		/* url: hostname/relation/detail/:relationId */
		url: '/detail/:relationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/relation/relation-detaillayout.html',
				controller: 'RelationDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationFactory',
				function($stateParams, RelationFactory) {
					return RelationFactory.get({ id: $stateParams.relationId });
				},
			],
		},
	});

	/* url: http://<hostname>/relation/list/new */
	$stateProvider.state('relation.list.new', getNewState());
	/* url: http://<hostname>/relation/list/:relationId/edit */
	$stateProvider.state('relation.list.edit', getEditState('/:relationId/edit'));
	/* url: http://<hostname>/relation/detail/:relationId/edit */
	$stateProvider.state('relation.detail.edit', getEditState('/edit'));

	$stateProvider.state('subscription.list.newRelation', getNewState('/relation/new'));
	$stateProvider.state('relationmailgroup.list.newRelation', getNewState('/relation/new'));
	$stateProvider.state('car.list.newRelation', getNewState('/relation/new'));
	$stateProvider.state('booking.list.newRelation', getNewState('/relation/new'));
	$stateProvider.state('document.list.newRelation', getNewState('/relation/new'));
	$stateProvider.state('instructor.list.newRelation', getNewState('/relation/new'));

	$stateProvider.state('event.detail.booking.newRelation', getNewState('/relation/new'));

	$stateProvider.state('user-relation', {
		/* abstract state for DTO UserRelationListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/user/relation',
		data: {
			authorities: ['ROLE_USER', 'ROLE_ADMIN'],
			pageTitle: 'Relation',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO user */
	var dtoListState = {
		/* list state for DTO UserRelationListDto */
		/* to access: ui-ref="user-relation.list" */
		/* url: http://<hostname>/user/relation/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/relation/relation-list-user.html',
				controller: 'UserRelationListDtoController',
			},
		},
	};

	$stateProvider.state('user-relation.list', dtoListState);

	/* detail state for DTO user */
	var dtoDetailState = {
		/* detail state for DTO UserRelationViewDto */
		/* to access: ui-sref="user-relation.detail" */
		/* url: http://<hostname>/user/relation/detail/:relationId */
		url: '/detail/:relationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/relation/relation-view-user.html',
				controller: 'RelationDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'UserRelationViewDtoFactory',
				function($stateParams, UserRelationViewDtoFactory) {
					return UserRelationViewDtoFactory.get({ id: $stateParams.relationId });
				},
			],
		},
	};

	$stateProvider.state('user-relation.detail', dtoDetailState);

	/* edit state for DTO user */
	var dtoEditState = {
		/* edit state for DTO UserRelationEditDto */
		/* to access: ui-sref="user-relation.edit" */
		/* url: http://<hostname>/user/relation/edit/:relationId */
		url: '/edit/:relationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/relation/relation-edit-user.html',
				controller: 'UserRelationEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'UserRelationEditDtoFactory',
				function($stateParams, $rootScope, UserRelationEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return UserRelationEditDtoFactory.get({ id: $stateParams.relationId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('user-relation.edit', dtoEditState);

	/* new (create) state for DTO user */
	var dtoCreateState = {
		/* new state for DTO UserRelationEditDto */
		/* to access: ui-sref="user-relation.new" */
		/* url: http://<hostname>/user/relation/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/relation/relation-edit-user.html',
				controller: 'UserRelationEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'UserRelationNewDtoFactory',
				function($stateParams, $rootScope, UserRelationNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return UserRelationNewDtoFactory.get({ id: $stateParams.relationId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('user-relation.new', dtoCreateState);

	/* Bulk edit state for DTO user */
	var dtoBulkEditState = {
		/* Bulk edit state for DTO UserRelationEditDto */
		/* to access: ui-sref="user-relation.bulkedit" */
		/* url: http://<hostname>/user/relation/bulkedit */
		url: '/bulkedit',
		views: {
			'content@': {
				templateUrl: 'app/entities/relation/relation-bulkedit-user.html',
				controller: 'UserRelationBulkEditDtoController',
			},
		},
		params: {
			selectedRelationList: [''],
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'UserRelationNewBulkEditDtoFactory',
				function($stateParams, $rootScope, UserRelationNewBulkEditDtoFactory) {
					return UserRelationNewBulkEditDtoFactory.get().$promise.then(function(result) {
						return _.merge(result, { type: 'UserRelationBulkEditDto' });
					});
				},
			],
		},
	};

	$stateProvider.state('user-relation.bulkedit', dtoBulkEditState);

	// create state for plus button on Subscription edit / new page for reference Relation and dto property value user
	$stateProvider.state('user-subscription.new.newRelation', getCreateState('/relation/new'));
	$stateProvider.state('user-subscription.edit.newRelation', getCreateState('/relation/new'));
	// create state for plus button on RelationMailGroup edit / new page for reference Relation and dto property value user
	$stateProvider.state('user-relationmailgroup.new.newRelation', getCreateState('/relation/new'));
	$stateProvider.state('user-relationmailgroup.edit.newRelation', getCreateState('/relation/new'));
	// create state for plus button on Car edit / new page for reference Relation and dto property value user
	$stateProvider.state('user-car.new.newRelation', getCreateState('/owner/new'));
	$stateProvider.state('user-car.edit.newRelation', getCreateState('/owner/new'));
	// create state for plus button on Booking edit / new page for reference Relation and dto property value user
	$stateProvider.state('user-booking.new.newRelation', getCreateState('/client/new'));
	$stateProvider.state('user-booking.edit.newRelation', getCreateState('/client/new'));
	// create state for plus button on Document edit / new page for reference Relation and dto property value user
	$stateProvider.state('user-document.new.newRelation', getCreateState('/relation/new'));
	$stateProvider.state('user-document.edit.newRelation', getCreateState('/relation/new'));
	// create state for plus button on Instructor edit / new page for reference Relation and dto property value user
	$stateProvider.state('user-instructor.new.newRelation', getCreateState('/relation/new'));
	$stateProvider.state('user-instructor.edit.newRelation', getCreateState('/relation/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/relation/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
