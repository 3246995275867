/** @format */

'use strict';

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('emsApp').directive('bookingsOverviewMappedByEvent', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/booking/booking-tab.html',
		controller: 'BookingTabController',
		scope: {
			eventId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('emsApp').directive('documentsOverviewMappedByEvent', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/document/document-tab.html',
		controller: 'DocumentTabController',
		scope: {
			eventId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('emsApp').directive('instructorsOverviewMappedByEvent', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/instructor/instructor-tab.html',
		controller: 'InstructorTabController',
		scope: {
			eventId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('emsApp').directive('eventrelationcontactsOverviewMappedByEvent', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/eventrelationcontact/eventrelationcontact-tab.html',
		controller: 'EventRelationContactTabController',
		scope: {
			eventId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('emsApp').directive('userEventRelationContactMappedByEvent', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/eventrelationcontact/eventrelationcontact-list-user.tab.html',
		controller: 'UserEventRelationContactListDtoTabController',
		scope: {
			eventId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
