/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('MailGroupTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		MailGroupFactory,
		FindAllMailGroupsByQueryParamsNonPageableFactory,
		NoPagingMailGroupFactory
	) {
		$scope.mailGroupList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newMailGroup', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(mailGroup) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editMailGroup', {
				mailGroupId: mailGroup.id,
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
			});
		};

		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.MailGroupSearchTab = angular.copy($scope.MailGroupSearchTab);

			FindAllMailGroupsByQueryParamsNonPageableFactory.query(
				{
					name: $scope.MailGroupSearchTab.name,

					sort: [$scope.MailGroupSearchTab.predicate + ',' + ($scope.MailGroupSearchTab.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.mailGroupList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isMailGroupSearchTabDirty = function() {
			return !angular.equals($scope.MailGroupSearchTab, $scope.originalMailGroupSearchTab);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.MailGroupSearchTab = {};
			$rootScope.MailGroupSearchTab.predicate = 'id';
			$rootScope.MailGroupSearchTab.ascending = false;

			$scope.MailGroupSearchTab = angular.copy($rootScope.MailGroupSearchTab);

			$scope.originalMailGroupSearchTab = angular.copy($scope.MailGroupSearchTab);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.MailGroupSearchTab) || $rootScope.MailGroupSearchTab === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.MailGroupSearchTab = angular.copy($rootScope.MailGroupSearchTab);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedMailGroupId = id;
			$('#deleteMailGroupConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			MailGroupFactory.delete(
				{ id: $scope.selectedMailGroupId },
				function() {
					$scope.search();
					$('#deleteMailGroupConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteMailGroupConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.MailGroupSearchTab.predicate === itemName) {
				if ($scope.MailGroupSearchTab.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
