/** @format */

'use strict';

// Event has edit layout enabled. Edit layout template url: event-dialog.html
// Event has detail layout enabled. Detail layout template url: event-eventdetail.html
angular.module('emsApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Event'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('event'); // main translation loaded for event
		$translatePartialLoader.addPart('eventstatus'); // one to many reference from main entity
		$translatePartialLoader.addPart('templateevent'); // one to many reference from main entity
		$translatePartialLoader.addPart('location'); // one to many reference from main entity
		$translatePartialLoader.addPart('booking'); // one to many reference from main entity
		$translatePartialLoader.addPart('bookingstatus'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('confirmationemailstatus'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('document'); // one to many reference from main entity
		$translatePartialLoader.addPart('instructor'); // one to many reference from main entity
		$translatePartialLoader.addPart('subscriptionstatus'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('eventrelationcontact'); // one to many reference from main entity
		$translatePartialLoader.addPart('salesstatus'); // one to one or many to one reference from main entity

		return $translate.refresh();
	};

	/**
	 * "private" function called by wrapper new and edit functions.
	 */
	var getModalState = function(url, isEditMode) {
		if (!angular.isDefined(url) || url === null) {
			url = '/new';
		}

		if (!angular.isDefined(isEditMode) || isEditMode === null) {
			// default mode is NEW, however this situation should and will be avoided by as this function should only be
			isEditMode = false;
		}

		var templateUrl;
		// The default assignment to templateUrl can be overridden in hook custom/jhipster/state/template_url/add_or_edit.ftl
		templateUrl = 'app/entities/event/event-dialog.html';

		return {
			/* new state for entity event */
			/* to access: ui-ref="event.list.new" */
			/* url: hostname/event/new */
			url: url,
			data: {
				authorities: ['ROLE_USER'],
				isModal: true,
			},
			params: {
				mappedBy: null,
				mappedByType: null,
				mappedById: null,
				dataModel: null, // this data model is set from the new relation state OR from the save and new logic
				mappedByDisplayValue: null,
				dataModelType: null, // this data model type is the name of the modelobject, lowercase
				referredBy: null, // will be populated once this new state is triggered from another new state with + button
				hiddenParam: 'YES', // hides params from url
			},
			onEnter: [
				'$stateParams',
				'$state',
				'$uibModal',
				'$rootScope',
				'HistoryService',
				function($stateParams, $state, $uibModal, $rootScope, HistoryService) {
					var navigateBack = true;

					$uibModal
						.open({
							templateUrl: templateUrl,
							controller: 'EventDialogController',
							size: 'lg',
							backdrop: 'static',
							resolve: {
								entity: [
									'EventFactory',
									'NewEventFactory',
									function(EventFactory, NewEventFactory) {
										// NOTE either this dialog was opened with the purpose of
										// - creating a new Event
										// - resuming the creation of a new new Event AFTER new instances were created of x..1 reference(s)

										// if dataModel is defined, we're coming back from a new created x..1 reference
										if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
											var model = $stateParams.dataModel;
											// reset 'remembered' values to prevent future misbehaviour
											$rootScope.previousStateParams.dataModel = null;
											$stateParams.dataModel = null;

											// instead of returning a new model, we return the updated model
											return model;
										} else {
											if (isEditMode) {
												// get the entity by its ID
												return EventFactory.get({ id: $stateParams.eventId }).$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											} else {
												// get a new empty entity
												return NewEventFactory.get().$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											}
										}
									},
								],
								translatePartialLoader: [
									'$translate',
									'$translatePartialLoader',
									function($translate, $translatePartialLoader) {
										return getTranslations($translate, $translatePartialLoader);
									},
								],
							},
						})
						.result.then(function(result) {
							// process success result, if necessary
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// set relation as reference in the referredBy data model
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;
							}
						})
						.catch(function(closeReason) {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// reset relation in referred data model object
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = null;
							}
							if (closeReason === 'createNew') {
								// only navigate back in history if the close reason allows us to
								navigateBack = false;
							}
						})
						.finally(function() {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
							}

							if (navigateBack) {
								$rootScope.back();
							}
						});
				},
			],
		};
	};

	/**
	 * Body for event new state
	 */
	function getNewState(url) {
		return getModalState(url, false);
	}

	/**
	 * Body for event edit state
	 */
	function getEditState(url) {
		return getModalState(url, true);
	}

	$stateProvider.state('event', {
		/* abstract state for entity event */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/event',
		data: {
			authorities: ['ROLE_USER'],
			pageTitle: 'Events',
			isModal: false,
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	$stateProvider.state('event.list', {
		/* list state for entity event */
		/* to access: ui-ref="event.list" */
		/* url: hostname/event/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/event/events.html',
				controller: 'EventController',
			},
		},
	});

	$stateProvider.state('event.detail', {
		/* detail state for entity event */
		/* to access: ui-sref="event.detail" */
		/* url: hostname/event/detail/:eventId */
		url: '/detail/:eventId',
		views: {
			'content@': {
				templateUrl: 'app/entities/event/event-eventdetail.html',
				controller: 'EventDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'EventFactory',
				function($stateParams, EventFactory) {
					return EventFactory.get({ id: $stateParams.eventId });
				},
			],
		},
	});

	/* url: http://<hostname>/event/list/new */
	$stateProvider.state('event.list.new', getNewState());
	/* url: http://<hostname>/event/list/:eventId/edit */
	$stateProvider.state('event.list.edit', getEditState('/:eventId/edit'));
	/* url: http://<hostname>/event/detail/:eventId/edit */
	$stateProvider.state('event.detail.edit', getEditState('/edit'));
	// TemplateEvent.event with multiplicity 0..n points to Event
	/* url: http://<hostname>/templateevent/detail/:templateEventId/event/new */
	$stateProvider.state('templateevent.detail.newEvent', getNewState('/event/new'));
	/* url: http://<hostname>/templateevent/detail/:templateEventId/event/:eventId/edit */
	$stateProvider.state('templateevent.detail.editEvent', getEditState('/event/:eventId/edit'));
	// Location.event with multiplicity 0..n points to Event
	/* url: http://<hostname>/location/detail/:locationId/event/new */
	$stateProvider.state('location.detail.newEvent', getNewState('/event/new'));
	/* url: http://<hostname>/location/detail/:locationId/event/:eventId/edit */
	$stateProvider.state('location.detail.editEvent', getEditState('/event/:eventId/edit'));

	$stateProvider.state('booking.list.newEvent', getNewState('/event/new'));
	$stateProvider.state('document.list.newEvent', getNewState('/event/new'));

	$stateProvider.state('event.detail.subscriptions.edit', getEditState('/edit'));

	$stateProvider.state('event.detail.subscriptions', {
		/* detail state for entity event with custom subscriptions overview*/
		url: '/subscriptions',
		data: {
			authorities: ['ROLE_USER'],
			pageTitle: 'Event',
		},
		views: {
			'content@': {
				templateUrl: 'app/entities/event/event-detail-with-subscriptions.html',
				controller: 'EventDetailWithSubscriptionsController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'EventFactory',
				function($stateParams, EventFactory) {
					return EventFactory.get({
						id: $stateParams.eventId,
					});
				},
			],
		},
	});

	$stateProvider.state('event.detail.booking', {
		/* detail state for entity event with custom subscriptions overview*/
		url: '/booking/:bookingId',
		data: {
			authorities: ['ROLE_USER'],
			pageTitle: 'Event',
		},
		views: {
			'content@': {
				templateUrl: 'app/entities/event/booking-subscription-details.html',
				controller: 'BookingSubscriptionDetailsController',
			},
		},
		params: {
			predefinedSubscriptionStatus: 'CONFIRMED', // can be set to reserved or confirmed
			relationId: null,
			driverId: null,
			dataModel: null, // the datamodel that is filled with the created entity from a modal dialog
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('car'); // main translation loaded for event
					$translatePartialLoader.addPart('subscription'); // main translation loaded for event
					$translatePartialLoader.addPart('subscriptiontype'); // main translation loaded for subscriptiontype
					return $translate.refresh();
				},
			],
			entity: [
				'$stateParams',
				'$rootScope',
				'$q',
				'BookingFactory',
				'NewBookingFactory',
				'EventFactory',
				'RelationFactory',
				function($stateParams, $rootScope, $q, BookingFactory, NewBookingFactory, EventFactory, RelationFactory) {
					if ($rootScope.isDefinedAndNotNull($stateParams.bookingId) && $stateParams.bookingId !== '') {
						return BookingFactory.get({
							id: $stateParams.bookingId,
						});
					} else {
						// get the event by it's id, plus get a new booking
						var eventPromise = EventFactory.get({ id: $stateParams.eventId }).$promise.then(function(result) {
							return result;
						});
						var newBookingPromise = NewBookingFactory.get().$promise.then(function(result) {
							return result;
						});
						var relationPromise = $stateParams.relationId
							? RelationFactory.get({ id: $stateParams.relationId }).$promise.then(function(result) {
									return result;
							  })
							: $q.resolve(null);

						// once both event and booking are retrieved we update the booking with the event and return the booking as entity
						return $q.all({ event: eventPromise, booking: newBookingPromise, client: relationPromise }).then(function(results) {
							var booking = results.booking;
							booking.event = results.event;
							if (results.client) {
								// this part is only relevant when the page is opened from the sales relation dashboard
								booking.client = results.client;
							}
							return booking;
						});
					}
				},
			],
		},
	});

	$stateProvider.state('event.dashboard', {
		/* dashboardstate for entity event */
		/* to access: ui-ref="event.dashboard" */
		/* url: hostname/event/dashboard */
		url: '/dashboard',
		views: {
			'content@': {
				templateUrl: 'app/sales/events-dashboard.html',
				controller: 'EventDashboardController',
			},
		},
	});
	/* url: http://<hostname>/event/dashboard/new */
	$stateProvider.state('event.dashboard.new', getNewState());
	/* url: http://<hostname>/event/dashboard/:eventId/edit */
	$stateProvider.state('event.dashboard.edit', getEditState('/:eventId/edit'));

	$stateProvider.state('user-event', {
		/* abstract state for DTO UserEventListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/user/event',
		data: {
			authorities: ['ROLE_USER', 'ROLE_ADMIN'],
			pageTitle: 'Event',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO user */
	var dtoListState = {
		/* list state for DTO UserEventListDto */
		/* to access: ui-ref="user-event.list" */
		/* url: http://<hostname>/user/event/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/event/event-list-user.html',
				controller: 'UserEventListDtoController',
			},
		},
	};

	$stateProvider.state('user-event.list', dtoListState);

	/* edit state for DTO user */
	var dtoEditState = {
		/* edit state for DTO UserEventEditDto */
		/* to access: ui-sref="user-event.edit" */
		/* url: http://<hostname>/user/event/edit/:eventId */
		url: '/edit/:eventId',
		views: {
			'content@': {
				templateUrl: 'app/entities/event/event-edit-user.html',
				controller: 'UserEventEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'UserEventEditDtoFactory',
				function($stateParams, $rootScope, UserEventEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as templateEvent has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.templateEvent)) {
							// push the created templateEvent to the list with selectable templateEvent items
							$stateParams.dataModel.templateEventList.push($stateParams.dataModel.templateEvent);

							// set the created templateEvent as selected templateEvent
							$stateParams.dataModel.selectedTemplateEvent = $stateParams.dataModel.templateEvent;
						}
						// as location has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.location)) {
							// push the created location to the list with selectable location items
							$stateParams.dataModel.locationList.push($stateParams.dataModel.location);

							// set the created location as selected location
							$stateParams.dataModel.selectedLocation = $stateParams.dataModel.location;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return UserEventEditDtoFactory.get({ id: $stateParams.eventId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('user-event.edit', dtoEditState);

	/* new (create) state for DTO user */
	var dtoCreateState = {
		/* new state for DTO UserEventEditDto */
		/* to access: ui-sref="user-event.new" */
		/* url: http://<hostname>/user/event/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/event/event-edit-user.html',
				controller: 'UserEventEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'UserEventNewDtoFactory',
				function($stateParams, $rootScope, UserEventNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as templateEvent has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.templateEvent)) {
							// push the created templateEvent to the list with selectable templateEvent items
							$stateParams.dataModel.templateEventList.push($stateParams.dataModel.templateEvent);

							// set the created templateEvent as selected templateEvent
							$stateParams.dataModel.selectedTemplateEvent = $stateParams.dataModel.templateEvent;
						}
						// as location has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.location)) {
							// push the created location to the list with selectable location items
							$stateParams.dataModel.locationList.push($stateParams.dataModel.location);

							// set the created location as selected location
							$stateParams.dataModel.selectedLocation = $stateParams.dataModel.location;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return UserEventNewDtoFactory.get({ id: $stateParams.eventId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('user-event.new', dtoCreateState);

	// create state for plus button on Booking edit / new page for reference Event and dto property value user
	$stateProvider.state('user-booking.new.newEvent', getCreateState('/event/new'));
	$stateProvider.state('user-booking.edit.newEvent', getCreateState('/event/new'));
	// create state for plus button on Document edit / new page for reference Event and dto property value user
	$stateProvider.state('user-document.new.newEvent', getCreateState('/event/new'));
	$stateProvider.state('user-document.edit.newEvent', getCreateState('/event/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/event/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
