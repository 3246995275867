/** @format */

(function() {
	'use strict';

	angular.module('emsApp').factory('Register', Register);

	Register.$inject = ['$resource'];

	function Register($resource) {
		return $resource('api/register', {}, {});
	}
})();
