/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('ArticleController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ArticleFactory,
		FindAllArticlesByQueryParamsFactory,
		NoPagingArticleFactory,
		NoPagingArticleTypeFactory,
		NoPagingVatTypeFactory
	) {
		$scope.articleList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeArticle)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeArticle);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeArticle = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('article.list.new');
		};

		$scope.goToEditState = function(article) {
			// this overview is NOT mapped, it is the main overview
			$state.go('article.list.edit', { articleId: article.id });
		};

		var getArticleTypeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.ArticleSearchOverview.articleType)) {
				return $scope.ArticleSearchOverview.articleType.id;
			} else {
				return undefined;
			}
		};

		/**
		 * This function queries all entities of type ArticleType and checks if this Article dialog is called in the context of ArticleType.
		 * If this is the case, we detect the parent entity Article and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryArticleType = function() {
			return NoPagingArticleTypeFactory.query(function(result) {
				return result;
			});
		};
		$scope.articleTypeReferenceFilterList = $scope.queryArticleType();

		NoPagingVatTypeFactory.query(function(result) {
			$scope.vatTypeList = result;
		});
		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.ArticleSearchOverview = angular.copy($scope.ArticleSearchOverview);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeArticle = angular.copy($scope.pageSize);

			FindAllArticlesByQueryParamsFactory.query(
				{
					page: $scope.ArticleSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.ArticleSearchOverview.name,
					articleTypeId: getArticleTypeId(),
					vatType: $scope.ArticleSearchOverview.vatType,

					sort: [$scope.ArticleSearchOverview.predicate + ',' + ($scope.ArticleSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.articleList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isArticleSearchOverviewDirty = function() {
			return !angular.equals($scope.ArticleSearchOverview, $scope.originalArticleSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.ArticleSearchOverview = {};
			$rootScope.ArticleSearchOverview.page = 1;
			$rootScope.ArticleSearchOverview.predicate = 'id';
			$rootScope.ArticleSearchOverview.ascending = false;

			$scope.ArticleSearchOverview = angular.copy($rootScope.ArticleSearchOverview);

			$scope.originalArticleSearchOverview = angular.copy($scope.ArticleSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.ArticleSearchOverview) || $rootScope.ArticleSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.ArticleSearchOverview = angular.copy($rootScope.ArticleSearchOverview);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedArticleId = id;
			$('#deleteArticleConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			ArticleFactory.delete(
				{ id: $scope.selectedArticleId },
				function() {
					$scope.search();
					$('#deleteArticleConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteArticleConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('emsApp:articleUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.ArticleSearchOverview.predicate === itemName) {
				if ($scope.ArticleSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
