/** @format */

'use strict';

angular.module('emsApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CompanyType'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('companytype'); // main translation loaded for companytype

		return $translate.refresh();
	};

	/**
	 * "private" function called by wrapper new and edit functions.
	 */
	var getModalState = function(url, isEditMode) {
		if (!angular.isDefined(url) || url === null) {
			url = '/new';
		}

		if (!angular.isDefined(isEditMode) || isEditMode === null) {
			// default mode is NEW, however this situation should and will be avoided by as this function should only be
			isEditMode = false;
		}

		var templateUrl;
		// The default assignment to templateUrl can be overridden in hook custom/jhipster/state/template_url/add_or_edit.ftl
		templateUrl = 'app/entities/companytype/companytype-dialog.html';

		return {
			/* new state for entity companyType */
			/* to access: ui-ref="companytype.list.new" */
			/* url: hostname/companytype/new */
			url: url,
			data: {
				authorities: ['ROLE_USER'],
				isModal: true,
			},
			params: {
				mappedBy: null,
				mappedByType: null,
				mappedById: null,
				referredBy: null, // will be populated once this new state is triggered from another new state with + button
				hiddenParam: 'YES', // hides params from url
			},
			onEnter: [
				'$stateParams',
				'$state',
				'$uibModal',
				'$rootScope',
				'HistoryService',
				function($stateParams, $state, $uibModal, $rootScope, HistoryService) {
					var navigateBack = true;

					$uibModal
						.open({
							templateUrl: templateUrl,
							controller: 'CompanyTypeDialogController',
							size: 'lg',
							backdrop: 'static',
							resolve: {
								entity: [
									'CompanyTypeFactory',
									'NewCompanyTypeFactory',
									function(CompanyTypeFactory, NewCompanyTypeFactory) {
										if (isEditMode) {
											// get the entity by its ID
											return CompanyTypeFactory.get({ id: $stateParams.companyTypeId }).$promise.then(
												function(data) {
													return data;
												},
												function(error) {
													return error;
												}
											);
										} else {
											// get a new empty entity
											return NewCompanyTypeFactory.get().$promise.then(
												function(data) {
													return data;
												},
												function(error) {
													return error;
												}
											);
										}
									},
								],
								translatePartialLoader: [
									'$translate',
									'$translatePartialLoader',
									function($translate, $translatePartialLoader) {
										return getTranslations($translate, $translatePartialLoader);
									},
								],
							},
						})
						.result.then(function(result) {
							// process success result, if necessary
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// set relation as reference in the referredBy data model
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;
							}
						})
						.catch(function(closeReason) {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// reset relation in referred data model object
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = null;
							}
							if (closeReason === 'createNew') {
								// only navigate back in history if the close reason allows us to
								navigateBack = false;
							}
						})
						.finally(function() {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
							}

							if (navigateBack) {
								$rootScope.back();
							}
						});
				},
			],
		};
	};

	/**
	 * Body for companyType new state
	 */
	function getNewState(url) {
		return getModalState(url, false);
	}

	/**
	 * Body for companyType edit state
	 */
	function getEditState(url) {
		return getModalState(url, true);
	}

	$stateProvider.state('companytype', {
		/* abstract state for entity companyType */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/companytype',
		data: {
			authorities: ['ROLE_USER'],
			pageTitle: 'CompanyTypes',
			isModal: false,
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	$stateProvider.state('companytype.list', {
		/* list state for entity companyType */
		/* to access: ui-ref="companytype.list" */
		/* url: hostname/companytype/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/companytype/companytypes.html',
				controller: 'CompanyTypeController',
			},
		},
	});

	$stateProvider.state('companytype.detail', {
		/* detail state for entity companyType */
		/* to access: ui-sref="companytype.detail" */
		/* url: hostname/companytype/detail/:companyTypeId */
		url: '/detail/:companyTypeId',
		views: {
			'content@': {
				templateUrl: 'app/entities/companytype/companytype-detail.html',
				controller: 'CompanyTypeDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CompanyTypeFactory',
				function($stateParams, CompanyTypeFactory) {
					return CompanyTypeFactory.get({ id: $stateParams.companyTypeId });
				},
			],
		},
	});

	/* url: http://<hostname>/companytype/list/new */
	$stateProvider.state('companytype.list.new', getNewState());
	/* url: http://<hostname>/companytype/list/:companyTypeId/edit */
	$stateProvider.state('companytype.list.edit', getEditState('/:companyTypeId/edit'));
	/* url: http://<hostname>/companytype/detail/:companyTypeId/edit */
	$stateProvider.state('companytype.detail.edit', getEditState('/edit'));

	$stateProvider.state('relation.list.newCompanyType', getNewState('/companytype/new'));
});
