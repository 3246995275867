/** @format */

'use strict';

angular.module('emsApp').controller('ArticleDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.article = entity;
	$scope.objectName = 'Article';
	$scope.articleId = $stateParams.articleId;

	if (angular.isUndefined($rootScope.articleActiveTab)) {
		//set the default here
		$rootScope.articleActiveTab = 0;
	}

	$scope.goToEditState = function(article) {
		$state.go('article.detail.edit({articleId:article.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:articleUpdate', function(event, result) {
		$scope.article = result;
	});
});
