/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('UserSalesFilterTemplateEventListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		SalesFilterTemplateEventFactory,
		UserSalesFilterTemplateEventListDtoSearchFactory,
		UserSalesFilterTemplateEventViewDtoFactory
	) {
		$scope.salesFilterTemplateEventList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeSalesFilterTemplateEvent)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeSalesFilterTemplateEvent);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeSalesFilterTemplateEvent = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('user-salesfiltertemplateevent.new');
		};

		var getSalesFilterId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SalesFilterTemplateEventSearchOverview.salesFilter)) {
				return $scope.SalesFilterTemplateEventSearchOverview.salesFilter.id;
			} else {
				return undefined;
			}
		};

		var getTemplateEventId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SalesFilterTemplateEventSearchOverview.templateEvent)) {
				return $scope.SalesFilterTemplateEventSearchOverview.templateEvent.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.SalesFilterTemplateEventSearchOverview = angular.copy($scope.SalesFilterTemplateEventSearchOverview);

			$rootScope.pageSizeSalesFilterTemplateEvent = angular.copy($scope.pageSize);

			UserSalesFilterTemplateEventListDtoSearchFactory.query(
				{
					page: $scope.SalesFilterTemplateEventSearchOverview.page - 1,
					size: $scope.pageSize,
					salesFilterId: getSalesFilterId(),
					templateEventId: getTemplateEventId(),

					sort: [
						$scope.SalesFilterTemplateEventSearchOverview.predicate +
							',' +
							($scope.SalesFilterTemplateEventSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.salesFilterTemplateEventList = result.items;

					$scope.salesFilterReferenceFilterList = result.salesFilterSearchOptions;

					$scope.templateEventReferenceFilterList = result.templateEventSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isSalesFilterTemplateEventSearchOverviewDirty = function() {
			return !angular.equals($scope.SalesFilterTemplateEventSearchOverview, $scope.originalSalesFilterTemplateEventSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.SalesFilterTemplateEventSearchOverview = {};
			$rootScope.SalesFilterTemplateEventSearchOverview.page = 1;
			$rootScope.SalesFilterTemplateEventSearchOverview.predicate = 'id';
			$rootScope.SalesFilterTemplateEventSearchOverview.ascending = false;

			$scope.SalesFilterTemplateEventSearchOverview = angular.copy($rootScope.SalesFilterTemplateEventSearchOverview);

			$scope.originalSalesFilterTemplateEventSearchOverview = angular.copy($scope.SalesFilterTemplateEventSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (
				angular.isUndefined($rootScope.SalesFilterTemplateEventSearchOverview) ||
				$rootScope.SalesFilterTemplateEventSearchOverview === null
			) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.SalesFilterTemplateEventSearchOverview = angular.copy($rootScope.SalesFilterTemplateEventSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.SalesFilterTemplateEventSearchOverview.predicate === itemName) {
				if ($scope.SalesFilterTemplateEventSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
