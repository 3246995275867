/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('UserRelationListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		RelationFactory,
		UserRelationListDtoSearchFactory,
		UserRelationViewDtoFactory
	) {
		$scope.relationList = [];
		$scope.selectedRelationList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeRelation)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeRelation);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeRelation = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('user-relation.new');
		};

		var getCompanyTypeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationSearchOverview.companyType)) {
				return $scope.RelationSearchOverview.companyType.id;
			} else {
				return undefined;
			}
		};

		var getAccountManagerId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationSearchOverview.accountManager)) {
				return $scope.RelationSearchOverview.accountManager.id;
			} else {
				return undefined;
			}
		};

		var getBusinessCountryId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationSearchOverview.businessCountry)) {
				return $scope.RelationSearchOverview.businessCountry.id;
			} else {
				return undefined;
			}
		};

		var getPrivateCountryId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationSearchOverview.privateCountry)) {
				return $scope.RelationSearchOverview.privateCountry.id;
			} else {
				return undefined;
			}
		};

		// handle attribute carRentalAgreementSigned of javaType Boolean
		// handle attribute indemnificationSigned of javaType Boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.RelationSearchOverview = angular.copy($scope.RelationSearchOverview);

			$rootScope.pageSizeRelation = angular.copy($scope.pageSize);

			UserRelationListDtoSearchFactory.query(
				{
					page: $scope.RelationSearchOverview.page - 1,
					size: $scope.pageSize,
					externalId: $scope.RelationSearchOverview.externalId,
					companyName: $scope.RelationSearchOverview.companyName,
					firstName: $scope.RelationSearchOverview.firstName,
					lastName: $scope.RelationSearchOverview.lastName,
					email: $scope.RelationSearchOverview.email,
					telephone: $scope.RelationSearchOverview.telephone,
					mobile: $scope.RelationSearchOverview.mobile,
					potentialFrom: $scope.RelationSearchOverview.potentialFrom,
					potentialTo: $scope.RelationSearchOverview.potentialTo,
					carRentalAgreementSigned: $scope.RelationSearchOverview.carRentalAgreementSignedGroup,
					indemnificationSigned: $scope.RelationSearchOverview.indemnificationSignedGroup,
					type: $scope.RelationSearchOverview.type,
					companyTypeId: getCompanyTypeId(),
					relationSource: $scope.RelationSearchOverview.relationSource,
					gender: $scope.RelationSearchOverview.gender,
					clientOf: $scope.RelationSearchOverview.clientOf,
					accountManagerId: getAccountManagerId(),
					businessCountryId: getBusinessCountryId(),
					privateCountryId: getPrivateCountryId(),
					langKey: $scope.RelationSearchOverview.langKey,

					sort: [$scope.RelationSearchOverview.predicate + ',' + ($scope.RelationSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.relationList = result.items;

					$scope.accountManagerReferenceFilterList = result.accountManagerSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isRelationSearchOverviewDirty = function() {
			return !angular.equals($scope.RelationSearchOverview, $scope.originalRelationSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.RelationSearchOverview = {};
			$rootScope.RelationSearchOverview.page = 1;
			$rootScope.RelationSearchOverview.predicate = 'id';
			$rootScope.RelationSearchOverview.ascending = false;

			$scope.RelationSearchOverview = angular.copy($rootScope.RelationSearchOverview);

			$scope.originalRelationSearchOverview = angular.copy($scope.RelationSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.RelationSearchOverview) || $rootScope.RelationSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.RelationSearchOverview = angular.copy($rootScope.RelationSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedRelationId = id;
			$('#deleteRelationConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			UserRelationViewDtoFactory.delete(
				{ id: $scope.selectedRelationId },
				function() {
					$scope.search();
					$('#deleteRelationConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteRelationConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleUpdate = function() {
			$state.go('user-relation.bulkedit', { selectedRelationList: $scope.selectedRelationList });
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.relationList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedRelationList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedRelationList = [];

			angular.forEach($scope.relationList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedRelationList.push(value);
				}
			});
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('emsApp:relationUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.RelationSearchOverview.predicate === itemName) {
				if ($scope.RelationSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
