/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('BookingFactory', function($resource) {
	return $resource(
		'api/bookings/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewBookingFactory', function($resource) {
	return $resource(
		'api/bookings/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Bookings without the use of paging
angular.module('emsApp').factory('NoPagingBookingFactory', function($resource) {
	return $resource(
		'api/bookings/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible BookingStatuses Enum values without the use of paging
angular.module('emsApp').factory('NoPagingBookingStatusFactory', function($resource) {
	return $resource(
		'api/booking/bookingStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ConfirmationEmailStatuses Enum values without the use of paging
angular.module('emsApp').factory('NoPagingConfirmationEmailStatusFactory', function($resource) {
	return $resource(
		'api/booking/confirmationEmailStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('OrderLinesByBookingFactory', function($resource) {
	return $resource(
		'api/orderLines/booking/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('SubscriptionsByBookingFactory', function($resource) {
	return $resource(
		'api/subscriptions/booking/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllBookingsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/bookings/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('AutocompleteBookingsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/bookings/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// Bill a booking
angular.module('emsApp').factory('BookingSnelstartFactory', function($resource) {
	return $resource(
		'api/booking/bill',
		{},
		{
			billBooking: {
				method: 'PUT',
			},
		}
	);
});

// bill all bookings for an event
angular.module('emsApp').factory('EventSnelstartFactory', function($resource) {
	return $resource(
		'api/event/bill',
		{},
		{
			billEvent: {
				method: 'PUT',
			},
		}
	);
});

angular.module('emsApp').factory('EventMailChimpFactory', function($resource) {
	return {
		syncAudience: function(id) {
			return $resource(
				'api/event/syncaudience/:id',
				{ id: id },
				{
					sync: {
						method: 'PUT',
					},
				}
			);
		},
	};
});
