/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('SubscriptionDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		SubscriptionFactory,
		AlertService,
		NoPagingSubscriptionFactory,
		NoPagingSubscriptionTypeFactory,
		RelationFactory,
		NoPagingRelationFactory,
		AutocompleteRelationsByQueryParamsFactory,
		BookingFactory,
		NoPagingBookingFactory,
		AutocompleteBookingsByQueryParamsFactory,
		CarFactory,
		NoPagingCarFactory,
		AutocompleteCarsByQueryParamsFactory,
		NoPagingSubscriptionStatusFactory,
		NoPagingArticleFactory,
		NoPagingInstructorFactory,
		NoPagingTemplateEventFactory,
		SubscriptionSaveAndCreateFactory,
		SubscriptionFindArticleFilteredByDriverFactory,
		SubscriptionFindArticleFilteredBySecondDriverFactory,
		SubscriptionFindArticleFilteredBySpectatorFactory,
		SubscriptionFindArticleFilteredByInsuranceFactory,
		SubscriptionFindArticleFilteredByHotelFactory,
		SubscriptionFindArticleFilteredByFuelFactory,
		SubscriptionFindArticleFilteredByRentalCarFactory,
		SubscriptionFindArticleFilteredByInstructionFactory,
		NoPagingSubscriptionByBookingAndSubscriptionTypeFactory,
		NoPagingInstructorByBookingFactory
	) {
		$scope.subscription = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.subscription) && $rootScope.isDefinedAndNotNull($scope.subscription.id);
		};

		$scope.createSubscriptionType = function() {
			var referredBy = {
				refName: 'subscriptionType',
				data: $scope.subscription,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new subscriptionType will add this new subscription state to the history stack
				$state.go('subscription.list.newSubscriptionType', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createRelation = function() {
			var referredBy = {
				refName: 'relation',
				data: $scope.subscription,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new relation will add this new subscription state to the history stack
				$state.go('subscription.list.newRelation', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createBooking = function() {
			var referredBy = {
				refName: 'booking',
				data: $scope.subscription,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new booking will add this new subscription state to the history stack
				$state.go('subscription.list.newBooking', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createCar = function() {
			var referredBy = {
				refName: 'car',
				data: $scope.subscription,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new car will add this new subscription state to the history stack
				$state.go('subscription.list.newCar', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createStatus = function() {
			var referredBy = {
				refName: 'status',
				data: $scope.subscription,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new status will add this new subscription state to the history stack
				$state.go('subscription.list.newSubscriptionStatus', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createDriver = function() {
			var referredBy = {
				refName: 'driver',
				data: $scope.subscription,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new driver will add this new subscription state to the history stack
				$state.go('subscription.list.newArticle', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createSecondDriver = function() {
			var referredBy = {
				refName: 'secondDriver',
				data: $scope.subscription,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new secondDriver will add this new subscription state to the history stack
				$state.go('subscription.list.newArticle', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createSpectator = function() {
			var referredBy = {
				refName: 'spectator',
				data: $scope.subscription,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new spectator will add this new subscription state to the history stack
				$state.go('subscription.list.newArticle', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createInsurance = function() {
			var referredBy = {
				refName: 'insurance',
				data: $scope.subscription,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new insurance will add this new subscription state to the history stack
				$state.go('subscription.list.newArticle', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createHotel = function() {
			var referredBy = {
				refName: 'hotel',
				data: $scope.subscription,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new hotel will add this new subscription state to the history stack
				$state.go('subscription.list.newArticle', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createFuel = function() {
			var referredBy = {
				refName: 'fuel',
				data: $scope.subscription,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new fuel will add this new subscription state to the history stack
				$state.go('subscription.list.newArticle', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createRelationOf = function() {
			var referredBy = {
				refName: 'relationOf',
				data: $scope.subscription,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new relationOf will add this new subscription state to the history stack
				$state.go('subscription.list.newSubscription', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createInstructor = function() {
			var referredBy = {
				refName: 'instructor',
				data: $scope.subscription,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new instructor will add this new subscription state to the history stack
				$state.go('subscription.list.newInstructor', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createTrainingType = function() {
			var referredBy = {
				refName: 'trainingType',
				data: $scope.subscription,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new trainingType will add this new subscription state to the history stack
				$state.go('subscription.list.newTemplateEvent', { referredBy: referredBy }, { reload: false });
			}, 100);
		};

		$scope.submitButtonIsActive = true;

		$scope.autocompleteRelations = function(val) {
			var params = {
				relationTypeAheadSearchString: val,
			};

			return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};
		$scope.autocompleteBookings = function(val) {
			var params = {
				bookingTypeAheadSearchString: val,
			};

			return AutocompleteBookingsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};
		$scope.autocompleteCars = function(val) {
			var params = {
				carTypeAheadSearchString: val,
			};

			return AutocompleteCarsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		/**
		 * Function to find an item in a list of items based on the parent id if this dialog is opened from a tabbed overview
		 */
		$scope.findItemByParent = function(items, property) {
			//This function preselects and disables the parent reference when called from tab
			if ($stateParams.mappedBy === property && $stateParams.mappedById !== undefined) {
				angular.forEach(items, function(value) {
					// value.id is a number, $stateParams.mappedById is a string, hence we cast the $stateParams.mappedById to a number so we can compare the id using the typesafe === operator
					if (value.id === _.toNumber($stateParams.mappedById)) {
						var model = $parse('subscription.' + property);
						model.assign($scope, value);
					}
				});
			}
		};

		// --- start default implementation
		/**
		 * This function queries all entities of type SubscriptionType and checks if this Subscription dialog is called in the context of SubscriptionType.
		 * If this is the case, we detect the parent entity Subscription and select it as model value so it is shown in the dropdown select.
		 */
		$scope.querySubscriptionType = function() {
			return NoPagingSubscriptionTypeFactory.query(function(result) {
				$scope.findItemByParent(result, 'subscriptionType');
			});
		};
		$scope.subscriptionTypeList = $scope.querySubscriptionType();
		// --- end default implementation

		/**
		 * This function preselects and disables the parent reference in autocomplete field when called from tab
		 */
		$scope.queryRelation = function() {
			// If the id of a preselected relation is available, then retrieve from backend and set in model
			if ($stateParams.mappedById === null) {
				return null;
			} else {
				return RelationFactory.get({ id: $stateParams.mappedById }, function(result) {
					if (result !== null) {
						$scope.findItemByParent([result], 'relation');
					}
				});
			}
		};
		$scope.relationList = $scope.queryRelation();

		/**
		 * This function preselects and disables the parent reference in autocomplete field when called from tab
		 */
		$scope.queryBooking = function() {
			// If the id of a preselected booking is available, then retrieve from backend and set in model
			if ($stateParams.mappedById === null) {
				return null;
			} else {
				return BookingFactory.get({ id: $stateParams.mappedById }, function(result) {
					if (result !== null) {
						$scope.findItemByParent([result], 'booking');
					}
				});
			}
		};
		$scope.bookingList = $scope.queryBooking();

		/**
		 * This function preselects and disables the parent reference in autocomplete field when called from tab
		 */
		$scope.queryCar = function() {
			// If the id of a preselected car is available, then retrieve from backend and set in model
			if ($stateParams.mappedById === null) {
				return null;
			} else {
				return CarFactory.get({ id: $stateParams.mappedById }, function(result) {
					if (result !== null) {
						$scope.findItemByParent([result], 'car');
					}
				});
			}
		};
		$scope.carList = $scope.queryCar();

		// --- start default implementation
		/**
		 * This function queries all entities of type Status and checks if this Subscription dialog is called in the context of Status.
		 * If this is the case, we detect the parent entity Subscription and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryStatus = function() {
			return NoPagingSubscriptionStatusFactory.query(function(result) {
				$scope.findItemByParent(result, 'status');
			});
		};
		$scope.statusList = $scope.queryStatus();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type Driver and checks if this Subscription dialog is called in the context of Driver.
		 * If this is the case, we detect the parent entity Subscription and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryDriver = function() {
			return SubscriptionFindArticleFilteredByDriverFactory.query(function(result) {
				$scope.findItemByParent(result, 'driver');
			});
		};
		$scope.driverList = $scope.queryDriver();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type SecondDriver and checks if this Subscription dialog is called in the context of SecondDriver.
		 * If this is the case, we detect the parent entity Subscription and select it as model value so it is shown in the dropdown select.
		 */
		$scope.querySecondDriver = function() {
			return SubscriptionFindArticleFilteredBySecondDriverFactory.query(function(result) {
				$scope.findItemByParent(result, 'secondDriver');
			});
		};
		$scope.secondDriverList = $scope.querySecondDriver();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type Spectator and checks if this Subscription dialog is called in the context of Spectator.
		 * If this is the case, we detect the parent entity Subscription and select it as model value so it is shown in the dropdown select.
		 */
		$scope.querySpectator = function() {
			return SubscriptionFindArticleFilteredBySpectatorFactory.query(function(result) {
				$scope.findItemByParent(result, 'spectator');
			});
		};
		$scope.spectatorList = $scope.querySpectator();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type Insurance and checks if this Subscription dialog is called in the context of Insurance.
		 * If this is the case, we detect the parent entity Subscription and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryInsurance = function() {
			return SubscriptionFindArticleFilteredByInsuranceFactory.query(function(result) {
				$scope.findItemByParent(result, 'insurance');
			});
		};
		$scope.insuranceList = $scope.queryInsurance();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type Hotel and checks if this Subscription dialog is called in the context of Hotel.
		 * If this is the case, we detect the parent entity Subscription and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryHotel = function() {
			return SubscriptionFindArticleFilteredByHotelFactory.query(function(result) {
				$scope.findItemByParent(result, 'hotel');
			});
		};
		$scope.hotelList = $scope.queryHotel();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type Fuel and checks if this Subscription dialog is called in the context of Fuel.
		 * If this is the case, we detect the parent entity Subscription and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryFuel = function() {
			return SubscriptionFindArticleFilteredByFuelFactory.query(function(result) {
				$scope.findItemByParent(result, 'fuel');
			});
		};
		$scope.fuelList = $scope.queryFuel();
		// --- end default implementation

		// --- start custom implementation
		// custom method to load the list with all relationOf entries
		$scope.queryRelationOf = function() {
			var bookingId =
				$rootScope.isDefinedAndNotNull($scope.subscription) && $rootScope.isDefinedAndNotNull($scope.subscription.booking)
					? $scope.subscription.booking.id
					: -1;

			// check if creating / editing this subscription happens in the context of a booking (from a sub tab), if so we have to set the booking id from that perspective
			if ($stateParams.mappedBy == 'booking' && $rootScope.isDefinedAndNotNull($stateParams.mappedById)) {
				bookingId = $stateParams.mappedById;
			}

			return NoPagingSubscriptionByBookingAndSubscriptionTypeFactory.query(
				{
					bookingId: bookingId,
					subscriptionType: 'Driver',
				},
				function(result) {
					$scope.findItemByParent(result, 'relationOf');
				}
			);
		};

		// call the method to load all relationOf entries once the controller loads
		$scope.relationOfList = $scope.queryRelationOf();
		// --- end custom implementation

		// --- start custom implementation
		// custom method to load the list with all instructor entries
		$scope.queryInstructor = function() {
			var bookingId =
				$rootScope.isDefinedAndNotNull($scope.subscription) && $rootScope.isDefinedAndNotNull($scope.subscription.booking)
					? $scope.subscription.booking.id
					: -1;

			// check if creating / editing this subscription happens in the context of a booking (from a sub tab), if so we have to set the booking id from that perspective
			if ($stateParams.mappedBy == 'booking' && $rootScope.isDefinedAndNotNull($stateParams.mappedById)) {
				bookingId = $stateParams.mappedById;
			}

			return NoPagingInstructorByBookingFactory.query(
				{
					bookingId: bookingId,
				},
				function(result) {
					$scope.findItemByParent(result, 'instructor');
				}
			);
		};

		// call the method to load all instructor entries once the controller loads
		$scope.instructorList = $scope.queryInstructor();
		// --- end custom implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type TrainingType and checks if this Subscription dialog is called in the context of TrainingType.
		 * If this is the case, we detect the parent entity Subscription and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryTrainingType = function() {
			return NoPagingTemplateEventFactory.query(function(result) {
				$scope.findItemByParent(result, 'trainingType');
			});
		};
		$scope.trainingTypeList = $scope.queryTrainingType();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type RentalCar and checks if this Subscription dialog is called in the context of RentalCar.
		 * If this is the case, we detect the parent entity Subscription and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryRentalCar = function() {
			return SubscriptionFindArticleFilteredByRentalCarFactory.query(function(result) {
				$scope.findItemByParent(result, 'rentalCar');
			});
		};
		$scope.rentalCarList = $scope.queryRentalCar();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type Instruction and checks if this Subscription dialog is called in the context of Instruction.
		 * If this is the case, we detect the parent entity Subscription and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryInstruction = function() {
			return SubscriptionFindArticleFilteredByInstructionFactory.query(function(result) {
				$scope.findItemByParent(result, 'instruction');
			});
		};
		$scope.instructionList = $scope.queryInstruction();
		// --- end default implementation

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:subscriptionUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving Subscription failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		var onSaveAndNewFinishedCallback = function(result) {
			$scope.$emit('emsApp:subscriptionUpdate', result);
			// the result contains a new entity, but with the copied values from the previous
			$scope.subscription = result;
			$scope.updateDropdownModels();
			$scope.submitButtonIsActive = true;
		};

		$scope.updateDropdownModels = function() {
			$scope.querySubscriptionType();
			$scope.queryRelation();
			$scope.queryBooking();
			$scope.queryCar();
			$scope.queryStatus();
			$scope.queryDriver();
			$scope.querySecondDriver();
			$scope.querySpectator();
			$scope.queryInsurance();
			$scope.queryHotel();
			$scope.queryFuel();
			$scope.queryRelationOf();
			$scope.queryInstructor();
			$scope.queryTrainingType();
			$scope.queryRentalCar();
			$scope.queryInstruction();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;

			//check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
			if ($scope.subscription.relation === '') {
				$scope.subscription.relation = null;
			}
			//check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
			if ($scope.subscription.booking === '') {
				$scope.subscription.booking = null;
			}
			//check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
			if ($scope.subscription.car === '') {
				$scope.subscription.car = null;
			}

			if ($scope.subscription.id != null) {
				SubscriptionFactory.update($scope.subscription, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				SubscriptionFactory.save($scope.subscription, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			}
		};

		$scope.saveAndNew = function() {
			$scope.submitButtonIsActive = false;

			//check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
			if ($scope.subscription.relation === '') {
				$scope.subscription.relation = null;
			}
			//check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
			if ($scope.subscription.booking === '') {
				$scope.subscription.booking = null;
			}
			//check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
			if ($scope.subscription.car === '') {
				$scope.subscription.car = null;
			}

			if ($scope.subscription.id != null) {
				SubscriptionSaveAndCreateFactory.updateAndCreate($scope.subscription, onSaveAndNewFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				SubscriptionSaveAndCreateFactory.saveAndCreate($scope.subscription, onSaveAndNewFinishedCallback, saveOrUpdateErrorCallback);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};
	});
