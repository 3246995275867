/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('CarRentalTypeTemplateEventArticleDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
		$scope.carRentalTypeTemplateEventArticle = entity;
		$scope.objectName = 'CarRentalTypeTemplateEventArticle';
		$scope.carRentalTypeTemplateEventArticleId = $stateParams.carRentalTypeTemplateEventArticleId;

		if (angular.isUndefined($rootScope.carRentalTypeTemplateEventArticleActiveTab)) {
			//set the default here
			$rootScope.carRentalTypeTemplateEventArticleActiveTab = 0;
		}

		$scope.goToEditState = function(carRentalTypeTemplateEventArticle) {
			$state.go(
				'carrentaltypetemplateeventarticle.detail.edit({carRentalTypeTemplateEventArticleId:carRentalTypeTemplateEventArticle.id, mappedBy:mappedBy, mappedById:mappedById})'
			);
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('emsApp:carRentalTypeTemplateEventArticleUpdate', function(event, result) {
			$scope.carRentalTypeTemplateEventArticle = result;
		});
	});
