/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('VatFactory', function($resource) {
	return $resource(
		'api/vats/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to search / retrieve an array of UserVatListDto's
 */
angular.module('emsApp').factory('UserVatListDtoSearchFactory', function($resource) {
	return $resource(
		'api/user/vat',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewVatFactory', function($resource) {
	return $resource(
		'api/vats/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Vats without the use of paging
angular.module('emsApp').factory('NoPagingVatFactory', function($resource) {
	return $resource(
		'api/vats/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible VatTypes Enum values without the use of paging
angular.module('emsApp').factory('NoPagingVatTypeFactory', function($resource) {
	return $resource(
		'api/vat/vatTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllVatsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/vats/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
