/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('EventController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		EventFactory,
		FindAllEventsByQueryParamsFactory,
		NoPagingEventFactory,
		NoPagingTemplateEventFactory,
		NoPagingLocationFactory,
		NoPagingEventStatusFactory
	) {
		$scope.eventList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeEvent)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeEvent);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeEvent = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('event.list.new');
		};

		$scope.goToEditState = function(event) {
			// this overview is NOT mapped, it is the main overview
			$state.go('event.list.edit', { eventId: event.id });
		};

		var getTemplateEventIdList = function() {
			return $rootScope.getListValues($scope.EventSearchOverview.templateEventIdList, 'id');
		};

		var getLocationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.EventSearchOverview.location)) {
				return $scope.EventSearchOverview.location.id;
			} else {
				return undefined;
			}
		};

		// handle attribute startDate of javaType java.time.LocalDate
		$scope.processStartDate = function() {
			var startDateFromDefined = $rootScope.isDefinedAndNotNull($scope.EventSearchOverview.startDateFrom);
			var startDateToDefined = $rootScope.isDefinedAndNotNull($scope.EventSearchOverview.startDateTo);

			if (startDateFromDefined) {
				$scope.EventSearchOverview.startDateFromString = $rootScope.convertDateWithoutZone($scope.EventSearchOverview.startDateFrom);
			} else {
				$scope.EventSearchOverview.startDateFromString = undefined;
			}

			if (startDateToDefined) {
				$scope.EventSearchOverview.startDateToString = $rootScope.convertDateWithoutZone($scope.EventSearchOverview.startDateTo);
			} else {
				$scope.EventSearchOverview.startDateToString = undefined;
			}
		};

		// handle attribute exclusive of javaType boolean

		/**
		 * This function queries all entities of type TemplateEvent and checks if this Event dialog is called in the context of TemplateEvent.
		 * If this is the case, we detect the parent entity Event and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryTemplateEvent = function() {
			return NoPagingTemplateEventFactory.query(function(result) {
				return result;
			});
		};
		$scope.templateEventReferenceFilterList = $scope.queryTemplateEvent();

		/**
		 * This function queries all entities of type Location and checks if this Event dialog is called in the context of Location.
		 * If this is the case, we detect the parent entity Event and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryLocation = function() {
			return NoPagingLocationFactory.query(function(result) {
				return result;
			});
		};
		$scope.locationReferenceFilterList = $scope.queryLocation();
		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processStartDate();

			$rootScope.EventSearchOverview = angular.copy($scope.EventSearchOverview);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeEvent = angular.copy($scope.pageSize);

			FindAllEventsByQueryParamsFactory.query(
				{
					page: $scope.EventSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.EventSearchOverview.name,
					startDateFrom: $scope.EventSearchOverview.startDateFromString,
					startDateTo: $scope.EventSearchOverview.startDateToString,
					exclusive: $scope.EventSearchOverview.exclusiveGroup,
					templateEventIdList: getTemplateEventIdList(),
					locationId: getLocationId(),
					status: $scope.EventSearchOverview.status,

					sort: [$scope.EventSearchOverview.predicate + ',' + ($scope.EventSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.eventList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.updateTemplateEventUISelect = function(item, model) {
			$scope.search();
		};
		$scope.isEventSearchOverviewDirty = function() {
			return !angular.equals($scope.EventSearchOverview, $scope.originalEventSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.EventSearchOverview = {};
			$rootScope.EventSearchOverview.page = 1;
			$rootScope.EventSearchOverview.predicate = 'startDate';
			$rootScope.EventSearchOverview.ascending = true;

			$rootScope.EventSearchOverview.startDateFrom = new Date();

			$scope.EventSearchOverview = angular.copy($rootScope.EventSearchOverview);

			$scope.originalEventSearchOverview = angular.copy($scope.EventSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.EventSearchOverview) || $rootScope.EventSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.EventSearchOverview = angular.copy($rootScope.EventSearchOverview);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedEventId = id;
			$('#deleteEventConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			EventFactory.delete(
				{ id: $scope.selectedEventId },
				function() {
					$scope.search();
					$('#deleteEventConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteEventConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('emsApp:eventUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.EventSearchOverview.predicate === itemName) {
				if ($scope.EventSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
