/** @format */

(function() {
	'use strict';

	angular.module('emsApp').config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider.state('admin', {
			abstract: true,
			parent: 'app',
		});
	}
})();
