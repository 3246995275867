/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('DocumentTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		DocumentFactory,
		FindAllDocumentsByQueryParamsFactory,
		AutocompleteCarsByQueryParamsFactory,
		AutocompleteRelationsByQueryParamsFactory,
		AutocompleteEventsByQueryParamsFactory,
		NoPagingDocumentFactory,
		NoPagingDocumentTypeFactory,
		NoPagingCarFactory,
		NoPagingLocationFactory,
		NoPagingRelationFactory,
		NoPagingEventFactory,
		DocumentDownloadService
	) {
		$scope.documentList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeDocument)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeDocument);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeDocument = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newDocument', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(document) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editDocument', {
				documentId: document.id,
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
			});
		};

		var getTypeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.typeId)) {
				return $scope.typeId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.DocumentSearchTab.type)) {
					return $scope.DocumentSearchTab.type.id;
				} else {
					return undefined;
				}
			}
		};

		var getCarId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.carId)) {
				return $scope.carId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.DocumentSearchTab.car)) {
					return $scope.DocumentSearchTab.car.id;
				} else {
					return undefined;
				}
			}
		};

		var getLocationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.locationId)) {
				return $scope.locationId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.DocumentSearchTab.location)) {
					return $scope.DocumentSearchTab.location.id;
				} else {
					return undefined;
				}
			}
		};

		var getRelationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.relationId)) {
				return $scope.relationId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.DocumentSearchTab.relation)) {
					return $scope.DocumentSearchTab.relation.id;
				} else {
					return undefined;
				}
			}
		};

		var getEventId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.eventId)) {
				return $scope.eventId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.DocumentSearchTab.event)) {
					return $scope.DocumentSearchTab.event.id;
				} else {
					return undefined;
				}
			}
		};

		// handle attribute createdOn of javaType java.time.ZonedDateTime
		$scope.processCreatedOn = function() {
			var createdOnFromDefined = $rootScope.isDefinedAndNotNull($scope.DocumentSearchTab.createdOnFrom);
			var createdOnToDefined = $rootScope.isDefinedAndNotNull($scope.DocumentSearchTab.createdOnTo);

			// TODO either we assign a value, or undefined, plus we check for undefined, hence we could assign the variable, period

			if (createdOnFromDefined) {
				$scope.DocumentSearchTab.createdOnFromString = $rootScope.convertDateWithoutZone($scope.DocumentSearchTab.createdOnFrom);
			} else {
				$scope.DocumentSearchTab.createdOnFromString = undefined;
			}

			if (createdOnToDefined) {
				$scope.DocumentSearchTab.createdOnToString = $rootScope.convertDateWithoutZone($scope.DocumentSearchTab.createdOnTo);
			} else {
				$scope.DocumentSearchTab.createdOnToString = undefined;
			}
		};

		/**
		 * This function queries all entities of type Type and checks if this Document dialog is called in the context of Type.
		 * If this is the case, we detect the parent entity Document and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryType = function() {
			return NoPagingDocumentTypeFactory.query(function(result) {
				return result;
			});
		};
		$scope.typeReferenceFilterList = $scope.queryType();

		$scope.autocompleteCars = function(val) {
			var params = {
				carTypeAheadSearchString: val,
			};

			return AutocompleteCarsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		/**
		 * This function queries all entities of type Location and checks if this Document dialog is called in the context of Location.
		 * If this is the case, we detect the parent entity Document and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryLocation = function() {
			return NoPagingLocationFactory.query(function(result) {
				return result;
			});
		};
		$scope.locationReferenceFilterList = $scope.queryLocation();

		$scope.autocompleteRelations = function(val) {
			var params = {
				relationTypeAheadSearchString: val,
			};

			return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		$scope.autocompleteEvents = function(val) {
			var params = {
				eventTypeAheadSearchString: val,
			};

			return AutocompleteEventsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processCreatedOn();

			$rootScope.DocumentSearchTab = angular.copy($scope.DocumentSearchTab);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeDocument = angular.copy($scope.pageSize);

			FindAllDocumentsByQueryParamsFactory.query(
				{
					page: $scope.DocumentSearchTab.page - 1,
					size: $scope.pageSize,
					createdOnFrom: $scope.DocumentSearchTab.createdOnFromString,
					createdOnTo: $scope.DocumentSearchTab.createdOnToString,
					name: $scope.DocumentSearchTab.name,
					typeId: getTypeId(),
					carId: getCarId(),
					locationId: getLocationId(),
					relationId: getRelationId(),
					eventId: getEventId(),

					sort: [$scope.DocumentSearchTab.predicate + ',' + ($scope.DocumentSearchTab.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.documentList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isDocumentSearchTabDirty = function() {
			return !angular.equals($scope.DocumentSearchTab, $scope.originalDocumentSearchTab);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.DocumentSearchTab = {};
			$rootScope.DocumentSearchTab.page = 1;
			$rootScope.DocumentSearchTab.predicate = 'id';
			$rootScope.DocumentSearchTab.ascending = false;

			$scope.DocumentSearchTab = angular.copy($rootScope.DocumentSearchTab);

			$scope.originalDocumentSearchTab = angular.copy($scope.DocumentSearchTab);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.DocumentSearchTab) || $rootScope.DocumentSearchTab === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.DocumentSearchTab = angular.copy($rootScope.DocumentSearchTab);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedDocumentId = id;
			$('#deleteDocumentConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			DocumentFactory.delete(
				{ id: $scope.selectedDocumentId },
				function() {
					$scope.search();
					$('#deleteDocumentConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteDocumentConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.downloadDocument = function(id) {
			DocumentDownloadService(id);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.DocumentSearchTab.predicate === itemName) {
				if ($scope.DocumentSearchTab.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
