/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('ArticleTypeController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ArticleTypeFactory,
		FindAllArticleTypesByQueryParamsNonPageableFactory,
		NoPagingArticleTypeFactory,
		NoPagingPredefinedArticleTypeFactory
	) {
		$scope.articleTypeList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('articletype.list.new');
		};

		$scope.goToEditState = function(articleType) {
			// this overview is NOT mapped, it is the main overview
			$state.go('articletype.list.edit', { articleTypeId: articleType.id });
		};

		$scope.getArticleTypeList = function() {
			FindAllArticleTypesByQueryParamsNonPageableFactory.query(
				{
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.articleTypeList = result;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getArticleTypeList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedArticleTypeId = id;
			$('#deleteArticleTypeConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			ArticleTypeFactory.delete(
				{ id: $scope.selectedArticleTypeId },
				function() {
					$scope.loadAll();
					$('#deleteArticleTypeConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteArticleTypeConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
