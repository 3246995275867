/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('CarRentalTypeTemplateEventArticleDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		CarRentalTypeTemplateEventArticleFactory,
		AlertService,
		NoPagingCarRentalTypeTemplateEventArticleFactory,
		NoPagingCarRentalTypeFactory,
		NoPagingTemplateEventFactory,
		NoPagingArticleFactory,
		CarRentalTypeTemplateEventArticleFindArticleFilteredByArticleForRentalFactory
	) {
		$scope.carRentalTypeTemplateEventArticle = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return (
				$rootScope.isDefinedAndNotNull($scope.carRentalTypeTemplateEventArticle) &&
				$rootScope.isDefinedAndNotNull($scope.carRentalTypeTemplateEventArticle.id)
			);
		};

		$scope.createArticleForRental = function() {
			var referredBy = {
				refName: 'articleForRental',
				data: $scope.carRentalTypeTemplateEventArticle,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new articleForRental will add this new carrentaltypetemplateeventarticle state to the history stack
				$state.go('carrentaltypetemplateeventarticle.list.newArticle', { referredBy: referredBy }, { reload: false });
			}, 100);
		};

		$scope.submitButtonIsActive = true;

		/**
		 * Function to find an item in a list of items based on the parent id if this dialog is opened from a tabbed overview
		 */
		$scope.findItemByParent = function(items, property) {
			//This function preselects and disables the parent reference when called from tab
			if ($stateParams.mappedBy === property && $stateParams.mappedById !== undefined) {
				angular.forEach(items, function(value) {
					// value.id is a number, $stateParams.mappedById is a string, hence we cast the $stateParams.mappedById to a number so we can compare the id using the typesafe === operator
					if (value.id === _.toNumber($stateParams.mappedById)) {
						var model = $parse('carRentalTypeTemplateEventArticle.' + property);
						model.assign($scope, value);
					}
				});
			}
		};

		// --- start default implementation
		/**
		 * This function queries all entities of type CarRentalType and checks if this CarRentalTypeTemplateEventArticle dialog is called in the context of CarRentalType.
		 * If this is the case, we detect the parent entity CarRentalTypeTemplateEventArticle and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryCarRentalType = function() {
			return NoPagingCarRentalTypeFactory.query(function(result) {
				$scope.findItemByParent(result, 'carRentalType');
			});
		};
		$scope.carRentalTypeList = $scope.queryCarRentalType();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type TemplateEvent and checks if this CarRentalTypeTemplateEventArticle dialog is called in the context of TemplateEvent.
		 * If this is the case, we detect the parent entity CarRentalTypeTemplateEventArticle and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryTemplateEvent = function() {
			return NoPagingTemplateEventFactory.query(function(result) {
				$scope.findItemByParent(result, 'templateEvent');
			});
		};
		$scope.templateEventList = $scope.queryTemplateEvent();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type ArticleForRental and checks if this CarRentalTypeTemplateEventArticle dialog is called in the context of ArticleForRental.
		 * If this is the case, we detect the parent entity CarRentalTypeTemplateEventArticle and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryArticleForRental = function() {
			return CarRentalTypeTemplateEventArticleFindArticleFilteredByArticleForRentalFactory.query(function(result) {
				$scope.findItemByParent(result, 'articleForRental');
			});
		};
		$scope.articleForRentalList = $scope.queryArticleForRental();
		// --- end default implementation

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:carRentalTypeTemplateEventArticleUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving CarRentalTypeTemplateEventArticle failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;

			if ($scope.carRentalTypeTemplateEventArticle.id != null) {
				CarRentalTypeTemplateEventArticleFactory.update(
					$scope.carRentalTypeTemplateEventArticle,
					onSaveFinishedCallback,
					saveOrUpdateErrorCallback
				);
			} else {
				CarRentalTypeTemplateEventArticleFactory.save(
					$scope.carRentalTypeTemplateEventArticle,
					onSaveFinishedCallback,
					saveOrUpdateErrorCallback
				);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};
	});
