/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('ArticleFactory', function($resource) {
	return $resource(
		'api/articles/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of UserArticleViewDto
 */
angular.module('emsApp').factory('UserArticleViewDtoFactory', function($resource) {
	return $resource(
		'api/user/article/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of UserArticleListDto's
 */
angular.module('emsApp').factory('UserArticleListDtoSearchFactory', function($resource) {
	return $resource(
		'api/user/article',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/* A custom hook can be implemented here custom/jhipster/factory/create_custom_edit_factory.ftl */
/**
 * Factory to get / update an instance of UserArticleEditDto
 */
angular.module('emsApp').factory('UserArticleEditDtoFactory', function($resource) {
	return $resource(
		'api/user/article/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of UserArticleEditDto
 * with defaults from server
 */
angular.module('emsApp').factory('UserArticleNewDtoFactory', function($resource) {
	return $resource(
		'api/user/article/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/*
 * Factory to fetch a new object of UserArticleEditDto
 * without defaults from server, to use in bulkedit
 */
angular.module('emsApp').factory('UserArticleNewBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/user/article/bulkedit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to bulkupdate an instance of UserArticleEditDto
 */
angular.module('emsApp').factory('UserArticleBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/user/article/bulkedit',
		{},
		{
			update: {
				method: 'PUT',
			},
		}
	);
});

/**
 * Factory to save a new object of UserArticleEditDto
 * and returns a copy to create another one.
 */
angular.module('emsApp').factory('UserArticleEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/user/article/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewArticleFactory', function($resource) {
	return $resource(
		'api/articles/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Articles without the use of paging
angular.module('emsApp').factory('NoPagingArticleFactory', function($resource) {
	return $resource(
		'api/articles/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible VatTypes Enum values without the use of paging
angular.module('emsApp').factory('NoPagingVatTypeFactory', function($resource) {
	return $resource(
		'api/article/vatTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('OrderLinesByArticleFactory', function($resource) {
	return $resource(
		'api/orderLines/article/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('ArticleRelationPricesByArticleFactory', function($resource) {
	return $resource(
		'api/articleRelationPrices/article/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllArticlesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/articles/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all articles with booking ID without paging
angular.module('emsApp').factory('NoPagingArticleWithBookingFactory', function($resource) {
	return $resource(
		'api/articles/nopaging/booking/:bookingId',
		{},
		{
			query: {
				method: 'GET',
				isArray: true,
			},
		}
	);
});

angular.module('emsApp').factory('ArticleSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/articles/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
