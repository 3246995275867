/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('CarTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarFactory,
		FindAllCarsByQueryParamsFactory,
		AutocompleteRelationsByQueryParamsFactory,
		NoPagingCarFactory,
		NoPagingRelationFactory,
		NoPagingArticleFactory,
		NoPagingCarRentalTypeFactory,
		CarFindArticleFilteredByArticleForRentalFactory
	) {
		$scope.carList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.page = 1;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCar)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCar);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeCar = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newCar', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(car) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editCar', { carId: car.id, mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getCarList = function() {
			FindAllCarsByQueryParamsFactory.query(
				{
					ownerId: angular.isDefined($scope.ownerId) ? $scope.ownerId : null,
					articleForRentalId: angular.isDefined($scope.articleForRentalId) ? $scope.articleForRentalId : null,
					carRentalTypeId: angular.isDefined($scope.carRentalTypeId) ? $scope.carRentalTypeId : null,

					page: $scope.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carList = result;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getCarList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarId = id;
			$('#deleteCarConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarFactory.delete(
				{ id: $scope.selectedCarId },
				function() {
					$scope.loadAll();
					$('#deleteCarConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteCarConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('emsApp:carUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
