/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('SalesFilterTemplateEventController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		SalesFilterTemplateEventFactory,
		FindAllSalesFilterTemplateEventsByQueryParamsFactory,
		NoPagingSalesFilterTemplateEventFactory,
		NoPagingSalesFilterFactory,
		NoPagingTemplateEventFactory
	) {
		$scope.salesFilterTemplateEventList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeSalesFilterTemplateEvent)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeSalesFilterTemplateEvent);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeSalesFilterTemplateEvent = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('salesfiltertemplateevent.list.new');
		};

		$scope.goToEditState = function(salesFilterTemplateEvent) {
			// this overview is NOT mapped, it is the main overview
			$state.go('salesfiltertemplateevent.list.edit', { salesFilterTemplateEventId: salesFilterTemplateEvent.id });
		};

		var getSalesFilterId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SalesFilterTemplateEventSearchOverview.salesFilter)) {
				return $scope.SalesFilterTemplateEventSearchOverview.salesFilter.id;
			} else {
				return undefined;
			}
		};

		var getTemplateEventId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SalesFilterTemplateEventSearchOverview.templateEvent)) {
				return $scope.SalesFilterTemplateEventSearchOverview.templateEvent.id;
			} else {
				return undefined;
			}
		};

		/**
		 * This function queries all entities of type SalesFilter and checks if this SalesFilterTemplateEvent dialog is called in the context of SalesFilter.
		 * If this is the case, we detect the parent entity SalesFilterTemplateEvent and select it as model value so it is shown in the dropdown select.
		 */
		$scope.querySalesFilter = function() {
			return NoPagingSalesFilterFactory.query(function(result) {
				return result;
			});
		};
		$scope.salesFilterReferenceFilterList = $scope.querySalesFilter();

		/**
		 * This function queries all entities of type TemplateEvent and checks if this SalesFilterTemplateEvent dialog is called in the context of TemplateEvent.
		 * If this is the case, we detect the parent entity SalesFilterTemplateEvent and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryTemplateEvent = function() {
			return NoPagingTemplateEventFactory.query(function(result) {
				return result;
			});
		};
		$scope.templateEventReferenceFilterList = $scope.queryTemplateEvent();
		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.SalesFilterTemplateEventSearchOverview = angular.copy($scope.SalesFilterTemplateEventSearchOverview);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeSalesFilterTemplateEvent = angular.copy($scope.pageSize);

			FindAllSalesFilterTemplateEventsByQueryParamsFactory.query(
				{
					page: $scope.SalesFilterTemplateEventSearchOverview.page - 1,
					size: $scope.pageSize,
					salesFilterId: getSalesFilterId(),
					templateEventId: getTemplateEventId(),

					sort: [
						$scope.SalesFilterTemplateEventSearchOverview.predicate +
							',' +
							($scope.SalesFilterTemplateEventSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.salesFilterTemplateEventList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isSalesFilterTemplateEventSearchOverviewDirty = function() {
			return !angular.equals($scope.SalesFilterTemplateEventSearchOverview, $scope.originalSalesFilterTemplateEventSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.SalesFilterTemplateEventSearchOverview = {};
			$rootScope.SalesFilterTemplateEventSearchOverview.page = 1;
			$rootScope.SalesFilterTemplateEventSearchOverview.predicate = 'id';
			$rootScope.SalesFilterTemplateEventSearchOverview.ascending = false;

			$scope.SalesFilterTemplateEventSearchOverview = angular.copy($rootScope.SalesFilterTemplateEventSearchOverview);

			$scope.originalSalesFilterTemplateEventSearchOverview = angular.copy($scope.SalesFilterTemplateEventSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (
				angular.isUndefined($rootScope.SalesFilterTemplateEventSearchOverview) ||
				$rootScope.SalesFilterTemplateEventSearchOverview === null
			) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.SalesFilterTemplateEventSearchOverview = angular.copy($rootScope.SalesFilterTemplateEventSearchOverview);
			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.SalesFilterTemplateEventSearchOverview.predicate === itemName) {
				if ($scope.SalesFilterTemplateEventSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
