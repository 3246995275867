/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('RelationController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		RelationFactory,
		FindAllRelationsByQueryParamsFactory,
		NoPagingRelationFactory,
		NoPagingRelationTypeFactory,
		NoPagingCompanyTypeFactory,
		NoPagingRelationSourceFactory,
		NoPagingGenderFactory,
		NoPagingClientOfFactory,
		NoPagingUserFactory,
		NoPagingCountryFactory,
		NoPagingLangKeyFactory,
		RelationFindUserFilteredByAccountManagerFactory
	) {
		$scope.relationList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeRelation)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeRelation);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeRelation = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation.list.new');
		};

		$scope.goToEditState = function(relation) {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation.list.edit', { relationId: relation.id });
		};

		var getCompanyTypeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationSearchOverview.companyType)) {
				return $scope.RelationSearchOverview.companyType.id;
			} else {
				return undefined;
			}
		};

		var getAccountManagerId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationSearchOverview.accountManager)) {
				return $scope.RelationSearchOverview.accountManager.id;
			} else {
				return undefined;
			}
		};

		var getBusinessCountryIdList = function() {
			return $rootScope.getListValues($scope.RelationSearchOverview.businessCountryIdList, 'id');
		};

		var getPrivateCountryIdList = function() {
			return $rootScope.getListValues($scope.RelationSearchOverview.privateCountryIdList, 'id');
		};

		// handle attribute carRentalAgreementSigned of javaType Boolean
		// handle attribute indemnificationSigned of javaType Boolean

		/**
		 * This function queries all entities of type AccountManager and checks if this Relation dialog is called in the context of AccountManager.
		 * If this is the case, we detect the parent entity Relation and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryAccountManager = function() {
			return RelationFindUserFilteredByAccountManagerFactory.query(function(result) {
				return result;
			});
		};
		$scope.accountManagerReferenceFilterList = $scope.queryAccountManager();
		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.RelationSearchOverview = angular.copy($scope.RelationSearchOverview);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeRelation = angular.copy($scope.pageSize);

			FindAllRelationsByQueryParamsFactory.query(
				{
					page: $scope.RelationSearchOverview.page - 1,
					size: $scope.pageSize,
					externalId: $scope.RelationSearchOverview.externalId,
					companyName: $scope.RelationSearchOverview.companyName,
					firstName: $scope.RelationSearchOverview.firstName,
					lastName: $scope.RelationSearchOverview.lastName,
					email: $scope.RelationSearchOverview.email,
					telephone: $scope.RelationSearchOverview.telephone,
					mobile: $scope.RelationSearchOverview.mobile,
					potentialFrom: $scope.RelationSearchOverview.potentialFrom,
					potentialTo: $scope.RelationSearchOverview.potentialTo,
					carRentalAgreementSigned: $scope.RelationSearchOverview.carRentalAgreementSignedGroup,
					indemnificationSigned: $scope.RelationSearchOverview.indemnificationSignedGroup,
					type: $scope.RelationSearchOverview.type,
					companyTypeId: getCompanyTypeId(),
					relationSource: $scope.RelationSearchOverview.relationSource,
					gender: $scope.RelationSearchOverview.gender,
					clientOfList: $scope.RelationSearchOverview.clientOfList,
					accountManagerId: getAccountManagerId(),
					businessCountryIdList: getBusinessCountryIdList(),
					privateCountryIdList: getPrivateCountryIdList(),
					langKeyList: $scope.RelationSearchOverview.langKeyList,

					sort: [$scope.RelationSearchOverview.predicate + ',' + ($scope.RelationSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.relationList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.updateClientOfUISelect = function(item, model) {
			$scope.search();
		};
		$scope.updateBusinessCountryUISelect = function(item, model) {
			$scope.search();
		};
		$scope.updatePrivateCountryUISelect = function(item, model) {
			$scope.search();
		};
		$scope.updateLangKeyUISelect = function(item, model) {
			$scope.search();
		};
		$scope.isRelationSearchOverviewDirty = function() {
			return !angular.equals($scope.RelationSearchOverview, $scope.originalRelationSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.RelationSearchOverview = {};
			$rootScope.RelationSearchOverview.page = 1;
			$rootScope.RelationSearchOverview.predicate = 'id';
			$rootScope.RelationSearchOverview.ascending = false;

			$scope.RelationSearchOverview = angular.copy($rootScope.RelationSearchOverview);

			$scope.originalRelationSearchOverview = angular.copy($scope.RelationSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.RelationSearchOverview) || $rootScope.RelationSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.RelationSearchOverview = angular.copy($rootScope.RelationSearchOverview);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedRelationId = id;
			$('#deleteRelationConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationFactory.delete(
				{ id: $scope.selectedRelationId },
				function() {
					$scope.search();
					$('#deleteRelationConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteRelationConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('emsApp:relationUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.RelationSearchOverview.predicate === itemName) {
				if ($scope.RelationSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
