/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('TemplateEventController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		TemplateEventFactory,
		FindAllTemplateEventsByQueryParamsNonPageableFactory,
		NoPagingTemplateEventFactory,
		NoPagingEventTypeFactory,
		NoPagingArticleFactory,
		TemplateEventFindArticleFilteredByDefaultDriverFactory,
		TemplateEventFindArticleFilteredByDefaultSecondDriverFactory,
		TemplateEventFindArticleFilteredByDefaultSpectatorFactory,
		TemplateEventFindArticleFilteredByDefaultInsuranceFactory,
		TemplateEventFindArticleFilteredByDefaultHotelFactory,
		TemplateEventFindArticleFilteredByDefaultFuelFactory,
		TemplateEventFindArticleFilteredByDefaultInstructionFactory
	) {
		$scope.templateEventList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('templateevent.list.new');
		};

		$scope.goToEditState = function(templateEvent) {
			// this overview is NOT mapped, it is the main overview
			$state.go('templateevent.list.edit', { templateEventId: templateEvent.id });
		};

		var getEventTypeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.TemplateEventSearchOverview.eventType)) {
				return $scope.TemplateEventSearchOverview.eventType.id;
			} else {
				return undefined;
			}
		};

		var getDefaultDriverId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.TemplateEventSearchOverview.defaultDriver)) {
				return $scope.TemplateEventSearchOverview.defaultDriver.id;
			} else {
				return undefined;
			}
		};

		var getDefaultSecondDriverId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.TemplateEventSearchOverview.defaultSecondDriver)) {
				return $scope.TemplateEventSearchOverview.defaultSecondDriver.id;
			} else {
				return undefined;
			}
		};

		var getDefaultSpectatorId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.TemplateEventSearchOverview.defaultSpectator)) {
				return $scope.TemplateEventSearchOverview.defaultSpectator.id;
			} else {
				return undefined;
			}
		};

		var getDefaultInsuranceId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.TemplateEventSearchOverview.defaultInsurance)) {
				return $scope.TemplateEventSearchOverview.defaultInsurance.id;
			} else {
				return undefined;
			}
		};

		var getDefaultHotelId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.TemplateEventSearchOverview.defaultHotel)) {
				return $scope.TemplateEventSearchOverview.defaultHotel.id;
			} else {
				return undefined;
			}
		};

		var getDefaultFuelId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.TemplateEventSearchOverview.defaultFuel)) {
				return $scope.TemplateEventSearchOverview.defaultFuel.id;
			} else {
				return undefined;
			}
		};

		var getDefaultInstructionId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.TemplateEventSearchOverview.defaultInstruction)) {
				return $scope.TemplateEventSearchOverview.defaultInstruction.id;
			} else {
				return undefined;
			}
		};

		/**
		 * This function queries all entities of type EventType and checks if this TemplateEvent dialog is called in the context of EventType.
		 * If this is the case, we detect the parent entity TemplateEvent and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryEventType = function() {
			return NoPagingEventTypeFactory.query(function(result) {
				return result;
			});
		};
		$scope.eventTypeReferenceFilterList = $scope.queryEventType();
		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.TemplateEventSearchOverview = angular.copy($scope.TemplateEventSearchOverview);

			FindAllTemplateEventsByQueryParamsNonPageableFactory.query(
				{
					name: $scope.TemplateEventSearchOverview.name,
					eventTypeId: getEventTypeId(),
					defaultDriverId: getDefaultDriverId(),
					defaultSecondDriverId: getDefaultSecondDriverId(),
					defaultSpectatorId: getDefaultSpectatorId(),
					defaultInsuranceId: getDefaultInsuranceId(),
					defaultHotelId: getDefaultHotelId(),
					defaultFuelId: getDefaultFuelId(),
					defaultInstructionId: getDefaultInstructionId(),

					sort: [$scope.TemplateEventSearchOverview.predicate + ',' + ($scope.TemplateEventSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.templateEventList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isTemplateEventSearchOverviewDirty = function() {
			return !angular.equals($scope.TemplateEventSearchOverview, $scope.originalTemplateEventSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.TemplateEventSearchOverview = {};
			$rootScope.TemplateEventSearchOverview.predicate = 'id';
			$rootScope.TemplateEventSearchOverview.ascending = false;

			$scope.TemplateEventSearchOverview = angular.copy($rootScope.TemplateEventSearchOverview);

			$scope.originalTemplateEventSearchOverview = angular.copy($scope.TemplateEventSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.TemplateEventSearchOverview) || $rootScope.TemplateEventSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.TemplateEventSearchOverview = angular.copy($rootScope.TemplateEventSearchOverview);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedTemplateEventId = id;
			$('#deleteTemplateEventConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			TemplateEventFactory.delete(
				{ id: $scope.selectedTemplateEventId },
				function() {
					$scope.search();
					$('#deleteTemplateEventConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteTemplateEventConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.TemplateEventSearchOverview.predicate === itemName) {
				if ($scope.TemplateEventSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
