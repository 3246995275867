/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('CompanyTypeTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CompanyTypeFactory,
		FindAllCompanyTypesByQueryParamsNonPageableFactory,
		NoPagingCompanyTypeFactory
	) {
		$scope.companyTypeList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newCompanyType', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(companyType) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editCompanyType', {
				companyTypeId: companyType.id,
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
			});
		};

		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CompanyTypeSearchTab = angular.copy($scope.CompanyTypeSearchTab);

			FindAllCompanyTypesByQueryParamsNonPageableFactory.query(
				{
					name: $scope.CompanyTypeSearchTab.name,
					description: $scope.CompanyTypeSearchTab.description,

					sort: [$scope.CompanyTypeSearchTab.predicate + ',' + ($scope.CompanyTypeSearchTab.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.companyTypeList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCompanyTypeSearchTabDirty = function() {
			return !angular.equals($scope.CompanyTypeSearchTab, $scope.originalCompanyTypeSearchTab);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CompanyTypeSearchTab = {};
			$rootScope.CompanyTypeSearchTab.predicate = 'id';
			$rootScope.CompanyTypeSearchTab.ascending = false;

			$scope.CompanyTypeSearchTab = angular.copy($rootScope.CompanyTypeSearchTab);

			$scope.originalCompanyTypeSearchTab = angular.copy($scope.CompanyTypeSearchTab);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CompanyTypeSearchTab) || $rootScope.CompanyTypeSearchTab === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CompanyTypeSearchTab = angular.copy($rootScope.CompanyTypeSearchTab);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCompanyTypeId = id;
			$('#deleteCompanyTypeConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CompanyTypeFactory.delete(
				{ id: $scope.selectedCompanyTypeId },
				function() {
					$scope.search();
					$('#deleteCompanyTypeConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteCompanyTypeConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CompanyTypeSearchTab.predicate === itemName) {
				if ($scope.CompanyTypeSearchTab.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
