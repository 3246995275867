/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('LocationTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		LocationFactory,
		FindAllLocationsByQueryParamsFactory,
		NoPagingLocationFactory,
		NoPagingCountryFactory
	) {
		$scope.locationList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeLocation)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeLocation);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeLocation = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newLocation', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(location) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editLocation', {
				locationId: location.id,
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
			});
		};

		var getDeliveryAddressCountryIdList = function() {
			if ($rootScope.isDefinedAndNotNull($scope.deliveryAddressCountryId)) {
				return $scope.deliveryAddressCountryId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.LocationSearchTab.deliveryAddressCountryIdList)) {
					return $rootScope.getListValues($scope.LocationSearchTab.deliveryAddressCountryIdList, 'id');
				} else {
					return undefined;
				}
			}
		};

		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.LocationSearchTab = angular.copy($scope.LocationSearchTab);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeLocation = angular.copy($scope.pageSize);

			FindAllLocationsByQueryParamsFactory.query(
				{
					page: $scope.LocationSearchTab.page - 1,
					size: $scope.pageSize,
					name: $scope.LocationSearchTab.name,
					description: $scope.LocationSearchTab.description,
					deliveryContactPerson: $scope.LocationSearchTab.deliveryContactPerson,
					deliveryAddressStreet: $scope.LocationSearchTab.deliveryAddressStreet,
					deliveryAddressZip: $scope.LocationSearchTab.deliveryAddressZip,
					deliveryAddressCity: $scope.LocationSearchTab.deliveryAddressCity,
					deliveryAddressCountryIdList: getDeliveryAddressCountryIdList(),

					sort: [$scope.LocationSearchTab.predicate + ',' + ($scope.LocationSearchTab.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.locationList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.updateDeliveryAddressCountryUISelect = function(item, model) {
			$scope.search();
		};
		$scope.isLocationSearchTabDirty = function() {
			return !angular.equals($scope.LocationSearchTab, $scope.originalLocationSearchTab);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.LocationSearchTab = {};
			$rootScope.LocationSearchTab.page = 1;
			$rootScope.LocationSearchTab.predicate = 'id';
			$rootScope.LocationSearchTab.ascending = false;

			$scope.LocationSearchTab = angular.copy($rootScope.LocationSearchTab);

			$scope.originalLocationSearchTab = angular.copy($scope.LocationSearchTab);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.LocationSearchTab) || $rootScope.LocationSearchTab === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.LocationSearchTab = angular.copy($rootScope.LocationSearchTab);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedLocationId = id;
			$('#deleteLocationConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			LocationFactory.delete(
				{ id: $scope.selectedLocationId },
				function() {
					$scope.search();
					$('#deleteLocationConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteLocationConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.LocationSearchTab.predicate === itemName) {
				if ($scope.LocationSearchTab.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
