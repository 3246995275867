(function() {
	'use strict';

	angular.module('emsApp').controller('UserManagementDialogController', UserManagementDialogController);

	UserManagementDialogController.$inject = [ '$stateParams', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService',
			'NoPagingUserTypeFactory' ];

	function UserManagementDialogController($stateParams, $uibModalInstance, entity, User, JhiLanguageService, NoPagingUserTypeFactory) {
		var vm = this;

		vm.authorities = [ 'ROLE_USER', 'ROLE_ADMIN' ];
		vm.clear = clear;
		vm.languages = null;
		vm.userTypes = null;
		vm.save = save;
		vm.user = entity;

		JhiLanguageService.getAll().then(function(languages) {
			vm.languages = languages;
		});

		NoPagingUserTypeFactory.query(function(result) {
			vm.userTypes = result;
		});

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function onSaveSuccess(result) {
			vm.isSaving = false;
			$uibModalInstance.close(result);
		}

		function onSaveError() {
			vm.isSaving = false;
		}

		function save() {
			vm.isSaving = true;
			if (vm.user.id !== null) {
				User.update(vm.user, onSaveSuccess, onSaveError);
			} else {
				User.save(vm.user, onSaveSuccess, onSaveError);
			}
		}
	}
})();
