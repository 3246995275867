/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('TemplateEventDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		TemplateEventFactory,
		AlertService,
		NoPagingTemplateEventFactory,
		NoPagingEventTypeFactory,
		NoPagingArticleFactory,
		TemplateEventFindArticleFilteredByDefaultDriverFactory,
		TemplateEventFindArticleFilteredByDefaultSecondDriverFactory,
		TemplateEventFindArticleFilteredByDefaultSpectatorFactory,
		TemplateEventFindArticleFilteredByDefaultInsuranceFactory,
		TemplateEventFindArticleFilteredByDefaultHotelFactory,
		TemplateEventFindArticleFilteredByDefaultFuelFactory,
		TemplateEventFindArticleFilteredByDefaultInstructionFactory,
		SalesFilterFromSalesFilterTemplateEventPicklistUpdateFactory,
		NoPagingSalesFilterFactory
	) {
		$scope.templateEvent = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.templateEvent) && $rootScope.isDefinedAndNotNull($scope.templateEvent.id);
		};

		$scope.createEventType = function() {
			var referredBy = {
				refName: 'eventType',
				data: $scope.templateEvent,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new eventType will add this new templateevent state to the history stack
				$state.go('templateevent.list.newEventType', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createDefaultDriver = function() {
			var referredBy = {
				refName: 'defaultDriver',
				data: $scope.templateEvent,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new defaultDriver will add this new templateevent state to the history stack
				$state.go('templateevent.list.newArticle', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createDefaultSecondDriver = function() {
			var referredBy = {
				refName: 'defaultSecondDriver',
				data: $scope.templateEvent,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new defaultSecondDriver will add this new templateevent state to the history stack
				$state.go('templateevent.list.newArticle', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createDefaultSpectator = function() {
			var referredBy = {
				refName: 'defaultSpectator',
				data: $scope.templateEvent,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new defaultSpectator will add this new templateevent state to the history stack
				$state.go('templateevent.list.newArticle', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createDefaultInsurance = function() {
			var referredBy = {
				refName: 'defaultInsurance',
				data: $scope.templateEvent,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new defaultInsurance will add this new templateevent state to the history stack
				$state.go('templateevent.list.newArticle', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createDefaultHotel = function() {
			var referredBy = {
				refName: 'defaultHotel',
				data: $scope.templateEvent,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new defaultHotel will add this new templateevent state to the history stack
				$state.go('templateevent.list.newArticle', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createDefaultFuel = function() {
			var referredBy = {
				refName: 'defaultFuel',
				data: $scope.templateEvent,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new defaultFuel will add this new templateevent state to the history stack
				$state.go('templateevent.list.newArticle', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createDefaultInstruction = function() {
			var referredBy = {
				refName: 'defaultInstruction',
				data: $scope.templateEvent,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new defaultInstruction will add this new templateevent state to the history stack
				$state.go('templateevent.list.newArticle', { referredBy: referredBy }, { reload: false });
			}, 100);
		};

		$scope.submitButtonIsActive = true;

		/**
		 * Function to find an item in a list of items based on the parent id if this dialog is opened from a tabbed overview
		 */
		$scope.findItemByParent = function(items, property) {
			//This function preselects and disables the parent reference when called from tab
			if ($stateParams.mappedBy === property && $stateParams.mappedById !== undefined) {
				angular.forEach(items, function(value) {
					// value.id is a number, $stateParams.mappedById is a string, hence we cast the $stateParams.mappedById to a number so we can compare the id using the typesafe === operator
					if (value.id === _.toNumber($stateParams.mappedById)) {
						var model = $parse('templateEvent.' + property);
						model.assign($scope, value);
					}
				});
			}
		};

		// --- start default implementation
		/**
		 * This function queries all entities of type EventType and checks if this TemplateEvent dialog is called in the context of EventType.
		 * If this is the case, we detect the parent entity TemplateEvent and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryEventType = function() {
			return NoPagingEventTypeFactory.query(function(result) {
				$scope.findItemByParent(result, 'eventType');
			});
		};
		$scope.eventTypeList = $scope.queryEventType();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type DefaultDriver and checks if this TemplateEvent dialog is called in the context of DefaultDriver.
		 * If this is the case, we detect the parent entity TemplateEvent and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryDefaultDriver = function() {
			return TemplateEventFindArticleFilteredByDefaultDriverFactory.query(function(result) {
				$scope.findItemByParent(result, 'defaultDriver');
			});
		};
		$scope.defaultDriverList = $scope.queryDefaultDriver();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type DefaultSecondDriver and checks if this TemplateEvent dialog is called in the context of DefaultSecondDriver.
		 * If this is the case, we detect the parent entity TemplateEvent and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryDefaultSecondDriver = function() {
			return TemplateEventFindArticleFilteredByDefaultSecondDriverFactory.query(function(result) {
				$scope.findItemByParent(result, 'defaultSecondDriver');
			});
		};
		$scope.defaultSecondDriverList = $scope.queryDefaultSecondDriver();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type DefaultSpectator and checks if this TemplateEvent dialog is called in the context of DefaultSpectator.
		 * If this is the case, we detect the parent entity TemplateEvent and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryDefaultSpectator = function() {
			return TemplateEventFindArticleFilteredByDefaultSpectatorFactory.query(function(result) {
				$scope.findItemByParent(result, 'defaultSpectator');
			});
		};
		$scope.defaultSpectatorList = $scope.queryDefaultSpectator();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type DefaultInsurance and checks if this TemplateEvent dialog is called in the context of DefaultInsurance.
		 * If this is the case, we detect the parent entity TemplateEvent and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryDefaultInsurance = function() {
			return TemplateEventFindArticleFilteredByDefaultInsuranceFactory.query(function(result) {
				$scope.findItemByParent(result, 'defaultInsurance');
			});
		};
		$scope.defaultInsuranceList = $scope.queryDefaultInsurance();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type DefaultHotel and checks if this TemplateEvent dialog is called in the context of DefaultHotel.
		 * If this is the case, we detect the parent entity TemplateEvent and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryDefaultHotel = function() {
			return TemplateEventFindArticleFilteredByDefaultHotelFactory.query(function(result) {
				$scope.findItemByParent(result, 'defaultHotel');
			});
		};
		$scope.defaultHotelList = $scope.queryDefaultHotel();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type DefaultFuel and checks if this TemplateEvent dialog is called in the context of DefaultFuel.
		 * If this is the case, we detect the parent entity TemplateEvent and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryDefaultFuel = function() {
			return TemplateEventFindArticleFilteredByDefaultFuelFactory.query(function(result) {
				$scope.findItemByParent(result, 'defaultFuel');
			});
		};
		$scope.defaultFuelList = $scope.queryDefaultFuel();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type DefaultInstruction and checks if this TemplateEvent dialog is called in the context of DefaultInstruction.
		 * If this is the case, we detect the parent entity TemplateEvent and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryDefaultInstruction = function() {
			return TemplateEventFindArticleFilteredByDefaultInstructionFactory.query(function(result) {
				$scope.findItemByParent(result, 'defaultInstruction');
			});
		};
		$scope.defaultInstructionList = $scope.queryDefaultInstruction();
		// --- end default implementation

		var getSelectedListItems = function(allItems, selectedItemList) {
			var itemIDs = [];
			angular.forEach(selectedItemList, function(value, key) {
				itemIDs.push(value.id);
			});

			var selectedItems = [];
			angular.forEach(allItems, function(value, key) {
				angular.forEach(itemIDs, function(value2, key2) {
					if (value.id === value2) {
						selectedItems.push(value);
					}
				});
			});

			return selectedItems;
		};

		var tempSelectedSalesFilterList = [];
		$scope.selectedSalesFilterList = [];

		$scope.getSelectedSalesFilterList = function() {
			SalesFilterFromSalesFilterTemplateEventPicklistUpdateFactory.query({ id: $stateParams.templateEventId }, function(result) {
				//put all selected SalesFilter-items in a list
				angular.forEach(result, function(value, key) {
					tempSelectedSalesFilterList.push(value);
				});
				NoPagingSalesFilterFactory.query(function(result) {
					$scope.salesFilterPickListOptions = result;
					$scope.selectedSalesFilterList = getSelectedListItems(result, tempSelectedSalesFilterList);
				});
			});
		};

		$scope.getSalesFilterPickListOptions = function() {
			if ($stateParams.templateEventId != null) {
				$scope.getSelectedSalesFilterList();
			} else {
				NoPagingSalesFilterFactory.query(function(result) {
					$scope.salesFilterPickListOptions = result;
				});
			}
		};

		$scope.getSalesFilterPickListOptions();

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:templateEventUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving TemplateEvent failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;

			if ($scope.templateEvent.id != null) {
				TemplateEventFactory.update(
					$scope.templateEvent,
					function(result) {
						$scope.templateEvent = result;

						if (
							!angular.isUndefined($scope.selectedSalesFilterList) &&
							angular.isArray($scope.selectedSalesFilterList) &&
							$scope.selectedSalesFilterList.length > 0
						) {
							SalesFilterFromSalesFilterTemplateEventPicklistUpdateFactory.update(
								{ id: result.id },
								$scope.selectedSalesFilterList,
								function(successResult) {
									onSaveFinishedCallback(successResult);
								},
								function(error) {}
							);
						} else {
							onSaveFinishedCallback(result);
						} // if
					},
					saveOrUpdateErrorCallback
				);
			} else {
				TemplateEventFactory.save(
					$scope.templateEvent,
					function(result) {
						$scope.templateEvent = result;

						if (
							!angular.isUndefined($scope.selectedSalesFilterList) &&
							angular.isArray($scope.selectedSalesFilterList) &&
							$scope.selectedSalesFilterList.length > 0
						) {
							SalesFilterFromSalesFilterTemplateEventPicklistUpdateFactory.update(
								{ id: result.id },
								$scope.selectedSalesFilterList,
								function(successResult) {
									onSaveFinishedCallback(successResult);
								},
								function(error) {}
							);
						} else {
							onSaveFinishedCallback(result);
						} // if
					},
					saveOrUpdateErrorCallback
				);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};
	});
