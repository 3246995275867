/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('CompanyTypeFactory', function($resource) {
	return $resource(
		'api/companyTypes/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewCompanyTypeFactory', function($resource) {
	return $resource(
		'api/companyTypes/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CompanyTypes without the use of paging
angular.module('emsApp').factory('NoPagingCompanyTypeFactory', function($resource) {
	return $resource(
		'api/companyTypes/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllCompanyTypesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/companyTypes/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllCompanyTypesByQueryParamsNonPageableFactory', function($resource) {
	return $resource(
		'api/query/companyTypesNonPageable/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
