/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('SubscriptionFactory', function($resource) {
	return $resource(
		'api/subscriptions/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewSubscriptionFactory', function($resource) {
	return $resource(
		'api/subscriptions/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Subscriptions without the use of paging
angular.module('emsApp').factory('NoPagingSubscriptionFactory', function($resource) {
	return $resource(
		'api/subscriptions/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SubscriptionTypes Enum values without the use of paging
angular.module('emsApp').factory('NoPagingSubscriptionTypeFactory', function($resource) {
	return $resource(
		'api/subscription/subscriptionTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SubscriptionStatuses Enum values without the use of paging
angular.module('emsApp').factory('NoPagingSubscriptionStatusFactory', function($resource) {
	return $resource(
		'api/subscription/subscriptionStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by driver
angular.module('emsApp').factory('SubscriptionFindArticleFilteredByDriverFactory', function($resource) {
	return $resource(
		'api/subscriptions/article/filteredby/driver',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by seconddriver
angular.module('emsApp').factory('SubscriptionFindArticleFilteredBySecondDriverFactory', function($resource) {
	return $resource(
		'api/subscriptions/article/filteredby/seconddriver',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by spectator
angular.module('emsApp').factory('SubscriptionFindArticleFilteredBySpectatorFactory', function($resource) {
	return $resource(
		'api/subscriptions/article/filteredby/spectator',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by insurance
angular.module('emsApp').factory('SubscriptionFindArticleFilteredByInsuranceFactory', function($resource) {
	return $resource(
		'api/subscriptions/article/filteredby/insurance',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by hotel
angular.module('emsApp').factory('SubscriptionFindArticleFilteredByHotelFactory', function($resource) {
	return $resource(
		'api/subscriptions/article/filteredby/hotel',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by fuel
angular.module('emsApp').factory('SubscriptionFindArticleFilteredByFuelFactory', function($resource) {
	return $resource(
		'api/subscriptions/article/filteredby/fuel',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by rentalcar
angular.module('emsApp').factory('SubscriptionFindArticleFilteredByRentalCarFactory', function($resource) {
	return $resource(
		'api/subscriptions/article/filteredby/rentalcar',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by instruction
angular.module('emsApp').factory('SubscriptionFindArticleFilteredByInstructionFactory', function($resource) {
	return $resource(
		'api/subscriptions/article/filteredby/instruction',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('OrderLinesBySubscriptionFactory', function($resource) {
	return $resource(
		'api/orderLines/subscription/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllSubscriptionsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/subscriptions/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all Subscriptions by booking ID and subscription type without the use of paging
angular.module('emsApp').factory('NoPagingSubscriptionByBookingAndSubscriptionTypeFactory', function($resource) {
	return $resource(
		'api/subscriptions/booking/:bookingId/subscriptiontype/:subscriptionType',
		{},
		{
			query: {
				method: 'GET',
				isArray: true,
			},
		}
	);
});

//Get all subscriptions by booking ID without paging
angular.module('emsApp').factory('NoPagingSubscriptionByBookingFactory', function($resource) {
	return $resource(
		'api/subscriptions/booking/:bookingId',
		{},
		{
			query: {
				method: 'GET',
				isArray: true,
			},
		}
	);
});

// Delete a driver plus all related second drivers and spectators (via relationOf), and the booking (if it has no more subscriptions)
angular.module('emsApp').factory('DriverFactory', function($resource) {
	return $resource(
		'api/subscriptions/:id/booking/:bookingId',
		{},
		{
			deleteDriver: {
				method: 'DELETE',
			},
		}
	);
});

angular.module('emsApp').factory('SubscriptionSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/subscriptions/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
