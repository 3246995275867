/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('CountryFactory', function($resource) {
	return $resource(
		'api/countries/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to search / retrieve an array of UserCountryListDto's
 */
angular.module('emsApp').factory('UserCountryListDtoSearchFactory', function($resource) {
	return $resource(
		'api/user/country',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewCountryFactory', function($resource) {
	return $resource(
		'api/countries/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Countries without the use of paging
angular.module('emsApp').factory('NoPagingCountryFactory', function($resource) {
	return $resource(
		'api/countries/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllCountriesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/countries/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
