/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('VatDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		VatFactory,
		AlertService,
		NoPagingVatFactory,
		NoPagingVatTypeFactory
	) {
		$scope.vat = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.vat) && $rootScope.isDefinedAndNotNull($scope.vat.id);
		};

		$scope.submitButtonIsActive = true;

		/**
		 * Function to find an item in a list of items based on the parent id if this dialog is opened from a tabbed overview
		 */
		$scope.findItemByParent = function(items, property) {
			//This function preselects and disables the parent reference when called from tab
			if ($stateParams.mappedBy === property && $stateParams.mappedById !== undefined) {
				angular.forEach(items, function(value) {
					// value.id is a number, $stateParams.mappedById is a string, hence we cast the $stateParams.mappedById to a number so we can compare the id using the typesafe === operator
					if (value.id === _.toNumber($stateParams.mappedById)) {
						var model = $parse('vat.' + property);
						model.assign($scope, value);
					}
				});
			}
		};

		// --- start default implementation
		/**
		 * This function queries all entities of type VatType and checks if this Vat dialog is called in the context of VatType.
		 * If this is the case, we detect the parent entity Vat and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryVatType = function() {
			return NoPagingVatTypeFactory.query(function(result) {
				$scope.findItemByParent(result, 'vatType');
			});
		};
		$scope.vatTypeList = $scope.queryVatType();
		// --- end default implementation

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:vatUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving Vat failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;

			if ($scope.vat.id != null) {
				VatFactory.update($scope.vat, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				VatFactory.save($scope.vat, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};
	});
