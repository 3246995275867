'use strict';

angular.module('emsApp').controller(
		'BookingDialogController',
		function($scope, $rootScope, $timeout, $state, $stateParams, $uibModalInstance, $parse, entity, BookingFactory, AlertService,
				NoPagingBookingFactory, AutocompleteBookingsByQueryParamsFactory, NoPagingEventFactory,
				AutocompleteEventsByQueryParamsFactory, NoPagingRelationFactory, AutocompleteRelationsByQueryParamsFactory,
				NoPagingBookingStatusFactory) {

			$scope.booking = entity;

			if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
				$scope.mappedBy = $stateParams.mappedBy;
			}

			/**
			 * Checks if the page is in create or edit state based on the scope model and it's id value
			 */
			$scope.isEdit = function() {
				return ($rootScope.isDefinedAndNotNull($scope.booking) && $rootScope.isDefinedAndNotNull($scope.booking.id));
			};

			$scope.createEvent = function() {
				var referredBy = {
					refName : 'event',
					data : $scope.booking
				};

				// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
				$uibModalInstance.dismiss('createNew');

				$timeout(function() {
					// we add a little timeout to prevent the modal dismiss not to be ready
					// navigating to new event will add this new booking state to the history stack
					$state.go('booking.list.newEvent', {
						referredBy : referredBy
					}, {
						reload : false
					});
				}, 100);
			};
			$scope.createClient = function() {
				var referredBy = {
					refName : 'client',
					data : $scope.booking
				};

				// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
				$uibModalInstance.dismiss('createNew');

				$timeout(function() {
					// we add a little timeout to prevent the modal dismiss not to be ready
					// navigating to new client will add this new booking state to the history stack
					$state.go('booking.list.newRelation', {
						referredBy : referredBy
					}, {
						reload : false
					});
				}, 100);
			};
			$scope.createStatus = function() {
				var referredBy = {
					refName : 'status',
					data : $scope.booking
				};

				// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
				$uibModalInstance.dismiss('createNew');

				$timeout(function() {
					// we add a little timeout to prevent the modal dismiss not to be ready
					// navigating to new status will add this new booking state to the history stack
					$state.go('booking.list.newBookingStatus', {
						referredBy : referredBy
					}, {
						reload : false
					});
				}, 100);
			};

			$scope.submitButtonIsActive = true;

			$scope.autocompleteEvents = function(val) {
				var params = {
					'eventTypeAheadSearchString' : val

				};

				return AutocompleteEventsByQueryParamsFactory.query(params).$promise.then(function(results) {
					return results;
				});
			};
			$scope.autocompleteRelations = function(val) {
				var params = {
					'relationTypeAheadSearchString' : val

				};

				return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
					return results;
				});
			};

			/**
			 * Function to find an item in a list of items based on the parent id if this dialog is opened from a tabbed overview
			 */
			$scope.findItemByParent = function(items, property) {
				//This function preselects and disables the parent reference when called from tab
				if ($stateParams.mappedBy === property && $stateParams.mappedById !== undefined) {
					angular.forEach(items, function(value) {
						// value.id is a number, $stateParams.mappedById is a string, hence we cast the $stateParams.mappedById to a number so we can compare the id using the typesafe === operator
						if (value.id === _.toNumber($stateParams.mappedById)) {
							var model = $parse("booking." + property);
							model.assign($scope, value);
						}
					});
				}
			};

			/**
			 * This function preselects and disables the parent reference in autocomplete field when called from tab
			 */
			$scope.queryEvent = function() {
				// FIXME this call to retrieve all Events seems unnecessary for autocomplete references!
				return NoPagingEventFactory.query(function(result) {
					$scope.findItemByParent(result, "event");
				});
			};
			$scope.eventList = $scope.queryEvent();

			/**
			 * This function preselects and disables the parent reference in autocomplete field when called from tab
			 */
			$scope.queryClient = function() {
				// FIXME this call to retrieve all Relations seems unnecessary for autocomplete references!
				return NoPagingRelationFactory.query(function(result) {
					$scope.findItemByParent(result, "client");
				});
			};
			$scope.clientList = $scope.queryClient();

			// --- start default implementation
			/**
			 * This function queries all entities of type Status and checks if this Booking dialog is called in the context of Status.
			 * If this is the case, we detect the parent entity Booking and select it as model value so it is shown in the dropdown select.
			 */
			$scope.queryStatus = function() {
				return NoPagingBookingStatusFactory.query(function(result) {
					$scope.findItemByParent(result, "status");
				});
			};
			$scope.statusList = $scope.queryStatus();
			// --- end default implementation

			var onSaveFinishedCallback = function(result) {
				$scope.$emit('emsApp:bookingUpdate', result);
				$uibModalInstance.close(result);
				$scope.submitButtonIsActive = true;
			};

			var saveOrUpdateErrorCallback = function(error) {
				console.error("Saving Booking failed", error);
				AlertService.error("error." + error.data.message, error.data.params);
				$scope.submitButtonIsActive = true;
			};

			$scope.save = function() {
				$scope.submitButtonIsActive = false;

				//check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
				if ($scope.booking.event === "") {
					$scope.booking.event = null;
				}
				//check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
				if ($scope.booking.client === "") {
					$scope.booking.client = null;
				}

				if ($scope.booking.id != null) {
					BookingFactory.update($scope.booking, onSaveFinishedCallback, saveOrUpdateErrorCallback);
				} else {
					BookingFactory.save($scope.booking, onSaveFinishedCallback, saveOrUpdateErrorCallback);
				}
			};

			// called from cancel button in dialog
			$scope.clear = function() {
				// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
				$uibModalInstance.dismiss('cancel');
			};

		});
