/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('ArticleRelationPriceTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ArticleRelationPriceFactory,
		FindAllArticleRelationPricesByQueryParamsFactory,
		AutocompleteRelationsByQueryParamsFactory,
		NoPagingArticleRelationPriceFactory,
		NoPagingRelationFactory,
		NoPagingArticleFactory
	) {
		$scope.articleRelationPriceList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeArticleRelationPrice)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeArticleRelationPrice);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeArticleRelationPrice = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newArticleRelationPrice', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(articleRelationPrice) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editArticleRelationPrice', {
				articleRelationPriceId: articleRelationPrice.id,
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
			});
		};

		var getRelationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.relationId)) {
				return $scope.relationId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.ArticleRelationPriceSearchTab.relation)) {
					return $scope.ArticleRelationPriceSearchTab.relation.id;
				} else {
					return undefined;
				}
			}
		};

		var getArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.articleId)) {
				return $scope.articleId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.ArticleRelationPriceSearchTab.article)) {
					return $scope.ArticleRelationPriceSearchTab.article.id;
				} else {
					return undefined;
				}
			}
		};

		$scope.autocompleteRelations = function(val) {
			var params = {
				relationTypeAheadSearchString: val,
			};

			return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		/**
		 * This function queries all entities of type Article and checks if this ArticleRelationPrice dialog is called in the context of Article.
		 * If this is the case, we detect the parent entity ArticleRelationPrice and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryArticle = function() {
			return NoPagingArticleFactory.query(function(result) {
				return result;
			});
		};
		$scope.articleReferenceFilterList = $scope.queryArticle();
		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.ArticleRelationPriceSearchTab = angular.copy($scope.ArticleRelationPriceSearchTab);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeArticleRelationPrice = angular.copy($scope.pageSize);

			FindAllArticleRelationPricesByQueryParamsFactory.query(
				{
					page: $scope.ArticleRelationPriceSearchTab.page - 1,
					size: $scope.pageSize,
					relationId: getRelationId(),
					articleId: getArticleId(),

					sort: [$scope.ArticleRelationPriceSearchTab.predicate + ',' + ($scope.ArticleRelationPriceSearchTab.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.articleRelationPriceList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isArticleRelationPriceSearchTabDirty = function() {
			return !angular.equals($scope.ArticleRelationPriceSearchTab, $scope.originalArticleRelationPriceSearchTab);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.ArticleRelationPriceSearchTab = {};
			$rootScope.ArticleRelationPriceSearchTab.page = 1;
			$rootScope.ArticleRelationPriceSearchTab.predicate = 'id';
			$rootScope.ArticleRelationPriceSearchTab.ascending = false;

			$scope.ArticleRelationPriceSearchTab = angular.copy($rootScope.ArticleRelationPriceSearchTab);

			$scope.originalArticleRelationPriceSearchTab = angular.copy($scope.ArticleRelationPriceSearchTab);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.ArticleRelationPriceSearchTab) || $rootScope.ArticleRelationPriceSearchTab === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.ArticleRelationPriceSearchTab = angular.copy($rootScope.ArticleRelationPriceSearchTab);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedArticleRelationPriceId = id;
			$('#deleteArticleRelationPriceConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			ArticleRelationPriceFactory.delete(
				{ id: $scope.selectedArticleRelationPriceId },
				function() {
					$scope.search();
					$('#deleteArticleRelationPriceConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteArticleRelationPriceConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.ArticleRelationPriceSearchTab.predicate === itemName) {
				if ($scope.ArticleRelationPriceSearchTab.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
