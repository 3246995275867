/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('UserTypeFactory', function($resource) {
	return $resource(
		'api/userTypes/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewUserTypeFactory', function($resource) {
	return $resource(
		'api/userTypes/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all UserTypes without the use of paging
angular.module('emsApp').factory('NoPagingUserTypeFactory', function($resource) {
	return $resource(
		'api/userTypes/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PredefinedUserTypes Enum values without the use of paging
angular.module('emsApp').factory('NoPagingPredefinedUserTypeFactory', function($resource) {
	return $resource(
		'api/userType/predefinedUserTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('UsersByUserTypeFactory', function($resource) {
	return $resource(
		'api/users/userType/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllUserTypesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/userTypes/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllUserTypesByQueryParamsNonPageableFactory', function($resource) {
	return $resource(
		'api/query/userTypesNonPageable/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
