/** @format */

(function() {
	'use strict';

	angular
		.module('emsApp')
		.filter('findLanguageFromKey', findLanguageFromKey)
		.filter('findLanguageRtlFromKey', findLanguageRtlFromKey);

	var languages = {
		en: { name: 'English' },
		nl: { name: 'Dutch' },
		// jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
	};

	function findLanguageFromKey() {
		return findLanguageFromKeyFilter;

		function findLanguageFromKeyFilter(lang) {
			return languages[lang].name;
		}
	}

	function findLanguageRtlFromKey() {
		return findLanguageRtlFromKeyFilter;

		function findLanguageRtlFromKeyFilter(lang) {
			return languages[lang].rtl;
		}
	}
})();
