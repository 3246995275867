/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('LocationTemplateEventArticleFactory', function($resource) {
	return $resource(
		'api/locationTemplateEventArticles/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewLocationTemplateEventArticleFactory', function($resource) {
	return $resource(
		'api/locationTemplateEventArticles/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all LocationTemplateEventArticles without the use of paging
angular.module('emsApp').factory('NoPagingLocationTemplateEventArticleFactory', function($resource) {
	return $resource(
		'api/locationTemplateEventArticles/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PredefinedArticleTypes Enum values without the use of paging
angular.module('emsApp').factory('NoPagingPredefinedArticleTypeFactory', function($resource) {
	return $resource(
		'api/locationTemplateEventArticle/predefinedArticleTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by article
angular.module('emsApp').factory('LocationTemplateEventArticleFindArticleFilteredByArticleFactory', function($resource) {
	return $resource(
		'api/locationtemplateeventarticles/article/filteredby/article',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllLocationTemplateEventArticlesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/locationTemplateEventArticles/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
