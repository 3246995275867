/** @format */

'use strict';

angular.module('emsApp').controller('ArticleRelationPriceDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.articleRelationPrice = entity;
	$scope.objectName = 'ArticleRelationPrice';
	$scope.articleRelationPriceId = $stateParams.articleRelationPriceId;

	if (angular.isUndefined($rootScope.articleRelationPriceActiveTab)) {
		//set the default here
		$rootScope.articleRelationPriceActiveTab = 0;
	}

	$scope.goToEditState = function(articleRelationPrice) {
		$state.go(
			'articlerelationprice.detail.edit({articleRelationPriceId:articleRelationPrice.id, mappedBy:mappedBy, mappedById:mappedById})'
		);
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:articleRelationPriceUpdate', function(event, result) {
		$scope.articleRelationPrice = result;
	});
});
