'use strict';

angular
  .module('emsApp')
  .controller('ConfirmationEmailDialogController', function (
    $scope,
    $rootScope,
    $uibModalInstance,
    ProfileService,
    ConfirmationEmailTemplateList
  ) {
    $scope.isProduction = false;
    $scope.waitingForData = true;
    $scope.waitingForEnvironment = true;

    $scope.selectedEmailTemplate = null;
    $scope.sendToTestAddress = true;

    $scope.emailTemplateListOptions = [];

    ConfirmationEmailTemplateList.get()
      .$promise.then(function (response) {
        $scope.emailTemplateListOptions = response;
      })
      .finally(function () {
        $scope.waitingForData = false;
      });

    ProfileService.getProfileInfo()
      .then(function (response) {
        $scope.isProduction = response.inProduction;
        if (response.inProduction) {
          $scope.sendToTestAddress = false;
        }
      })
      .finally(function () {
        $scope.waitingForEnvironment = false;
      });

    $scope.formShouldBeEnabled = function () {
      return !$scope.waitingForData && !$scope.waitingForEnvironment && !$scope.form.$invalid;
    };

    $scope.save = function () {
			var slug = $scope.selectedEmailTemplate.slug;
			var emailLabels = $scope.emailTemplateListOptions.filter(function (option) {
				return option.slug === slug;
			})[0].labels;
      $uibModalInstance.close({
        emailTemplateId: $scope.selectedEmailTemplate.slug,
				emailLabels: emailLabels,
        sendToTestAddress: $scope.sendToTestAddress,
      });
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
