/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('EventRelationContactDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		EventRelationContactFactory,
		AlertService,
		NoPagingEventRelationContactFactory,
		EventFactory,
		NoPagingEventFactory,
		AutocompleteEventsByQueryParamsFactory,
		RelationFactory,
		NoPagingRelationFactory,
		AutocompleteRelationsByQueryParamsFactory,
		NoPagingSalesStatusFactory
	) {
		$scope.eventRelationContact = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.eventRelationContact) && $rootScope.isDefinedAndNotNull($scope.eventRelationContact.id);
		};

		$scope.submitButtonIsActive = true;

		$scope.autocompleteEvents = function(val) {
			var params = {
				eventTypeAheadSearchString: val,
			};

			return AutocompleteEventsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};
		$scope.autocompleteRelations = function(val) {
			var params = {
				relationTypeAheadSearchString: val,
			};

			return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		/**
		 * Function to find an item in a list of items based on the parent id if this dialog is opened from a tabbed overview
		 */
		$scope.findItemByParent = function(items, property) {
			//This function preselects and disables the parent reference when called from tab
			if ($stateParams.mappedBy === property && $stateParams.mappedById !== undefined) {
				angular.forEach(items, function(value) {
					// value.id is a number, $stateParams.mappedById is a string, hence we cast the $stateParams.mappedById to a number so we can compare the id using the typesafe === operator
					if (value.id === _.toNumber($stateParams.mappedById)) {
						var model = $parse('eventRelationContact.' + property);
						model.assign($scope, value);
					}
				});
			}
		};

		/**
		 * This function preselects and disables the parent reference in autocomplete field when called from tab
		 */
		$scope.queryEvent = function() {
			// If the id of a preselected event is available, then retrieve from backend and set in model
			if ($stateParams.mappedById === null) {
				return null;
			} else {
				return EventFactory.get({ id: $stateParams.mappedById }, function(result) {
					if (result !== null) {
						$scope.findItemByParent([result], 'event');
					}
				});
			}
		};
		$scope.eventList = $scope.queryEvent();

		/**
		 * This function preselects and disables the parent reference in autocomplete field when called from tab
		 */
		$scope.queryRelation = function() {
			// If the id of a preselected relation is available, then retrieve from backend and set in model
			if ($stateParams.mappedById === null) {
				return null;
			} else {
				return RelationFactory.get({ id: $stateParams.mappedById }, function(result) {
					if (result !== null) {
						$scope.findItemByParent([result], 'relation');
					}
				});
			}
		};
		$scope.relationList = $scope.queryRelation();

		// --- start default implementation
		/**
		 * This function queries all entities of type SalesStatus and checks if this EventRelationContact dialog is called in the context of SalesStatus.
		 * If this is the case, we detect the parent entity EventRelationContact and select it as model value so it is shown in the dropdown select.
		 */
		$scope.querySalesStatus = function() {
			return NoPagingSalesStatusFactory.query(function(result) {
				$scope.findItemByParent(result, 'salesStatus');
			});
		};
		$scope.salesStatusList = $scope.querySalesStatus();
		// --- end default implementation

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:eventRelationContactUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving EventRelationContact failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;

			//check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
			if ($scope.eventRelationContact.event === '') {
				$scope.eventRelationContact.event = null;
			}
			//check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
			if ($scope.eventRelationContact.relation === '') {
				$scope.eventRelationContact.relation = null;
			}

			if ($scope.eventRelationContact.id != null) {
				EventRelationContactFactory.update($scope.eventRelationContact, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				EventRelationContactFactory.save($scope.eventRelationContact, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};
	});
