/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('EventRelationContactController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		EventRelationContactFactory,
		FindAllEventRelationContactsByQueryParamsFactory,
		AutocompleteEventsByQueryParamsFactory,
		AutocompleteRelationsByQueryParamsFactory,
		NoPagingEventRelationContactFactory,
		NoPagingEventFactory,
		NoPagingRelationFactory,
		NoPagingSalesStatusFactory
	) {
		$scope.eventRelationContactList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeEventRelationContact)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeEventRelationContact);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeEventRelationContact = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('eventrelationcontact.list.new');
		};

		$scope.goToEditState = function(eventRelationContact) {
			// this overview is NOT mapped, it is the main overview
			$state.go('eventrelationcontact.list.edit', { eventRelationContactId: eventRelationContact.id });
		};

		var getEventId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.EventRelationContactSearchOverview.event)) {
				return $scope.EventRelationContactSearchOverview.event.id;
			} else {
				return undefined;
			}
		};

		var getRelationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.EventRelationContactSearchOverview.relation)) {
				return $scope.EventRelationContactSearchOverview.relation.id;
			} else {
				return undefined;
			}
		};

		$scope.autocompleteEvents = function(val) {
			var params = {
				eventTypeAheadSearchString: val,
			};

			return AutocompleteEventsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		$scope.autocompleteRelations = function(val) {
			var params = {
				relationTypeAheadSearchString: val,
			};

			return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		NoPagingSalesStatusFactory.query(function(result) {
			$scope.salesStatusList = result;
		});
		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.EventRelationContactSearchOverview = angular.copy($scope.EventRelationContactSearchOverview);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeEventRelationContact = angular.copy($scope.pageSize);

			FindAllEventRelationContactsByQueryParamsFactory.query(
				{
					page: $scope.EventRelationContactSearchOverview.page - 1,
					size: $scope.pageSize,
					eventId: getEventId(),
					relationId: getRelationId(),
					salesStatus: $scope.EventRelationContactSearchOverview.salesStatus,

					sort: [
						$scope.EventRelationContactSearchOverview.predicate +
							',' +
							($scope.EventRelationContactSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.eventRelationContactList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isEventRelationContactSearchOverviewDirty = function() {
			return !angular.equals($scope.EventRelationContactSearchOverview, $scope.originalEventRelationContactSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.EventRelationContactSearchOverview = {};
			$rootScope.EventRelationContactSearchOverview.page = 1;
			$rootScope.EventRelationContactSearchOverview.predicate = 'id';
			$rootScope.EventRelationContactSearchOverview.ascending = false;

			$scope.EventRelationContactSearchOverview = angular.copy($rootScope.EventRelationContactSearchOverview);

			$scope.originalEventRelationContactSearchOverview = angular.copy($scope.EventRelationContactSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.EventRelationContactSearchOverview) || $rootScope.EventRelationContactSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.EventRelationContactSearchOverview = angular.copy($rootScope.EventRelationContactSearchOverview);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedEventRelationContactId = id;
			$('#deleteEventRelationContactConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			EventRelationContactFactory.delete(
				{ id: $scope.selectedEventRelationContactId },
				function() {
					$scope.search();
					$('#deleteEventRelationContactConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteEventRelationContactConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.EventRelationContactSearchOverview.predicate === itemName) {
				if ($scope.EventRelationContactSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
