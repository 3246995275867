/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('LocationTemplateEventArticleDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
		$scope.locationTemplateEventArticle = entity;
		$scope.objectName = 'LocationTemplateEventArticle';
		$scope.locationTemplateEventArticleId = $stateParams.locationTemplateEventArticleId;

		if (angular.isUndefined($rootScope.locationTemplateEventArticleActiveTab)) {
			//set the default here
			$rootScope.locationTemplateEventArticleActiveTab = 0;
		}

		$scope.goToEditState = function(locationTemplateEventArticle) {
			$state.go(
				'locationtemplateeventarticle.detail.edit({locationTemplateEventArticleId:locationTemplateEventArticle.id, mappedBy:mappedBy, mappedById:mappedById})'
			);
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('emsApp:locationTemplateEventArticleUpdate', function(event, result) {
			$scope.locationTemplateEventArticle = result;
		});
	});
