/** @format */

'use strict';

angular.module('emsApp').controller('EventRelationContactDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.eventRelationContact = entity;
	$scope.objectName = 'EventRelationContact';
	$scope.eventRelationContactId = $stateParams.eventRelationContactId;

	if (angular.isUndefined($rootScope.eventRelationContactActiveTab)) {
		//set the default here
		$rootScope.eventRelationContactActiveTab = 0;
	}

	$scope.goToEditState = function(eventRelationContact) {
		$state.go(
			'eventrelationcontact.detail.edit({eventRelationContactId:eventRelationContact.id, mappedBy:mappedBy, mappedById:mappedById})'
		);
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:eventRelationContactUpdate', function(event, result) {
		$scope.eventRelationContact = result;
	});
});
