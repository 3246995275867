// CodeComposer:ignore
/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('emsApp')
	.controller('UserRelationEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		UserRelationEditDtoFactory,
		AlertService,
		HistoryService,
		UserRelationEditDtoSaveAndCreateFactory
	) {
		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.relation = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.relation['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.relation) && $rootScope.isDefinedAndNotNull($scope.relation.id);
		};

		var onSaveAndNewFinishedCallback = function(result) {
			// navigate to new state with result (a new entity, but with the copied values from the previous)
			$state.go('user-relation.new', {
				dataModel: result,
				mappedBy: $stateParams.mappedBy,
				mappedById: $stateParams.mappedById,
			});
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:relationUpdate', result);
			$scope.submitButtonIsActive = true;
			// process success result, if necessary
			if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
				console.log('as we came on this create new page from plus button we will update history');

				// set relation as reference in the referredBy data model
				$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;

				// update previous state
				HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
			}
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			UserRelationEditDtoFactory.update($scope.relation, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		$scope.saveAndNew = function() {
			$scope.submitButtonIsActive = false;

			// To prevent navigating back to this current view, remove it from history.
			HistoryService.removeHistory();

			if ($scope.relation.id != null) {
				UserRelationEditDtoSaveAndCreateFactory.updateAndNew($scope.relation, onSaveAndNewFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				UserRelationEditDtoSaveAndCreateFactory.createAndNew($scope.relation, onSaveAndNewFinishedCallback, saveOrUpdateErrorCallback);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};

		// Custom validation of the summarize length of the first-last name fields
		$scope.$watchGroup(['relation.firstName', 'relation.lastName'], function (firstName, lastName) {
			var summOfLengths = ($scope.relation.firstName || '').length + ($scope.relation.lastName || '').length;
			$scope._validationSumOfLengths = summOfLengths
			var isLengthValid = summOfLengths <= 49;
			$scope.editForm.firstName.$setValidity('firstAndLastNameLengthSumm', isLengthValid);
			$scope.editForm.lastName.$setValidity('firstAndLastNameLengthSumm', isLengthValid);
		});
	});
