'use strict';

angular.module('emsApp').directive("mfdEventRelationStatus", function() {
	return {
		restrict : 'E',
		replace : true,
		templateUrl : 'app/sales/relation-sales-status.html',
		controller : 'EventRelationStatusController',
		scope : {
			relation : "=", // the relation
			event : "=", // the event
			eventContacts : "=",// the list of eventRelationContacts (if available, can be null)
			salesStatus : "=", // the array of possible sales statusses
			statusCellStyle : "=" // style to apply to the status cell
		}
	};
});
