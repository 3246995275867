/** @format */

'use strict';

angular.module('emsApp').controller('TemplateEventDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.templateEvent = entity;
	$scope.objectName = 'TemplateEvent';
	$scope.templateEventId = $stateParams.templateEventId;

	if (angular.isUndefined($rootScope.templateEventActiveTab)) {
		//set the default here
		$rootScope.templateEventActiveTab = 0;
	}

	$scope.goToEditState = function(templateEvent) {
		$state.go('templateevent.detail.edit({templateEventId:templateEvent.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:templateEventUpdate', function(event, result) {
		$scope.templateEvent = result;
	});
});
