/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('MailGroupFactory', function($resource) {
	return $resource(
		'api/mailGroups/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewMailGroupFactory', function($resource) {
	return $resource(
		'api/mailGroups/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all MailGroups without the use of paging
angular.module('emsApp').factory('NoPagingMailGroupFactory', function($resource) {
	return $resource(
		'api/mailGroups/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('RelationMailGroupsByMailGroupFactory', function($resource) {
	return $resource(
		'api/relationMailGroups/mailGroup/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('emsApp').factory('RelationFromRelationMailGroupPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/relationMailGroupList/AsRelationList/mailGroup/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

angular.module('emsApp').factory('FindAllMailGroupsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/mailGroups/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllMailGroupsByQueryParamsNonPageableFactory', function($resource) {
	return $resource(
		'api/query/mailGroupsNonPageable/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
