/** @format */

'use strict';

angular.module('emsApp').controller('SalesFilterTemplateEventDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.salesFilterTemplateEvent = entity;
	$scope.objectName = 'SalesFilterTemplateEvent';
	$scope.salesFilterTemplateEventId = $stateParams.salesFilterTemplateEventId;

	if (angular.isUndefined($rootScope.salesFilterTemplateEventActiveTab)) {
		//set the default here
		$rootScope.salesFilterTemplateEventActiveTab = 0;
	}

	$scope.goToEditState = function(salesFilterTemplateEvent) {
		$state.go(
			'salesfiltertemplateevent.detail.edit({salesFilterTemplateEventId:salesFilterTemplateEvent.id, mappedBy:mappedBy, mappedById:mappedById})'
		);
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:salesFilterTemplateEventUpdate', function(event, result) {
		$scope.salesFilterTemplateEvent = result;
	});
});
