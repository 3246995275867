/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('LocationTemplateEventArticleController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		LocationTemplateEventArticleFactory,
		FindAllLocationTemplateEventArticlesByQueryParamsFactory,
		NoPagingLocationTemplateEventArticleFactory,
		NoPagingLocationFactory,
		NoPagingTemplateEventFactory,
		NoPagingPredefinedArticleTypeFactory,
		NoPagingArticleFactory,
		LocationTemplateEventArticleFindArticleFilteredByArticleFactory
	) {
		$scope.locationTemplateEventArticleList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeLocationTemplateEventArticle)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeLocationTemplateEventArticle);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeLocationTemplateEventArticle = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('locationtemplateeventarticle.list.new');
		};

		$scope.goToEditState = function(locationTemplateEventArticle) {
			// this overview is NOT mapped, it is the main overview
			$state.go('locationtemplateeventarticle.list.edit', { locationTemplateEventArticleId: locationTemplateEventArticle.id });
		};

		var getLocationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.LocationTemplateEventArticleSearchOverview.location)) {
				return $scope.LocationTemplateEventArticleSearchOverview.location.id;
			} else {
				return undefined;
			}
		};

		var getTemplateEventId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.LocationTemplateEventArticleSearchOverview.templateEvent)) {
				return $scope.LocationTemplateEventArticleSearchOverview.templateEvent.id;
			} else {
				return undefined;
			}
		};

		var getArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.LocationTemplateEventArticleSearchOverview.article)) {
				return $scope.LocationTemplateEventArticleSearchOverview.article.id;
			} else {
				return undefined;
			}
		};

		/**
		 * This function queries all entities of type Location and checks if this LocationTemplateEventArticle dialog is called in the context of Location.
		 * If this is the case, we detect the parent entity LocationTemplateEventArticle and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryLocation = function() {
			return NoPagingLocationFactory.query(function(result) {
				return result;
			});
		};
		$scope.locationReferenceFilterList = $scope.queryLocation();

		/**
		 * This function queries all entities of type TemplateEvent and checks if this LocationTemplateEventArticle dialog is called in the context of TemplateEvent.
		 * If this is the case, we detect the parent entity LocationTemplateEventArticle and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryTemplateEvent = function() {
			return NoPagingTemplateEventFactory.query(function(result) {
				return result;
			});
		};
		$scope.templateEventReferenceFilterList = $scope.queryTemplateEvent();

		/**
		 * This function queries all entities of type Article and checks if this LocationTemplateEventArticle dialog is called in the context of Article.
		 * If this is the case, we detect the parent entity LocationTemplateEventArticle and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryArticle = function() {
			return LocationTemplateEventArticleFindArticleFilteredByArticleFactory.query(function(result) {
				return result;
			});
		};
		$scope.articleReferenceFilterList = $scope.queryArticle();
		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.LocationTemplateEventArticleSearchOverview = angular.copy($scope.LocationTemplateEventArticleSearchOverview);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeLocationTemplateEventArticle = angular.copy($scope.pageSize);

			FindAllLocationTemplateEventArticlesByQueryParamsFactory.query(
				{
					page: $scope.LocationTemplateEventArticleSearchOverview.page - 1,
					size: $scope.pageSize,
					locationId: getLocationId(),
					templateEventId: getTemplateEventId(),
					predefinedArticleType: $scope.LocationTemplateEventArticleSearchOverview.predefinedArticleType,
					articleId: getArticleId(),

					sort: [
						$scope.LocationTemplateEventArticleSearchOverview.predicate +
							',' +
							($scope.LocationTemplateEventArticleSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.locationTemplateEventArticleList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isLocationTemplateEventArticleSearchOverviewDirty = function() {
			return !angular.equals($scope.LocationTemplateEventArticleSearchOverview, $scope.originalLocationTemplateEventArticleSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.LocationTemplateEventArticleSearchOverview = {};
			$rootScope.LocationTemplateEventArticleSearchOverview.page = 1;
			$rootScope.LocationTemplateEventArticleSearchOverview.predicate = 'id';
			$rootScope.LocationTemplateEventArticleSearchOverview.ascending = false;

			$scope.LocationTemplateEventArticleSearchOverview = angular.copy($rootScope.LocationTemplateEventArticleSearchOverview);

			$scope.originalLocationTemplateEventArticleSearchOverview = angular.copy($scope.LocationTemplateEventArticleSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (
				angular.isUndefined($rootScope.LocationTemplateEventArticleSearchOverview) ||
				$rootScope.LocationTemplateEventArticleSearchOverview === null
			) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.LocationTemplateEventArticleSearchOverview = angular.copy($rootScope.LocationTemplateEventArticleSearchOverview);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedLocationTemplateEventArticleId = id;
			$('#deleteLocationTemplateEventArticleConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			LocationTemplateEventArticleFactory.delete(
				{ id: $scope.selectedLocationTemplateEventArticleId },
				function() {
					$scope.search();
					$('#deleteLocationTemplateEventArticleConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteLocationTemplateEventArticleConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.LocationTemplateEventArticleSearchOverview.predicate === itemName) {
				if ($scope.LocationTemplateEventArticleSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
