/** @format */

'use strict';

angular.module('emsApp').controller('CarRentalTypeDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.carRentalType = entity;
	$scope.objectName = 'CarRentalType';
	$scope.carRentalTypeId = $stateParams.carRentalTypeId;

	if (angular.isUndefined($rootScope.carRentalTypeActiveTab)) {
		//set the default here
		$rootScope.carRentalTypeActiveTab = 0;
	}

	$scope.goToEditState = function(carRentalType) {
		$state.go('carrentaltype.detail.edit({carRentalTypeId:carRentalType.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:carRentalTypeUpdate', function(event, result) {
		$scope.carRentalType = result;
	});
});
