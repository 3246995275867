/** @format */

'use strict';

angular.module('emsApp').controller('LocationDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.location = entity;
	$scope.objectName = 'Location';
	$scope.locationId = $stateParams.locationId;

	if (angular.isUndefined($rootScope.locationActiveTab)) {
		//set the default here
		$rootScope.locationActiveTab = 0;
	}

	$scope.goToEditState = function(location) {
		$state.go('location.detail.edit({locationId:location.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:locationUpdate', function(event, result) {
		$scope.location = result;
	});
});
