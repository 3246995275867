/** @format */

(function() {
	'use strict';

	angular
		.module('emsApp')
		.config(stateConfig)
		.config(uiSelectConfig)
		.config(localStorageConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider.state('app', {
			abstract: true,
			views: {
				'navbar@': {
					templateUrl: 'app/layouts/navbar/navbar.html',
					controller: 'NavbarController',
					controllerAs: 'vm',
				},
			},
			resolve: {
				authorize: [
					'Auth',
					function(Auth) {
						return Auth.authorize();
					},
				],
				translatePartialLoader: [
					'$translate',
					'$translatePartialLoader',
					function($translate, $translatePartialLoader) {
						$translatePartialLoader.addPart('global');
					},
				],
			},
		});
	}

	uiSelectConfig.$inject = ['uiSelectConfig'];

	function uiSelectConfig(uiSelectConfig) {
		// AngularJS UI select (https://github.com/angular-ui/ui-select) global settings
		uiSelectConfig.theme = 'bootstrap';
		uiSelectConfig.resetSearchInput = true;
		uiSelectConfig.appendToBody = true;
	}

	localStorageConfig.$inject = ['localStorageServiceProvider'];

	function localStorageConfig(localStorageServiceProvider) {
		localStorageServiceProvider.setPrefix('emsApp').setStorageType('sessionStorage');
	}
})();
