/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('SalesFilterDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		SalesFilterFactory,
		AlertService,
		NoPagingSalesFilterFactory,
		NoPagingSalesStatusFactory,
		NoPagingUserFactory,
		EventTypeFromSalesFilterEventTypePicklistUpdateFactory,
		NoPagingEventTypeFactory,
		TemplateEventFromSalesFilterTemplateEventPicklistUpdateFactory,
		NoPagingTemplateEventFactory
	) {
		$scope.salesFilter = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.salesFilter) && $rootScope.isDefinedAndNotNull($scope.salesFilter.id);
		};

		$scope.submitButtonIsActive = true;

		/**
		 * Function to find an item in a list of items based on the parent id if this dialog is opened from a tabbed overview
		 */
		$scope.findItemByParent = function(items, property) {
			//This function preselects and disables the parent reference when called from tab
			if ($stateParams.mappedBy === property && $stateParams.mappedById !== undefined) {
				angular.forEach(items, function(value) {
					// value.id is a number, $stateParams.mappedById is a string, hence we cast the $stateParams.mappedById to a number so we can compare the id using the typesafe === operator
					if (value.id === _.toNumber($stateParams.mappedById)) {
						var model = $parse('salesFilter.' + property);
						model.assign($scope, value);
					}
				});
			}
		};

		// --- start default implementation
		/**
		 * This function queries all entities of type SalesStatus and checks if this SalesFilter dialog is called in the context of SalesStatus.
		 * If this is the case, we detect the parent entity SalesFilter and select it as model value so it is shown in the dropdown select.
		 */
		$scope.querySalesStatus = function() {
			return NoPagingSalesStatusFactory.query(function(result) {
				$scope.findItemByParent(result, 'salesStatus');
			});
		};
		$scope.salesStatusList = $scope.querySalesStatus();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type User and checks if this SalesFilter dialog is called in the context of User.
		 * If this is the case, we detect the parent entity SalesFilter and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryUser = function() {
			return NoPagingUserFactory.query(function(result) {
				$scope.findItemByParent(result, 'user');
			});
		};
		$scope.userList = $scope.queryUser();
		// --- end default implementation

		var getSelectedListItems = function(allItems, selectedItemList) {
			var itemIDs = [];
			angular.forEach(selectedItemList, function(value, key) {
				itemIDs.push(value.id);
			});

			var selectedItems = [];
			angular.forEach(allItems, function(value, key) {
				angular.forEach(itemIDs, function(value2, key2) {
					if (value.id === value2) {
						selectedItems.push(value);
					}
				});
			});

			return selectedItems;
		};

		var tempSelectedEventTypeList = [];
		$scope.selectedEventTypeList = [];

		$scope.getSelectedEventTypeList = function() {
			EventTypeFromSalesFilterEventTypePicklistUpdateFactory.query({ id: $stateParams.salesFilterId }, function(result) {
				//put all selected EventType-items in a list
				angular.forEach(result, function(value, key) {
					tempSelectedEventTypeList.push(value);
				});
				NoPagingEventTypeFactory.query(function(result) {
					$scope.eventTypePickListOptions = result;
					$scope.selectedEventTypeList = getSelectedListItems(result, tempSelectedEventTypeList);
				});
			});
		};

		$scope.getEventTypePickListOptions = function() {
			if ($stateParams.salesFilterId != null) {
				$scope.getSelectedEventTypeList();
			} else {
				NoPagingEventTypeFactory.query(function(result) {
					$scope.eventTypePickListOptions = result;
				});
			}
		};

		$scope.getEventTypePickListOptions();

		var tempSelectedTemplateEventList = [];
		$scope.selectedTemplateEventList = [];

		$scope.getSelectedTemplateEventList = function() {
			TemplateEventFromSalesFilterTemplateEventPicklistUpdateFactory.query({ id: $stateParams.salesFilterId }, function(result) {
				//put all selected TemplateEvent-items in a list
				angular.forEach(result, function(value, key) {
					tempSelectedTemplateEventList.push(value);
				});
				NoPagingTemplateEventFactory.query(function(result) {
					$scope.templateEventPickListOptions = result;
					$scope.selectedTemplateEventList = getSelectedListItems(result, tempSelectedTemplateEventList);
				});
			});
		};

		$scope.getTemplateEventPickListOptions = function() {
			if ($stateParams.salesFilterId != null) {
				$scope.getSelectedTemplateEventList();
			} else {
				NoPagingTemplateEventFactory.query(function(result) {
					$scope.templateEventPickListOptions = result;
				});
			}
		};

		$scope.getTemplateEventPickListOptions();

		var onUpdateTemplateEventListUpdateCallback = function(result) {
			TemplateEventFromSalesFilterTemplateEventPicklistUpdateFactory.update(
				{ id: $scope.salesFilter.id },
				$scope.selectedTemplateEventList,
				onSaveFinishedCallback
			);
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:salesFilterUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving SalesFilter failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;

			if ($scope.salesFilter.id != null) {
				SalesFilterFactory.update(
					$scope.salesFilter,
					function(result) {
						$scope.salesFilter = result;
						EventTypeFromSalesFilterEventTypePicklistUpdateFactory.update(
							{ id: result.id },
							$scope.selectedEventTypeList,
							function(successResult) {
								onUpdateTemplateEventListUpdateCallback();
							},
							function(error) {
								AlertService.error(error.data.message, error.data.params);
							}
						);
					},
					saveOrUpdateErrorCallback
				);
			} else {
				SalesFilterFactory.save(
					$scope.salesFilter,
					function(result) {
						$scope.salesFilter = result;
						EventTypeFromSalesFilterEventTypePicklistUpdateFactory.update(
							{ id: result.id },
							$scope.selectedEventTypeList,
							function(successResult) {
								onUpdateTemplateEventListUpdateCallback();
							},
							function(error) {
								AlertService.error(error.data.message, error.data.params);
							}
						);
					},
					saveOrUpdateErrorCallback
				);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};
	});
