/** @format */

'use strict';

angular.module('emsApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Subscription'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('subscription'); // main translation loaded for subscription
		$translatePartialLoader.addPart('subscriptiontype'); // one to many reference from main entity
		$translatePartialLoader.addPart('subscriptionstatus'); // one to many reference from main entity
		$translatePartialLoader.addPart('relation'); // one to many reference from main entity
		$translatePartialLoader.addPart('relationtype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('relationsource'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('gender'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('clientof'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('langkey'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('booking'); // one to many reference from main entity
		$translatePartialLoader.addPart('bookingstatus'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('confirmationemailstatus'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('car'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // one to many reference from main entity
		$translatePartialLoader.addPart('instructor'); // one to many reference from main entity
		$translatePartialLoader.addPart('templateevent'); // one to many reference from main entity
		$translatePartialLoader.addPart('orderline'); // one to many reference from main entity

		return $translate.refresh();
	};

	/**
	 * "private" function called by wrapper new and edit functions.
	 */
	var getModalState = function(url, isEditMode) {
		if (!angular.isDefined(url) || url === null) {
			url = '/new';
		}

		if (!angular.isDefined(isEditMode) || isEditMode === null) {
			// default mode is NEW, however this situation should and will be avoided by as this function should only be
			isEditMode = false;
		}

		var templateUrl;
		// The default assignment to templateUrl can be overridden in hook custom/jhipster/state/template_url/add_or_edit.ftl
		templateUrl = 'app/entities/subscription/subscription-dialog.html';

		return {
			/* new state for entity subscription */
			/* to access: ui-ref="subscription.list.new" */
			/* url: hostname/subscription/new */
			url: url,
			data: {
				authorities: ['ROLE_USER'],
				isModal: true,
			},
			params: {
				mappedBy: null,
				mappedByType: null,
				mappedById: null,
				dataModel: null, // this data model is set from the new relation state OR from the save and new logic
				mappedByDisplayValue: null,
				dataModelType: null, // this data model type is the name of the modelobject, lowercase
				referredBy: null, // will be populated once this new state is triggered from another new state with + button
				hiddenParam: 'YES', // hides params from url
			},
			onEnter: [
				'$stateParams',
				'$state',
				'$uibModal',
				'$rootScope',
				'HistoryService',
				function($stateParams, $state, $uibModal, $rootScope, HistoryService) {
					var navigateBack = true;

					$uibModal
						.open({
							templateUrl: templateUrl,
							controller: 'SubscriptionDialogController',
							size: 'lg',
							backdrop: 'static',
							resolve: {
								entity: [
									'SubscriptionFactory',
									'NewSubscriptionFactory',
									function(SubscriptionFactory, NewSubscriptionFactory) {
										// NOTE either this dialog was opened with the purpose of
										// - creating a new Subscription
										// - resuming the creation of a new new Subscription AFTER new instances were created of x..1 reference(s)

										// if dataModel is defined, we're coming back from a new created x..1 reference
										if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
											var model = $stateParams.dataModel;
											// reset 'remembered' values to prevent future misbehaviour
											$rootScope.previousStateParams.dataModel = null;
											$stateParams.dataModel = null;

											// instead of returning a new model, we return the updated model
											return model;
										} else {
											if (isEditMode) {
												// get the entity by its ID
												return SubscriptionFactory.get({ id: $stateParams.subscriptionId }).$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											} else {
												// get a new empty entity
												return NewSubscriptionFactory.get().$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											}
										}
									},
								],
								translatePartialLoader: [
									'$translate',
									'$translatePartialLoader',
									function($translate, $translatePartialLoader) {
										return getTranslations($translate, $translatePartialLoader);
									},
								],
							},
						})
						.result.then(function(result) {
							// process success result, if necessary
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// set relation as reference in the referredBy data model
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;
							}
						})
						.catch(function(closeReason) {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// reset relation in referred data model object
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = null;
							}
							if (closeReason === 'createNew') {
								// only navigate back in history if the close reason allows us to
								navigateBack = false;
							}
						})
						.finally(function() {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
							}

							if (navigateBack) {
								$rootScope.back();
							}
						});
				},
			],
		};
	};

	/**
	 * Body for subscription new state
	 */
	function getNewState(url) {
		return getModalState(url, false);
	}

	/**
	 * Body for subscription edit state
	 */
	function getEditState(url) {
		return getModalState(url, true);
	}

	$stateProvider.state('subscription', {
		/* abstract state for entity subscription */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/subscription',
		data: {
			authorities: ['ROLE_USER'],
			pageTitle: 'Subscriptions',
			isModal: false,
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	$stateProvider.state('subscription.list', {
		/* list state for entity subscription */
		/* to access: ui-ref="subscription.list" */
		/* url: hostname/subscription/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/subscription/subscriptions.html',
				controller: 'SubscriptionController',
			},
		},
	});

	$stateProvider.state('subscription.detail', {
		/* detail state for entity subscription */
		/* to access: ui-sref="subscription.detail" */
		/* url: hostname/subscription/detail/:subscriptionId */
		url: '/detail/:subscriptionId',
		views: {
			'content@': {
				templateUrl: 'app/entities/subscription/subscription-detail.html',
				controller: 'SubscriptionDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'SubscriptionFactory',
				function($stateParams, SubscriptionFactory) {
					return SubscriptionFactory.get({ id: $stateParams.subscriptionId });
				},
			],
		},
	});

	/* url: http://<hostname>/subscription/list/new */
	$stateProvider.state('subscription.list.new', getNewState());
	/* url: http://<hostname>/subscription/list/:subscriptionId/edit */
	$stateProvider.state('subscription.list.edit', getEditState('/:subscriptionId/edit'));
	/* url: http://<hostname>/subscription/detail/:subscriptionId/edit */
	$stateProvider.state('subscription.detail.edit', getEditState('/edit'));
	// Relation.subscription with multiplicity 0..n points to Subscription
	/* url: http://<hostname>/relation/detail/:relationId/subscription/new */
	$stateProvider.state('relation.detail.newSubscription', getNewState('/subscription/new'));
	/* url: http://<hostname>/relation/detail/:relationId/subscription/:subscriptionId/edit */
	$stateProvider.state('relation.detail.editSubscription', getEditState('/subscription/:subscriptionId/edit'));
	// Rare case of mixing non-dto's on a dto screen
	/* url: http://<hostname>/user-relation/detail/:relationId/subscription/new */
	$stateProvider.state('user-relation.detail.newSubscription', getNewState('/subscription/new'));
	/* url: http://<hostname>/user-relation/detail/:relationId/subscription/:subscriptionId/edit */
	$stateProvider.state('user-relation.detail.editSubscription', getEditState('/subscription/:subscriptionId/edit'));
	// Car.subscription with multiplicity 0..n points to Subscription
	/* url: http://<hostname>/car/detail/:carId/subscription/new */
	$stateProvider.state('car.detail.newSubscription', getNewState('/subscription/new'));
	/* url: http://<hostname>/car/detail/:carId/subscription/:subscriptionId/edit */
	$stateProvider.state('car.detail.editSubscription', getEditState('/subscription/:subscriptionId/edit'));
	// Booking.subscription with multiplicity 0..n points to Subscription
	/* url: http://<hostname>/booking/detail/:bookingId/subscription/new */
	$stateProvider.state('booking.detail.newSubscription', getNewState('/subscription/new'));
	/* url: http://<hostname>/booking/detail/:bookingId/subscription/:subscriptionId/edit */
	$stateProvider.state('booking.detail.editSubscription', getEditState('/subscription/:subscriptionId/edit'));
	// Instructor.subscription with multiplicity 0..n points to Subscription
	/* url: http://<hostname>/instructor/detail/:instructorId/subscription/new */
	$stateProvider.state('instructor.detail.newSubscription', getNewState('/subscription/new'));
	/* url: http://<hostname>/instructor/detail/:instructorId/subscription/:subscriptionId/edit */
	$stateProvider.state('instructor.detail.editSubscription', getEditState('/subscription/:subscriptionId/edit'));

	$stateProvider.state('subscription.list.newSubscription', getNewState('/subscription/new'));
	$stateProvider.state('orderline.list.newSubscription', getNewState('/subscription/new'));
});
