/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('RelationTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		RelationFactory,
		FindAllRelationsByQueryParamsFactory,
		NoPagingRelationFactory,
		NoPagingRelationTypeFactory,
		NoPagingCompanyTypeFactory,
		NoPagingRelationSourceFactory,
		NoPagingGenderFactory,
		NoPagingClientOfFactory,
		NoPagingUserFactory,
		NoPagingCountryFactory,
		NoPagingLangKeyFactory,
		RelationFindUserFilteredByAccountManagerFactory
	) {
		$scope.relationList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeRelation)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeRelation);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeRelation = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newRelation', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(relation) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editRelation', {
				relationId: relation.id,
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
			});
		};

		var getCompanyTypeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.companyTypeId)) {
				return $scope.companyTypeId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.RelationSearchTab.companyType)) {
					return $scope.RelationSearchTab.companyType.id;
				} else {
					return undefined;
				}
			}
		};

		var getAccountManagerId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.accountManagerId)) {
				return $scope.accountManagerId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.RelationSearchTab.accountManager)) {
					return $scope.RelationSearchTab.accountManager.id;
				} else {
					return undefined;
				}
			}
		};

		var getBusinessCountryIdList = function() {
			if ($rootScope.isDefinedAndNotNull($scope.businessCountryId)) {
				return $scope.businessCountryId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.RelationSearchTab.businessCountryIdList)) {
					return $rootScope.getListValues($scope.RelationSearchTab.businessCountryIdList, 'id');
				} else {
					return undefined;
				}
			}
		};

		var getPrivateCountryIdList = function() {
			if ($rootScope.isDefinedAndNotNull($scope.privateCountryId)) {
				return $scope.privateCountryId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.RelationSearchTab.privateCountryIdList)) {
					return $rootScope.getListValues($scope.RelationSearchTab.privateCountryIdList, 'id');
				} else {
					return undefined;
				}
			}
		};

		// handle attribute carRentalAgreementSigned of javaType Boolean
		// handle attribute indemnificationSigned of javaType Boolean

		/**
		 * This function queries all entities of type AccountManager and checks if this Relation dialog is called in the context of AccountManager.
		 * If this is the case, we detect the parent entity Relation and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryAccountManager = function() {
			return RelationFindUserFilteredByAccountManagerFactory.query(function(result) {
				return result;
			});
		};
		$scope.accountManagerReferenceFilterList = $scope.queryAccountManager();
		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.RelationSearchTab = angular.copy($scope.RelationSearchTab);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeRelation = angular.copy($scope.pageSize);

			FindAllRelationsByQueryParamsFactory.query(
				{
					page: $scope.RelationSearchTab.page - 1,
					size: $scope.pageSize,
					externalId: $scope.RelationSearchTab.externalId,
					companyName: $scope.RelationSearchTab.companyName,
					firstName: $scope.RelationSearchTab.firstName,
					lastName: $scope.RelationSearchTab.lastName,
					email: $scope.RelationSearchTab.email,
					telephone: $scope.RelationSearchTab.telephone,
					mobile: $scope.RelationSearchTab.mobile,
					potentialFrom: $scope.RelationSearchTab.potentialFrom,
					potentialTo: $scope.RelationSearchTab.potentialTo,
					carRentalAgreementSigned: $scope.RelationSearchTab.carRentalAgreementSignedGroup,
					indemnificationSigned: $scope.RelationSearchTab.indemnificationSignedGroup,
					type: $scope.RelationSearchTab.type,
					companyTypeId: getCompanyTypeId(),
					relationSource: $scope.RelationSearchTab.relationSource,
					gender: $scope.RelationSearchTab.gender,
					clientOfList: $scope.RelationSearchTab.clientOfList,
					accountManagerId: getAccountManagerId(),
					businessCountryIdList: getBusinessCountryIdList(),
					privateCountryIdList: getPrivateCountryIdList(),
					langKeyList: $scope.RelationSearchTab.langKeyList,

					sort: [$scope.RelationSearchTab.predicate + ',' + ($scope.RelationSearchTab.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.relationList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.updateClientOfUISelect = function(item, model) {
			$scope.search();
		};
		$scope.updateBusinessCountryUISelect = function(item, model) {
			$scope.search();
		};
		$scope.updatePrivateCountryUISelect = function(item, model) {
			$scope.search();
		};
		$scope.updateLangKeyUISelect = function(item, model) {
			$scope.search();
		};
		$scope.isRelationSearchTabDirty = function() {
			return !angular.equals($scope.RelationSearchTab, $scope.originalRelationSearchTab);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.RelationSearchTab = {};
			$rootScope.RelationSearchTab.page = 1;
			$rootScope.RelationSearchTab.predicate = 'id';
			$rootScope.RelationSearchTab.ascending = false;

			$scope.RelationSearchTab = angular.copy($rootScope.RelationSearchTab);

			$scope.originalRelationSearchTab = angular.copy($scope.RelationSearchTab);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.RelationSearchTab) || $rootScope.RelationSearchTab === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.RelationSearchTab = angular.copy($rootScope.RelationSearchTab);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedRelationId = id;
			$('#deleteRelationConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationFactory.delete(
				{ id: $scope.selectedRelationId },
				function() {
					$scope.search();
					$('#deleteRelationConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteRelationConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('emsApp:relationUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.RelationSearchTab.predicate === itemName) {
				if ($scope.RelationSearchTab.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
