'use strict';

angular.module('emsApp').config(function($stateProvider) {

	/**
	 * Add all translations for the sales dashboard
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('relation-sales'); // main translation loaded for article
		$translatePartialLoader.addPart('relation');
		$translatePartialLoader.addPart('event');
		$translatePartialLoader.addPart('salesfilter');
		$translatePartialLoader.addPart('salesstatus');
		$translatePartialLoader.addPart('subscription');
		return $translate.refresh();
	};

	$stateProvider.state('sales', {
		/* abstract state for entity sales, which is not an existing entity */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract : true,
		parent : 'entity',
		url : '/sales',
		data : {
			authorities : [ 'ROLE_USER' ],
			pageTitle : 'Sales',
			isModal : false
		},
		resolve : {
			translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
				return getTranslations($translate, $translatePartialLoader);
			} ]
		}
	});

	// TODO we could reroute the events dashboard through this state, if we want to
	// $stateProvider.state('sales.dashboard', {
	//     /* list state for entity event */
	//     /* to access: ui-ref="sales.dashboard" */
	//     /* url: hostname/sales/dashboard */
	//     url: '/dashboard',
	//     views: {
	//         'content@': {
	//             templateUrl: '/app/sales/events-dashboard.html',
	//             controller: 'EventDashboardController'
	//         }
	//     }
	// });

	$stateProvider.state('sales.relation', {
		/* detail state for entity SalesDTO */
		/* to access: ui-sref="sales.relation" */
		/* url: hostname/sales/relation */
		url : '/relation',
		views : {
			'content@' : {
				templateUrl : 'app/sales/relation-sales.html',
				controller : 'RelationSalesController'
			}
		}
	});

});
