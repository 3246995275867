/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('MailGroupDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		MailGroupFactory,
		AlertService,
		NoPagingMailGroupFactory,
		RelationFromRelationMailGroupPicklistUpdateFactory,
		NoPagingRelationFactory
	) {
		$scope.mailGroup = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.mailGroup) && $rootScope.isDefinedAndNotNull($scope.mailGroup.id);
		};

		$scope.submitButtonIsActive = true;

		var getSelectedListItems = function(allItems, selectedItemList) {
			var itemIDs = [];
			angular.forEach(selectedItemList, function(value, key) {
				itemIDs.push(value.id);
			});

			var selectedItems = [];
			angular.forEach(allItems, function(value, key) {
				angular.forEach(itemIDs, function(value2, key2) {
					if (value.id === value2) {
						selectedItems.push(value);
					}
				});
			});

			return selectedItems;
		};

		var tempSelectedRelationList = [];
		$scope.selectedRelationList = [];

		$scope.getSelectedRelationList = function() {
			RelationFromRelationMailGroupPicklistUpdateFactory.query({ id: $stateParams.mailGroupId }, function(result) {
				//put all selected Relation-items in a list
				angular.forEach(result, function(value, key) {
					tempSelectedRelationList.push(value);
				});
				NoPagingRelationFactory.query(function(result) {
					$scope.relationPickListOptions = result;
					$scope.selectedRelationList = getSelectedListItems(result, tempSelectedRelationList);
				});
			});
		};

		$scope.getRelationPickListOptions = function() {
			if ($stateParams.mailGroupId != null) {
				$scope.getSelectedRelationList();
			} else {
				NoPagingRelationFactory.query(function(result) {
					$scope.relationPickListOptions = result;
				});
			}
		};

		$scope.getRelationPickListOptions();

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:mailGroupUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving MailGroup failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;

			if ($scope.mailGroup.id != null) {
				MailGroupFactory.update(
					$scope.mailGroup,
					function(result) {
						$scope.mailGroup = result;

						if (
							!angular.isUndefined($scope.selectedRelationList) &&
							angular.isArray($scope.selectedRelationList) &&
							$scope.selectedRelationList.length > 0
						) {
							RelationFromRelationMailGroupPicklistUpdateFactory.update(
								{ id: result.id },
								$scope.selectedRelationList,
								function(successResult) {
									onSaveFinishedCallback(successResult);
								},
								function(error) {}
							);
						} else {
							onSaveFinishedCallback(result);
						} // if
					},
					saveOrUpdateErrorCallback
				);
			} else {
				MailGroupFactory.save(
					$scope.mailGroup,
					function(result) {
						$scope.mailGroup = result;

						if (
							!angular.isUndefined($scope.selectedRelationList) &&
							angular.isArray($scope.selectedRelationList) &&
							$scope.selectedRelationList.length > 0
						) {
							RelationFromRelationMailGroupPicklistUpdateFactory.update(
								{ id: result.id },
								$scope.selectedRelationList,
								function(successResult) {
									onSaveFinishedCallback(successResult);
								},
								function(error) {}
							);
						} else {
							onSaveFinishedCallback(result);
						} // if
					},
					saveOrUpdateErrorCallback
				);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};
	});
