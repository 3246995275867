'use strict';

angular.module('emsApp').controller(
    'BookingSubscriptionDetailsController',
    function ($scope, $rootScope, $stateParams, $state, $translate, $timeout, $q, entity, dialogs,
              AlertService, BookingFactory, BookingSubscriptionsFactory, AutocompleteRelationsByQueryParamsFactory, SubscriptionFactory,
              NoPagingSubscriptionTypeFactory, NoPagingSubscriptionStatusFactory, SubscriptionFindArticleFilteredByHotelFactory,
              InstructorsByEventFactory, NoPagingTemplateEventFactory, SubscriptionFindArticleFilteredByDriverFactory,
              SubscriptionFindArticleFilteredBySpectatorFactory, SubscriptionFindArticleFilteredBySecondDriverFactory, NewSubscriptionFactory,
              SubscriptionFindArticleFilteredByInsuranceFactory, SubscriptionFindArticleFilteredByFuelFactory,
              AutocompleteCarsByQueryParamsFactory, HistoryService, NoPagingBookingStatusFactory, BookingSnelstartFactory, ConfirmationEmailManager) {
        $scope.booking = entity;
        $scope.objectName = "Booking";
        $scope.bookingId = $stateParams.bookingId;
        $scope.eventId = $stateParams.eventId;
        $scope.driverId = $stateParams.driverId;
        $scope.relationId = $stateParams.relationId;
        // list of drivers for this booking, per driver there could be second drivers and spectators (all dtos)
        $scope.subscriptionList = [];
        $scope.currentSubscription = null;
        $scope.currentSubscriptionIndex = 0;
        // entities to edit
        $scope.subscriptionToEdit = null;
        $scope.subscriptionToDelete = null;
        // properties to indicate certain parts of DOM should be shown, or not
        $scope.showBookingData = true;
        $scope.showInstructionData = false;
        $scope.showEditInstructionData = false;
        $scope.showBookingForm = false;
        $scope.showEditSubscriptionForm = false;
        $scope.showCarForm = false;
        $scope.hideAddingNewDriver = false;

        $scope.modelOptions = {
            debounce: {
                default: 500,
                blur: 100
            },
            getterSetter: true
        };

        $scope.title = function() {
            var title = "";

            if ($rootScope.isDefinedAndNotNull($scope.booking)) {
                if ($rootScope.isDefinedAndNotNull($scope.booking.description)) {
                    title = $scope.booking.description + " - ";
                }
                if ($rootScope.isDefinedAndNotNull($scope.booking.event)) {
                    title += $scope.booking.event.name + " - ";
                }
                if ($rootScope.isDefinedAndNotNull($scope.booking.client)) {
                    if ($rootScope.isDefinedAndNotNull($scope.booking.client.externalId)) {
                        title += $scope.booking.client.externalId + ", ";
                    }
                    if ($rootScope.isDefinedAndNotNull($scope.booking.client.personName)) {
                        title += $scope.booking.client.personName + ", ";
                    }
                    if ($rootScope.isDefinedAndNotNull($scope.booking.client.companyName)) {
                        title += $scope.booking.client.companyName;
                    }
                }
            }

            return title;
        };

        $scope.backToSalesDashboard = function() {
            // override for the rootscope back function to navigate to sales relation dashboard
            $state.go('sales.relation', {}, {reload: true});
        };

        $scope.backToEvent = function() {
            // override for the rootscope back function to navigate to event details
            $state.go('event.detail.subscriptions', {eventId: $scope.eventId}, {reload: true});
        };

        $scope.createCar = function() {
            console.log("creating new car for driver", $scope.subscriptionToEdit);

            // $scope.subscriptionToEdit is populated with a driver subscription by editCar()
            var referredBy = {
                refName: 'car',
                data: $scope.subscriptionToEdit
            };

            $timeout(function(){
                // we add a little timeout to prevent the modal dismiss not to be ready
                $state.go('event.detail.booking.newCar', {referredBy: referredBy, dataModel: null}, {reload: false});
            },10);
        };

        $scope.createBookingClient = function() {
            console.log("creating new client for booking", $scope.booking);

            // $scope.booking will be assigned by the result of creating the new booking
            var referredBy = {
                refName: 'client',
                data: $scope.booking
            };

            $timeout(function() {
                // we add a little timeout to prevent the modal dismiss not to be ready
                $state.go('event.detail.booking.newRelation', {referredBy: referredBy, dataModel: null}, {reload: false});
            },10);
        };

        $scope.createInstructor = function() {
            console.log("creating new instructor for driver", $scope.subscriptionToEdit);

            // $scope.subscriptionToEdit is populated with an instructor
            var referredBy = {
                refName: 'instructor',
                data: $scope.subscriptionToEdit
            };

            $timeout(function() {
                // we add a little timeout to prevent the modal dismiss not to be ready
                $state.go('event.detail.booking.newInstructor', {referredBy: referredBy, dataModel: null, driverId:$scope.driverId, mappedBy:'event', mappedById:$scope.subscriptionToEdit.booking.event.id}, {reload: false});
            },10);
        };

        $scope.createRelation = function() {
            console.log("creating new relation for subscriptopn", $scope.subscriptionToEdit);

            // $scope.subscriptionToEdit is populated with a relation
            var referredBy = {
                refName: 'relation',
                data: $scope.subscriptionToEdit
            };

            $timeout(function() {
                // we add a little timeout to prevent the modal dismiss not to be ready
                $state.go('event.detail.booking.newRelation', {referredBy: referredBy, dataModel: null, driverId:$scope.driverId}, {reload: false});
            },10);
        };



        $scope.getBookingArticle = function (subscription) {
            if (!$rootScope.isDefinedAndNotNull(subscription)) {
                return "";
            }

            if (subscription.subscriptionType == 'Driver') {
                return subscription.driver;
            }

            if (subscription.subscriptionType == 'Second_driver') {
                return subscription.secondDriver;
            }

            if (subscription.subscriptionType == 'Spectator') {
                return subscription.spectator;
            }
        };

// BOOKING functions

        $scope.toggleBookingForm = function() {
            $scope.showBookingForm = !$scope.showBookingForm;

            if ($scope.showBookingForm) {
                $scope.showEditBookingForm();
            } else {
                $scope.hideEditBookingForm();
            }
        };

        $scope.showEditBookingForm = function() {
            $scope.showBookingForm = true;
            angular.element("#field_client").focus();
        };

        $scope.hideEditBookingForm = function() {
            $scope.showBookingForm = false;
        };

        var getBooking = function() {
            BookingFactory.get({id : $scope.bookingId}).$promise.then(function(data) {
                $scope.booking = data;
            });
        };

        $scope.saveBooking = function () {
            $scope.submitButtonIsActive = false;

            //check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
            if($scope.booking.event === "") {
                $scope.booking.event = null;
            }
            //check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
            if($scope.booking.client === "") {
                $scope.booking.client = null;
                AlertService.warning("Please choose a client for a booking");
                return;
            }

            if(!$rootScope.isDefinedAndNotNull($scope.booking.client)) {
                AlertService.warning("Please choose a client for a booking");
                return;
            }

            if ($scope.booking.id != null) {
                return BookingFactory.update($scope.booking, onSaveBookingFinishedCallback, saveOrUpdateBookingErrorCallback);
            } else {
                return BookingFactory.save($scope.booking, function(booking) {
                    $scope.booking = booking;
                    $scope.bookingId = booking.id;
                    // HACK to update the browser url, we need to add the bookingId otherwise reloading the page breaks functionality
                    $state.go('event.detail.booking', {eventId:booking.event.id, bookingId:booking.id}, {notify:false, reload:false});
                    $scope.hideEditBookingForm();
                    $scope.addDriver();
                    return booking;
                }, saveOrUpdateBookingErrorCallback);
            }
        };

        var onSaveBookingFinishedCallback = function (result) {
            $scope.booking = result;
            $scope.bookingId = result.id;
            $scope.hideEditBookingForm();
            AlertService.success("Booking has been updated");
        };

        var saveOrUpdateBookingErrorCallback = function(error){
            AlertService.error(error.data.message, error.data.params);
        };

// CAR functions

        $scope.showEditCarForm = function() {
            if ($scope.showCarForm) {
                // if edit button is clicked while the form already was opened, we close it

                $scope.hideEditCarForm();
                return;
            }
            $scope.showCarForm = true;
            angular.element("input#field_car").focus();
        };

        $scope.hideEditCarForm = function() {
            $scope.showCarForm = false;
        };

        $scope.hasCarData = function() {
            return $rootScope.isDefinedAndNotNull($scope.currentSubscription)
                && $rootScope.isDefinedAndNotNull($scope.currentSubscription.driverDto)
                && $rootScope.isDefinedAndNotNull($scope.currentSubscription.driverDto.car);
        };

        $scope.saveCar = function() {
            if ($scope.subscriptionToEdit.car == "") {
                $scope.subscriptionToEdit.car = null;
            }

            // NOTE this resets the $scope.subscriptionToEdit
            $scope.saveSubscription();
        };

        $scope.editCar = function() {
            $scope.showEditCarForm();

            // get the subscription (driver) pojo and the car pojo and set it to $scope.subscriptionToEdit
            getDriver().then(function(result){
                $scope.subscriptionToEdit = result;

                // try to set insurance and fuel from the defaults
                if ($rootScope.isDefinedAndNotNull($scope.booking.event.templateEvent)) {
                    if (!$rootScope.isDefinedAndNotNull($scope.subscriptionToEdit.insurance)) {
                        $scope.subscriptionToEdit.insurance = $scope.booking.event.templateEvent.defaultInsurance;
                    }
                    if (!$rootScope.isDefinedAndNotNull($scope.subscriptionToEdit.fuel)) {
                        $scope.subscriptionToEdit.fuel = $scope.booking.event.templateEvent.defaultFuel;
                    }
                }
            });
        };

        $scope.onCarSelect = function($item, $model, $label, $event) {
            if ($scope.subscriptionToEdit.car == null || $scope.subscriptionToEdit.car.rental !== true) {
                $scope.subscriptionToEdit.fuel = null;
            }
        }

// SUBSCRIPTION functions
				var subscriptionUpdatingIsInProgress = false;
		    $scope.saveSubscription = function() {
					// Another callback did not complete yet
					if (subscriptionUpdatingIsInProgress) {
						return;
					}
					subscriptionUpdatingIsInProgress = true;
					// console.log($scope.subscriptionToEdit);
					if ($scope.subscriptionToEdit.id != null) {
						SubscriptionFactory.update($scope.subscriptionToEdit, onSaveSubscriptionFinishedCallback, saveOrUpdateSubscriptionErrorCallback);
					} else {
						SubscriptionFactory.save($scope.subscriptionToEdit, onSaveSubscriptionFinishedCallback, saveOrUpdateSubscriptionErrorCallback);
					}
				};

        var onSaveSubscriptionFinishedCallback = function (result) {
						subscriptionUpdatingIsInProgress = false;
            $scope.hideEditCarForm();
            hideSubscriptionForm();
            AlertService.info(result.relation.personName + " has been saved");
            $timeout(function(){
                $scope.loadData(result.id);
            }, 10);
        };

        var saveOrUpdateSubscriptionErrorCallback = function(error){
						subscriptionUpdatingIsInProgress = false;
            console.log(error);
            AlertService.error(error.data.message, error.data.params);
            $scope.hideEditCarForm();
            hideSubscriptionForm();
        };

        $scope.addDriver = function() {
            $scope.hideEditCarForm();

            if ($scope.hasCarData()) {
                $scope.currentSubscription.driverDto.car = null;
            }

            var driver = getNewSubscription('Driver'); // returns a Promise

            // once the driver (promise) is ready, then proceed
            driver.then(function(data) {
                $scope.subscriptionToEdit = data;
                if ($rootScope.isDefinedAndNotNull($scope.booking.client)) {
                    // preset the chosen booking relation as subscription relation
                    $scope.subscriptionToEdit.relation = $scope.booking.client;
                }
                if ($rootScope.isDefinedAndNotNull($stateParams.predefinedSubscriptionStatus)) {
                    // preset the chosen subscription status IF available
                    $scope.subscriptionToEdit.status = $stateParams.predefinedSubscriptionStatus;
                }

                showSubscriptionForm();

                $scope.hideAddingNewDriver = true;
            });

        };

        var getDriver = function() {
            var driverPkId = $scope.currentSubscription.driverDto.subscriptionIdPk;

            return SubscriptionFactory.get({
                id : driverPkId
            }).$promise.then(function(data) {
                return data;
            }, function(error) {
                AlertService.error("Failed to retrieve driver subscription",error);
            }); // the driver subscription
        };

        $scope.addSubscription = function(subscriptionType) {
            var subscription = getNewSubscription(subscriptionType);
            var driver = getDriver();

            $q.all({subscription: subscription, driver: driver}).then(function(results) {
                results.subscription.relationOf = results.driver;
                $scope.subscriptionToEdit = results.subscription;
                if ($scope.subscriptionToEdit.subscriptionType == 'Spectator') {
                    $scope.showInstructionData = false;
                }
                showSubscriptionForm();
            });
        };

        /**
         * Pops up delete driver subscription confirmation dialog
         * @param subscription
         */
        $scope.deleteDriverSubscription = function (subscription) {
            SubscriptionFactory.get({id: subscription.subscriptionIdPk}, function(result) {
                $scope.subscriptionToDelete = result;
                $('#deleteSubscriptionConfirmation').modal('show');
            });
        };

        /**
         * Deletes a driver subscription
         * @param id
         */
        $scope.confirmDeleteDriverSubscription = function (id) {
            SubscriptionFactory.delete({id: id}, function () {
                AlertService.success("Deleted driver " + $scope.subscriptionToDelete.relation.personName);
                $scope.subscriptionToDelete = null;
                $('#deleteSubscriptionConfirmation').modal('hide');

                // attempt to load previous subscription
                $scope.loadData(null);

            }, function (error) {
                AlertService.warning("Failed to delete ");
            });
        };

        /**
         * Deletes second driver / spectator subscriptions
         * @param subscription
         */
        $scope.deleteSubscription = function(subscription) {
            SubscriptionFactory.delete({id: subscription.subscriptionIdPk}, function () {
                $scope.loadData(-1);
                AlertService.success("Deleted subscription " + subscription.subscriptionIdPk);
            }, function (error) {
                AlertService.warning("Failed to delete ");
            });
        };

        var getNewSubscription = function(subscriptionType) {
            return NewSubscriptionFactory.get().$promise.then(function(data) {
                var subscription = data;

                subscription.subscriptionType = subscriptionType;
                subscription.booking = $scope.booking;
                if ($rootScope.isDefinedAndNotNull($scope.booking.event.templateEvent)) {
                    subscription.driver = $scope.booking.event.templateEvent.defaultDriver;
                    subscription.secondDriver = $scope.booking.event.templateEvent.defaultSecondDriver;
                    subscription.spectator = $scope.booking.event.templateEvent.defaultSpectator;
                    subscription.hotel = $scope.booking.event.templateEvent.defaultHotel;
                    subscription.trainingType = $scope.booking.event.templateEvent;
                }
                subscription.status = 'CONFIRMED';
                switch (subscriptionType) {
                    case 'Driver':
                        if (subscription.driver) {
                            subscription.instructionHours = subscription.driver.includedInstructionHours;
                        } else {
                            subscription.instructionHours = $scope.driverList[0].includedInstructionHours;
                        }
                        break;
                    case 'Second_driver':
                        if (subscription.secondDriver) {
                            subscription.instructionHours = subscription.secondDriver.includedInstructionHours;
                        } else {
                            subscription.instructionHours = $scope.secondDriverList[0].includedInstructionHours;
                        }
                        break;
                    case 'Spectator':
                        if (subscription.spectator) {
                            subscription.instructionHours = subscription.spectator.includedInstructionHours;
                        } else {
                            subscription.instructionHours = $scope.spectatorList[0].includedInstructionHours;
                        }
                        break;
                }

                return subscription;
            }, function(error) {
                return error;
            });
        };

        $scope.cancelSubscription = function() {
            hideSubscriptionForm();
            $scope.loadData()
        };

        $scope.editSubscription = function(subscription) {
            // retrieve the subscription from backend
            SubscriptionFactory.get({
                id : subscription.subscriptionIdPk
            }).$promise.then(function(data) {
                $scope.subscriptionToEdit = data;
                $scope.showEditInstructionData = ($scope.subscriptionToEdit.subscriptionType!='Spectator');
                $scope.showEditSubscriptionForm = true;
            }, function(error) {
                AlertService.error("Failed to retrieve subscription",error);
            });
        };

				$scope.showEmailDialog = function (bookingId, subscriptionId) {
					ConfirmationEmailManager.confirmationEmailDialog(bookingId, subscriptionId, function () {
						// noop
					});
				};

        $scope.updateInstructionHours = function() {
            switch ($scope.subscriptionToEdit.subscriptionType) {
                case 'Driver':
                    $scope.subscriptionToEdit.instructionHours = $scope.subscriptionToEdit.driver.includedInstructionHours;
                    break;
                case 'Second_driver':
                    $scope.subscriptionToEdit.instructionHours = $scope.subscriptionToEdit.secondDriver.includedInstructionHours;
                    break;
                case 'Spectator':
                    $scope.subscriptionToEdit.instructionHours = $scope.subscriptionToEdit.spectator.includedInstructionHours;
                    break;
            }
        };

        var showSubscriptionForm = function() {
            // TODO remove this once it works well, the idea is that $scope.hideAddingNewDriver is set to false when hiding the form, but only set to true when adding a driver not other subscriptions
            // $scope.hideAddingNewDriver = true;

            $scope.showEditSubscriptionForm = true;
            $scope.showEditInstructionData = ($scope.subscriptionToEdit.subscriptionType!='Spectator');
            $timeout(function(){
                // simply setting focus does not set focus, probably as this relation input is a typeahead autocomplete that also needs to be initialized, by setting a timeout it executes this focus later in the js chain
                angular.element("input#field_relation").focus();
            },0)
        };

        var hideSubscriptionForm = function() {
            $scope.hideAddingNewDriver = false;
            $scope.showEditSubscriptionForm = false;
            $scope.subscriptionToEdit = null;
        };

        $scope.isValidDriverSubscription = function() {
            return $rootScope.isDefinedAndNotNull($scope.currentSubscription)
                && $rootScope.isDefinedAndNotNull($scope.currentSubscription.driverDto)
                && $rootScope.isDefinedAndNotNull($scope.currentSubscription.driverDto.subscriptionIdPk);
        };

        $scope.previousSubscription = function() {
            hideSubscriptionForm();
            if (!$rootScope.isDefinedAndNotNull($scope.currentSubscriptionIndex)) {
                $scope.currentSubscriptionIndex = 0;
            }
            if ($scope.currentSubscriptionIndex > 0) {
                $scope.currentSubscriptionIndex--;
            } else {
                console.warn("Unable to decrease the $scope.currentSubscriptionIndex as it is already 0");
            }

            updateCurrentSubscription();
        };

        $scope.nextSubscription = function() {
            hideSubscriptionForm();
            if (!$rootScope.isDefinedAndNotNull($scope.currentSubscriptionIndex)) {
                $scope.currentSubscriptionIndex = 0;
            }
            if ($scope.currentSubscriptionIndex < $scope.subscriptionList.length - 1) {
                $scope.currentSubscriptionIndex++;
            } else {
                console.warn("Unable to increase the $scope.currentSubscriptionIndex as it is already the max index");
            }

            updateCurrentSubscription();
        };

        function updateCurrentSubscription() {
            $scope.currentSubscription = $scope.subscriptionList[$scope.currentSubscriptionIndex];
            $scope.driverId = $scope.currentSubscription.driverDto.subscriptionIdPk;
            // we have to update the state params driver ID so it is contained for the situation where we come back from a new relation dialog
            $stateParams.driverId = $scope.driverId;
        }

        $scope.hasPreviousSubscription = function() {
            if ($scope.currentSubscriptionIndex > 0) {
                return true;
            }
            return false;
        };

        $scope.hasNextSubscription = function() {
            if ($scope.currentSubscriptionIndex < $scope.subscriptionList.length - 1) {
                return true;
            }
            return false;
        };

// additional helper functions

        $scope.toggleBookingData = function() {
            $scope.showBookingData = !$scope.showBookingData;
            if ($scope.showBookingData) {
                angular.element("#showBookingDataIcon").removeClass("glyphicon-remove-circle");
                angular.element("#showBookingDataIcon").addClass("glyphicon-ok-circle");
            } else {
                angular.element("#showBookingDataIcon").removeClass("glyphicon-ok-circle");
                angular.element("#showBookingDataIcon").addClass("glyphicon-remove-circle");
            }
        };

        $scope.toggleInstructionData = function() {
            $scope.showInstructionData = !$scope.showInstructionData;
            if ($scope.showInstructionData) {
                angular.element("#showInstructionDataIcon").removeClass("glyphicon-remove-circle");
                angular.element("#showInstructionDataIcon").addClass("glyphicon-ok-circle");
            } else {
                angular.element("#showInstructionDataIcon").removeClass("glyphicon-ok-circle");
                angular.element("#showInstructionDataIcon").addClass("glyphicon-remove-circle");
            }
        };

        /**
         * This function queries all entities of type SubscriptionType
         */
        $scope.instructorList = null;
        $scope.subscriptionTypeList = NoPagingSubscriptionTypeFactory.query();
        $scope.statusList = NoPagingSubscriptionStatusFactory.query();
        $scope.hotelList = SubscriptionFindArticleFilteredByHotelFactory.query();
        $scope.trainingTypeList = NoPagingTemplateEventFactory.query();
        $scope.driverList = SubscriptionFindArticleFilteredByDriverFactory.query();
        $scope.secondDriverList = SubscriptionFindArticleFilteredBySecondDriverFactory.query();
        $scope.spectatorList = SubscriptionFindArticleFilteredBySpectatorFactory.query();
        $scope.fuelList = SubscriptionFindArticleFilteredByFuelFactory.query();
        $scope.insuranceList = SubscriptionFindArticleFilteredByInsuranceFactory.query();
        $scope.instructorList = InstructorsByEventFactory.query({id: $scope.eventId});
        $scope.bookingStatusList = NoPagingBookingStatusFactory.query();
        $scope.autocompleteRelations = function(val) {
            var params = {
                'relationTypeAheadSearchString': val
            };

            return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
                return results;
            });
        };
        $scope.autocompleteCars = function(val) {
            var params = {
                'carTypeAheadSearchString': val
            };

            return AutocompleteCarsByQueryParamsFactory.query(params).$promise.then(function(results) {
                return results;
            });
        };

        $scope.selectSubscriptionRelation = function() {
            console.log($scope.subscriptionToEdit.relation);

        };

        var resetParams = function() {
            // reset the dataModel attribute in the current state params
            HistoryService.resetCurrentHistoryParamsData();

            // reset 'remembered' values to prevent future misbehaviour
            $rootScope.previousStateParams.dataModel = null;
            $stateParams.dataModel = null;
        };

        /**
         * Function to load the page data. This involves a booking, but if applicable also a driver subscription plus its dependants (2nd drivers and spectators, car)
         *
         * @param driverPkId The driver ID is provided by the caller, and can be the driverId from the scope (populated from stateParams), or by request none or something else
         */
        $scope.loadData = function(driverPkId) {
            console.log("Loading data for driver",driverPkId);

            // this IF handles the situation where a new booking.client, car, subscription.instructor or subscription.relation has been created
            if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
                console.log("dataModel detected in stateParams:",$stateParams.dataModel,"dataModelType:",$stateParams.dataModelType);

                // either a booking, car, subscription relation or subscription instructor has been updated, hence we save the dataModel as subscription to edit!
                switch ($stateParams.dataModelType) {
                    case "client":
                        console.log("a client (relation) was detected when loading the page, we will update the booking and show the form");

                        $scope.booking = $stateParams.dataModel;
                        $scope.bookingId = $scope.booking.id;

                        // HACK to update the browser url, we need to add the bookingId otherwise reloading the page breaks functionality
                        $state.go('event.detail.booking', {eventId:$scope.booking.event.id, bookingId:$scope.bookingId}, {notify:false, reload:false});

                        resetParams();

                        $scope.showEditBookingForm();

                        break;
                    case "car":
                        console.log("a car was detected when loading the page");

                        // in case of a car we have the new car set as property of the dataModel which is subscriptionToEdit
                        $scope.subscriptionToEdit = $stateParams.dataModel;
                        $scope.booking = $scope.subscriptionToEdit.booking;
                        $scope.bookingId = $scope.booking.id;

                        resetParams();

                        // we execute saveSubscription after 50ms as we want to return first
                        // after 50ms saveSubscription will run and eventually after doing what it needs to do call this loadData function again
                        $timeout(function(){
                            $scope.saveSubscription();
                        },50);

                        // right after
                        return;
                    case "instructor":
                        console.log("an instructor was detected when loading the page");

                        // in case of a car we have the new car set as property of the dataModel which is subscriptionToEdit
                        $scope.subscriptionToEdit = $stateParams.dataModel;
                        $scope.booking = $scope.subscriptionToEdit.booking;
                        $scope.bookingId = $scope.booking.id;

                        // HACK to update the browser url, we need to add the bookingId otherwise reloading the page breaks functionality
                        $state.go('event.detail.booking', {eventId:$scope.booking.event.id, bookingId:$scope.bookingId}, {notify:false, reload:false});

                        resetParams();

                        showSubscriptionForm();

                        // we execute a trick to show the instruction data by initially setting the show boolean to false, then we toggle it thus it becomes true causing to show the data
                        $scope.showInstructionData = false;
                        $scope.toggleInstructionData();
                        // TODO we should update the visual state of the button too

                        break;
                    case "relation":
                        console.log("a relation was detected when loading the page");

                        // in case of a car we have the new car set as property of the dataModel which is subscriptionToEdit
                        $scope.subscriptionToEdit = $stateParams.dataModel;
                        $scope.booking = $scope.subscriptionToEdit.booking;
                        $scope.bookingId = $scope.booking.id;

                        // HACK to update the browser url, we need to add the bookingId otherwise reloading the page breaks functionality
                        $state.go('event.detail.booking', {eventId:$scope.booking.event.id, bookingId:$scope.bookingId}, {notify:false, reload:false});

                        // $scope.subscriptionToEdit.relationOf.id - driver id

                        resetParams();

                        showSubscriptionForm();

                        // we execute a trick to show the instruction data by initially setting the show boolean to false, then we toggle it thus it becomes true causing to show the data
                        $scope.showBookingData = false;
                        $scope.toggleBookingData();

                        break;
                    default:
                        console.log("$stateParams.dataModelType unknown:",$stateParams.dataModelType);
                }

                // as saveSubscription reloads the data (by executing this loadData method) we can now savely return from this method
            }

            $scope.currentSubscription = null;

            if (!$rootScope.isDefinedAndNotNull(driverPkId)) {
                $scope.currentSubscriptionIndex = 0;
            }

            if (!$rootScope.isDefinedAndNotNull($scope.currentSubscriptionIndex)) {
                $scope.currentSubscriptionIndex = 0;
            }

            if ($rootScope.isDefinedAndNotNull($scope.bookingId) && $scope.bookingId !== "") {
                console.log("Loading data for existing booking", $scope.bookingId);

                $scope.hideEditBookingForm();

                BookingSubscriptionsFactory.query({
                    bookingId: $scope.bookingId
                }, function (result) {
                    $scope.subscriptionList = result;

                    angular.forEach($scope.subscriptionList, function(value, key) {
                        if ($rootScope.isDefinedAndNotNull(value.driverDto) && value.driverDto.subscriptionIdPk === driverPkId) {
                            $scope.currentSubscriptionIndex = key;
                        }
                    });

                    // get the first subscription as current
                    if ($scope.subscriptionList.length > 0) {
                        $scope.currentSubscription = $scope.subscriptionList[$scope.currentSubscriptionIndex];
                        getBooking();
                    }
                }, function (error) {
                    AlertService.error(error.data.detail);
                });
            } else {
                // the page was loaded without a booking id meaning we're dealing with a new booking, prepare this!
                console.log("Loading data for new booking and driver subscription");
                $scope.showEditBookingForm();
            }

        };

        $scope.hasDriver = function() {
            return $rootScope.isDefinedAndNotNull($scope.currentSubscription) && $rootScope.isDefinedAndNotNull($scope.currentSubscription.driverDto);
        };

        $scope.translateStatus = function(status) {
            return $rootScope.isDefinedAndNotNull(status) ? $translate.instant('SubscriptionStatus.' + status) : "";
        };

        $scope.billBooking = function(booking, omitNotification) {
            $("#billButtonIcon").addClass("glyphicon-euro-animate");

            var preSnelstartId = booking.snelstartVerkoopOrderId;

            BookingSnelstartFactory.billBooking(booking, function(result) {
                $scope.booking = result;

                if (preSnelstartId != result.snelstartVerkoopOrderId) {
                    var dlgMsg = $translate.instant("global.messages.info.bookingBilledSuccesfully", {"status": result.status});
                } else {
                    var dlgMsg = $translate.instant("global.messages.info.bookingAcceptedSuccesfully", {"status": result.status});
                }
								if (!omitNotification) {
									dialogs.notify($translate.instant("booking.dialog.bill.title.success"), dlgMsg).result.then(function() {
										$("#billButtonIcon").removeClass("glyphicon-euro-animate");
									});
								}
            }, function(error) {
                console.error(error);
                var dlgMsg = $translate.instant(error.data.message, error.data.params);
                dialogs.error($translate.instant("booking.dialog.bill.title.failed"), dlgMsg).result.then(function() {
                    $("#billButtonIcon").removeClass("glyphicon-euro-animate");
                });
            });
        };

        $scope.loadData($scope.driverId);
    });
