/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('CarRentalTypeTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarRentalTypeFactory,
		FindAllCarRentalTypesByQueryParamsNonPageableFactory,
		NoPagingCarRentalTypeFactory
	) {
		$scope.carRentalTypeList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newCarRentalType', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(carRentalType) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editCarRentalType', {
				carRentalTypeId: carRentalType.id,
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
			});
		};

		$scope.getCarRentalTypeList = function() {
			FindAllCarRentalTypesByQueryParamsNonPageableFactory.query(
				{
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carRentalTypeList = result;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getCarRentalTypeList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarRentalTypeId = id;
			$('#deleteCarRentalTypeConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarRentalTypeFactory.delete(
				{ id: $scope.selectedCarRentalTypeId },
				function() {
					$scope.loadAll();
					$('#deleteCarRentalTypeConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteCarRentalTypeConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
