/** @format */

'use strict';

angular.module('emsApp').controller('CarDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.car = entity;
	$scope.objectName = 'Car';
	$scope.carId = $stateParams.carId;

	if (angular.isUndefined($rootScope.carActiveTab)) {
		//set the default here
		$rootScope.carActiveTab = 0;
	}

	$scope.goToEditState = function(car) {
		$state.go('car.detail.edit({carId:car.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:carUpdate', function(event, result) {
		$scope.car = result;
	});
});
