'use strict';


angular.module('emsApp').controller('EventDashboardController', function ($scope, $rootScope, $state, $stateParams, $q, $interval, EventFactory, FindAllEventsByQueryParamsFactory, NoPagingEventFactory, NoPagingEventStatusFactory, UserEventListDtoSearchFactory, NoPagingLocationFactory) {
    $scope.eventList = [];
    $scope.pageSize = 100;
    $scope.showSearchForm = false;
    $scope.secondsCounter = 120;
    $scope._refreshIntervalId = null;

    var spotWidth = 20; // in pixels
    $scope.statusBarStyle = "";
    $scope.calculateStatusBarStyle = function(event) {
        if ($rootScope.isDefinedAndNotNull(event.target) && $rootScope.isDefinedAndNotNull(event.totalConfirmed) && $rootScope.isDefinedAndNotNull(event.totalReserved)) {
						var barWidth;
						// If totalConfirmed + totalReserved is bigger than target, then take sum of confirmed and reserved as base for total bar width
						if ((event.totalConfirmed + event.totalReserved) > event.target) {
								barWidth = spotWidth * (event.totalConfirmed + event.totalReserved);
						} else {
								barWidth = spotWidth * event.target;
						}
        		var style = {"width": barWidth + "px"};
          	return style;
        }
        return  {"visibility":"hidden"};
    };
    $scope.calculateSpotsConfirmed = function(event) {
        if ($rootScope.isDefinedAndNotNull(event.totalConfirmed)) {
						var confirmedWidth = spotWidth * event.totalConfirmed;
						var style = {"width": confirmedWidth + "px"};
						return style;
        }
    };
    $scope.calculateSpotsReserved = function(event) {
				if ($rootScope.isDefinedAndNotNull(event.totalReserved)) {
						var reservedWidth = spotWidth * event.totalReserved;
						var style = {"width": reservedWidth + "px"};
						return style;
        }
    };
    $scope.calculateSpotsOpen = function(event) {
				if ($rootScope.isDefinedAndNotNull(event.availableSpots)) {
						var openWidth;
						if (event.availableSpots < 0) {
								openWidth = 0;
						} else {
								openWidth = spotWidth * event.availableSpots;
						}
						var style = {"width": openWidth + "px"};
						return style;
        }
    };

    $scope.goToNewState = function () {
        // this overview is NOT mapped, it is the main overview
        $state.go('event.list.new');
    };

    $scope.goToEditState = function (event) {
        // this overview is NOT mapped, it is the main overview
        $state.go('user-event.edit', {eventId: event.id});
    };


    var getLocationId = function () {
        if ($rootScope.isDefinedAndNotNull($scope.EventSearchOverview.location)) {
            return $scope.EventSearchOverview.location.id;
        } else {
            return undefined;
        }
    };


    // handle attribute startDate of javaType java.time.LocalDate
    $scope.processStartDate = function () {
        var startDateFromDefined = $rootScope.isDefinedAndNotNull($scope.EventSearchOverview.startDateFrom);
        var startDateToDefined = $rootScope.isDefinedAndNotNull($scope.EventSearchOverview.startDateTo);

        if (startDateFromDefined) {
            $scope.EventSearchOverview.startDateFromString = $rootScope.convertDateWithoutZone($scope.EventSearchOverview.startDateFrom);
        } else {
            $scope.EventSearchOverview.startDateFromString = undefined;
        }

        if (startDateToDefined) {
            $scope.EventSearchOverview.startDateToString = $rootScope.convertDateWithoutZone($scope.EventSearchOverview.startDateTo);
        } else {
            $scope.EventSearchOverview.startDateToString = undefined;
        }
    };


    NoPagingEventStatusFactory.query(function (result) {
        $scope.eventStatusList = result;
    });

		UserEventListDtoSearchFactory.query(function(result) {
				$scope.templateEventReferenceFilterList = result.templateEventSearchOptions;
		});

    NoPagingLocationFactory.query(function (result) {
        $scope.locationReferenceFilterList = result;
    });
    $scope.search = function () {
        $("#search-button").addClass("glyphicon-refresh-animate");

        // Call the javascript functions to handle search parameters
        $scope.processStartDate();

        $rootScope.EventSearchOverview = angular.copy($scope.EventSearchOverview);

        FindAllEventsByQueryParamsFactory.query({
            page: $scope.EventSearchOverview.page - 1,
            size: $scope.pageSize,
            name: $scope.EventSearchOverview.name,
            startDateFrom: $scope.EventSearchOverview.startDateFromString,
            startDateTo: $scope.EventSearchOverview.startDateToString,
            statusId: $scope.EventSearchOverview.status,
            templateEventIdList: $rootScope.getListValues($scope.EventSearchOverview.selectedTemplateEventList, 'id'),
            locationId: getLocationId(),
            sort: [$scope.EventSearchOverview.predicate + ',' + ($scope.EventSearchOverview.ascending ? 'asc' : 'desc')]

        }, function (result, headers) {
            $scope.totalItems = headers('X-Total-Count');
            $scope.eventList = result;
            console.log("X-Total-Count", $scope.totalItems);
            console.log("eventList size", $scope.eventList.length);
            console.log("$scope.EventSearchOverview", $scope.EventSearchOverview);
            $("#search-button").removeClass("glyphicon-refresh-animate");
        });

    };


    $scope.isEventSearchOverviewDirty = function () {
        return !angular.equals($scope.EventSearchOverview, $scope.originalEventSearchOverview);
    };

    $scope.loadDefaultSearchParams = function () {

        $rootScope.EventSearchOverview = {};
        $rootScope.EventSearchOverview.page = 1;
        $rootScope.EventSearchOverview.predicate = 'startDate';
        $rootScope.EventSearchOverview.ascending = true;

        $rootScope.EventSearchOverview.startDateFrom = new Date();

        $scope.EventSearchOverview = angular.copy($rootScope.EventSearchOverview);

        $scope.originalEventSearchOverview = angular.copy($scope.EventSearchOverview);
    };

    $scope.reset = function () {
        $scope.loadDefaultSearchParams();
        $scope.searchForm.$setPristine();
        $scope.search();
    };

    $scope.loadPage = function () {
        if (angular.isUndefined($rootScope.EventSearchOverview) || $rootScope.EventSearchOverview === null) {
            $scope.loadDefaultSearchParams(); // load defaults
        }

        $scope.EventSearchOverview = angular.copy($rootScope.EventSearchOverview);
        $scope.search();
    };

    $scope.singleDelete = function (id) {
        $scope.selectedEventId = id;
        $('#deleteEventConfirmation').modal('show');
    };

    $scope.confirmSingleDelete = function () {
        EventFactory.delete({id: $scope.selectedEventId}, function () {
            $scope.search();
            $('#deleteEventConfirmation').modal('hide');
        });
    };

    // the dialog controller can emit an event to update the overview, here we react on it
    $rootScope.$on('emsApp:eventUpdate', function () {
        $scope.loadPage();
    });

    $scope.loadPage();


    $scope.getSortIcon = function (itemName) {
        if ($scope.EventSearchOverview.predicate === itemName) {
            if ($scope.EventSearchOverview.ascending) {
                return "glyphicon-sort-by-attributes";
            } else {
                return "glyphicon-sort-by-attributes-alt";
            }
        } else {
            return "glyphicon-sort";
        }
    };

		$scope.$on('$destroy', function () {
			$interval.cancel($scope._refreshIntervalId);
		});

		$scope._refreshIntervalId = $interval(function () {
			$scope.loadPage();
		}, $scope.secondsCounter * 1000);
});
