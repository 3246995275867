/** @format */

'use strict';

angular.module('emsApp').controller('CountryDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.country = entity;
	$scope.objectName = 'Country';
	$scope.countryId = $stateParams.countryId;

	if (angular.isUndefined($rootScope.countryActiveTab)) {
		//set the default here
		$rootScope.countryActiveTab = 0;
	}

	$scope.goToEditState = function(country) {
		$state.go('country.detail.edit({countryId:country.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:countryUpdate', function(event, result) {
		$scope.country = result;
	});
});
