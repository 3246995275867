/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('BookingController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		BookingFactory,
		FindAllBookingsByQueryParamsFactory,
		AutocompleteEventsByQueryParamsFactory,
		AutocompleteRelationsByQueryParamsFactory,
		NoPagingBookingFactory,
		NoPagingEventFactory,
		NoPagingRelationFactory,
		NoPagingBookingStatusFactory,
		NoPagingConfirmationEmailStatusFactory,
		ConfirmationEmailManager
	) {
		$scope.confirmationStatuses = ConfirmationEmailManager.confirmationStatuses;
		$scope.bookingList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeBooking)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeBooking);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeBooking = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('booking.list.new');
		};

		$scope.goToEditState = function(booking) {
			// this overview is NOT mapped, it is the main overview
			$state.go('booking.list.edit', { bookingId: booking.id });
		};

		var getEventId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.BookingSearchOverview.event)) {
				return $scope.BookingSearchOverview.event.id;
			} else {
				return undefined;
			}
		};

		var getClientId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.BookingSearchOverview.client)) {
				return $scope.BookingSearchOverview.client.id;
			} else {
				return undefined;
			}
		};

		// handle attribute bookingDate of javaType java.time.LocalDate
		$scope.processBookingDate = function() {
			var bookingDateFromDefined = $rootScope.isDefinedAndNotNull($scope.BookingSearchOverview.bookingDateFrom);
			var bookingDateToDefined = $rootScope.isDefinedAndNotNull($scope.BookingSearchOverview.bookingDateTo);

			if (bookingDateFromDefined) {
				$scope.BookingSearchOverview.bookingDateFromString = $rootScope.convertDateWithoutZone(
					$scope.BookingSearchOverview.bookingDateFrom
				);
			} else {
				$scope.BookingSearchOverview.bookingDateFromString = undefined;
			}

			if (bookingDateToDefined) {
				$scope.BookingSearchOverview.bookingDateToString = $rootScope.convertDateWithoutZone($scope.BookingSearchOverview.bookingDateTo);
			} else {
				$scope.BookingSearchOverview.bookingDateToString = undefined;
			}
		};

		$scope.autocompleteEvents = function(val) {
			var params = {
				eventTypeAheadSearchString: val,
			};

			return AutocompleteEventsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		$scope.autocompleteRelations = function(val) {
			var params = {
				relationTypeAheadSearchString: val,
			};

			return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		NoPagingBookingStatusFactory.query(function(result) {
			$scope.bookingStatusList = result;
		});

		NoPagingConfirmationEmailStatusFactory.query(function(result) {
			$scope.confirmationEmailStatusList = result;
		});
		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processBookingDate();

			$rootScope.BookingSearchOverview = angular.copy($scope.BookingSearchOverview);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeBooking = angular.copy($scope.pageSize);

			FindAllBookingsByQueryParamsFactory.query(
				{
					page: $scope.BookingSearchOverview.page - 1,
					size: $scope.pageSize,
					bookingDateFrom: $scope.BookingSearchOverview.bookingDateFromString,
					bookingDateTo: $scope.BookingSearchOverview.bookingDateToString,
					eventId: getEventId(),
					clientId: getClientId(),
					status: $scope.BookingSearchOverview.status,
					confirmationEmailStatus: $scope.BookingSearchOverview.confirmationEmailStatus,

					sort: [$scope.BookingSearchOverview.predicate + ',' + ($scope.BookingSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.bookingList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isBookingSearchOverviewDirty = function() {
			return !angular.equals($scope.BookingSearchOverview, $scope.originalBookingSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.BookingSearchOverview = {};
			$rootScope.BookingSearchOverview.page = 1;
			$rootScope.BookingSearchOverview.predicate = 'id';
			$rootScope.BookingSearchOverview.ascending = false;

			$scope.BookingSearchOverview = angular.copy($rootScope.BookingSearchOverview);

			$scope.originalBookingSearchOverview = angular.copy($scope.BookingSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.BookingSearchOverview) || $rootScope.BookingSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.BookingSearchOverview = angular.copy($rootScope.BookingSearchOverview);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedBookingId = id;
			$('#deleteBookingConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			BookingFactory.delete(
				{ id: $scope.selectedBookingId },
				function() {
					$scope.search();
					$('#deleteBookingConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteBookingConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.BookingSearchOverview.predicate === itemName) {
				if ($scope.BookingSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.confirmationEmailDialog = function(bookingId) {
			ConfirmationEmailManager.confirmationEmailDialog(bookingId, null, function() {
				$scope.search();
			});
		};
	});
