/** @format */

'use strict';

angular.module('emsApp').controller('SubscriptionDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.subscription = entity;
	$scope.objectName = 'Subscription';
	$scope.subscriptionId = $stateParams.subscriptionId;

	if (angular.isUndefined($rootScope.subscriptionActiveTab)) {
		//set the default here
		$rootScope.subscriptionActiveTab = 0;
	}

	$scope.goToEditState = function(subscription) {
		$state.go('subscription.detail.edit({subscriptionId:subscription.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:subscriptionUpdate', function(event, result) {
		$scope.subscription = result;
	});
});
