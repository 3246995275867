/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('BaseModelDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		BaseModelFactory,
		AlertService,
		NoPagingBaseModelFactory
	) {
		$scope.baseModel = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.baseModel) && $rootScope.isDefinedAndNotNull($scope.baseModel.id);
		};

		/**
		 * Tells caller whether the current shown screen (and state) is in edit mode, hence a dialog is shown
		 */
		$scope.isInEditState = function() {
			// check on stateName: *.edit  or  *.detail.edit
			return $state.current.name.toUpperCase().endsWith('EDIT');
		};

		$scope.submitButtonIsActive = true;

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:baseModelUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving BaseModel failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;

			if ($scope.baseModel.id != null) {
				BaseModelFactory.update($scope.baseModel, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				BaseModelFactory.save($scope.baseModel, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};
	});
