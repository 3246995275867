/** @format */

'use strict';

angular.module('emsApp').controller('CompanyTypeDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.companyType = entity;
	$scope.objectName = 'CompanyType';
	$scope.companyTypeId = $stateParams.companyTypeId;

	if (angular.isUndefined($rootScope.companyTypeActiveTab)) {
		//set the default here
		$rootScope.companyTypeActiveTab = 0;
	}

	$scope.goToEditState = function(companyType) {
		$state.go('companytype.detail.edit({companyTypeId:companyType.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:companyTypeUpdate', function(event, result) {
		$scope.companyType = result;
	});
});
