'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('RelationSalesFactory', function($resource) {
	return $resource('api/sales/relations', {}, {
		'query' : {
			method : 'GET',
			isArray : false
		},
		'create' : {
			method : 'POST'
		}
	});
});

angular.module('emsApp').factory('EventSalesFactory', function($resource) {
	return $resource('api/sales/events', {}, {
		'query' : {
			method : 'GET',
			isArray : false
		}
	});
});

angular.module('emsApp').factory('SalesSyncFactory', function($resource) {
	return $resource('api/sales/sync', {}, {
		'sync' : {
			method : 'GET',
			isArray : false
		}
	});
});

angular.module('emsApp').factory('SalesRelationSubscriptionFactory', function($resource) {
	return $resource('api/sales/relation/subscription', {}, {
		'get' : {
			method : 'GET',
			isArray : false
		}
	});
});
