/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('UserSalesFilterListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		SalesFilterFactory,
		UserSalesFilterListDtoSearchFactory,
		UserSalesFilterViewDtoFactory
	) {
		$scope.salesFilterList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeSalesFilter)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeSalesFilter);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeSalesFilter = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('user-salesfilter.new');
		};

		var getUserId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SalesFilterSearchOverview.user)) {
				return $scope.SalesFilterSearchOverview.user.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.SalesFilterSearchOverview = angular.copy($scope.SalesFilterSearchOverview);

			$rootScope.pageSizeSalesFilter = angular.copy($scope.pageSize);

			UserSalesFilterListDtoSearchFactory.query(
				{
					page: $scope.SalesFilterSearchOverview.page - 1,
					size: $scope.pageSize,
					description: $scope.SalesFilterSearchOverview.description,
					firstName: $scope.SalesFilterSearchOverview.firstName,
					lastName: $scope.SalesFilterSearchOverview.lastName,
					potentialFromFrom: $scope.SalesFilterSearchOverview.potentialFromFrom,
					potentialFromTo: $scope.SalesFilterSearchOverview.potentialFromTo,
					potentialToFrom: $scope.SalesFilterSearchOverview.potentialToFrom,
					potentialToTo: $scope.SalesFilterSearchOverview.potentialToTo,
					offsetStartDateFromFrom: $scope.SalesFilterSearchOverview.offsetStartDateFromFrom,
					offsetStartDateFromTo: $scope.SalesFilterSearchOverview.offsetStartDateFromTo,
					offsetStartDateToFrom: $scope.SalesFilterSearchOverview.offsetStartDateToFrom,
					offsetStartDateToTo: $scope.SalesFilterSearchOverview.offsetStartDateToTo,
					salesStatus: $scope.SalesFilterSearchOverview.salesStatus,
					userId: getUserId(),

					sort: [$scope.SalesFilterSearchOverview.predicate + ',' + ($scope.SalesFilterSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.salesFilterList = result.items;

					$scope.salesStatusList = result.salesStatusSearchOptions;

					$scope.userReferenceFilterList = result.userSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isSalesFilterSearchOverviewDirty = function() {
			return !angular.equals($scope.SalesFilterSearchOverview, $scope.originalSalesFilterSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.SalesFilterSearchOverview = {};
			$rootScope.SalesFilterSearchOverview.page = 1;
			$rootScope.SalesFilterSearchOverview.predicate = 'id';
			$rootScope.SalesFilterSearchOverview.ascending = false;

			$scope.SalesFilterSearchOverview = angular.copy($rootScope.SalesFilterSearchOverview);

			$scope.originalSalesFilterSearchOverview = angular.copy($scope.SalesFilterSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.SalesFilterSearchOverview) || $rootScope.SalesFilterSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.SalesFilterSearchOverview = angular.copy($rootScope.SalesFilterSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedSalesFilterId = id;
			$('#deleteSalesFilterConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			UserSalesFilterViewDtoFactory.delete(
				{ id: $scope.selectedSalesFilterId },
				function() {
					$scope.search();
					$('#deleteSalesFilterConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteSalesFilterConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.SalesFilterSearchOverview.predicate === itemName) {
				if ($scope.SalesFilterSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
