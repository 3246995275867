/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('TemplateEventFactory', function($resource) {
	return $resource(
		'api/templateEvents/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/* A custom hook can be implemented here custom/jhipster/factory/create_custom_edit_factory.ftl */
/**
 * Factory to get / update an instance of UserTemplateEventEditDto
 */
angular.module('emsApp').factory('UserTemplateEventEditDtoFactory', function($resource) {
	return $resource(
		'api/user/templateevent/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of UserTemplateEventEditDto
 * with defaults from server
 */
angular.module('emsApp').factory('UserTemplateEventNewDtoFactory', function($resource) {
	return $resource(
		'api/user/templateevent/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewTemplateEventFactory', function($resource) {
	return $resource(
		'api/templateEvents/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all TemplateEvents without the use of paging
angular.module('emsApp').factory('NoPagingTemplateEventFactory', function($resource) {
	return $resource(
		'api/templateEvents/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by defaultdriver
angular.module('emsApp').factory('TemplateEventFindArticleFilteredByDefaultDriverFactory', function($resource) {
	return $resource(
		'api/templateevents/article/filteredby/defaultdriver',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by defaultseconddriver
angular.module('emsApp').factory('TemplateEventFindArticleFilteredByDefaultSecondDriverFactory', function($resource) {
	return $resource(
		'api/templateevents/article/filteredby/defaultseconddriver',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by defaultspectator
angular.module('emsApp').factory('TemplateEventFindArticleFilteredByDefaultSpectatorFactory', function($resource) {
	return $resource(
		'api/templateevents/article/filteredby/defaultspectator',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by defaultinsurance
angular.module('emsApp').factory('TemplateEventFindArticleFilteredByDefaultInsuranceFactory', function($resource) {
	return $resource(
		'api/templateevents/article/filteredby/defaultinsurance',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by defaulthotel
angular.module('emsApp').factory('TemplateEventFindArticleFilteredByDefaultHotelFactory', function($resource) {
	return $resource(
		'api/templateevents/article/filteredby/defaulthotel',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by defaultfuel
angular.module('emsApp').factory('TemplateEventFindArticleFilteredByDefaultFuelFactory', function($resource) {
	return $resource(
		'api/templateevents/article/filteredby/defaultfuel',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by defaultinstruction
angular.module('emsApp').factory('TemplateEventFindArticleFilteredByDefaultInstructionFactory', function($resource) {
	return $resource(
		'api/templateevents/article/filteredby/defaultinstruction',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('EventsByTemplateEventFactory', function($resource) {
	return $resource(
		'api/events/templateEvent/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('SalesFilterTemplateEventsByTemplateEventFactory', function($resource) {
	return $resource(
		'api/salesFilterTemplateEvents/templateEvent/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('emsApp').factory('SalesFilterFromSalesFilterTemplateEventPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/salesFilterTemplateEventList/AsSalesFilterList/templateEvent/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

angular.module('emsApp').factory('FindAllTemplateEventsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/templateEvents/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllTemplateEventsByQueryParamsNonPageableFactory', function($resource) {
	return $resource(
		'api/query/templateEventsNonPageable/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
