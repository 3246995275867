/** @format */

'use strict';

// TemplateEvent has edit layout enabled. Edit layout template url: templateevent-dialog.html
angular.module('emsApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'TemplateEvent'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('templateevent'); // main translation loaded for templateevent
		$translatePartialLoader.addPart('eventtype'); // one to many reference from main entity
		$translatePartialLoader.addPart('event'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // one to many reference from main entity
		$translatePartialLoader.addPart('salesfiltertemplateevent'); // one to many reference from main entity

		return $translate.refresh();
	};

	/**
	 * "private" function called by wrapper new and edit functions.
	 */
	var getModalState = function(url, isEditMode) {
		if (!angular.isDefined(url) || url === null) {
			url = '/new';
		}

		if (!angular.isDefined(isEditMode) || isEditMode === null) {
			// default mode is NEW, however this situation should and will be avoided by as this function should only be
			isEditMode = false;
		}

		var templateUrl;
		// The default assignment to templateUrl can be overridden in hook custom/jhipster/state/template_url/add_or_edit.ftl
		templateUrl = 'app/entities/templateevent/templateevent-dialog.html';

		return {
			/* new state for entity templateEvent */
			/* to access: ui-ref="templateevent.list.new" */
			/* url: hostname/templateevent/new */
			url: url,
			data: {
				authorities: ['ROLE_USER'],
				isModal: true,
			},
			params: {
				mappedBy: null,
				mappedByType: null,
				mappedById: null,
				dataModel: null, // this data model is set from the new relation state OR from the save and new logic
				mappedByDisplayValue: null,
				dataModelType: null, // this data model type is the name of the modelobject, lowercase
				referredBy: null, // will be populated once this new state is triggered from another new state with + button
				hiddenParam: 'YES', // hides params from url
			},
			onEnter: [
				'$stateParams',
				'$state',
				'$uibModal',
				'$rootScope',
				'HistoryService',
				function($stateParams, $state, $uibModal, $rootScope, HistoryService) {
					var navigateBack = true;

					$uibModal
						.open({
							templateUrl: templateUrl,
							controller: 'TemplateEventDialogController',
							size: 'lg',
							backdrop: 'static',
							resolve: {
								entity: [
									'TemplateEventFactory',
									'NewTemplateEventFactory',
									function(TemplateEventFactory, NewTemplateEventFactory) {
										// NOTE either this dialog was opened with the purpose of
										// - creating a new TemplateEvent
										// - resuming the creation of a new new TemplateEvent AFTER new instances were created of x..1 reference(s)

										// if dataModel is defined, we're coming back from a new created x..1 reference
										if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
											var model = $stateParams.dataModel;
											// reset 'remembered' values to prevent future misbehaviour
											$rootScope.previousStateParams.dataModel = null;
											$stateParams.dataModel = null;

											// instead of returning a new model, we return the updated model
											return model;
										} else {
											if (isEditMode) {
												// get the entity by its ID
												return TemplateEventFactory.get({ id: $stateParams.templateEventId }).$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											} else {
												// get a new empty entity
												return NewTemplateEventFactory.get().$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											}
										}
									},
								],
								translatePartialLoader: [
									'$translate',
									'$translatePartialLoader',
									function($translate, $translatePartialLoader) {
										return getTranslations($translate, $translatePartialLoader);
									},
								],
							},
						})
						.result.then(function(result) {
							// process success result, if necessary
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// set relation as reference in the referredBy data model
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;
							}
						})
						.catch(function(closeReason) {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// reset relation in referred data model object
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = null;
							}
							if (closeReason === 'createNew') {
								// only navigate back in history if the close reason allows us to
								navigateBack = false;
							}
						})
						.finally(function() {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
							}

							if (navigateBack) {
								$rootScope.back();
							}
						});
				},
			],
		};
	};

	/**
	 * Body for templateEvent new state
	 */
	function getNewState(url) {
		return getModalState(url, false);
	}

	/**
	 * Body for templateEvent edit state
	 */
	function getEditState(url) {
		return getModalState(url, true);
	}

	$stateProvider.state('templateevent', {
		/* abstract state for entity templateEvent */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/templateevent',
		data: {
			authorities: ['ROLE_USER'],
			pageTitle: 'TemplateEvents',
			isModal: false,
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	$stateProvider.state('templateevent.list', {
		/* list state for entity templateEvent */
		/* to access: ui-ref="templateevent.list" */
		/* url: hostname/templateevent/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/templateevent/templateevents.html',
				controller: 'TemplateEventController',
			},
		},
	});

	$stateProvider.state('templateevent.detail', {
		/* detail state for entity templateEvent */
		/* to access: ui-sref="templateevent.detail" */
		/* url: hostname/templateevent/detail/:templateEventId */
		url: '/detail/:templateEventId',
		views: {
			'content@': {
				templateUrl: 'app/entities/templateevent/templateevent-detail.html',
				controller: 'TemplateEventDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'TemplateEventFactory',
				function($stateParams, TemplateEventFactory) {
					return TemplateEventFactory.get({ id: $stateParams.templateEventId });
				},
			],
		},
	});

	/* url: http://<hostname>/templateevent/list/new */
	$stateProvider.state('templateevent.list.new', getNewState());
	/* url: http://<hostname>/templateevent/list/:templateEventId/edit */
	$stateProvider.state('templateevent.list.edit', getEditState('/:templateEventId/edit'));
	/* url: http://<hostname>/templateevent/detail/:templateEventId/edit */
	$stateProvider.state('templateevent.detail.edit', getEditState('/edit'));
	// EventType.templateEvent with multiplicity 0..n points to TemplateEvent
	/* url: http://<hostname>/eventtype/detail/:eventTypeId/templateevent/new */
	$stateProvider.state('eventtype.detail.newTemplateEvent', getNewState('/templateevent/new'));
	/* url: http://<hostname>/eventtype/detail/:eventTypeId/templateevent/:templateEventId/edit */
	$stateProvider.state('eventtype.detail.editTemplateEvent', getEditState('/templateevent/:templateEventId/edit'));

	$stateProvider.state('subscription.list.newTemplateEvent', getNewState('/templateevent/new'));
	$stateProvider.state('event.list.newTemplateEvent', getNewState('/templateevent/new'));

	$stateProvider.state('user-templateevent', {
		/* abstract state for DTO UserTemplateEventListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/user/templateevent',
		data: {
			authorities: ['ROLE_USER', 'ROLE_ADMIN'],
			pageTitle: 'TemplateEvent',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO user */
	var dtoEditState = {
		/* edit state for DTO UserTemplateEventEditDto */
		/* to access: ui-sref="user-templateevent.edit" */
		/* url: http://<hostname>/user/templateevent/edit/:templateEventId */
		url: '/edit/:templateEventId',
		views: {
			'content@': {
				templateUrl: 'app/entities/templateevent/templateevent-edit-user.html',
				controller: 'UserTemplateEventEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'UserTemplateEventEditDtoFactory',
				function($stateParams, $rootScope, UserTemplateEventEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as defaultDriver has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.defaultDriver)) {
							// push the created defaultDriver to the list with selectable defaultDriver items
							$stateParams.dataModel.defaultDriverList.push($stateParams.dataModel.defaultDriver);

							// set the created defaultDriver as selected defaultDriver
							$stateParams.dataModel.selectedDefaultDriver = $stateParams.dataModel.defaultDriver;
						}
						// as defaultSecondDriver has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.defaultSecondDriver)) {
							// push the created defaultSecondDriver to the list with selectable defaultSecondDriver items
							$stateParams.dataModel.defaultSecondDriverList.push($stateParams.dataModel.defaultSecondDriver);

							// set the created defaultSecondDriver as selected defaultSecondDriver
							$stateParams.dataModel.selectedDefaultSecondDriver = $stateParams.dataModel.defaultSecondDriver;
						}
						// as defaultSpectator has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.defaultSpectator)) {
							// push the created defaultSpectator to the list with selectable defaultSpectator items
							$stateParams.dataModel.defaultSpectatorList.push($stateParams.dataModel.defaultSpectator);

							// set the created defaultSpectator as selected defaultSpectator
							$stateParams.dataModel.selectedDefaultSpectator = $stateParams.dataModel.defaultSpectator;
						}
						// as defaultInsurance has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.defaultInsurance)) {
							// push the created defaultInsurance to the list with selectable defaultInsurance items
							$stateParams.dataModel.defaultInsuranceList.push($stateParams.dataModel.defaultInsurance);

							// set the created defaultInsurance as selected defaultInsurance
							$stateParams.dataModel.selectedDefaultInsurance = $stateParams.dataModel.defaultInsurance;
						}
						// as defaultHotel has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.defaultHotel)) {
							// push the created defaultHotel to the list with selectable defaultHotel items
							$stateParams.dataModel.defaultHotelList.push($stateParams.dataModel.defaultHotel);

							// set the created defaultHotel as selected defaultHotel
							$stateParams.dataModel.selectedDefaultHotel = $stateParams.dataModel.defaultHotel;
						}
						// as defaultFuel has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.defaultFuel)) {
							// push the created defaultFuel to the list with selectable defaultFuel items
							$stateParams.dataModel.defaultFuelList.push($stateParams.dataModel.defaultFuel);

							// set the created defaultFuel as selected defaultFuel
							$stateParams.dataModel.selectedDefaultFuel = $stateParams.dataModel.defaultFuel;
						}
						// as defaultInstruction has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.defaultInstruction)) {
							// push the created defaultInstruction to the list with selectable defaultInstruction items
							$stateParams.dataModel.defaultInstructionList.push($stateParams.dataModel.defaultInstruction);

							// set the created defaultInstruction as selected defaultInstruction
							$stateParams.dataModel.selectedDefaultInstruction = $stateParams.dataModel.defaultInstruction;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return UserTemplateEventEditDtoFactory.get({ id: $stateParams.templateEventId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('user-templateevent.edit', dtoEditState);

	/* new (create) state for DTO user */
	var dtoCreateState = {
		/* new state for DTO UserTemplateEventEditDto */
		/* to access: ui-sref="user-templateevent.new" */
		/* url: http://<hostname>/user/templateevent/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/templateevent/templateevent-edit-user.html',
				controller: 'UserTemplateEventEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'UserTemplateEventNewDtoFactory',
				function($stateParams, $rootScope, UserTemplateEventNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as defaultDriver has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.defaultDriver)) {
							// push the created defaultDriver to the list with selectable defaultDriver items
							$stateParams.dataModel.defaultDriverList.push($stateParams.dataModel.defaultDriver);

							// set the created defaultDriver as selected defaultDriver
							$stateParams.dataModel.selectedDefaultDriver = $stateParams.dataModel.defaultDriver;
						}
						// as defaultSecondDriver has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.defaultSecondDriver)) {
							// push the created defaultSecondDriver to the list with selectable defaultSecondDriver items
							$stateParams.dataModel.defaultSecondDriverList.push($stateParams.dataModel.defaultSecondDriver);

							// set the created defaultSecondDriver as selected defaultSecondDriver
							$stateParams.dataModel.selectedDefaultSecondDriver = $stateParams.dataModel.defaultSecondDriver;
						}
						// as defaultSpectator has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.defaultSpectator)) {
							// push the created defaultSpectator to the list with selectable defaultSpectator items
							$stateParams.dataModel.defaultSpectatorList.push($stateParams.dataModel.defaultSpectator);

							// set the created defaultSpectator as selected defaultSpectator
							$stateParams.dataModel.selectedDefaultSpectator = $stateParams.dataModel.defaultSpectator;
						}
						// as defaultInsurance has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.defaultInsurance)) {
							// push the created defaultInsurance to the list with selectable defaultInsurance items
							$stateParams.dataModel.defaultInsuranceList.push($stateParams.dataModel.defaultInsurance);

							// set the created defaultInsurance as selected defaultInsurance
							$stateParams.dataModel.selectedDefaultInsurance = $stateParams.dataModel.defaultInsurance;
						}
						// as defaultHotel has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.defaultHotel)) {
							// push the created defaultHotel to the list with selectable defaultHotel items
							$stateParams.dataModel.defaultHotelList.push($stateParams.dataModel.defaultHotel);

							// set the created defaultHotel as selected defaultHotel
							$stateParams.dataModel.selectedDefaultHotel = $stateParams.dataModel.defaultHotel;
						}
						// as defaultFuel has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.defaultFuel)) {
							// push the created defaultFuel to the list with selectable defaultFuel items
							$stateParams.dataModel.defaultFuelList.push($stateParams.dataModel.defaultFuel);

							// set the created defaultFuel as selected defaultFuel
							$stateParams.dataModel.selectedDefaultFuel = $stateParams.dataModel.defaultFuel;
						}
						// as defaultInstruction has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.defaultInstruction)) {
							// push the created defaultInstruction to the list with selectable defaultInstruction items
							$stateParams.dataModel.defaultInstructionList.push($stateParams.dataModel.defaultInstruction);

							// set the created defaultInstruction as selected defaultInstruction
							$stateParams.dataModel.selectedDefaultInstruction = $stateParams.dataModel.defaultInstruction;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return UserTemplateEventNewDtoFactory.get({ id: $stateParams.templateEventId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('user-templateevent.new', dtoCreateState);

	// create state for plus button on Subscription edit / new page for reference TemplateEvent and dto property value user
	$stateProvider.state('user-subscription.new.newTemplateEvent', getCreateState('/trainingtype/new'));
	$stateProvider.state('user-subscription.edit.newTemplateEvent', getCreateState('/trainingtype/new'));
	// create state for plus button on Event edit / new page for reference TemplateEvent and dto property value user
	$stateProvider.state('user-event.new.newTemplateEvent', getCreateState('/templateevent/new'));
	$stateProvider.state('user-event.edit.newTemplateEvent', getCreateState('/templateevent/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/templateevent/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
