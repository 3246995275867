/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('DocumentFactory', function($resource) {
	return $resource(
		'api/documents/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewDocumentFactory', function($resource) {
	return $resource(
		'api/documents/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Documents without the use of paging
angular.module('emsApp').factory('NoPagingDocumentFactory', function($resource) {
	return $resource(
		'api/documents/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllDocumentsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/documents/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('DocumentDownloadFactory', function($resource) {
	return $resource(
		'api/documents/download/:id',
		{},
		{
			download: {
				method: 'GET',
				responseType: 'arraybuffer',
				transformResponse: function(data, headers) {
					console.log(headers);
					return {
						data: data,
						headerData: headers('content-disposition'),
					};
				},
			},
		}
	);
});
