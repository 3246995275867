'use strict';

angular.module('emsApp').factory('ConfirmationEmailManager', function ($uibModal, AlertService, BookingConfirmationEmailSender, SubscriptionConfirmationEmailSender) {
  var instance = {};

  instance.confirmationStatuses = {
    NOT_SENT: 'Not sent',
    SENT: 'Sent',
    CONFIRMED: 'Confirmed',
    REJECTED: 'Rejected',
		ERROR: 'Error',
		QUEUED: 'Queued',
		INVALID: 'Invalid'
  };

  instance.confirmationEmailDialog = function (bookingId, optionalSubscriptionId, successCallback) {
    $uibModal
      .open({
					templateUrl: 'app/entities/confirmation_email/confirmation-email-dialog.html',
        controller: 'ConfirmationEmailDialogController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          translatePartialLoader: [
            '$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('emails');
              $translate.refresh();
            },
          ],
        },
      })
      .result.then(function (result) {
				var requestObj;
				if (optionalSubscriptionId) {
					requestObj = SubscriptionConfirmationEmailSender.post({
						subscriptionId: optionalSubscriptionId ? optionalSubscriptionId : null,
						emailTemplateId: result.emailTemplateId,
						emailLabels: result.emailLabels,
						sendToTestAddress: result.sendToTestAddress,
					});
				} else {
					requestObj = BookingConfirmationEmailSender.post({
						bookingId: bookingId,
						emailTemplateId: result.emailTemplateId,
						emailLabels: result.emailLabels,
						sendToTestAddress: result.sendToTestAddress,
					})
				}
				requestObj
        .$promise.then(function () {
					AlertService.add({
						type: 'success',
						msg: 'Email was sent',
						timeout: 5000,
					});
					if (successCallback) {
						successCallback();
					}
      	})
        .catch(function (error) {
					var failureMessageHeader = (typeof error.headers === 'function') ? error.headers('Failure') : '';
					var failureMessageData = error.data.message;
					var failureMessageDefault = 'failure';
					var messageForUser = failureMessageHeader || failureMessageData || failureMessageDefault;
          AlertService.add({
            type: 'danger',
            msg: 'Email was not sent. Server responded: ' + messageForUser,
            timeout: 5000,
          });
        });
			});
  };

  return instance;
});
