/** @format */

'use strict';

angular.module('emsApp').controller('EventTypeDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.eventType = entity;
	$scope.objectName = 'EventType';
	$scope.eventTypeId = $stateParams.eventTypeId;

	if (angular.isUndefined($rootScope.eventTypeActiveTab)) {
		//set the default here
		$rootScope.eventTypeActiveTab = 0;
	}

	$scope.goToEditState = function(eventType) {
		$state.go('eventtype.detail.edit({eventTypeId:eventType.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:eventTypeUpdate', function(event, result) {
		$scope.eventType = result;
	});
});
