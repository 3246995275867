/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('UserSalesFilterEventTypeListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		SalesFilterEventTypeFactory,
		UserSalesFilterEventTypeListDtoSearchFactory,
		UserSalesFilterEventTypeViewDtoFactory
	) {
		$scope.salesFilterEventTypeList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeSalesFilterEventType)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeSalesFilterEventType);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeSalesFilterEventType = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('user-salesfiltereventtype.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getSalesFilterEventTypeList = function() {
			UserSalesFilterEventTypeListDtoSearchFactory.query(
				{
					salesFilterId: angular.isDefined($scope.salesFilterId) ? $scope.salesFilterId : null,
					eventTypeId: angular.isDefined($scope.eventTypeId) ? $scope.eventTypeId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.salesFilterEventTypeList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getSalesFilterEventTypeList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
