/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('InstructorTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		InstructorFactory,
		FindAllInstructorsByQueryParamsFactory,
		AutocompleteRelationsByQueryParamsFactory,
		AutocompleteEventsByQueryParamsFactory,
		NoPagingInstructorFactory,
		NoPagingRelationFactory,
		NoPagingEventFactory,
		NoPagingArticleFactory,
		NoPagingSubscriptionStatusFactory,
		InstructorFindArticleFilteredByHotelFactory
	) {
		$scope.instructorList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.page = 1;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeInstructor)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeInstructor);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeInstructor = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newInstructor', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(instructor) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editInstructor', {
				instructorId: instructor.id,
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
			});
		};

		$scope.getInstructorList = function() {
			FindAllInstructorsByQueryParamsFactory.query(
				{
					relationId: angular.isDefined($scope.relationId) ? $scope.relationId : null,
					eventId: angular.isDefined($scope.eventId) ? $scope.eventId : null,
					hotelId: angular.isDefined($scope.hotelId) ? $scope.hotelId : null,
					statusId: angular.isDefined($scope.statusId) ? $scope.statusId : null,

					page: $scope.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.instructorList = result;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getInstructorList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedInstructorId = id;
			$('#deleteInstructorConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			InstructorFactory.delete(
				{ id: $scope.selectedInstructorId },
				function() {
					$scope.loadAll();
					$('#deleteInstructorConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteInstructorConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
