/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('EventFactory', function($resource) {
	return $resource(
		'api/events/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of UserEventViewDto
 */
angular.module('emsApp').factory('UserEventViewDtoFactory', function($resource) {
	return $resource(
		'api/user/event/:id',
		{},
		{
			// Factory is only used to delete a UserEventViewDto
		}
	);
});
/**
 * Factory to search / retrieve an array of UserEventListDto's
 */
angular.module('emsApp').factory('UserEventListDtoSearchFactory', function($resource) {
	return $resource(
		'api/user/event',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/* A custom hook can be implemented here custom/jhipster/factory/create_custom_edit_factory.ftl */
/**
 * Factory to get / update an instance of UserEventEditDto
 */
angular.module('emsApp').factory('UserEventEditDtoFactory', function($resource) {
	return $resource(
		'api/user/event/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of UserEventEditDto
 * with defaults from server
 */
angular.module('emsApp').factory('UserEventNewDtoFactory', function($resource) {
	return $resource(
		'api/user/event/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of UserEventEditDto
 * and returns a copy to create another one.
 */
angular.module('emsApp').factory('UserEventEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/user/event/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewEventFactory', function($resource) {
	return $resource(
		'api/events/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Events without the use of paging
angular.module('emsApp').factory('NoPagingEventFactory', function($resource) {
	return $resource(
		'api/events/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible Statuses Enum values without the use of paging
angular.module('emsApp').factory('NoPagingEventStatusFactory', function($resource) {
	return $resource(
		'api/event/statuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('BookingsByEventFactory', function($resource) {
	return $resource(
		'api/bookings/event/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('DocumentsByEventFactory', function($resource) {
	return $resource(
		'api/documents/event/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('InstructorsByEventFactory', function($resource) {
	return $resource(
		'api/instructors/event/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('EventRelationContactsByEventFactory', function($resource) {
	return $resource(
		'api/eventRelationContacts/event/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllEventsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/events/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('AutocompleteEventsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/events/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('AutocompleteUserEventsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/user/autocomplete/events',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// Get all Subscriptions per Booking for an Event
angular.module('emsApp').factory('EventSubscriptionsFactory', function($resource) {
	return $resource(
		'api/events/:id/subscriptions',
		{},
		{
			query: {
				method: 'GET',
				isArray: true,
			},
		}
	);
});

// update info / after mail for all subscriptions of an event
angular.module('emsApp').factory('EventSubscriptionsMailUpdateFactory', function($resource) {
	return {
		updateInfoMail: function(currentEventId) {
			return $resource(
				'api/events/:eventId/subscriptions/infoMail',
				{ eventId: currentEventId },
				{
					update: {
						method: 'GET',
						isArray: true,
					},
				}
			);
		},
		updateAfterMail: function(currentEventId) {
			return $resource(
				'api/events/:eventId/subscriptions/afterMail',
				{ eventId: currentEventId },
				{
					update: {
						method: 'GET',
						isArray: true,
					},
				}
			);
		},
	};
});

// Get all Subscriptions for a Booking of an Event
angular.module('emsApp').factory('BookingSubscriptionsFactory', function($resource) {
	return $resource(
		'api/booking/:bookingId/subscriptions',
		{},
		{
			query: {
				method: 'GET',
				isArray: true,
			},
		}
	);
});

angular.module('emsApp').factory('EventSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/events/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
