/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('RelationDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		RelationFactory,
		AlertService,
		NoPagingRelationFactory,
		AutocompleteRelationsByQueryParamsFactory,
		NoPagingRelationTypeFactory,
		NoPagingCompanyTypeFactory,
		NoPagingRelationSourceFactory,
		NoPagingGenderFactory,
		NoPagingClientOfFactory,
		NoPagingUserFactory,
		NoPagingCountryFactory,
		NoPagingLangKeyFactory,
		RelationSaveAndCreateFactory,
		RelationFindUserFilteredByAccountManagerFactory,
		MailGroupFromRelationMailGroupPicklistUpdateFactory,
		NoPagingMailGroupFactory
	) {
		$scope.relation = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.relation) && $rootScope.isDefinedAndNotNull($scope.relation.id);
		};

		$scope.createType = function() {
			var referredBy = {
				refName: 'type',
				data: $scope.relation,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new type will add this new relation state to the history stack
				$state.go('relation.list.newRelationType', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createCompanyType = function() {
			var referredBy = {
				refName: 'companyType',
				data: $scope.relation,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new companyType will add this new relation state to the history stack
				$state.go('relation.list.newCompanyType', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createRelationSource = function() {
			var referredBy = {
				refName: 'relationSource',
				data: $scope.relation,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new relationSource will add this new relation state to the history stack
				$state.go('relation.list.newRelationSource', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createGender = function() {
			var referredBy = {
				refName: 'gender',
				data: $scope.relation,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new gender will add this new relation state to the history stack
				$state.go('relation.list.newGender', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createClientOf = function() {
			var referredBy = {
				refName: 'clientOf',
				data: $scope.relation,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new clientOf will add this new relation state to the history stack
				$state.go('relation.list.newClientOf', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createLangKey = function() {
			var referredBy = {
				refName: 'langKey',
				data: $scope.relation,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new langKey will add this new relation state to the history stack
				$state.go('relation.list.newLangKey', { referredBy: referredBy }, { reload: false });
			}, 100);
		};

		$scope.submitButtonIsActive = true;

		/**
		 * Function to find an item in a list of items based on the parent id if this dialog is opened from a tabbed overview
		 */
		$scope.findItemByParent = function(items, property) {
			//This function preselects and disables the parent reference when called from tab
			if ($stateParams.mappedBy === property && $stateParams.mappedById !== undefined) {
				angular.forEach(items, function(value) {
					// value.id is a number, $stateParams.mappedById is a string, hence we cast the $stateParams.mappedById to a number so we can compare the id using the typesafe === operator
					if (value.id === _.toNumber($stateParams.mappedById)) {
						var model = $parse('relation.' + property);
						model.assign($scope, value);
					}
				});
			}
		};

		// --- start default implementation
		/**
		 * This function queries all entities of type Type and checks if this Relation dialog is called in the context of Type.
		 * If this is the case, we detect the parent entity Relation and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryType = function() {
			return NoPagingRelationTypeFactory.query(function(result) {
				$scope.findItemByParent(result, 'type');
			});
		};
		$scope.typeList = $scope.queryType();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type CompanyType and checks if this Relation dialog is called in the context of CompanyType.
		 * If this is the case, we detect the parent entity Relation and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryCompanyType = function() {
			return NoPagingCompanyTypeFactory.query(function(result) {
				$scope.findItemByParent(result, 'companyType');
			});
		};
		$scope.companyTypeList = $scope.queryCompanyType();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type RelationSource and checks if this Relation dialog is called in the context of RelationSource.
		 * If this is the case, we detect the parent entity Relation and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryRelationSource = function() {
			return NoPagingRelationSourceFactory.query(function(result) {
				$scope.findItemByParent(result, 'relationSource');
			});
		};
		$scope.relationSourceList = $scope.queryRelationSource();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type Gender and checks if this Relation dialog is called in the context of Gender.
		 * If this is the case, we detect the parent entity Relation and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryGender = function() {
			return NoPagingGenderFactory.query(function(result) {
				$scope.findItemByParent(result, 'gender');
			});
		};
		$scope.genderList = $scope.queryGender();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type ClientOf and checks if this Relation dialog is called in the context of ClientOf.
		 * If this is the case, we detect the parent entity Relation and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryClientOf = function() {
			return NoPagingClientOfFactory.query(function(result) {
				$scope.findItemByParent(result, 'clientOf');
			});
		};
		$scope.clientOfList = $scope.queryClientOf();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type AccountManager and checks if this Relation dialog is called in the context of AccountManager.
		 * If this is the case, we detect the parent entity Relation and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryAccountManager = function() {
			return RelationFindUserFilteredByAccountManagerFactory.query(function(result) {
				$scope.findItemByParent(result, 'accountManager');
			});
		};
		$scope.accountManagerList = $scope.queryAccountManager();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type BusinessCountry and checks if this Relation dialog is called in the context of BusinessCountry.
		 * If this is the case, we detect the parent entity Relation and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryBusinessCountry = function() {
			return NoPagingCountryFactory.query(function(result) {
				$scope.findItemByParent(result, 'businessCountry');
			});
		};
		$scope.businessCountryList = $scope.queryBusinessCountry();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type PrivateCountry and checks if this Relation dialog is called in the context of PrivateCountry.
		 * If this is the case, we detect the parent entity Relation and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryPrivateCountry = function() {
			return NoPagingCountryFactory.query(function(result) {
				$scope.findItemByParent(result, 'privateCountry');
			});
		};
		$scope.privateCountryList = $scope.queryPrivateCountry();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type LangKey and checks if this Relation dialog is called in the context of LangKey.
		 * If this is the case, we detect the parent entity Relation and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryLangKey = function() {
			return NoPagingLangKeyFactory.query(function(result) {
				$scope.findItemByParent(result, 'langKey');
			});
		};
		$scope.langKeyList = $scope.queryLangKey();
		// --- end default implementation

		var getSelectedListItems = function(allItems, selectedItemList) {
			var itemIDs = [];
			angular.forEach(selectedItemList, function(value, key) {
				itemIDs.push(value.id);
			});

			var selectedItems = [];
			angular.forEach(allItems, function(value, key) {
				angular.forEach(itemIDs, function(value2, key2) {
					if (value.id === value2) {
						selectedItems.push(value);
					}
				});
			});

			return selectedItems;
		};

		var tempSelectedMailGroupList = [];
		$scope.selectedMailGroupList = [];

		$scope.getSelectedMailGroupList = function() {
			MailGroupFromRelationMailGroupPicklistUpdateFactory.query({ id: $stateParams.relationId }, function(result) {
				//put all selected MailGroup-items in a list
				angular.forEach(result, function(value, key) {
					tempSelectedMailGroupList.push(value);
				});
				NoPagingMailGroupFactory.query(function(result) {
					$scope.mailGroupPickListOptions = result;
					$scope.selectedMailGroupList = getSelectedListItems(result, tempSelectedMailGroupList);
				});
			});
		};

		$scope.getMailGroupPickListOptions = function() {
			if ($stateParams.relationId != null) {
				$scope.getSelectedMailGroupList();
			} else {
				NoPagingMailGroupFactory.query(function(result) {
					$scope.mailGroupPickListOptions = result;
				});
			}
		};

		$scope.getMailGroupPickListOptions();

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:relationUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving Relation failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		var onSaveAndNewFinishedCallback = function(result) {
			$scope.$emit('emsApp:relationUpdate', result);
			// the result contains a new entity, but with the copied values from the previous
			$scope.relation = result;
			$scope.updateDropdownModels();
			$scope.submitButtonIsActive = true;
		};

		$scope.updateDropdownModels = function() {
			$scope.queryType();
			$scope.queryCompanyType();
			$scope.queryRelationSource();
			$scope.queryGender();
			$scope.queryClientOf();
			$scope.queryAccountManager();
			$scope.queryBusinessCountry();
			$scope.queryPrivateCountry();
			$scope.queryLangKey();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;

			if ($scope.relation.id != null) {
				RelationFactory.update(
					$scope.relation,
					function(result) {
						$scope.relation = result;

						if (
							!angular.isUndefined($scope.selectedMailGroupList) &&
							angular.isArray($scope.selectedMailGroupList) &&
							$scope.selectedMailGroupList.length > 0
						) {
							MailGroupFromRelationMailGroupPicklistUpdateFactory.update(
								{ id: result.id },
								$scope.selectedMailGroupList,
								function(successResult) {
									onSaveFinishedCallback(successResult);
								},
								function(error) {}
							);
						} else {
							onSaveFinishedCallback(result);
						} // if
					},
					saveOrUpdateErrorCallback
				);
			} else {
				RelationFactory.save(
					$scope.relation,
					function(result) {
						$scope.relation = result;

						if (
							!angular.isUndefined($scope.selectedMailGroupList) &&
							angular.isArray($scope.selectedMailGroupList) &&
							$scope.selectedMailGroupList.length > 0
						) {
							MailGroupFromRelationMailGroupPicklistUpdateFactory.update(
								{ id: result.id },
								$scope.selectedMailGroupList,
								function(successResult) {
									onSaveFinishedCallback(successResult);
								},
								function(error) {}
							);
						} else {
							onSaveFinishedCallback(result);
						} // if
					},
					saveOrUpdateErrorCallback
				);
			}
		};

		$scope.saveAndNew = function() {
			$scope.submitButtonIsActive = false;

			if ($scope.relation.id != null) {
				RelationSaveAndCreateFactory.updateAndCreate(
					$scope.relation,
					function(result) {
						$scope.relation = result;
						$scope.$emit('emsApp:relationUpdate', result);

						if (
							!angular.isUndefined($scope.selectedMailGroupList) &&
							angular.isArray($scope.selectedMailGroupList) &&
							$scope.selectedMailGroupList.length > 0
						) {
							MailGroupFromRelationMailGroupPicklistUpdateFactory.update(
								{ id: result.id },
								$scope.selectedMailGroupList,
								function(successResult) {
									onSaveAndNewFinishedCallback(successResult);
								},
								function(error) {}
							);
						} else {
							onSaveAndNewFinishedCallback(result);
						} // if
					},
					saveOrUpdateErrorCallback
				);
			} else {
				RelationSaveAndCreateFactory.saveAndCreate(
					$scope.relation,
					function(result) {
						$scope.relation = result;
						$scope.$emit('emsApp:relationUpdate', result);

						if (
							!angular.isUndefined($scope.selectedMailGroupList) &&
							angular.isArray($scope.selectedMailGroupList) &&
							$scope.selectedMailGroupList.length > 0
						) {
							MailGroupFromRelationMailGroupPicklistUpdateFactory.update(
								{ id: result.id },
								$scope.selectedMailGroupList,
								function(successResult) {
									onSaveAndNewFinishedCallback(successResult);
								},
								function(error) {}
							);
						} else {
							onSaveAndNewFinishedCallback(result);
						} // if
					},
					saveOrUpdateErrorCallback
				);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};
	});
