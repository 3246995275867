/** @format */

'use strict';

angular.module('emsApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Country'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('country'); // main translation loaded for country

		return $translate.refresh();
	};

	/**
	 * "private" function called by wrapper new and edit functions.
	 */
	var getModalState = function(url, isEditMode) {
		if (!angular.isDefined(url) || url === null) {
			url = '/new';
		}

		if (!angular.isDefined(isEditMode) || isEditMode === null) {
			// default mode is NEW, however this situation should and will be avoided by as this function should only be
			isEditMode = false;
		}

		var templateUrl;
		// The default assignment to templateUrl can be overridden in hook custom/jhipster/state/template_url/add_or_edit.ftl
		templateUrl = 'app/entities/country/country-dialog.html';

		return {
			/* new state for entity country */
			/* to access: ui-ref="country.list.new" */
			/* url: hostname/country/new */
			url: url,
			data: {
				authorities: [],
				isModal: true,
			},
			params: {
				mappedBy: null,
				mappedByType: null,
				mappedById: null,
				referredBy: null, // will be populated once this new state is triggered from another new state with + button
				hiddenParam: 'YES', // hides params from url
			},
			onEnter: [
				'$stateParams',
				'$state',
				'$uibModal',
				'$rootScope',
				'HistoryService',
				function($stateParams, $state, $uibModal, $rootScope, HistoryService) {
					var navigateBack = true;

					$uibModal
						.open({
							templateUrl: templateUrl,
							controller: 'CountryDialogController',
							size: 'lg',
							backdrop: 'static',
							resolve: {
								entity: [
									'CountryFactory',
									'NewCountryFactory',
									function(CountryFactory, NewCountryFactory) {
										if (isEditMode) {
											// get the entity by its ID
											return CountryFactory.get({ id: $stateParams.countryId }).$promise.then(
												function(data) {
													return data;
												},
												function(error) {
													return error;
												}
											);
										} else {
											// get a new empty entity
											return NewCountryFactory.get().$promise.then(
												function(data) {
													return data;
												},
												function(error) {
													return error;
												}
											);
										}
									},
								],
								translatePartialLoader: [
									'$translate',
									'$translatePartialLoader',
									function($translate, $translatePartialLoader) {
										return getTranslations($translate, $translatePartialLoader);
									},
								],
							},
						})
						.result.then(function(result) {
							// process success result, if necessary
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// set relation as reference in the referredBy data model
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;
							}
						})
						.catch(function(closeReason) {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// reset relation in referred data model object
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = null;
							}
							if (closeReason === 'createNew') {
								// only navigate back in history if the close reason allows us to
								navigateBack = false;
							}
						})
						.finally(function() {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
							}

							if (navigateBack) {
								$rootScope.back();
							}
						});
				},
			],
		};
	};

	/**
	 * Body for country new state
	 */
	function getNewState(url) {
		return getModalState(url, false);
	}

	/**
	 * Body for country edit state
	 */
	function getEditState(url) {
		return getModalState(url, true);
	}

	$stateProvider.state('country', {
		/* abstract state for entity country */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/country',
		data: {
			authorities: [],
			pageTitle: 'Countries',
			isModal: false,
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	$stateProvider.state('country.list', {
		/* list state for entity country */
		/* to access: ui-ref="country.list" */
		/* url: hostname/country/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/country/countries.html',
				controller: 'CountryController',
			},
		},
	});

	$stateProvider.state('country.detail', {
		/* detail state for entity country */
		/* to access: ui-sref="country.detail" */
		/* url: hostname/country/detail/:countryId */
		url: '/detail/:countryId',
		views: {
			'content@': {
				templateUrl: 'app/entities/country/country-detail.html',
				controller: 'CountryDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CountryFactory',
				function($stateParams, CountryFactory) {
					return CountryFactory.get({ id: $stateParams.countryId });
				},
			],
		},
	});

	$stateProvider.state('user-country', {
		/* abstract state for DTO UserCountryListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/user/country',
		data: {
			authorities: ['ROLE_USER', 'ROLE_ADMIN'],
			pageTitle: 'Country',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO user */
	var dtoListState = {
		/* list state for DTO UserCountryListDto */
		/* to access: ui-ref="user-country.list" */
		/* url: http://<hostname>/user/country/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/country/country-list-user.html',
				controller: 'UserCountryListDtoController',
			},
		},
	};

	$stateProvider.state('user-country.list', dtoListState);
});
