/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('EventTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		EventFactory,
		FindAllEventsByQueryParamsFactory,
		NoPagingEventFactory,
		NoPagingTemplateEventFactory,
		NoPagingLocationFactory,
		NoPagingEventStatusFactory
	) {
		$scope.eventList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeEvent)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeEvent);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeEvent = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newEvent', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(event) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editEvent', { eventId: event.id, mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		var getTemplateEventIdList = function() {
			if ($rootScope.isDefinedAndNotNull($scope.templateEventId)) {
				return $scope.templateEventId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.EventSearchTab.templateEventIdList)) {
					return $rootScope.getListValues($scope.EventSearchTab.templateEventIdList, 'id');
				} else {
					return undefined;
				}
			}
		};

		var getLocationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.locationId)) {
				return $scope.locationId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.EventSearchTab.location)) {
					return $scope.EventSearchTab.location.id;
				} else {
					return undefined;
				}
			}
		};

		// handle attribute startDate of javaType java.time.LocalDate
		$scope.processStartDate = function() {
			var startDateFromDefined = $rootScope.isDefinedAndNotNull($scope.EventSearchTab.startDateFrom);
			var startDateToDefined = $rootScope.isDefinedAndNotNull($scope.EventSearchTab.startDateTo);

			if (startDateFromDefined) {
				$scope.EventSearchTab.startDateFromString = $rootScope.convertDateWithoutZone($scope.EventSearchTab.startDateFrom);
			} else {
				$scope.EventSearchTab.startDateFromString = undefined;
			}

			if (startDateToDefined) {
				$scope.EventSearchTab.startDateToString = $rootScope.convertDateWithoutZone($scope.EventSearchTab.startDateTo);
			} else {
				$scope.EventSearchTab.startDateToString = undefined;
			}
		};

		// handle attribute exclusive of javaType boolean

		/**
		 * This function queries all entities of type TemplateEvent and checks if this Event dialog is called in the context of TemplateEvent.
		 * If this is the case, we detect the parent entity Event and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryTemplateEvent = function() {
			return NoPagingTemplateEventFactory.query(function(result) {
				return result;
			});
		};
		$scope.templateEventReferenceFilterList = $scope.queryTemplateEvent();

		/**
		 * This function queries all entities of type Location and checks if this Event dialog is called in the context of Location.
		 * If this is the case, we detect the parent entity Event and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryLocation = function() {
			return NoPagingLocationFactory.query(function(result) {
				return result;
			});
		};
		$scope.locationReferenceFilterList = $scope.queryLocation();
		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processStartDate();

			$rootScope.EventSearchTab = angular.copy($scope.EventSearchTab);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeEvent = angular.copy($scope.pageSize);

			FindAllEventsByQueryParamsFactory.query(
				{
					page: $scope.EventSearchTab.page - 1,
					size: $scope.pageSize,
					name: $scope.EventSearchTab.name,
					startDateFrom: $scope.EventSearchTab.startDateFromString,
					startDateTo: $scope.EventSearchTab.startDateToString,
					exclusive: $scope.EventSearchTab.exclusiveGroup,
					templateEventIdList: getTemplateEventIdList(),
					locationId: getLocationId(),
					status: $scope.EventSearchTab.status,

					sort: [$scope.EventSearchTab.predicate + ',' + ($scope.EventSearchTab.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.eventList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.updateTemplateEventUISelect = function(item, model) {
			$scope.search();
		};
		$scope.isEventSearchTabDirty = function() {
			return !angular.equals($scope.EventSearchTab, $scope.originalEventSearchTab);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.EventSearchTab = {};
			$rootScope.EventSearchTab.page = 1;
			$rootScope.EventSearchTab.predicate = 'startDate';
			$rootScope.EventSearchTab.ascending = true;

			$rootScope.EventSearchTab.startDateFrom = new Date();

			$scope.EventSearchTab = angular.copy($rootScope.EventSearchTab);

			$scope.originalEventSearchTab = angular.copy($scope.EventSearchTab);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.EventSearchTab) || $rootScope.EventSearchTab === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.EventSearchTab = angular.copy($rootScope.EventSearchTab);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedEventId = id;
			$('#deleteEventConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			EventFactory.delete(
				{ id: $scope.selectedEventId },
				function() {
					$scope.search();
					$('#deleteEventConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteEventConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('emsApp:eventUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.EventSearchTab.predicate === itemName) {
				if ($scope.EventSearchTab.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
