/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('EventTypeFactory', function($resource) {
	return $resource(
		'api/eventTypes/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewEventTypeFactory', function($resource) {
	return $resource(
		'api/eventTypes/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all EventTypes without the use of paging
angular.module('emsApp').factory('NoPagingEventTypeFactory', function($resource) {
	return $resource(
		'api/eventTypes/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('TemplateEventsByEventTypeFactory', function($resource) {
	return $resource(
		'api/templateEvents/eventType/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('SalesFilterEventTypesByEventTypeFactory', function($resource) {
	return $resource(
		'api/salesFilterEventTypes/eventType/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('emsApp').factory('SalesFilterFromSalesFilterEventTypePicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/salesFilterEventTypeList/AsSalesFilterList/eventType/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

angular.module('emsApp').factory('FindAllEventTypesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/eventTypes/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllEventTypesByQueryParamsNonPageableFactory', function($resource) {
	return $resource(
		'api/query/eventTypesNonPageable/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
