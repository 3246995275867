/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('BookingTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		BookingFactory,
		FindAllBookingsByQueryParamsFactory,
		AutocompleteEventsByQueryParamsFactory,
		AutocompleteRelationsByQueryParamsFactory,
		$translate,
		dialogs,
		EventSnelstartFactory,
		NoPagingBookingFactory,
		NoPagingEventFactory,
		NoPagingRelationFactory,
		NoPagingBookingStatusFactory,
		NoPagingConfirmationEmailStatusFactory,
		ConfirmationEmailManager
	) {
		$scope.confirmationStatuses = ConfirmationEmailManager.confirmationStatuses;
		$scope.bookingList = [];
		$scope.showSearchForm = true;
		// additional field defined in custom/jhipster/controller/overview/additional-fields.ftl
		$scope.selectedBookingList = [];

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeBooking)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeBooking);
		} else {
			$scope.pageSize = 20;
			// Custom hook for setting page size is implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			if ($scope.mappedBy !== 'event') {
				$rootScope.pageSizeBooking = angular.copy($scope.pageSize);
			}
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newBooking', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(booking) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editBooking', { bookingId: booking.id, mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		var getEventId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.eventId)) {
				return $scope.eventId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.BookingSearchTab.event)) {
					return $scope.BookingSearchTab.event.id;
				} else {
					return undefined;
				}
			}
		};

		var getClientId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.clientId)) {
				return $scope.clientId;
			} else {
				if ($rootScope.isDefinedAndNotNull($scope.BookingSearchTab.client)) {
					return $scope.BookingSearchTab.client.id;
				} else {
					return undefined;
				}
			}
		};

		// handle attribute bookingDate of javaType java.time.LocalDate
		$scope.processBookingDate = function() {
			var bookingDateFromDefined = $rootScope.isDefinedAndNotNull($scope.BookingSearchTab.bookingDateFrom);
			var bookingDateToDefined = $rootScope.isDefinedAndNotNull($scope.BookingSearchTab.bookingDateTo);

			if (bookingDateFromDefined) {
				$scope.BookingSearchTab.bookingDateFromString = $rootScope.convertDateWithoutZone($scope.BookingSearchTab.bookingDateFrom);
			} else {
				$scope.BookingSearchTab.bookingDateFromString = undefined;
			}

			if (bookingDateToDefined) {
				$scope.BookingSearchTab.bookingDateToString = $rootScope.convertDateWithoutZone($scope.BookingSearchTab.bookingDateTo);
			} else {
				$scope.BookingSearchTab.bookingDateToString = undefined;
			}
		};

		$scope.autocompleteEvents = function(val) {
			var params = {
				eventTypeAheadSearchString: val,
			};

			return AutocompleteEventsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		$scope.autocompleteRelations = function(val) {
			var params = {
				relationTypeAheadSearchString: val,
			};

			return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		NoPagingBookingStatusFactory.query(function(result) {
			$scope.bookingStatusList = result;
		});

		NoPagingConfirmationEmailStatusFactory.query(function(result) {
			$scope.confirmationEmailStatusList = result;
		});
		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processBookingDate();

			$rootScope.BookingSearchTab = angular.copy($scope.BookingSearchTab);

			// Custom hook for setting page size is implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			if ($scope.mappedBy !== 'event') {
				$rootScope.pageSizeBooking = angular.copy($scope.pageSize);
			}

			FindAllBookingsByQueryParamsFactory.query(
				{
					page: $scope.BookingSearchTab.page - 1,
					size: $scope.pageSize,
					bookingDateFrom: $scope.BookingSearchTab.bookingDateFromString,
					bookingDateTo: $scope.BookingSearchTab.bookingDateToString,
					eventId: getEventId(),
					clientId: getClientId(),
					status: $scope.BookingSearchTab.status,
					confirmationEmailStatus: $scope.BookingSearchTab.confirmationEmailStatus,

					sort: [$scope.BookingSearchTab.predicate + ',' + ($scope.BookingSearchTab.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.bookingList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isBookingSearchTabDirty = function() {
			return !angular.equals($scope.BookingSearchTab, $scope.originalBookingSearchTab);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.BookingSearchTab = {};
			$rootScope.BookingSearchTab.page = 1;
			$rootScope.BookingSearchTab.predicate = 'id';
			$rootScope.BookingSearchTab.ascending = false;

			$scope.BookingSearchTab = angular.copy($rootScope.BookingSearchTab);

			$scope.originalBookingSearchTab = angular.copy($scope.BookingSearchTab);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.BookingSearchTab) || $rootScope.BookingSearchTab === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.BookingSearchTab = angular.copy($rootScope.BookingSearchTab);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedBookingId = id;
			$('#deleteBookingConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			BookingFactory.delete(
				{ id: $scope.selectedBookingId },
				function() {
					$scope.search();
					$('#deleteBookingConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteBookingConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.bookingList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedBookingList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedBookingList = [];

			angular.forEach($scope.bookingList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedBookingList.push(value);
				}
			});
		};

		// additional actions added by custom/jhipster/controller/overview/additional-actions.ftl
		$scope.billEventBookings = function() {
			// get event from first booking
			console.log('number of selected bookings: ' + $scope.selectedBookingList.length);
			if ($scope.selectedBookingList.length > 0) {
				var event = $scope.selectedBookingList[0].event;
				var body = {
					eventId: event.id,
					bookingIdList: _.map($scope.selectedBookingList, 'id'),
				};
				EventSnelstartFactory.billEvent(
					body,
					function(result) {
						var dlgTitle = $translate.instant('event.detail.bill.title', {
							costCenter: event.costCenter,
						});
						var dlgMsg =
							'<div class="alert alert-info" role="alert">' +
							$translate.instant('event.detail.bill.ignore', {
								numberOfBookings: result.countIgnoredBookings,
							}) +
							'</div>';
						dlgMsg +=
							'<div class="alert alert-success" role="alert">' +
							$translate.instant('event.detail.bill.success', {
								numberOfBookings: result.countValidResults,
							}) +
							'</div>';
						if (result.hasErrors) {
							dlgMsg +=
								'<div class="alert alert-danger" role="alert">' +
								$translate.instant('event.detail.bill.warning', {
									numberOfBookings: result.countInvalidResults,
								}) +
								'</div>';
							angular.forEach(result.invalidBookingResults, function(invalidResult) {
								dlgMsg +=
									'<div class="alert alert-warning" role="alert">' +
									$translate.instant(invalidResult.errorKey) +
									' (' +
									invalidResult.bookingDisplayValue +
									')</div>';
							});
						}

						dialogs.notify(dlgTitle, dlgMsg).result.then(function() {
							$scope.loadPage();
						});
					},
					function(error) {
						console.error('A problem occurred when billing event ' + event.id, error);
						AlertService.error('event.detail.bill.error', {
							error: error,
						});
						$scope.loadPage();
					}
				);
			}
		};
		// EMS-1456 set pagesize to 500 if the tab is shown on the event page
		if ($scope.mappedBy === 'event') {
			$scope.pageSize = 500;
		}

		// end additional actions

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.BookingSearchTab.predicate === itemName) {
				if ($scope.BookingSearchTab.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.confirmationEmailDialog = function(bookingId) {
			ConfirmationEmailManager.confirmationEmailDialog(bookingId, null, function() {
				$scope.search();
			});
		};
	});
