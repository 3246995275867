/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('CarController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarFactory,
		FindAllCarsByQueryParamsFactory,
		AutocompleteRelationsByQueryParamsFactory,
		NoPagingCarFactory,
		NoPagingRelationFactory,
		NoPagingArticleFactory,
		NoPagingCarRentalTypeFactory,
		CarFindArticleFilteredByArticleForRentalFactory
	) {
		$scope.carList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCar)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCar);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeCar = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car.list.new');
		};

		$scope.goToEditState = function(car) {
			// this overview is NOT mapped, it is the main overview
			$state.go('car.list.edit', { carId: car.id });
		};

		var getOwnerId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarSearchOverview.owner)) {
				return $scope.CarSearchOverview.owner.id;
			} else {
				return undefined;
			}
		};

		var getArticleForRentalId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarSearchOverview.articleForRental)) {
				return $scope.CarSearchOverview.articleForRental.id;
			} else {
				return undefined;
			}
		};

		var getCarRentalTypeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarSearchOverview.carRentalType)) {
				return $scope.CarSearchOverview.carRentalType.id;
			} else {
				return undefined;
			}
		};

		// handle attribute readyForUse of javaType Boolean
		// handle attribute rental of javaType Boolean
		// handle attribute service of javaType Boolean

		$scope.autocompleteRelations = function(val) {
			var params = {
				relationTypeAheadSearchString: val,
			};

			return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		/**
		 * This function queries all entities of type ArticleForRental and checks if this Car dialog is called in the context of ArticleForRental.
		 * If this is the case, we detect the parent entity Car and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryArticleForRental = function() {
			return CarFindArticleFilteredByArticleForRentalFactory.query(function(result) {
				return result;
			});
		};
		$scope.articleForRentalReferenceFilterList = $scope.queryArticleForRental();

		/**
		 * This function queries all entities of type CarRentalType and checks if this Car dialog is called in the context of CarRentalType.
		 * If this is the case, we detect the parent entity Car and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryCarRentalType = function() {
			return NoPagingCarRentalTypeFactory.query(function(result) {
				return result;
			});
		};
		$scope.carRentalTypeReferenceFilterList = $scope.queryCarRentalType();
		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarSearchOverview = angular.copy($scope.CarSearchOverview);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeCar = angular.copy($scope.pageSize);

			FindAllCarsByQueryParamsFactory.query(
				{
					page: $scope.CarSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.CarSearchOverview.name,
					licensePlate: $scope.CarSearchOverview.licensePlate,
					readyForUse: $scope.CarSearchOverview.readyForUseGroup,
					rental: $scope.CarSearchOverview.rentalGroup,
					service: $scope.CarSearchOverview.serviceGroup,
					ownerId: getOwnerId(),
					articleForRentalId: getArticleForRentalId(),
					carRentalTypeId: getCarRentalTypeId(),

					sort: [$scope.CarSearchOverview.predicate + ',' + ($scope.CarSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarSearchOverviewDirty = function() {
			return !angular.equals($scope.CarSearchOverview, $scope.originalCarSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarSearchOverview = {};
			$rootScope.CarSearchOverview.page = 1;
			$rootScope.CarSearchOverview.predicate = 'id';
			$rootScope.CarSearchOverview.ascending = false;

			$scope.CarSearchOverview = angular.copy($rootScope.CarSearchOverview);

			$scope.originalCarSearchOverview = angular.copy($scope.CarSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarSearchOverview) || $rootScope.CarSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarSearchOverview = angular.copy($rootScope.CarSearchOverview);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarId = id;
			$('#deleteCarConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarFactory.delete(
				{ id: $scope.selectedCarId },
				function() {
					$scope.search();
					$('#deleteCarConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCarConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('emsApp:carUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarSearchOverview.predicate === itemName) {
				if ($scope.CarSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
