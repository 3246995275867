/** @format */

'use strict';

angular.module('emsApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'LocationTemplateEventArticle'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('locationtemplateeventarticle'); // main translation loaded for locationtemplateeventarticle
		$translatePartialLoader.addPart('predefinedarticletype'); // one to many reference from main entity
		$translatePartialLoader.addPart('location'); // one to many reference from main entity
		$translatePartialLoader.addPart('templateevent'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // one to many reference from main entity

		return $translate.refresh();
	};

	/**
	 * "private" function called by wrapper new and edit functions.
	 */
	var getModalState = function(url, isEditMode) {
		if (!angular.isDefined(url) || url === null) {
			url = '/new';
		}

		if (!angular.isDefined(isEditMode) || isEditMode === null) {
			// default mode is NEW, however this situation should and will be avoided by as this function should only be
			isEditMode = false;
		}

		var templateUrl;
		// The default assignment to templateUrl can be overridden in hook custom/jhipster/state/template_url/add_or_edit.ftl
		templateUrl = 'app/entities/locationtemplateeventarticle/locationtemplateeventarticle-dialog.html';

		return {
			/* new state for entity locationTemplateEventArticle */
			/* to access: ui-ref="locationtemplateeventarticle.list.new" */
			/* url: hostname/locationtemplateeventarticle/new */
			url: url,
			data: {
				authorities: ['ROLE_USER'],
				isModal: true,
			},
			params: {
				mappedBy: null,
				mappedByType: null,
				mappedById: null,
				dataModel: null, // this data model is set from the new relation state OR from the save and new logic
				mappedByDisplayValue: null,
				dataModelType: null, // this data model type is the name of the modelobject, lowercase

				hiddenParam: 'YES', // hides params from url
			},
			onEnter: [
				'$stateParams',
				'$state',
				'$uibModal',
				'$rootScope',
				function($stateParams, $state, $uibModal, $rootScope) {
					var navigateBack = true;

					$uibModal
						.open({
							templateUrl: templateUrl,
							controller: 'LocationTemplateEventArticleDialogController',
							size: 'lg',
							backdrop: 'static',
							resolve: {
								entity: [
									'LocationTemplateEventArticleFactory',
									'NewLocationTemplateEventArticleFactory',
									function(LocationTemplateEventArticleFactory, NewLocationTemplateEventArticleFactory) {
										// NOTE either this dialog was opened with the purpose of
										// - creating a new LocationTemplateEventArticle
										// - resuming the creation of a new new LocationTemplateEventArticle AFTER new instances were created of x..1 reference(s)

										// if dataModel is defined, we're coming back from a new created x..1 reference
										if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
											var model = $stateParams.dataModel;
											// reset 'remembered' values to prevent future misbehaviour
											$rootScope.previousStateParams.dataModel = null;
											$stateParams.dataModel = null;

											// instead of returning a new model, we return the updated model
											return model;
										} else {
											if (isEditMode) {
												// get the entity by its ID
												return LocationTemplateEventArticleFactory.get({ id: $stateParams.locationTemplateEventArticleId }).$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											} else {
												// get a new empty entity
												return NewLocationTemplateEventArticleFactory.get().$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											}
										}
									},
								],
								translatePartialLoader: [
									'$translate',
									'$translatePartialLoader',
									function($translate, $translatePartialLoader) {
										return getTranslations($translate, $translatePartialLoader);
									},
								],
							},
						})
						.result.then(function() {
							// process success result, if necessary
						})
						.catch(function(closeReason) {
							if (closeReason === 'createNew') {
								// only navigate back in history if the close reason allows us to
								navigateBack = false;
							}
						})
						.finally(function() {
							if (navigateBack) {
								$rootScope.back();
							}
						});
				},
			],
		};
	};

	/**
	 * Body for locationTemplateEventArticle new state
	 */
	function getNewState(url) {
		return getModalState(url, false);
	}

	/**
	 * Body for locationTemplateEventArticle edit state
	 */
	function getEditState(url) {
		return getModalState(url, true);
	}

	$stateProvider.state('locationtemplateeventarticle', {
		/* abstract state for entity locationTemplateEventArticle */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/locationtemplateeventarticle',
		data: {
			authorities: ['ROLE_USER'],
			pageTitle: 'LocationTemplateEventArticles',
			isModal: false,
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	$stateProvider.state('locationtemplateeventarticle.list', {
		/* list state for entity locationTemplateEventArticle */
		/* to access: ui-ref="locationtemplateeventarticle.list" */
		/* url: hostname/locationtemplateeventarticle/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/locationtemplateeventarticle/locationtemplateeventarticles.html',
				controller: 'LocationTemplateEventArticleController',
			},
		},
	});

	$stateProvider.state('locationtemplateeventarticle.detail', {
		/* detail state for entity locationTemplateEventArticle */
		/* to access: ui-sref="locationtemplateeventarticle.detail" */
		/* url: hostname/locationtemplateeventarticle/detail/:locationTemplateEventArticleId */
		url: '/detail/:locationTemplateEventArticleId',
		views: {
			'content@': {
				templateUrl: 'app/entities/locationtemplateeventarticle/locationtemplateeventarticle-detail.html',
				controller: 'LocationTemplateEventArticleDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'LocationTemplateEventArticleFactory',
				function($stateParams, LocationTemplateEventArticleFactory) {
					return LocationTemplateEventArticleFactory.get({ id: $stateParams.locationTemplateEventArticleId });
				},
			],
		},
	});

	/* url: http://<hostname>/locationtemplateeventarticle/list/new */
	$stateProvider.state('locationtemplateeventarticle.list.new', getNewState());
	/* url: http://<hostname>/locationtemplateeventarticle/list/:locationTemplateEventArticleId/edit */
	$stateProvider.state('locationtemplateeventarticle.list.edit', getEditState('/:locationTemplateEventArticleId/edit'));
	/* url: http://<hostname>/locationtemplateeventarticle/detail/:locationTemplateEventArticleId/edit */
	$stateProvider.state('locationtemplateeventarticle.detail.edit', getEditState('/edit'));
});
