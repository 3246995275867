/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('RelationMailGroupTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		RelationMailGroupFactory,
		FindAllRelationMailGroupsByQueryParamsFactory,
		AutocompleteRelationsByQueryParamsFactory,
		NoPagingRelationMailGroupFactory,
		NoPagingRelationFactory,
		NoPagingMailGroupFactory
	) {
		$scope.relationMailGroupList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.page = 1;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeRelationMailGroup)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeRelationMailGroup);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeRelationMailGroup = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newRelationMailGroup', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(relationMailGroup) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editRelationMailGroup', {
				relationMailGroupId: relationMailGroup.id,
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
			});
		};

		$scope.getRelationMailGroupList = function() {
			FindAllRelationMailGroupsByQueryParamsFactory.query(
				{
					relationId: angular.isDefined($scope.relationId) ? $scope.relationId : null,
					mailGroupId: angular.isDefined($scope.mailGroupId) ? $scope.mailGroupId : null,

					page: $scope.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.relationMailGroupList = result;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getRelationMailGroupList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedRelationMailGroupId = id;
			$('#deleteRelationMailGroupConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMailGroupFactory.delete(
				{ id: $scope.selectedRelationMailGroupId },
				function() {
					$scope.loadAll();
					$('#deleteRelationMailGroupConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteRelationMailGroupConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
