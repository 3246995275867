/** @format */

'use strict';

angular.module('emsApp').controller('MailGroupDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.mailGroup = entity;
	$scope.objectName = 'MailGroup';
	$scope.mailGroupId = $stateParams.mailGroupId;

	if (angular.isUndefined($rootScope.mailGroupActiveTab)) {
		//set the default here
		$rootScope.mailGroupActiveTab = 0;
	}

	$scope.goToEditState = function(mailGroup) {
		$state.go('mailgroup.detail.edit({mailGroupId:mailGroup.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:mailGroupUpdate', function(event, result) {
		$scope.mailGroup = result;
	});
});
