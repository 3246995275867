/** @format */

'use strict';

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('emsApp').directive('eventsOverviewMappedByTemplateevent', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/event/event-tab.html',
		controller: 'EventTabController',
		scope: {
			templateEventId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('emsApp').directive('userEventMappedByTemplateEvent', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/event/event-list-user.tab.html',
		controller: 'UserEventListDtoTabController',
		scope: {
			templateEventId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('emsApp').directive('salesfiltertemplateeventsOverviewMappedByTemplateevent', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/salesfiltertemplateevent/salesfiltertemplateevent-tab.html',
		controller: 'SalesFilterTemplateEventTabController',
		scope: {
			templateEventId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('emsApp').directive('userSalesFilterTemplateEventMappedByTemplateEvent', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/salesfiltertemplateevent/salesfiltertemplateevent-list-user.tab.html',
		controller: 'UserSalesFilterTemplateEventListDtoTabController',
		scope: {
			templateEventId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
