/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('EventTypeDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		EventTypeFactory,
		AlertService,
		NoPagingEventTypeFactory,
		SalesFilterFromSalesFilterEventTypePicklistUpdateFactory,
		NoPagingSalesFilterFactory
	) {
		$scope.eventType = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.eventType) && $rootScope.isDefinedAndNotNull($scope.eventType.id);
		};

		$scope.submitButtonIsActive = true;

		var getSelectedListItems = function(allItems, selectedItemList) {
			var itemIDs = [];
			angular.forEach(selectedItemList, function(value, key) {
				itemIDs.push(value.id);
			});

			var selectedItems = [];
			angular.forEach(allItems, function(value, key) {
				angular.forEach(itemIDs, function(value2, key2) {
					if (value.id === value2) {
						selectedItems.push(value);
					}
				});
			});

			return selectedItems;
		};

		var tempSelectedSalesFilterList = [];
		$scope.selectedSalesFilterList = [];

		$scope.getSelectedSalesFilterList = function() {
			SalesFilterFromSalesFilterEventTypePicklistUpdateFactory.query({ id: $stateParams.eventTypeId }, function(result) {
				//put all selected SalesFilter-items in a list
				angular.forEach(result, function(value, key) {
					tempSelectedSalesFilterList.push(value);
				});
				NoPagingSalesFilterFactory.query(function(result) {
					$scope.salesFilterPickListOptions = result;
					$scope.selectedSalesFilterList = getSelectedListItems(result, tempSelectedSalesFilterList);
				});
			});
		};

		$scope.getSalesFilterPickListOptions = function() {
			if ($stateParams.eventTypeId != null) {
				$scope.getSelectedSalesFilterList();
			} else {
				NoPagingSalesFilterFactory.query(function(result) {
					$scope.salesFilterPickListOptions = result;
				});
			}
		};

		$scope.getSalesFilterPickListOptions();

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:eventTypeUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving EventType failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;

			if ($scope.eventType.id != null) {
				EventTypeFactory.update(
					$scope.eventType,
					function(result) {
						$scope.eventType = result;

						if (
							!angular.isUndefined($scope.selectedSalesFilterList) &&
							angular.isArray($scope.selectedSalesFilterList) &&
							$scope.selectedSalesFilterList.length > 0
						) {
							SalesFilterFromSalesFilterEventTypePicklistUpdateFactory.update(
								{ id: result.id },
								$scope.selectedSalesFilterList,
								function(successResult) {
									onSaveFinishedCallback(successResult);
								},
								function(error) {}
							);
						} else {
							onSaveFinishedCallback(result);
						} // if
					},
					saveOrUpdateErrorCallback
				);
			} else {
				EventTypeFactory.save(
					$scope.eventType,
					function(result) {
						$scope.eventType = result;

						if (
							!angular.isUndefined($scope.selectedSalesFilterList) &&
							angular.isArray($scope.selectedSalesFilterList) &&
							$scope.selectedSalesFilterList.length > 0
						) {
							SalesFilterFromSalesFilterEventTypePicklistUpdateFactory.update(
								{ id: result.id },
								$scope.selectedSalesFilterList,
								function(successResult) {
									onSaveFinishedCallback(successResult);
								},
								function(error) {}
							);
						} else {
							onSaveFinishedCallback(result);
						} // if
					},
					saveOrUpdateErrorCallback
				);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};
	});
