/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('RelationMailGroupFactory', function($resource) {
	return $resource(
		'api/relationMailGroups/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewRelationMailGroupFactory', function($resource) {
	return $resource(
		'api/relationMailGroups/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all RelationMailGroups without the use of paging
angular.module('emsApp').factory('NoPagingRelationMailGroupFactory', function($resource) {
	return $resource(
		'api/relationMailGroups/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllRelationMailGroupsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/relationMailGroups/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
