/** @format */

'use strict';

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('emsApp').directive('articlesOverviewMappedByArticletype', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/article/article-tab.html',
		controller: 'ArticleTabController',
		scope: {
			articleTypeId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('emsApp').directive('userArticleMappedByArticleType', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/article/article-list-user.tab.html',
		controller: 'UserArticleListDtoTabController',
		scope: {
			articleTypeId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
