/** @format */

'use strict';

angular.module('emsApp').controller('ArticleTypeDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.articleType = entity;
	$scope.objectName = 'ArticleType';
	$scope.articleTypeId = $stateParams.articleTypeId;

	if (angular.isUndefined($rootScope.articleTypeActiveTab)) {
		//set the default here
		$rootScope.articleTypeActiveTab = 0;
	}

	$scope.goToEditState = function(articleType) {
		$state.go('articletype.detail.edit({articleTypeId:articleType.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:articleTypeUpdate', function(event, result) {
		$scope.articleType = result;
	});
});
