/** @format */

'use strict';

// Article has edit layout enabled. Edit layout template url: article-dialog.html
// Article has detail layout enabled. Detail layout template url: article-detail.html
angular.module('emsApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Article'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('article'); // main translation loaded for article
		$translatePartialLoader.addPart('vattype'); // one to many reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to many reference from main entity
		$translatePartialLoader.addPart('orderline'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlerelationprice'); // one to many reference from main entity

		return $translate.refresh();
	};

	/**
	 * "private" function called by wrapper new and edit functions.
	 */
	var getModalState = function(url, isEditMode) {
		if (!angular.isDefined(url) || url === null) {
			url = '/new';
		}

		if (!angular.isDefined(isEditMode) || isEditMode === null) {
			// default mode is NEW, however this situation should and will be avoided by as this function should only be
			isEditMode = false;
		}

		var templateUrl;
		// The default assignment to templateUrl can be overridden in hook custom/jhipster/state/template_url/add_or_edit.ftl
		templateUrl = 'app/entities/article/article-dialog.html';

		return {
			/* new state for entity article */
			/* to access: ui-ref="article.list.new" */
			/* url: hostname/article/new */
			url: url,
			data: {
				authorities: ['ROLE_USER'],
				isModal: true,
			},
			params: {
				mappedBy: null,
				mappedByType: null,
				mappedById: null,
				dataModel: null, // this data model is set from the new relation state OR from the save and new logic
				mappedByDisplayValue: null,
				dataModelType: null, // this data model type is the name of the modelobject, lowercase
				referredBy: null, // will be populated once this new state is triggered from another new state with + button
				hiddenParam: 'YES', // hides params from url
			},
			onEnter: [
				'$stateParams',
				'$state',
				'$uibModal',
				'$rootScope',
				'HistoryService',
				function($stateParams, $state, $uibModal, $rootScope, HistoryService) {
					var navigateBack = true;

					$uibModal
						.open({
							templateUrl: templateUrl,
							controller: 'ArticleDialogController',
							size: 'lg',
							backdrop: 'static',
							resolve: {
								entity: [
									'ArticleFactory',
									'NewArticleFactory',
									function(ArticleFactory, NewArticleFactory) {
										// NOTE either this dialog was opened with the purpose of
										// - creating a new Article
										// - resuming the creation of a new new Article AFTER new instances were created of x..1 reference(s)

										// if dataModel is defined, we're coming back from a new created x..1 reference
										if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
											var model = $stateParams.dataModel;
											// reset 'remembered' values to prevent future misbehaviour
											$rootScope.previousStateParams.dataModel = null;
											$stateParams.dataModel = null;

											// instead of returning a new model, we return the updated model
											return model;
										} else {
											if (isEditMode) {
												// get the entity by its ID
												return ArticleFactory.get({ id: $stateParams.articleId }).$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											} else {
												// get a new empty entity
												return NewArticleFactory.get().$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											}
										}
									},
								],
								translatePartialLoader: [
									'$translate',
									'$translatePartialLoader',
									function($translate, $translatePartialLoader) {
										return getTranslations($translate, $translatePartialLoader);
									},
								],
							},
						})
						.result.then(function(result) {
							// process success result, if necessary
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// set relation as reference in the referredBy data model
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;
							}
						})
						.catch(function(closeReason) {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// reset relation in referred data model object
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = null;
							}
							if (closeReason === 'createNew') {
								// only navigate back in history if the close reason allows us to
								navigateBack = false;
							}
						})
						.finally(function() {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
							}

							if (navigateBack) {
								$rootScope.back();
							}
						});
				},
			],
		};
	};

	/**
	 * Body for article new state
	 */
	function getNewState(url) {
		return getModalState(url, false);
	}

	/**
	 * Body for article edit state
	 */
	function getEditState(url) {
		return getModalState(url, true);
	}

	$stateProvider.state('article', {
		/* abstract state for entity article */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/article',
		data: {
			authorities: ['ROLE_USER'],
			pageTitle: 'Articles',
			isModal: false,
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	$stateProvider.state('article.list', {
		/* list state for entity article */
		/* to access: ui-ref="article.list" */
		/* url: hostname/article/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/articles.html',
				controller: 'ArticleController',
			},
		},
	});

	$stateProvider.state('article.detail', {
		/* detail state for entity article */
		/* to access: ui-sref="article.detail" */
		/* url: hostname/article/detail/:articleId */
		url: '/detail/:articleId',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-detail.html',
				controller: 'ArticleDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'ArticleFactory',
				function($stateParams, ArticleFactory) {
					return ArticleFactory.get({ id: $stateParams.articleId });
				},
			],
		},
	});

	/* url: http://<hostname>/article/list/new */
	$stateProvider.state('article.list.new', getNewState());
	/* url: http://<hostname>/article/list/:articleId/edit */
	$stateProvider.state('article.list.edit', getEditState('/:articleId/edit'));
	/* url: http://<hostname>/article/detail/:articleId/edit */
	$stateProvider.state('article.detail.edit', getEditState('/edit'));
	// ArticleType.article with multiplicity 0..n points to Article
	/* url: http://<hostname>/articletype/detail/:articleTypeId/article/new */
	$stateProvider.state('articletype.detail.newArticle', getNewState('/article/new'));
	/* url: http://<hostname>/articletype/detail/:articleTypeId/article/:articleId/edit */
	$stateProvider.state('articletype.detail.editArticle', getEditState('/article/:articleId/edit'));
	// Rare case of mixing non-dto's on a dto screen
	/* url: http://<hostname>/-articletype/detail/:articleTypeId/article/new */
	$stateProvider.state('-articletype.detail.newArticle', getNewState('/article/new'));
	/* url: http://<hostname>/-articletype/detail/:articleTypeId/article/:articleId/edit */
	$stateProvider.state('-articletype.detail.editArticle', getEditState('/article/:articleId/edit'));

	$stateProvider.state('templateevent.list.newArticle', getNewState('/article/new'));
	$stateProvider.state('subscription.list.newArticle', getNewState('/article/new'));
	$stateProvider.state('orderline.list.newArticle', getNewState('/article/new'));
	$stateProvider.state('locationtemplateeventarticle.list.newArticle', getNewState('/article/new'));
	$stateProvider.state('carrentaltypetemplateeventarticle.list.newArticle', getNewState('/article/new'));
	$stateProvider.state('articlerelationprice.list.newArticle', getNewState('/article/new'));

	$stateProvider.state('user-article', {
		/* abstract state for DTO UserArticleListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/user/article',
		data: {
			authorities: ['ROLE_USER', 'ROLE_ADMIN'],
			pageTitle: 'Article',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO user */
	var dtoListState = {
		/* list state for DTO UserArticleListDto */
		/* to access: ui-ref="user-article.list" */
		/* url: http://<hostname>/user/article/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-list-user.html',
				controller: 'UserArticleListDtoController',
			},
		},
	};

	$stateProvider.state('user-article.list', dtoListState);

	/* detail state for DTO user */
	var dtoDetailState = {
		/* detail state for DTO UserArticleViewDto */
		/* to access: ui-sref="user-article.detail" */
		/* url: http://<hostname>/user/article/detail/:articleId */
		url: '/detail/:articleId',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-view-user.html',
				controller: 'ArticleDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'UserArticleViewDtoFactory',
				function($stateParams, UserArticleViewDtoFactory) {
					return UserArticleViewDtoFactory.get({ id: $stateParams.articleId });
				},
			],
		},
	};

	$stateProvider.state('user-article.detail', dtoDetailState);

	/* edit state for DTO user */
	var dtoEditState = {
		/* edit state for DTO UserArticleEditDto */
		/* to access: ui-sref="user-article.edit" */
		/* url: http://<hostname>/user/article/edit/:articleId */
		url: '/edit/:articleId',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-edit-user.html',
				controller: 'UserArticleEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'UserArticleEditDtoFactory',
				function($stateParams, $rootScope, UserArticleEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return UserArticleEditDtoFactory.get({ id: $stateParams.articleId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('user-article.edit', dtoEditState);

	/* new (create) state for DTO user */
	var dtoCreateState = {
		/* new state for DTO UserArticleEditDto */
		/* to access: ui-sref="user-article.new" */
		/* url: http://<hostname>/user/article/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-edit-user.html',
				controller: 'UserArticleEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'UserArticleNewDtoFactory',
				function($stateParams, $rootScope, UserArticleNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return UserArticleNewDtoFactory.get({ id: $stateParams.articleId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('user-article.new', dtoCreateState);

	/* Bulk edit state for DTO user */
	var dtoBulkEditState = {
		/* Bulk edit state for DTO UserArticleEditDto */
		/* to access: ui-sref="user-article.bulkedit" */
		/* url: http://<hostname>/user/article/bulkedit */
		url: '/bulkedit',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-bulkedit-user.html',
				controller: 'UserArticleBulkEditDtoController',
			},
		},
		params: {
			selectedArticleList: [''],
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'UserArticleNewBulkEditDtoFactory',
				function($stateParams, $rootScope, UserArticleNewBulkEditDtoFactory) {
					return UserArticleNewBulkEditDtoFactory.get().$promise.then(function(result) {
						return result;
					});
				},
			],
		},
	};

	$stateProvider.state('user-article.bulkedit', dtoBulkEditState);

	// create state for plus button on TemplateEvent edit / new page for reference Article and dto property value user
	$stateProvider.state('user-templateevent.new.newArticle', getCreateState('/defaultdriver/new'));
	$stateProvider.state('user-templateevent.edit.newArticle', getCreateState('/defaultdriver/new'));
	// create state for plus button on Subscription edit / new page for reference Article and dto property value user
	$stateProvider.state('user-subscription.new.newArticle', getCreateState('/driver/new'));
	$stateProvider.state('user-subscription.edit.newArticle', getCreateState('/driver/new'));
	// create state for plus button on OrderLine edit / new page for reference Article and dto property value user
	$stateProvider.state('user-orderline.new.newArticle', getCreateState('/article/new'));
	$stateProvider.state('user-orderline.edit.newArticle', getCreateState('/article/new'));
	// create state for plus button on LocationTemplateEventArticle edit / new page for reference Article and dto property value user
	$stateProvider.state('user-locationtemplateeventarticle.new.newArticle', getCreateState('/article/new'));
	$stateProvider.state('user-locationtemplateeventarticle.edit.newArticle', getCreateState('/article/new'));
	// create state for plus button on CarRentalTypeTemplateEventArticle edit / new page for reference Article and dto property value user
	$stateProvider.state('user-carrentaltypetemplateeventarticle.new.newArticle', getCreateState('/articleforrental/new'));
	$stateProvider.state('user-carrentaltypetemplateeventarticle.edit.newArticle', getCreateState('/articleforrental/new'));
	// create state for plus button on ArticleRelationPrice edit / new page for reference Article and dto property value user
	$stateProvider.state('user-articlerelationprice.new.newArticle', getCreateState('/article/new'));
	$stateProvider.state('user-articlerelationprice.edit.newArticle', getCreateState('/article/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/article/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
