'use strict';

angular.module('emsApp').factory('ConfirmationEmailTemplateList', function ($resource) {
  return $resource(
    'api/booking/emailtemplatelist',
    {},
    {
      get: {
        method: 'POST',
        isArray: true,
      },
    }
  );
});

angular.module('emsApp').factory('BookingConfirmationEmailSender', function ($resource) {
  return $resource(
    'api/booking/sendconfirmationemail',
    {},
    {
      post: {
        method: 'POST',
				cache: true
      },
    }
  );
});



angular.module('emsApp').factory('SubscriptionConfirmationEmailSender', function ($resource) {
  return $resource(
    'api/subscription/sendconfirmationemail',
    {},
    {
      post: {
        method: 'POST',
				cache: true
      },
    }
  );
});


