/** @format */

'use strict';

angular.module('emsApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'UserType'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('usertype'); // main translation loaded for usertype
		$translatePartialLoader.addPart('predefinedusertype'); // one to many reference from main entity
		$translatePartialLoader.addPart('user'); // one to many reference from main entity

		return $translate.refresh();
	};

	/**
	 * "private" function called by wrapper new and edit functions.
	 */
	var getModalState = function(url, isEditMode) {
		if (!angular.isDefined(url) || url === null) {
			url = '/new';
		}

		if (!angular.isDefined(isEditMode) || isEditMode === null) {
			// default mode is NEW, however this situation should and will be avoided by as this function should only be
			isEditMode = false;
		}

		var templateUrl;
		// The default assignment to templateUrl can be overridden in hook custom/jhipster/state/template_url/add_or_edit.ftl
		templateUrl = 'app/entities/usertype/usertype-dialog.html';

		return {
			/* new state for entity userType */
			/* to access: ui-ref="usertype.list.new" */
			/* url: hostname/usertype/new */
			url: url,
			data: {
				authorities: ['ROLE_USER'],
				isModal: true,
			},
			params: {
				mappedBy: null,
				mappedByType: null,
				mappedById: null,
				dataModel: null, // this data model is set from the new relation state OR from the save and new logic
				mappedByDisplayValue: null,
				dataModelType: null, // this data model type is the name of the modelobject, lowercase
				referredBy: null, // will be populated once this new state is triggered from another new state with + button
				hiddenParam: 'YES', // hides params from url
			},
			onEnter: [
				'$stateParams',
				'$state',
				'$uibModal',
				'$rootScope',
				'HistoryService',
				function($stateParams, $state, $uibModal, $rootScope, HistoryService) {
					var navigateBack = true;

					$uibModal
						.open({
							templateUrl: templateUrl,
							controller: 'UserTypeDialogController',
							size: 'lg',
							backdrop: 'static',
							resolve: {
								entity: [
									'UserTypeFactory',
									'NewUserTypeFactory',
									function(UserTypeFactory, NewUserTypeFactory) {
										// NOTE either this dialog was opened with the purpose of
										// - creating a new UserType
										// - resuming the creation of a new new UserType AFTER new instances were created of x..1 reference(s)

										// if dataModel is defined, we're coming back from a new created x..1 reference
										if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
											var model = $stateParams.dataModel;
											// reset 'remembered' values to prevent future misbehaviour
											$rootScope.previousStateParams.dataModel = null;
											$stateParams.dataModel = null;

											// instead of returning a new model, we return the updated model
											return model;
										} else {
											if (isEditMode) {
												// get the entity by its ID
												return UserTypeFactory.get({ id: $stateParams.userTypeId }).$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											} else {
												// get a new empty entity
												return NewUserTypeFactory.get().$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											}
										}
									},
								],
								translatePartialLoader: [
									'$translate',
									'$translatePartialLoader',
									function($translate, $translatePartialLoader) {
										return getTranslations($translate, $translatePartialLoader);
									},
								],
							},
						})
						.result.then(function(result) {
							// process success result, if necessary
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// set relation as reference in the referredBy data model
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;
							}
						})
						.catch(function(closeReason) {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								// reset relation in referred data model object
								$stateParams.referredBy.data[$stateParams.referredBy.refName] = null;
							}
							if (closeReason === 'createNew') {
								// only navigate back in history if the close reason allows us to
								navigateBack = false;
							}
						})
						.finally(function() {
							if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
								HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
							}

							if (navigateBack) {
								$rootScope.back();
							}
						});
				},
			],
		};
	};

	/**
	 * Body for userType new state
	 */
	function getNewState(url) {
		return getModalState(url, false);
	}

	/**
	 * Body for userType edit state
	 */
	function getEditState(url) {
		return getModalState(url, true);
	}

	$stateProvider.state('usertype', {
		/* abstract state for entity userType */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/usertype',
		data: {
			authorities: ['ROLE_USER'],
			pageTitle: 'UserTypes',
			isModal: false,
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	$stateProvider.state('usertype.list', {
		/* list state for entity userType */
		/* to access: ui-ref="usertype.list" */
		/* url: hostname/usertype/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/usertype/usertypes.html',
				controller: 'UserTypeController',
			},
		},
	});

	$stateProvider.state('usertype.detail', {
		/* detail state for entity userType */
		/* to access: ui-sref="usertype.detail" */
		/* url: hostname/usertype/detail/:userTypeId */
		url: '/detail/:userTypeId',
		views: {
			'content@': {
				templateUrl: 'app/entities/usertype/usertype-detail.html',
				controller: 'UserTypeDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'UserTypeFactory',
				function($stateParams, UserTypeFactory) {
					return UserTypeFactory.get({ id: $stateParams.userTypeId });
				},
			],
		},
	});

	/* url: http://<hostname>/usertype/list/new */
	$stateProvider.state('usertype.list.new', getNewState());
	/* url: http://<hostname>/usertype/list/:userTypeId/edit */
	$stateProvider.state('usertype.list.edit', getEditState('/:userTypeId/edit'));
	/* url: http://<hostname>/usertype/detail/:userTypeId/edit */
	$stateProvider.state('usertype.detail.edit', getEditState('/edit'));

	$stateProvider.state('user.list.newUserType', getNewState('/usertype/new'));
});
