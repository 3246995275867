/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('InstructorController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		InstructorFactory,
		FindAllInstructorsByQueryParamsFactory,
		AutocompleteRelationsByQueryParamsFactory,
		AutocompleteEventsByQueryParamsFactory,
		NoPagingInstructorFactory,
		NoPagingRelationFactory,
		NoPagingEventFactory,
		NoPagingArticleFactory,
		NoPagingSubscriptionStatusFactory,
		InstructorFindArticleFilteredByHotelFactory
	) {
		$scope.instructorList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeInstructor)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeInstructor);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeInstructor = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('instructor.list.new');
		};

		$scope.goToEditState = function(instructor) {
			// this overview is NOT mapped, it is the main overview
			$state.go('instructor.list.edit', { instructorId: instructor.id });
		};

		var getRelationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.InstructorSearchOverview.relation)) {
				return $scope.InstructorSearchOverview.relation.id;
			} else {
				return undefined;
			}
		};

		var getEventId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.InstructorSearchOverview.event)) {
				return $scope.InstructorSearchOverview.event.id;
			} else {
				return undefined;
			}
		};

		var getHotelId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.InstructorSearchOverview.hotel)) {
				return $scope.InstructorSearchOverview.hotel.id;
			} else {
				return undefined;
			}
		};

		$scope.autocompleteRelations = function(val) {
			var params = {
				relationTypeAheadSearchString: val,
			};

			return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		$scope.autocompleteEvents = function(val) {
			var params = {
				eventTypeAheadSearchString: val,
			};

			return AutocompleteEventsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.InstructorSearchOverview = angular.copy($scope.InstructorSearchOverview);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeInstructor = angular.copy($scope.pageSize);

			FindAllInstructorsByQueryParamsFactory.query(
				{
					page: $scope.InstructorSearchOverview.page - 1,
					size: $scope.pageSize,
					description: $scope.InstructorSearchOverview.description,
					relationId: getRelationId(),
					eventId: getEventId(),
					hotelId: getHotelId(),
					status: $scope.InstructorSearchOverview.status,

					sort: [$scope.InstructorSearchOverview.predicate + ',' + ($scope.InstructorSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.instructorList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isInstructorSearchOverviewDirty = function() {
			return !angular.equals($scope.InstructorSearchOverview, $scope.originalInstructorSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.InstructorSearchOverview = {};
			$rootScope.InstructorSearchOverview.page = 1;
			$rootScope.InstructorSearchOverview.predicate = 'id';
			$rootScope.InstructorSearchOverview.ascending = false;

			$scope.InstructorSearchOverview = angular.copy($rootScope.InstructorSearchOverview);

			$scope.originalInstructorSearchOverview = angular.copy($scope.InstructorSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.InstructorSearchOverview) || $rootScope.InstructorSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.InstructorSearchOverview = angular.copy($rootScope.InstructorSearchOverview);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedInstructorId = id;
			$('#deleteInstructorConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			InstructorFactory.delete(
				{ id: $scope.selectedInstructorId },
				function() {
					$scope.search();
					$('#deleteInstructorConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteInstructorConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.InstructorSearchOverview.predicate === itemName) {
				if ($scope.InstructorSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
