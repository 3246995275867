/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('RelationFactory', function($resource) {
	return $resource(
		'api/relations/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of UserRelationViewDto
 */
angular.module('emsApp').factory('UserRelationViewDtoFactory', function($resource) {
	return $resource(
		'api/user/relation/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of UserRelationListDto's
 */
angular.module('emsApp').factory('UserRelationListDtoSearchFactory', function($resource) {
	return $resource(
		'api/user/relation',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/* A custom hook can be implemented here custom/jhipster/factory/create_custom_edit_factory.ftl */
/**
 * Factory to get / update an instance of UserRelationEditDto
 */
angular.module('emsApp').factory('UserRelationEditDtoFactory', function($resource) {
	return $resource(
		'api/user/relation/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of UserRelationEditDto
 * with defaults from server
 */
angular.module('emsApp').factory('UserRelationNewDtoFactory', function($resource) {
	return $resource(
		'api/user/relation/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/*
 * Factory to fetch a new object of UserRelationEditDto
 * without defaults from server, to use in bulkedit
 */
angular.module('emsApp').factory('UserRelationNewBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/user/relation/bulkedit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to bulkupdate an instance of UserRelationEditDto
 */
angular.module('emsApp').factory('UserRelationBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/user/relation/bulkedit',
		{},
		{
			update: {
				method: 'PUT',
			},
		}
	);
});

/**
 * Factory to save a new object of UserRelationEditDto
 * and returns a copy to create another one.
 */
angular.module('emsApp').factory('UserRelationEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/user/relation/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewRelationFactory', function($resource) {
	return $resource(
		'api/relations/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Relations without the use of paging
angular.module('emsApp').factory('NoPagingRelationFactory', function($resource) {
	return $resource(
		'api/relations/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible RelationTypes Enum values without the use of paging
angular.module('emsApp').factory('NoPagingRelationTypeFactory', function($resource) {
	return $resource(
		'api/relation/relationTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible RelationSources Enum values without the use of paging
angular.module('emsApp').factory('NoPagingRelationSourceFactory', function($resource) {
	return $resource(
		'api/relation/relationSources',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible Genders Enum values without the use of paging
angular.module('emsApp').factory('NoPagingGenderFactory', function($resource) {
	return $resource(
		'api/relation/genders',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ClientsOf Enum values without the use of paging
angular.module('emsApp').factory('NoPagingClientOfFactory', function($resource) {
	return $resource(
		'api/relation/clientsOf',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all users filtered by accountmanager
angular.module('emsApp').factory('RelationFindUserFilteredByAccountManagerFactory', function($resource) {
	return $resource(
		'api/relations/user/filteredby/accountmanager',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible LangKeys Enum values without the use of paging
angular.module('emsApp').factory('NoPagingLangKeyFactory', function($resource) {
	return $resource(
		'api/relation/langKeys',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('RelationMailGroupsByRelationFactory', function($resource) {
	return $resource(
		'api/relationMailGroups/relation/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('emsApp').factory('MailGroupFromRelationMailGroupPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/relationMailGroupList/AsMailGroupList/relation/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('BookingsByClientFactory', function($resource) {
	return $resource(
		'api/bookings/client/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('CarsByOwnerFactory', function($resource) {
	return $resource(
		'api/cars/owner/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('SubscriptionsByRelationFactory', function($resource) {
	return $resource(
		'api/subscriptions/relation/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('DocumentsByRelationFactory', function($resource) {
	return $resource(
		'api/documents/relation/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('InstructorsByRelationFactory', function($resource) {
	return $resource(
		'api/instructors/relation/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('EventRelationContactsByRelationFactory', function($resource) {
	return $resource(
		'api/eventRelationContacts/relation/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('ArticleRelationPricesByRelationFactory', function($resource) {
	return $resource(
		'api/articleRelationPrices/relation/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllRelationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/relations/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('AutocompleteRelationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/relations/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('AutocompleteUserRelationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/user/autocomplete/relations',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('RelationSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/relations/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
