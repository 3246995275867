/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('VatTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		VatFactory,
		FindAllVatsByQueryParamsFactory,
		NoPagingVatFactory,
		NoPagingVatTypeFactory
	) {
		$scope.vatList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.page = 1;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeVat)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeVat);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeVat = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newVat', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(vat) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editVat', { vatId: vat.id, mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getVatList = function() {
			FindAllVatsByQueryParamsFactory.query(
				{
					// Searching Vat by vatType supports multiple
					vatTypeList: angular.isDefined($scope.vatTypeId) ? [$scope.vatTypeId] : [],

					page: $scope.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.vatList = result;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getVatList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedVatId = id;
			$('#deleteVatConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			VatFactory.delete(
				{ id: $scope.selectedVatId },
				function() {
					$scope.loadAll();
					$('#deleteVatConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteVatConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
