/** @format */

'use strict';

angular.module('emsApp').controller('RelationMailGroupDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.relationMailGroup = entity;
	$scope.objectName = 'RelationMailGroup';
	$scope.relationMailGroupId = $stateParams.relationMailGroupId;

	if (angular.isUndefined($rootScope.relationMailGroupActiveTab)) {
		//set the default here
		$rootScope.relationMailGroupActiveTab = 0;
	}

	$scope.goToEditState = function(relationMailGroup) {
		$state.go('relationmailgroup.detail.edit({relationMailGroupId:relationMailGroup.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:relationMailGroupUpdate', function(event, result) {
		$scope.relationMailGroup = result;
	});
});
