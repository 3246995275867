/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('SubscriptionController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		SubscriptionFactory,
		FindAllSubscriptionsByQueryParamsFactory,
		AutocompleteRelationsByQueryParamsFactory,
		AutocompleteBookingsByQueryParamsFactory,
		AutocompleteCarsByQueryParamsFactory,
		NoPagingSubscriptionFactory,
		NoPagingSubscriptionTypeFactory,
		NoPagingRelationFactory,
		NoPagingBookingFactory,
		NoPagingCarFactory,
		NoPagingSubscriptionStatusFactory,
		NoPagingArticleFactory,
		NoPagingInstructorFactory,
		NoPagingTemplateEventFactory,
		SubscriptionFindArticleFilteredByDriverFactory,
		SubscriptionFindArticleFilteredBySecondDriverFactory,
		SubscriptionFindArticleFilteredBySpectatorFactory,
		SubscriptionFindArticleFilteredByInsuranceFactory,
		SubscriptionFindArticleFilteredByHotelFactory,
		SubscriptionFindArticleFilteredByFuelFactory,
		SubscriptionFindArticleFilteredByRentalCarFactory,
		SubscriptionFindArticleFilteredByInstructionFactory
	) {
		$scope.subscriptionList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeSubscription)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeSubscription);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeSubscription = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('subscription.list.new');
		};

		$scope.goToEditState = function(subscription) {
			// this overview is NOT mapped, it is the main overview
			$state.go('subscription.list.edit', { subscriptionId: subscription.id });
		};

		var getRelationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchOverview.relation)) {
				return $scope.SubscriptionSearchOverview.relation.id;
			} else {
				return undefined;
			}
		};

		var getBookingId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchOverview.booking)) {
				return $scope.SubscriptionSearchOverview.booking.id;
			} else {
				return undefined;
			}
		};

		var getCarId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchOverview.car)) {
				return $scope.SubscriptionSearchOverview.car.id;
			} else {
				return undefined;
			}
		};

		var getDriverId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchOverview.driver)) {
				return $scope.SubscriptionSearchOverview.driver.id;
			} else {
				return undefined;
			}
		};

		var getSecondDriverId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchOverview.secondDriver)) {
				return $scope.SubscriptionSearchOverview.secondDriver.id;
			} else {
				return undefined;
			}
		};

		var getSpectatorId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchOverview.spectator)) {
				return $scope.SubscriptionSearchOverview.spectator.id;
			} else {
				return undefined;
			}
		};

		var getInsuranceId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchOverview.insurance)) {
				return $scope.SubscriptionSearchOverview.insurance.id;
			} else {
				return undefined;
			}
		};

		var getHotelId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchOverview.hotel)) {
				return $scope.SubscriptionSearchOverview.hotel.id;
			} else {
				return undefined;
			}
		};

		var getFuelId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchOverview.fuel)) {
				return $scope.SubscriptionSearchOverview.fuel.id;
			} else {
				return undefined;
			}
		};

		var getRelationOfId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchOverview.relationOf)) {
				return $scope.SubscriptionSearchOverview.relationOf.id;
			} else {
				return undefined;
			}
		};

		var getInstructorId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchOverview.instructor)) {
				return $scope.SubscriptionSearchOverview.instructor.id;
			} else {
				return undefined;
			}
		};

		var getTrainingTypeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchOverview.trainingType)) {
				return $scope.SubscriptionSearchOverview.trainingType.id;
			} else {
				return undefined;
			}
		};

		var getRentalCarId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchOverview.rentalCar)) {
				return $scope.SubscriptionSearchOverview.rentalCar.id;
			} else {
				return undefined;
			}
		};

		var getInstructionId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubscriptionSearchOverview.instruction)) {
				return $scope.SubscriptionSearchOverview.instruction.id;
			} else {
				return undefined;
			}
		};

		$scope.autocompleteRelations = function(val) {
			var params = {
				relationTypeAheadSearchString: val,
			};

			return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		$scope.autocompleteBookings = function(val) {
			var params = {
				bookingTypeAheadSearchString: val,
			};

			return AutocompleteBookingsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.SubscriptionSearchOverview = angular.copy($scope.SubscriptionSearchOverview);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeSubscription = angular.copy($scope.pageSize);

			FindAllSubscriptionsByQueryParamsFactory.query(
				{
					page: $scope.SubscriptionSearchOverview.page - 1,
					size: $scope.pageSize,
					subscriptionType: $scope.SubscriptionSearchOverview.subscriptionType,
					relationId: getRelationId(),
					bookingId: getBookingId(),
					carId: getCarId(),
					status: $scope.SubscriptionSearchOverview.status,
					driverId: getDriverId(),
					secondDriverId: getSecondDriverId(),
					spectatorId: getSpectatorId(),
					insuranceId: getInsuranceId(),
					hotelId: getHotelId(),
					fuelId: getFuelId(),
					relationOfId: getRelationOfId(),
					instructorId: getInstructorId(),
					trainingTypeId: getTrainingTypeId(),
					rentalCarId: getRentalCarId(),
					instructionId: getInstructionId(),

					sort: [$scope.SubscriptionSearchOverview.predicate + ',' + ($scope.SubscriptionSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.subscriptionList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isSubscriptionSearchOverviewDirty = function() {
			return !angular.equals($scope.SubscriptionSearchOverview, $scope.originalSubscriptionSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.SubscriptionSearchOverview = {};
			$rootScope.SubscriptionSearchOverview.page = 1;
			$rootScope.SubscriptionSearchOverview.predicate = 'description';
			$rootScope.SubscriptionSearchOverview.ascending = true;

			$scope.SubscriptionSearchOverview = angular.copy($rootScope.SubscriptionSearchOverview);

			$scope.originalSubscriptionSearchOverview = angular.copy($scope.SubscriptionSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.SubscriptionSearchOverview) || $rootScope.SubscriptionSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.SubscriptionSearchOverview = angular.copy($rootScope.SubscriptionSearchOverview);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedSubscriptionId = id;
			$('#deleteSubscriptionConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			SubscriptionFactory.delete(
				{ id: $scope.selectedSubscriptionId },
				function() {
					$scope.search();
					$('#deleteSubscriptionConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteSubscriptionConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('emsApp:subscriptionUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.SubscriptionSearchOverview.predicate === itemName) {
				if ($scope.SubscriptionSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
