/** @format */

(function() {
	'use strict';

	angular.module('emsApp').factory('PasswordResetFinish', PasswordResetFinish);

	PasswordResetFinish.$inject = ['$resource'];

	function PasswordResetFinish($resource) {
		var service = $resource('api/account/reset-password/finish', {}, {});

		return service;
	}
})();
