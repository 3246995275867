/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('UserEventListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		EventFactory,
		UserEventListDtoSearchFactory,
		UserEventViewDtoFactory
	) {
		$scope.eventList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeEvent)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeEvent);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeEvent = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('user-event.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		var getTemplateEventId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.templateEventId)) {
				return $scope.templateEventId;
			} else if ($rootScope.isDefinedAndNotNull($scope.EventSearchTab.templateEventIdList)) {
				return $scope.EventSearchTab.templateEventIdList;
			} else {
				return undefined;
			}
		};

		var getLocationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.locationId)) {
				return $scope.locationId;
			} else if ($rootScope.isDefinedAndNotNull($scope.EventSearchTab.location)) {
				return $scope.EventSearchTab.location.id;
			} else {
				return undefined;
			}
		};

		// handle attribute startDate of javaType java.time.LocalDate
		$scope.processStartDate = function() {
			var startDateFromDefined = $rootScope.isDefinedAndNotNull($scope.EventSearchTab.startDateFrom);
			var startDateToDefined = $rootScope.isDefinedAndNotNull($scope.EventSearchTab.startDateTo);

			if (startDateFromDefined) {
				$scope.EventSearchTab.startDateFromString = $rootScope.convertDateWithoutZone($scope.EventSearchTab.startDateFrom);
			} else {
				$scope.EventSearchTab.startDateFromString = undefined;
			}

			if (startDateToDefined) {
				$scope.EventSearchTab.startDateToString = $rootScope.convertDateWithoutZone($scope.EventSearchTab.startDateTo);
			} else {
				$scope.EventSearchTab.startDateToString = undefined;
			}
		};

		// handle attribute exclusive of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processStartDate();

			$rootScope.EventSearchTab = angular.copy($scope.EventSearchTab);

			$rootScope.pageSizeEvent = angular.copy($scope.pageSize);

			UserEventListDtoSearchFactory.query(
				{
					page: $scope.EventSearchTab.page - 1,
					size: $scope.pageSize,
					name: $scope.EventSearchTab.name,
					startDateFrom: $scope.EventSearchTab.startDateFromString,
					startDateTo: $scope.EventSearchTab.startDateToString,
					exclusive: $scope.EventSearchTab.exclusiveGroup,
					templateEventIdList: getTemplateEventId(),
					locationId: getLocationId(),
					status: $scope.EventSearchTab.status,

					sort: [$scope.EventSearchTab.predicate + ',' + ($scope.EventSearchTab.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.eventList = result.items;

					$scope.templateEventReferenceFilterList = result.templateEventSearchOptions;

					$scope.locationReferenceFilterList = result.locationSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isEventSearchTabDirty = function() {
			return !angular.equals($scope.EventSearchTab, $scope.originalEventSearchTab);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.EventSearchTab = {};
			$rootScope.EventSearchTab.page = 1;
			$rootScope.EventSearchTab.predicate = 'startDate';
			$rootScope.EventSearchTab.ascending = true;

			$rootScope.EventSearchTab.startDateFrom = new Date();

			$scope.EventSearchTab = angular.copy($rootScope.EventSearchTab);

			$scope.originalEventSearchTab = angular.copy($scope.EventSearchTab);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.EventSearchTab) || $rootScope.EventSearchTab === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.EventSearchTab = angular.copy($rootScope.EventSearchTab);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedEventId = id;
			$('#deleteEventConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			UserEventViewDtoFactory.delete(
				{ id: $scope.selectedEventId },
				function() {
					$scope.search();
					$('#deleteEventConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteEventConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('emsApp:eventUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.EventSearchTab.predicate === itemName) {
				if ($scope.EventSearchTab.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateTemplateEventUISelect = function(item, model) {
			$scope.search();
		};
	});
