/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('emsApp')
	.controller('UserTemplateEventEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		UserTemplateEventEditDtoFactory,
		AlertService,
		HistoryService
	) {
		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.templateEvent = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.templateEvent['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.templateEvent) && $rootScope.isDefinedAndNotNull($scope.templateEvent.id);
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:templateEventUpdate', result);
			$scope.submitButtonIsActive = true;
			// process success result, if necessary
			if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
				console.log('as we came on this create new page from plus button we will update history');

				// set relation as reference in the referredBy data model
				$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;

				// update previous state
				HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
			}
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			UserTemplateEventEditDtoFactory.update($scope.templateEvent, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		// triggered when click a plus button, navigates to creating a new defaultDriver of type Article
		// once Article is saved, history is updated and the user automatically navigates back to this edit page for TemplateEvent
		$scope.createDefaultDriver = function() {
			var referredBy = {
				refName: 'defaultDriver',
				data: $scope.templateEvent,
			};

			// navigating to new defaultDriver will add this new templateevent state to the history stack
			$state.go($state.current.name + '.newArticle', { referredBy: referredBy }, { notify: true });
		};

		// triggered when click a plus button, navigates to creating a new defaultSecondDriver of type Article
		// once Article is saved, history is updated and the user automatically navigates back to this edit page for TemplateEvent
		$scope.createDefaultSecondDriver = function() {
			var referredBy = {
				refName: 'defaultSecondDriver',
				data: $scope.templateEvent,
			};

			// navigating to new defaultSecondDriver will add this new templateevent state to the history stack
			$state.go($state.current.name + '.newArticle', { referredBy: referredBy }, { notify: true });
		};

		// triggered when click a plus button, navigates to creating a new defaultSpectator of type Article
		// once Article is saved, history is updated and the user automatically navigates back to this edit page for TemplateEvent
		$scope.createDefaultSpectator = function() {
			var referredBy = {
				refName: 'defaultSpectator',
				data: $scope.templateEvent,
			};

			// navigating to new defaultSpectator will add this new templateevent state to the history stack
			$state.go($state.current.name + '.newArticle', { referredBy: referredBy }, { notify: true });
		};

		// triggered when click a plus button, navigates to creating a new defaultInsurance of type Article
		// once Article is saved, history is updated and the user automatically navigates back to this edit page for TemplateEvent
		$scope.createDefaultInsurance = function() {
			var referredBy = {
				refName: 'defaultInsurance',
				data: $scope.templateEvent,
			};

			// navigating to new defaultInsurance will add this new templateevent state to the history stack
			$state.go($state.current.name + '.newArticle', { referredBy: referredBy }, { notify: true });
		};

		// triggered when click a plus button, navigates to creating a new defaultHotel of type Article
		// once Article is saved, history is updated and the user automatically navigates back to this edit page for TemplateEvent
		$scope.createDefaultHotel = function() {
			var referredBy = {
				refName: 'defaultHotel',
				data: $scope.templateEvent,
			};

			// navigating to new defaultHotel will add this new templateevent state to the history stack
			$state.go($state.current.name + '.newArticle', { referredBy: referredBy }, { notify: true });
		};

		// triggered when click a plus button, navigates to creating a new defaultFuel of type Article
		// once Article is saved, history is updated and the user automatically navigates back to this edit page for TemplateEvent
		$scope.createDefaultFuel = function() {
			var referredBy = {
				refName: 'defaultFuel',
				data: $scope.templateEvent,
			};

			// navigating to new defaultFuel will add this new templateevent state to the history stack
			$state.go($state.current.name + '.newArticle', { referredBy: referredBy }, { notify: true });
		};

		// triggered when click a plus button, navigates to creating a new defaultInstruction of type Article
		// once Article is saved, history is updated and the user automatically navigates back to this edit page for TemplateEvent
		$scope.createDefaultInstruction = function() {
			var referredBy = {
				refName: 'defaultInstruction',
				data: $scope.templateEvent,
			};

			// navigating to new defaultInstruction will add this new templateevent state to the history stack
			$state.go($state.current.name + '.newArticle', { referredBy: referredBy }, { notify: true });
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};
	});
