/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('DocumentTypeController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		DocumentTypeFactory,
		FindAllDocumentTypesByQueryParamsFactory,
		NoPagingDocumentTypeFactory
	) {
		$scope.documentTypeList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.page = 1;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeDocumentType)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeDocumentType);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeDocumentType = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('documenttype.list.new');
		};

		$scope.goToEditState = function(documentType) {
			// this overview is NOT mapped, it is the main overview
			$state.go('documenttype.list.edit', { documentTypeId: documentType.id });
		};

		$scope.getDocumentTypeList = function() {
			FindAllDocumentTypesByQueryParamsFactory.query(
				{
					page: $scope.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.documentTypeList = result;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getDocumentTypeList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedDocumentTypeId = id;
			$('#deleteDocumentTypeConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			DocumentTypeFactory.delete(
				{ id: $scope.selectedDocumentTypeId },
				function() {
					$scope.loadAll();
					$('#deleteDocumentTypeConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteDocumentTypeConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
