/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('InstructorFactory', function($resource) {
	return $resource(
		'api/instructors/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewInstructorFactory', function($resource) {
	return $resource(
		'api/instructors/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Instructors without the use of paging
angular.module('emsApp').factory('NoPagingInstructorFactory', function($resource) {
	return $resource(
		'api/instructors/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by hotel
angular.module('emsApp').factory('InstructorFindArticleFilteredByHotelFactory', function($resource) {
	return $resource(
		'api/instructors/article/filteredby/hotel',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SubscriptionStatuses Enum values without the use of paging
angular.module('emsApp').factory('NoPagingSubscriptionStatusFactory', function($resource) {
	return $resource(
		'api/instructor/subscriptionStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('emsApp').factory('SubscriptionsByInstructorFactory', function($resource) {
	return $resource(
		'api/subscriptions/instructor/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllInstructorsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/instructors/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all instructors for an event by booking ID without paging
angular.module('emsApp').factory('NoPagingInstructorByBookingFactory', function($resource) {
	return $resource(
		'api/instructors/booking/:bookingId',
		{},
		{
			query: {
				method: 'GET',
				isArray: true,
			},
		}
	);
});
