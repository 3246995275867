/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('CarRentalTypeTemplateEventArticleFactory', function($resource) {
	return $resource(
		'api/carRentalTypeTemplateEventArticles/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewCarRentalTypeTemplateEventArticleFactory', function($resource) {
	return $resource(
		'api/carRentalTypeTemplateEventArticles/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarRentalTypeTemplateEventArticles without the use of paging
angular.module('emsApp').factory('NoPagingCarRentalTypeTemplateEventArticleFactory', function($resource) {
	return $resource(
		'api/carRentalTypeTemplateEventArticles/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

// find all articles filtered by articleforrental
angular.module('emsApp').factory('CarRentalTypeTemplateEventArticleFindArticleFilteredByArticleForRentalFactory', function($resource) {
	return $resource(
		'api/carrentaltypetemplateeventarticles/article/filteredby/articleforrental',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllCarRentalTypeTemplateEventArticlesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carRentalTypeTemplateEventArticles/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
