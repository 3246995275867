/**
 * /*
 *     Define filters.
 *     Template: jhipster4_ajs/app_filters.ftl
 *
 * @format
 */

(function() {
	'use strict';

	angular
		.module('emsApp')

		.filter('percentage', function() {
			// console.log('filter percentage');
			return function(input) {
				if (angular.isDefined(input) && input !== null) {
					return input + '%';
				} else {
					return '';
				}
			};
		})

		.filter('nullableDateTimeZone', function($filter) {
			//input is in ms in ami, so multiply by 1000 is wrong
			console.log('filter nullableDateTimeZone');
			var defaultDateFilterFn = $filter('date');

			return function(input) {
				if (angular.isDefined(input) && input !== null) {
					return defaultDateFilterFn(input, 'yyyy-MM-dd HH:mm:ss Z');
				} else {
					return '-';
				}
			};
		})

		.filter('nullableDateTimeNoZone', function($filter) {
			//input is in ms in ami, so multiply by 1000 is wrong
			// used for displaying commissioning date times
			var defaultDateFilterFn = $filter('date');

			return function(input) {
				if (angular.isDefined(input) && input !== null) {
					return defaultDateFilterFn(input, 'dd-MM-yyyy HH:mm:ss');
				} else {
					return '';
				}
			};
		})

		.filter('nullableDate', function($filter) {
			// {{input | date:'yyyy-MM-dd'}}
			var defaultDateFilterFn = $filter('date');

			return function(input) {
				if (angular.isDefined(input) && input !== null) {
					return defaultDateFilterFn(input, 'yyyy-MM-dd');
				} else {
					return '';
				}
			};
		})

		.filter('customNullableDateTime', function($filter) {
			// used for displaying commissioning date times
			// {{input * 1000 | date:'dd-MM HH:mm'}}
			var defaultDateFilterFn = $filter('date');

			return function(input, format) {
				var defaultFormat = 'yyyy-dd-MM HH:mm';
				if (angular.isDefined(format) && format !== null && format !== '') {
					defaultFormat = format;
				}
				if (angular.isDefined(input) && input !== null) {
					return defaultDateFilterFn(input * 1000, defaultFormat);
				} else {
					return '';
				}
			};
		})

		.filter('visibleLimitTo', function($filter) {
			// {{input | visibleLimitTo:100}}
			var defaultLimitToFilterFn = $filter('limitTo');

			return function(input, limit, begin) {
				if (angular.isDefined(input) && input !== null) {
					var result = defaultLimitToFilterFn(input, limit, begin);
					if (result.length < input.length) {
						result += ' ...';
					}
					return result;
				} else {
					return '';
				}
			};
		})

		.filter('suffix', function() {
			// {{input | suffix:'whatever'}}
			return function(input, suffix) {
				if (angular.isDefined(input) && input !== null) {
					if (angular.isDefined(suffix) && suffix !== null && suffix !== '') {
						return input + suffix;
					} else {
						return input;
					}
				} else {
					return '';
				}
			};
		})

		.filter('propsFilter', function() {
			return function(items, props) {
				var out = [];

				if (angular.isArray(items)) {
					items.forEach(function(item) {
						var itemMatches = false;

						var keys = Object.keys(props);
						for (var i = 0; i < keys.length; i++) {
							var prop = keys[i];
							var text = props[prop].toLowerCase();
							if (
								item[prop]
									.toString()
									.toLowerCase()
									.indexOf(text) !== -1
							) {
								itemMatches = true;
								break;
							}
						}

						if (itemMatches) {
							out.push(item);
						}
					});
				} else {
					// Let the output be the input untouched
					out = items;
				}

				return out;
			};
		});
})();
