/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('UserArticleListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ArticleFactory,
		UserArticleListDtoSearchFactory,
		UserArticleViewDtoFactory
	) {
		$scope.articleList = [];
		$scope.selectedArticleList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeArticle)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeArticle);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeArticle = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('user-article.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getArticleList = function() {
			UserArticleListDtoSearchFactory.query(
				{
					articleTypeId: angular.isDefined($scope.articleTypeId) ? $scope.articleTypeId : null,
					vatType: angular.isDefined($scope.vatTypeId) ? $scope.vatTypeId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.articleList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getArticleList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedArticleId = id;
			$('#deleteArticleConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			UserArticleViewDtoFactory.delete(
				{ id: $scope.selectedArticleId },
				function() {
					$scope.loadAll();
					$('#deleteArticleConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteArticleConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleUpdate = function() {
			$state.go('user-article.bulkedit', { selectedArticleList: $scope.selectedArticleList });
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.articleList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedArticleList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedArticleList = [];

			angular.forEach($scope.articleList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedArticleList.push(value);
				}
			});
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('emsApp:articleUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
