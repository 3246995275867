/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('CarDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		CarFactory,
		AlertService,
		NoPagingCarFactory,
		AutocompleteCarsByQueryParamsFactory,
		RelationFactory,
		NoPagingRelationFactory,
		AutocompleteRelationsByQueryParamsFactory,
		NoPagingArticleFactory,
		NoPagingCarRentalTypeFactory,
		CarSaveAndCreateFactory,
		CarFindArticleFilteredByArticleForRentalFactory
	) {
		$scope.car = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.car) && $rootScope.isDefinedAndNotNull($scope.car.id);
		};

		$scope.createOwner = function() {
			var referredBy = {
				refName: 'owner',
				data: $scope.car,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new owner will add this new car state to the history stack
				$state.go('car.list.newRelation', { referredBy: referredBy }, { reload: false });
			}, 100);
		};

		$scope.submitButtonIsActive = true;

		$scope.autocompleteRelations = function(val) {
			var params = {
				relationTypeAheadSearchString: val,
			};

			return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		/**
		 * Function to find an item in a list of items based on the parent id if this dialog is opened from a tabbed overview
		 */
		$scope.findItemByParent = function(items, property) {
			//This function preselects and disables the parent reference when called from tab
			if ($stateParams.mappedBy === property && $stateParams.mappedById !== undefined) {
				angular.forEach(items, function(value) {
					// value.id is a number, $stateParams.mappedById is a string, hence we cast the $stateParams.mappedById to a number so we can compare the id using the typesafe === operator
					if (value.id === _.toNumber($stateParams.mappedById)) {
						var model = $parse('car.' + property);
						model.assign($scope, value);
					}
				});
			}
		};

		/**
		 * This function preselects and disables the parent reference in autocomplete field when called from tab
		 */
		$scope.queryOwner = function() {
			// If the id of a preselected owner is available, then retrieve from backend and set in model
			if ($stateParams.mappedById === null) {
				return null;
			} else {
				return RelationFactory.get({ id: $stateParams.mappedById }, function(result) {
					if (result !== null) {
						$scope.findItemByParent([result], 'owner');
					}
				});
			}
		};
		$scope.ownerList = $scope.queryOwner();

		// --- start default implementation
		/**
		 * This function queries all entities of type ArticleForRental and checks if this Car dialog is called in the context of ArticleForRental.
		 * If this is the case, we detect the parent entity Car and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryArticleForRental = function() {
			return CarFindArticleFilteredByArticleForRentalFactory.query(function(result) {
				$scope.findItemByParent(result, 'articleForRental');
			});
		};
		$scope.articleForRentalList = $scope.queryArticleForRental();
		// --- end default implementation

		// --- start default implementation
		/**
		 * This function queries all entities of type CarRentalType and checks if this Car dialog is called in the context of CarRentalType.
		 * If this is the case, we detect the parent entity Car and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryCarRentalType = function() {
			return NoPagingCarRentalTypeFactory.query(function(result) {
				$scope.findItemByParent(result, 'carRentalType');
			});
		};
		$scope.carRentalTypeList = $scope.queryCarRentalType();
		// --- end default implementation

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:carUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving Car failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		var onSaveAndNewFinishedCallback = function(result) {
			$scope.$emit('emsApp:carUpdate', result);
			// the result contains a new entity, but with the copied values from the previous
			$scope.car = result;
			$scope.updateDropdownModels();
			$scope.submitButtonIsActive = true;
		};

		$scope.updateDropdownModels = function() {
			$scope.queryOwner();
			$scope.queryArticleForRental();
			$scope.queryCarRentalType();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;

			//check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
			if ($scope.car.owner === '') {
				$scope.car.owner = null;
			}

			if ($scope.car.id != null) {
				CarFactory.update($scope.car, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				CarFactory.save($scope.car, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			}
		};

		$scope.saveAndNew = function() {
			$scope.submitButtonIsActive = false;

			//check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
			if ($scope.car.owner === '') {
				$scope.car.owner = null;
			}

			if ($scope.car.id != null) {
				CarSaveAndCreateFactory.updateAndCreate($scope.car, onSaveAndNewFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				CarSaveAndCreateFactory.saveAndCreate($scope.car, onSaveAndNewFinishedCallback, saveOrUpdateErrorCallback);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};
	});
