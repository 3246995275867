/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('LocationController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		LocationFactory,
		FindAllLocationsByQueryParamsFactory,
		NoPagingLocationFactory,
		NoPagingCountryFactory
	) {
		$scope.locationList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.page = 1;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeLocation)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeLocation);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeLocation = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('location.list.new');
		};

		$scope.goToEditState = function(location) {
			// this overview is NOT mapped, it is the main overview
			$state.go('location.list.edit', { locationId: location.id });
		};

		$scope.getLocationList = function() {
			FindAllLocationsByQueryParamsFactory.query(
				{
					page: $scope.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.locationList = result;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getLocationList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedLocationId = id;
			$('#deleteLocationConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			LocationFactory.delete(
				{ id: $scope.selectedLocationId },
				function() {
					$scope.loadAll();
					$('#deleteLocationConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteLocationConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
