/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('UserEventListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		EventFactory,
		UserEventListDtoSearchFactory,
		UserEventViewDtoFactory
	) {
		$scope.eventList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeEvent)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeEvent);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeEvent = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('user-event.new');
		};

		var getLocationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.EventSearchOverview.location)) {
				return $scope.EventSearchOverview.location.id;
			} else {
				return undefined;
			}
		};

		// handle attribute startDate of javaType java.time.LocalDate
		$scope.processStartDate = function() {
			var startDateFromDefined = $rootScope.isDefinedAndNotNull($scope.EventSearchOverview.startDateFrom);
			var startDateToDefined = $rootScope.isDefinedAndNotNull($scope.EventSearchOverview.startDateTo);

			if (startDateFromDefined) {
				$scope.EventSearchOverview.startDateFromString = $rootScope.convertDateWithoutZone($scope.EventSearchOverview.startDateFrom);
			} else {
				$scope.EventSearchOverview.startDateFromString = undefined;
			}

			if (startDateToDefined) {
				$scope.EventSearchOverview.startDateToString = $rootScope.convertDateWithoutZone($scope.EventSearchOverview.startDateTo);
			} else {
				$scope.EventSearchOverview.startDateToString = undefined;
			}
		};

		// handle attribute exclusive of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processStartDate();

			$rootScope.EventSearchOverview = angular.copy($scope.EventSearchOverview);

			$rootScope.pageSizeEvent = angular.copy($scope.pageSize);

			UserEventListDtoSearchFactory.query(
				{
					page: $scope.EventSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.EventSearchOverview.name,
					startDateFrom: $scope.EventSearchOverview.startDateFromString,
					startDateTo: $scope.EventSearchOverview.startDateToString,
					exclusive: $scope.EventSearchOverview.exclusiveGroup,
					templateEventIdList: $scope.EventSearchOverview.templateEventIdList,
					locationId: getLocationId(),
					status: $scope.EventSearchOverview.status,

					sort: [$scope.EventSearchOverview.predicate + ',' + ($scope.EventSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.eventList = result.items;

					$scope.templateEventReferenceFilterList = result.templateEventSearchOptions;

					$scope.locationReferenceFilterList = result.locationSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isEventSearchOverviewDirty = function() {
			return !angular.equals($scope.EventSearchOverview, $scope.originalEventSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.EventSearchOverview = {};
			$rootScope.EventSearchOverview.page = 1;
			$rootScope.EventSearchOverview.predicate = 'startDate';
			$rootScope.EventSearchOverview.ascending = true;

			$rootScope.EventSearchOverview.startDateFrom = new Date();

			$scope.EventSearchOverview = angular.copy($rootScope.EventSearchOverview);

			$scope.originalEventSearchOverview = angular.copy($scope.EventSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.EventSearchOverview) || $rootScope.EventSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.EventSearchOverview = angular.copy($rootScope.EventSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedEventId = id;
			$('#deleteEventConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			UserEventViewDtoFactory.delete(
				{ id: $scope.selectedEventId },
				function() {
					$scope.search();
					$('#deleteEventConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteEventConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('emsApp:eventUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.EventSearchOverview.predicate === itemName) {
				if ($scope.EventSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateTemplateEventUISelect = function(item, model) {
			$scope.search();
		};
	});
