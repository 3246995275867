/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('ArticleTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ArticleFactory,
		FindAllArticlesByQueryParamsFactory,
		NoPagingArticleFactory,
		NoPagingArticleTypeFactory,
		NoPagingVatTypeFactory
	) {
		$scope.articleList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.page = 1;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeArticle)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeArticle);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeArticle = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.newArticle', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.goToEditState = function(article) {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go($state.current.name + '.editArticle', { articleId: article.id, mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getArticleList = function() {
			FindAllArticlesByQueryParamsFactory.query(
				{
					articleTypeId: angular.isDefined($scope.articleTypeId) ? $scope.articleTypeId : null,
					vatTypeId: angular.isDefined($scope.vatTypeId) ? $scope.vatTypeId : null,

					page: $scope.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.articleList = result;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getArticleList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedArticleId = id;
			$('#deleteArticleConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			ArticleFactory.delete(
				{ id: $scope.selectedArticleId },
				function() {
					$scope.loadAll();
					$('#deleteArticleConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteArticleConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('emsApp:articleUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
