/** @format */

'use strict';

angular.module('emsApp').controller('SalesFilterEventTypeDetailController', function($scope, $rootScope, $stateParams, $state, entity) {
	$scope.salesFilterEventType = entity;
	$scope.objectName = 'SalesFilterEventType';
	$scope.salesFilterEventTypeId = $stateParams.salesFilterEventTypeId;

	if (angular.isUndefined($rootScope.salesFilterEventTypeActiveTab)) {
		//set the default here
		$rootScope.salesFilterEventTypeActiveTab = 0;
	}

	$scope.goToEditState = function(salesFilterEventType) {
		$state.go(
			'salesfiltereventtype.detail.edit({salesFilterEventTypeId:salesFilterEventType.id, mappedBy:mappedBy, mappedById:mappedById})'
		);
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('emsApp:salesFilterEventTypeUpdate', function(event, result) {
		$scope.salesFilterEventType = result;
	});
});
