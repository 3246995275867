/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('RelationMailGroupDialogController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$uibModalInstance,
		$parse,
		entity,
		RelationMailGroupFactory,
		AlertService,
		NoPagingRelationMailGroupFactory,
		RelationFactory,
		NoPagingRelationFactory,
		AutocompleteRelationsByQueryParamsFactory,
		NoPagingMailGroupFactory
	) {
		$scope.relationMailGroup = entity;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.relationMailGroup) && $rootScope.isDefinedAndNotNull($scope.relationMailGroup.id);
		};

		$scope.createRelation = function() {
			var referredBy = {
				refName: 'relation',
				data: $scope.relationMailGroup,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new relation will add this new relationmailgroup state to the history stack
				$state.go('relationmailgroup.list.newRelation', { referredBy: referredBy }, { reload: false });
			}, 100);
		};
		$scope.createMailGroup = function() {
			var referredBy = {
				refName: 'mailGroup',
				data: $scope.relationMailGroup,
			};

			// dismiss the modal, however pass a reason to indicate that we should not navigate back by passing the reason of dismiss
			$uibModalInstance.dismiss('createNew');

			$timeout(function() {
				// we add a little timeout to prevent the modal dismiss not to be ready
				// navigating to new mailGroup will add this new relationmailgroup state to the history stack
				$state.go('relationmailgroup.list.newMailGroup', { referredBy: referredBy }, { reload: false });
			}, 100);
		};

		$scope.submitButtonIsActive = true;

		$scope.autocompleteRelations = function(val) {
			var params = {
				relationTypeAheadSearchString: val,
			};

			return AutocompleteRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		/**
		 * Function to find an item in a list of items based on the parent id if this dialog is opened from a tabbed overview
		 */
		$scope.findItemByParent = function(items, property) {
			//This function preselects and disables the parent reference when called from tab
			if ($stateParams.mappedBy === property && $stateParams.mappedById !== undefined) {
				angular.forEach(items, function(value) {
					// value.id is a number, $stateParams.mappedById is a string, hence we cast the $stateParams.mappedById to a number so we can compare the id using the typesafe === operator
					if (value.id === _.toNumber($stateParams.mappedById)) {
						var model = $parse('relationMailGroup.' + property);
						model.assign($scope, value);
					}
				});
			}
		};

		/**
		 * This function preselects and disables the parent reference in autocomplete field when called from tab
		 */
		$scope.queryRelation = function() {
			// If the id of a preselected relation is available, then retrieve from backend and set in model
			if ($stateParams.mappedById === null) {
				return null;
			} else {
				return RelationFactory.get({ id: $stateParams.mappedById }, function(result) {
					if (result !== null) {
						$scope.findItemByParent([result], 'relation');
					}
				});
			}
		};
		$scope.relationList = $scope.queryRelation();

		// --- start default implementation
		/**
		 * This function queries all entities of type MailGroup and checks if this RelationMailGroup dialog is called in the context of MailGroup.
		 * If this is the case, we detect the parent entity RelationMailGroup and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryMailGroup = function() {
			return NoPagingMailGroupFactory.query(function(result) {
				$scope.findItemByParent(result, 'mailGroup');
			});
		};
		$scope.mailGroupList = $scope.queryMailGroup();
		// --- end default implementation

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:relationMailGroupUpdate', result);
			$uibModalInstance.close(result);
			$scope.submitButtonIsActive = true;
		};

		var saveOrUpdateErrorCallback = function(error) {
			console.error('Saving RelationMailGroup failed', error);
			AlertService.error(error.data.message, error.data.params);
			$scope.submitButtonIsActive = true;
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;

			//check for autocomplete reference. When backspaced the reference equals an empty string, which should be converted into null before posting
			if ($scope.relationMailGroup.relation === '') {
				$scope.relationMailGroup.relation = null;
			}

			if ($scope.relationMailGroup.id != null) {
				RelationMailGroupFactory.update($scope.relationMailGroup, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				RelationMailGroupFactory.save($scope.relationMailGroup, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			// dismiss the modal will eventually trigger HistoryService.back() via $rootScope.back() as the finish result of the modal in the state JS file
			$uibModalInstance.dismiss('cancel');
		};
	});
